import IHeuristicsUpdateHandler from '../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/IHeuristicsUpdateHandler'
import {
  currentYear,
  pastYearDateRange
} from '../../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import IPersonalInfoPutter from '../../../APIs/PersonalInfoPutter/IPersonalInfoPutter'
import PersonalInfoPutDto from '../../../APIs/PersonalInfoPutter/PersonalInfoPutDto'
import {
  currentRoleHeuristicId,
  currentSituationHeuristicId,
  desiredRoleHeuristicId,
  educationHeuristicId,
  linkedInUrlHeuristicId,
  oteHeuristicId,
  pastOteHeuristicId,
  quotaHeuristicId,
  w2IncomeHeuristicId,
  yearsInSalesHeuristicId
} from '../../../localCommon/constant'
import {
  locationPicklistResponse,
  rolesPicklistResponse,
  yearGraduatedListId,
  yearsInSalesListId
} from '../Dtos/ValidPicklistResponseType'
import {
  currentOteFreeResponse,
  quotaFreeResponse
} from '../FreeResponse/Presenters/Interfaces/ValidFreeResponseType'
import IResponsePresenterStore from '../ResponsePresenterStore/IResponsePresenterStore'
import IPersonalInfoDataCollector from './IPersonalInfoDataCollector'

class PersonalInfoDataCollector implements IPersonalInfoDataCollector {
  constructor(
    private readonly presenterStore: IResponsePresenterStore,
    private readonly personalDataPutter: IPersonalInfoPutter,
    private readonly heuristicUpdater: IHeuristicsUpdateHandler
  ) {}

  public async submitPersonalInfoData(): Promise<string> {
    return this.personalDataPutter.sendPersonalInfo(this.getDto())
  }

  public async updateOnboardingHeuristicsValue(): Promise<void> {
    const factsHeuristicsToUpdate = [
      currentRoleHeuristicId,
      desiredRoleHeuristicId,
      educationHeuristicId,
      yearsInSalesHeuristicId
    ]

    for (let i = 0; i < factsHeuristicsToUpdate.length; i++) {
      const id = factsHeuristicsToUpdate[i]
      const value =
        this.presenterStore.getPresenterByHeuristicId(id)?.getValue().replace(/,/g, '') ?? ''

      // eslint-disable-next-line no-await-in-loop
      await this.heuristicUpdater.updateFactHeuristicsValue(id, value)
    }

    const linkedIn =
      this.presenterStore.getPresenterByHeuristicId(linkedInUrlHeuristicId)?.getValue() ?? ''
    const linkedInValueToStore = linkedIn.includes('linkedin.com/in/') ? linkedIn : ''
    await this.heuristicUpdater.updateFactHeuristicsValue(
      linkedInUrlHeuristicId,
      linkedInValueToStore
    )

    const currentMindset = this.presenterStore
      .getPresenterByHeuristicId(currentSituationHeuristicId)
      ?.getPromptValue()

    if (currentMindset) {
      await this.heuristicUpdater.updateFactHeuristicsValue(
        currentSituationHeuristicId,
        currentMindset
      )
    }

    const ote =
      this.presenterStore
        .getPresenterByHeuristicId(oteHeuristicId)
        ?.getValue()
        ?.replace(/,/g, '') ?? 0
    if (ote) {
      await this.heuristicUpdater.updateMeasureHeuristicsValue(
        oteHeuristicId,
        parseInt(ote, 10),
        currentYear
      )
    }

    const lastYearOte = this.presenterStore
      .getPresenterByHeuristicId(pastOteHeuristicId)
      ?.getValue()
      ?.replace(/,/g, '')
    if (lastYearOte) {
      await this.heuristicUpdater.updateMeasureHeuristicsValue(
        pastOteHeuristicId,
        parseInt(lastYearOte, 10),
        pastYearDateRange
      )
    }

    const quota = this.presenterStore
      .getPresenterByHeuristicId(quotaHeuristicId)
      ?.getValue()
      ?.replace(/,/g, '')
    if (quota) {
      await this.heuristicUpdater.updateMeasureHeuristicsValue(
        quotaHeuristicId,
        parseInt(quota, 10),
        currentYear
      )
    }

    const income = this.presenterStore
      .getPresenterByHeuristicId(w2IncomeHeuristicId)
      ?.getValue()
      ?.replace(/,/g, '')
    if (income) {
      await this.heuristicUpdater.updateMeasureHeuristicsValue(
        w2IncomeHeuristicId,
        parseInt(income, 10),
        currentYear
      )
    }
  }

  private getDto(): PersonalInfoPutDto {
    return {
      jobTitle: this.getJobTitle(),
      location: this.getLocation(),
      yearGraduated: this.getYearGraduated()
    }
  }

  private getQuota(): number {
    const presenter = this.presenterStore.getPresenterById(quotaFreeResponse)

    if (presenter) {
      return parseFloat(presenter.getValue()?.replace(/,/g, ''))
    }

    return 0
  }

  private getCurrentOte(): number {
    const presenter = this.presenterStore.getPresenterById(currentOteFreeResponse)

    if (presenter) {
      return parseFloat(presenter.getValue()?.replace(/,/g, ''))
    }

    return 0
  }

  private getYearGraduated(): string {
    const presenter = this.presenterStore.getPresenterById(yearGraduatedListId)

    if (presenter) {
      return presenter.getValue()
    }
    return ''
  }

  private getYearOfExperience(): string {
    const presenter = this.presenterStore.getPresenterById(yearsInSalesListId)

    if (presenter) {
      return presenter.getValue()
    }
    return ''
  }

  private getJobTitle(): string {
    const presenter = this.presenterStore.getPresenterById(rolesPicklistResponse)

    if (presenter) {
      return presenter.getValue()
    }

    return ''
  }

  private getLocation(): string {
    const presenter = this.presenterStore.getPresenterById(locationPicklistResponse)

    if (presenter) {
      return presenter.getValue()
    }

    return ''
  }
}

export default PersonalInfoDataCollector
