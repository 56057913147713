import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import AssessmentBars from '../PersonalInfoData/CoreAssessments/AssessmentBars/AssessmentBars'
import IAssessmentResultPresenter from '../PersonalInfoData/Dashboard/AssessmentResult/Presenter/IAssessmentResultPresenter'

type Props = {
  presenter: IAssessmentResultPresenter
  title?: string
}

class CandidateAssessmentView extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter, title } = this.props
    return (
      <div className='candidate-assessment-view'>
        <div className='coreAssessments section-with-border'>
          <p className='coreAssessments-title-shareable'>{title ?? 'Assessment Scores'}</p>
          <AssessmentBars presenter={presenter} shouldShowDropdown={false} />
        </div>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateAssessmentView
