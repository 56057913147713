import IFetcher from '../../Drivers/Interfaces/IFetcher'
import PersonalInfoDto from '../Dtos/PersonalInfoDto'
import { PERSONAL_INFO_URL } from '../PersonalInfoPutter/PersonalInfoPutter'
import IPersonalInfoGetter from './IPersonalInfoGetter'

export const failedToGetPersonalInfoMessage = 'Failed to get personal data. Please try again.'
class PersonalInfoGetter implements IPersonalInfoGetter {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getUserInfoData(): Promise<string | PersonalInfoDto> {
    const result = await this.fetcher.fetch({
      body: null,
      method: 'GET',
      url: `${PERSONAL_INFO_URL}${this.userId}`
    })

    if (this.isValidPersonalInfoData(result)) {
      return result
    }

    return failedToGetPersonalInfoMessage
  }

  private isValidPersonalInfoData(obj: any): obj is PersonalInfoDto {
    return (
      obj &&
      obj.hasOwnProperty('email') &&
      obj.hasOwnProperty('graduationYear') &&
      obj.hasOwnProperty('location') &&
      obj.hasOwnProperty('name')
    )
  }
}

export default PersonalInfoGetter
