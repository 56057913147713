import './StyleSheets/CandidateJobPreferencesCard.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import ICandidateJobPreferencesCardPresenter from './InterfacesAndDtos/ICandidateJobPreferencesCardPresenter'

type Props = {
  presenter: ICandidateJobPreferencesCardPresenter
}

class CandidateJobPreferencesCard extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    const location = presenter.getCandidateLocation()
    const preferences = presenter.getJobPreferences()
    const isShareMode = presenter.isInShareMode()

    return (
      <div className='candidate-job-preferences-card'>
        <div className='location-label'>
          <span>Location</span>
        </div>
        <div className='location-value'>
          <span>{location}</span>
        </div>

        <hr className='location-divider' />
        <div className='preferences'>
          {preferences.map((preference, index) => (
            <div className='preference-row' key={index}>
              <span>{preference.label}</span>
              <div className='preference-toggle'>
                <span className={`preference-status ${preference.isChecked ? 'yes' : 'no'}`}>
                  {preference.isChecked ? 'Yes' : 'No'}
                </span>
                {!isShareMode && (
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={preference.isChecked}
                      onChange={(_) =>
                        this.handleToggle(Number.parseInt(preference.id, 10), preference.isChecked)
                      }
                    />
                    <span className='slider round' />
                  </label>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  handleToggle = (heuristicId: number, isChecked: boolean): void => {
    this.props.presenter.updateJobPreference(heuristicId, isChecked ? 'No' : 'Yes')
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateJobPreferencesCard
