import Cookies from 'js-cookie'

import HeuristicDto from '../../APIs/Dtos/HeuristicDto'
import { factHeuristicType, measureHeuristicType } from '../../APIs/Dtos/ValidHeuristicTypes'
import IHeuristicsUpdateHandler from '../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/IHeuristicsUpdateHandler'
import { currentYear } from '../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import IFetcher from '../../Drivers/Interfaces/IFetcher'
import NonSerializableFetcher from '../../Drivers/NonSerializableFetcher'
import IWebPageToFileConverter from '../../Handlers/WebPageToFileConverters/IWebPageToFileConverter'
import images from '../../images'
import {
  averageDealLengthHeuristicId,
  averageDealSizeHeuristicId,
  cumulativeGpaHeuristicId,
  inboundPipeGenPercentageHeuristicId,
  linkedInUrlHeuristicId,
  oteHeuristicId,
  outboundPipeGenPercentageHeuristicId,
  quotaAttainmentHeuristicId,
  quotaHeuristicId,
  selfSourcedDealWinRateHeuristicId,
  serverBaseUrl,
  w2IncomeHeuristicId,
  willingToHybridHeuristicId,
  willingToRelocateHeuristicId,
  willingToWorkInOfficeHeuristicId,
  willingToWorkRemotelyHeuristicId,
  winRateHeuristicId
} from '../../localCommon/constant'
import { userIdKey } from '../../localCommon/CookieKeys'
import DateHelper from '../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import INumberFormatter from '../../localCommon/NumberFormatter/INumberFormatter'
import ValidHeuristicUnit from '../../localCommon/ValidHeuristicUnit'
import HeuristicValuesDto from '../CandidatePool/Candidate/InterfacesAndDtos/HeuristicValuesDto'
import CheckBoxDto from '../CandidatePool/Filter/InterfacesAndDtos/CheckBoxDto'
import AssessmentPromptResponseDto from '../CandidatePool/Presenter/InterfacesAndDtos/AssessmentPromptResponseDto'
import CandidatePoolDto from '../CandidatePool/Presenter/InterfacesAndDtos/CandidatePoolDto'
import IResumeUploadPresenter from '../EditAccount/Resume/Presenter/IResumeUploadPresenter'
import ResumeUploadPresenter from '../EditAccount/Resume/Presenter/ResumeUploadPresenter'
import JobDto from '../Jobs/Dtos/JobDto'
import IAssessmentResultPresenter from '../PersonalInfoData/Dashboard/AssessmentResult/Presenter/IAssessmentResultPresenter'
import AssessmentDetailsDto from './InterfacesAndDtos/AssessmentDetailsDto'
import CandidateNoteDto from './InterfacesAndDtos/CandidateNoteDto'
import HeuristicDataViewDto from './InterfacesAndDtos/HeuristicDataViewDto'
import HeuristicValueAndNameDto from './InterfacesAndDtos/HeuristicValueAndNameDto'
import ICandidateHeuristicDataPresenter from './InterfacesAndDtos/ICandidateHeuristicDataPresenter'
import ICandidateJobPreferencesCardPresenter from './InterfacesAndDtos/ICandidateJobPreferencesCardPresenter'
import ICandidateNotesPresenter from './InterfacesAndDtos/ICandidateNotesPresenter'
import ICandidateQuestionnaireCardPresenter from './InterfacesAndDtos/ICandidateQuestionnaireCardPresenter'
import ICandidateSummaryOverviewPresenter from './InterfacesAndDtos/ICandidateSummaryOverviewPresenter'
import ICandidateSummaryPresenter from './InterfacesAndDtos/ICandidateSummaryPresenter'
import JobRequirementPromptsAndValues from './InterfacesAndDtos/JobRequirementPromptValues'
import NewCandidateNoteDto from './InterfacesAndDtos/NewCandidateNoteDto'
import QuestionnaireDropDownDto from './InterfacesAndDtos/QuestionnaireDropDownDto'
import UserJobRequirementPromptsAndValues from './InterfacesAndDtos/UserJobRequirementPromptsAndValues'

interface NewValueAndHeuristicIdDto {
  heuristicId: number
  newValue: string
}
const emptyHeuristicValue = '-'
const scoreThresholds = [
  {
    score: 4.1,
    label: 'Excellent',
    percentileStr: '(top 5% of passing candidates)'
  },
  {
    score: 4,
    label: 'Great',
    percentileStr: '(top 15% of passing candidates)'
  },
  {
    score: 3.9,
    label: 'Strong',
    percentileStr: '(top 30% of passing candidates)'
  },
  {
    score: 3.8,
    label: 'Above Average',
    percentileStr: '(top 40% of passing candidates)'
  },
  {
    score: 0,
    label: 'Proficient',
    percentileStr: '(top 50% of passing candidates)'
  }
]

class CandidateSummaryPresenter
  implements
    ICandidateSummaryPresenter,
    ICandidateSummaryOverviewPresenter,
    ICandidateHeuristicDataPresenter,
    ICandidateJobPreferencesCardPresenter,
    ISubscribingView,
    ICandidateQuestionnaireCardPresenter,
    ICandidateNotesPresenter
{
  private readonly defaultHeuristicIds: number[]
  private readonly heuristicIdsEligibleForCandidateView: number[]
  private views: ISubscribingView[]
  private loading: boolean
  private candidate: CandidatePoolDto | null
  private resumeFileName: string | null
  private heuristicIdToValue: Map<number, HeuristicValueAndNameDto>
  private heuristicIdToNewValue: Map<number, string>
  private heuristicIdToDto: Map<number, HeuristicDto>
  private isCardExpanded: boolean
  private heuristicEditMode: boolean
  private editModeQuestionnaire: boolean
  private assessment: AssessmentDetailsDto | null
  private questionnaires: UserJobRequirementPromptsAndValues | null
  private questionnaireIdxToPromptIdToNewValue: Map<number, Map<number, NewValueAndHeuristicIdDto>>
  private viewAssessmentResponsesView: boolean
  private candidateNote: CandidateNoteDto | null
  private candidateNewNote: string | null
  private shareMode: boolean
  private includeNotesInProfile: boolean
  private questionnaireJobId: number | null
  private jobIdToDto: Map<number, JobDto>

  constructor(
    private readonly userId: number,
    private readonly fetcher: IFetcher,
    private readonly heuristicValueUpdater: IHeuristicsUpdateHandler,
    private readonly numberFormatter: INumberFormatter,
    private readonly assessmentPresenter: IAssessmentResultPresenter,
    private readonly pageToFileConverter: IWebPageToFileConverter,
    private readonly isRecruiterUser: boolean
  ) {
    this.loading = false
    this.isCardExpanded = false
    this.heuristicEditMode = false
    this.shareMode = false
    this.includeNotesInProfile = true
    this.views = []
    this.candidate = null
    this.assessment = null
    this.resumeFileName = null
    this.questionnaires = null
    this.editModeQuestionnaire = false
    this.candidateNote = null
    this.questionnaireJobId = null
    this.viewAssessmentResponsesView = false
    this.heuristicIdToValue = new Map()
    this.heuristicIdToNewValue = new Map()
    this.heuristicIdToDto = new Map()
    this.questionnaireIdxToPromptIdToNewValue = new Map()
    this.candidateNewNote = null
    this.jobIdToDto = new Map()

    this.defaultHeuristicIds = [
      w2IncomeHeuristicId,
      oteHeuristicId,
      quotaHeuristicId,
      quotaAttainmentHeuristicId,
      averageDealSizeHeuristicId,
      averageDealLengthHeuristicId
    ]
    this.heuristicIdsEligibleForCandidateView = [
      ...this.defaultHeuristicIds,
      cumulativeGpaHeuristicId,
      inboundPipeGenPercentageHeuristicId,
      outboundPipeGenPercentageHeuristicId,
      winRateHeuristicId,
      selfSourcedDealWinRateHeuristicId
    ]
  }

  public update(): void {
    this.updateViews()
  }

  public async initialize(): Promise<void> {
    this.loading = true
    this.updateViews()

    try {
      this.resetNewHeuristicValues()
      await Promise.all([
        this.setCandidateDto(),
        this.setResume(),
        this.setHeuristics(),
        this.getQuestionnaires(),
        this.setCandidateNote(),
        this.getJobs(),
        this.assessmentPresenter.initialize()
      ])
    } catch (error) {
      console.error(error)
    }

    this.loading = false
    this.updateViews()
  }

  private async getJobs(): Promise<void> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}jobs`,
      body: null
    })
    if (Array.isArray(result)) {
      result.forEach((dto) => this.jobIdToDto.set(dto.id, dto))
    }
  }

  private async setCandidateNote(): Promise<void> {
    const candidateNote = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}users/${this.userId}/candidateNotes`,
      body: null
    })
    if (candidateNote) {
      this.candidateNote = candidateNote
    }
  }

  private async setCandidateDto(): Promise<void> {
    const candidate = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}candidates/${this.userId}`,
      body: null
    })
    if (candidate) {
      this.candidate = candidate
      if (this.candidate?.assessments?.length) {
        await this.getAssessmentDetails(this.candidate.assessments[0].assessmentId)
      }
    }
  }

  private async setResume(): Promise<void> {
    const response = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/resume/name`,
      body: null
    })
    if (response?.fileName) {
      this.resumeFileName = response.fileName
    }
  }

  private async getAssessmentDetails(assessmentId: number): Promise<void> {
    const assessment = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}assessment/${assessmentId}`,
      body: null
    })
    if (assessment) {
      this.assessment = assessment
    }
  }

  public getResumeName(): string | null {
    return this.resumeFileName
  }

  public handleResumeClick(): void {
    if (this.resumeFileName) {
      this.fetcher.fetch({
        method: 'GET',
        url: `${serverBaseUrl}user/${this.userId}/resume`,
        body: null,
        headers: {
          'Content-Type': `application/${this.getExt()}`
        }
      })
    }
  }

  private async setHeuristics(): Promise<void> {
    const heuristicValues = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/heuristics`,
      body: null
    })

    const heuristics = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}heuristics`,
      body: null
    })
    if (
      Array.isArray(heuristicValues?.facts) &&
      Array.isArray(heuristicValues?.measures) &&
      Array.isArray(heuristics)
    ) {
      this.setHeuristicValueMap(heuristicValues, heuristics)
    }
  }

  private async getQuestionnaires(): Promise<void> {
    const questionnaires = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}users/${this.userId}/questionnaires`,
      body: null
    })

    if (questionnaires?.userId === this.userId) {
      this.questionnaires = questionnaires
    }
  }

  private setHeuristicValueMap(values: HeuristicValuesDto, heuristics: HeuristicDto[]): void {
    heuristics.forEach((heuristic) => {
      this.heuristicIdToDto.set(heuristic.id, heuristic)
    })

    values.facts.forEach((fact) => {
      const dto = this.heuristicIdToDto.get(fact.heuristicId)

      if (dto) {
        this.heuristicIdToValue.set(fact.heuristicId, {
          ...dto,
          value: fact.value
        })
      }
    })

    values.measures.forEach((measure) => {
      const dto = this.heuristicIdToDto.get(measure.heuristicId)
      if (dto) {
        this.heuristicIdToValue.set(measure.heuristicId, {
          ...dto,
          value: measure.value
        })
      }
    })
  }

  private getExt(): string {
    const splits = this.resumeFileName?.split('.')
    return splits?.[splits.length - 1] ?? ''
  }

  public getCandidateEmail(): string {
    return this.candidate?.email ?? 'No email'
  }

  public getCandidateImage(): string {
    if (this.shareMode) {
      return images.candidate
    }
    if (this.candidate?.profilePicture?.name) {
      return `${serverBaseUrl}profile/${this.candidate?.profilePicture?.name}`
    }
    return images.candidate
  }

  public getCandidateLocation(): string {
    return this.candidate?.location ?? 'No Location Specified'
  }

  public getCandidateName(): string {
    if (this.shareMode) {
      return `Candidate: ${this.candidate?.userId}`
    }
    return this.candidate?.name ?? 'No Name Entered'
  }

  public getCandidateRoleInterestedIn(): string {
    return this.candidate?.roleInterestedIn ?? 'Not Specified'
  }

  public getResumePresenter(): IResumeUploadPresenter {
    return new ResumeUploadPresenter(new NonSerializableFetcher())
  }

  public isLoading(): boolean {
    return this.loading
  }

  public clearView(): void {
    this.views = []
  }

  public getLinkedInUrl(): string | null {
    const value = this.heuristicIdToValue.get(linkedInUrlHeuristicId)?.value
    if (value !== undefined && value !== null) {
      return String(value)
    }
    return this.isRecruiterUser ? 'Candidate has not uploaded LinkedIn URL' : null
  }

  public getFormattedHeuristicValue(id: number): string | number {
    const heuristic = this.heuristicIdToValue.get(id)
    const value = this.heuristicIdToValue.get(id)?.value
    if (value !== undefined && value !== null && heuristic !== undefined && heuristic !== null) {
      if (heuristic.unit === '$') {
        return `$${this.numberFormatter.formatLargeAmount(String(value))}`
      }
      if (heuristic.unit === '%') {
        return `${String(value)}%`
      }
      return String(value)
    }
    return emptyHeuristicValue
  }

  public getHeuristicValueInEditMode(id: number): string {
    const newVal = this.heuristicIdToNewValue.get(id)
    if (newVal) {
      return newVal
    }

    const originalVal = this.heuristicIdToValue.get(id)
    if (originalVal) {
      return String(originalVal.value)
    }
    return ''
  }

  public getNewValueForHeuristicId(heuristicId: number): string | null {
    return this.heuristicIdToNewValue.get(heuristicId) ?? null
  }

  public handleTextChange(value: string, heuristicId: number): void {
    this.heuristicIdToNewValue.set(heuristicId, value)
    this.updateViews()
  }

  public async saveHeuristicValue(heuristicId: number): Promise<void> {
    this.loading = true
    this.updateViews()

    const valueToSave = this.heuristicIdToNewValue.get(heuristicId)
    if (valueToSave !== undefined && valueToSave !== null) {
      await this.updateHeuristicValue(heuristicId, valueToSave)
    }
    await this.setHeuristics()
    this.loading = false
    this.updateViews()
  }

  private async updateHeuristicValue(heuristicId: number, value: string): Promise<void> {
    const heuristic = this.heuristicIdToDto.get(heuristicId)

    if (heuristic?.valueType === factHeuristicType) {
      await this.heuristicValueUpdater.updateFactHeuristicsValue(heuristicId, value)
    } else if (heuristic?.valueType === measureHeuristicType && !Number.isNaN(Number(value))) {
      await this.heuristicValueUpdater.updateMeasureHeuristicsValue(
        heuristicId,
        Number(value),
        heuristic.dateRange ?? currentYear
      )
    }
  }

  public async saveNewHeuristics(): Promise<void> {
    this.loading = true
    this.updateViews()

    const savePromises = Array.from(this.heuristicIdToNewValue.entries())
      .filter(([_heuristicId, valueToSave]) => valueToSave !== undefined && valueToSave !== null)
      .map(([heuristicId, valueToSave]) => this.updateHeuristicValue(heuristicId, valueToSave))

    await Promise.all(savePromises)
    await this.setHeuristics()
    this.heuristicEditMode = false
    this.loading = false
    this.updateViews()
  }

  public getDefaultViewHeuristicsToDisplay(): HeuristicDataViewDto[] {
    return this.getHeuristicDataViewDto(this.defaultHeuristicIds)
  }

  public getAllHeuristicsToDisplay(): HeuristicDataViewDto[] {
    return this.getHeuristicDataViewDto(this.heuristicIdsEligibleForCandidateView)
  }

  private getHeuristicDataViewDto(ids: number[]): HeuristicDataViewDto[] {
    const dtos = ids.map((id) => {
      const heuristic = this.heuristicIdToDto.get(id)

      return {
        heuristicId: id,
        title: heuristic?.name ?? '',
        value: this.getFormattedHeuristicValue(id),
        unit: heuristic?.unit ?? '',
        valueType: heuristic?.valueType ?? factHeuristicType
      }
    })
    return this.shareMode ? dtos.filter((dto) => dto.value !== emptyHeuristicValue) : dtos
  }

  public shouldShowAllHeuristics(): boolean {
    return this.isCardExpanded
  }

  public toggleCardExpansion(): void {
    this.isCardExpanded = !this.isCardExpanded
    this.updateViews()
  }

  public isInEditMode(): boolean {
    return this.heuristicEditMode
  }

  public enterEditMode(): void {
    this.heuristicEditMode = true
    this.isCardExpanded = true
    this.updateViews()
  }

  public exitEditMode(): void {
    this.heuristicEditMode = false
    this.resetNewHeuristicValues()
    this.updateViews()
  }

  private resetNewHeuristicValues(): void {
    this.heuristicIdToNewValue.clear()
  }

  public getJobPreferences(): CheckBoxDto[] {
    const heuristicIdsForJobPreferences = [
      willingToWorkRemotelyHeuristicId,
      willingToWorkInOfficeHeuristicId,
      willingToHybridHeuristicId,
      willingToRelocateHeuristicId
    ]

    return heuristicIdsForJobPreferences.map((id) => {
      const val = this.heuristicIdToValue.get(id)
      const heuristic = this.heuristicIdToDto.get(id)

      return {
        isChecked: val?.value === 'Yes',
        id: String(id),
        label: heuristic?.name ?? ''
      }
    })
  }

  public async updateJobPreference(heuristicId: number, values: string): Promise<void> {
    this.loading = true
    this.updateViews()
    await this.updateHeuristicValue(heuristicId, values)
    await this.setHeuristics()
    this.loading = false
    this.updateViews()
  }

  public getOverviewPresenter(): ICandidateSummaryOverviewPresenter {
    return this
  }

  public getHeuristicDataPresenter(): ICandidateHeuristicDataPresenter {
    return this
  }

  public getJobPreferencesPresenter(): ICandidateJobPreferencesCardPresenter {
    return this
  }

  public getAssessmentPresenter(): IAssessmentResultPresenter {
    return this.assessmentPresenter
  }

  public getQuestionnairePresenter(): ICandidateQuestionnaireCardPresenter {
    return this
  }

  public getCandidatesNotesPresenter(): ICandidateNotesPresenter {
    return this
  }

  public getAssessmentCardTitle(): string {
    const timestamp = this.candidate?.assessments?.[0]?.scoreCalculationTimestamp
    const title = this.assessment?.title
    if (!this.assessment || !timestamp) {
      return 'Assessment Scores'
    }
    const date = DateHelper.getDDMONYYYYDateString(new Date(timestamp))

    if (this.shareMode) {
      return `${title} | Score: ${this.getScoreLabel()} | ${date}`
    }

    const score = this.candidate?.assessments?.[0]?.scoreDto?.averageScore ?? null
    return this.isRecruiter() && score !== null
      ? `${title} | Score: ${score.toFixed(2)} | ${date}`
      : `${title} | ${date ?? ''}`
  }

  public getScoreLabel(): string {
    const score = this.candidate?.assessments?.[0]?.scoreDto?.averageScore ?? null
    if (score === null) {
      return 'Not completed'
    }

    const scoreDto = scoreThresholds.find((s) => score >= s.score)
    return scoreDto?.label ?? 'Good'
  }

  public getScorePercentileLabel(): string {
    const score = this.candidate?.assessments?.[0]?.scoreDto?.averageScore ?? null
    if (score === null) {
      return 'Not completed'
    }
    const scoreDto = scoreThresholds.find((s) => score >= s.score)
    return scoreDto?.percentileStr ?? ''
  }

  public setView(view: ISubscribingView): void {
    this.views.push(view)
    this.assessmentPresenter.setView(this)
  }

  public updateViews(): void {
    if (this.views.length) {
      this.views.forEach((view) => view.update())
    }
  }

  public shouldDisplayAssessmentCard(): boolean {
    const metrics = this.assessmentPresenter.getAssessmentMetrics() || []
    return metrics.length > 0
  }

  public getQuestionnaireDtos(): JobRequirementPromptsAndValues[] {
    if (!this.shareMode) {
      return this.questionnaires?.promptsAndResponses ?? []
    }

    if (this.questionnaireJobId === null) {
      return []
    }

    const questionnaire = this.questionnaires?.promptsAndResponses?.find(
      (dto) => dto.jobId === this.questionnaireJobId
    )
    return questionnaire ? [questionnaire] : []
  }

  public getQuestionnaireDropDownLabels(): QuestionnaireDropDownDto[] {
    const labels: QuestionnaireDropDownDto[] = []

    this.questionnaires?.promptsAndResponses?.forEach((dto) => {
      if (dto.promptResponseDtos.length > 0) {
        const job = this.jobIdToDto.get(dto.jobId)
        labels.push({
          jobId: dto.jobId,
          label: `${job?.companyName}: ${job?.jobTitle}`
        })
      }
    })

    return labels
  }

  public handleSelectedQuestionnaire(jobId: number | null): void {
    this.questionnaireJobId = jobId
    this.updateViews()
  }

  public shouldDisplayQuestionnaireCard(): boolean {
    if (
      this.questionnaires === null ||
      this.questionnaires?.promptsAndResponses?.length === 0 ||
      this.questionnaires?.promptsAndResponses?.[0]?.promptResponseDtos?.length === 0
    ) {
      return false
    }

    return true
  }

  public isQuestionnaireInEditMode(): boolean {
    return this.editModeQuestionnaire
  }

  public exitQuestionnaireEditMode(): void {
    this.editModeQuestionnaire = false
    this.updateViews()
  }

  public enterQuestionnaireEditMode(): void {
    this.editModeQuestionnaire = true
    this.updateViews()
  }

  public editQuestionnaireText(
    questionnaireIdx: number,
    promptId: number,
    value: string,
    heuristicId: number
  ): void {
    if (!this.questionnaireIdxToPromptIdToNewValue.has(questionnaireIdx)) {
      this.questionnaireIdxToPromptIdToNewValue.set(questionnaireIdx, new Map())
    }

    this.questionnaireIdxToPromptIdToNewValue
      .get(questionnaireIdx)
      ?.set(promptId, { newValue: value, heuristicId })

    this.updateViews()
  }

  public getEditedQuestionnaireText(questionnaireIdx: number, promptId: number): string | null {
    return (
      this.questionnaireIdxToPromptIdToNewValue.get(questionnaireIdx)?.get(promptId)?.newValue ??
      null
    )
  }

  public getQuestionnaireCardTitle(jobId: number): string {
    const job = this.jobIdToDto.get(jobId)
    if (!job) {
      return `Job: ${jobId}`
    }
    if (this.isRecruiter()) {
      return `${job.companyName}: ${job.jobTitle}`
    }

    return `${job.jobTitle} - ${job.location}`
  }

  public getInputType(heuristicId: number): 'number' | 'text' {
    const heuristic = this.heuristicIdToDto.get(heuristicId)
    return heuristic?.valueType === measureHeuristicType ? 'number' : 'text'
  }

  public getUnit(heuristicId: number): ValidHeuristicUnit {
    const heuristic = this.heuristicIdToDto.get(heuristicId)
    return heuristic?.unit ?? ''
  }

  public async saveNewQuestionnaireValues(): Promise<void> {
    this.loading = true
    this.updateViews()
    const updatePromises: Promise<void>[] = []

    this.questionnaireIdxToPromptIdToNewValue.forEach(
      (promptIdToNewVal: Map<number, NewValueAndHeuristicIdDto>, _questionnaireIdx: number) => {
        promptIdToNewVal.forEach((newVal: NewValueAndHeuristicIdDto) => {
          updatePromises.push(this.updateHeuristicValue(newVal.heuristicId, newVal.newValue))
        })
      }
    )

    await Promise.all(updatePromises)
    this.questionnaireIdxToPromptIdToNewValue.clear()
    this.editModeQuestionnaire = false
    await this.initialize()
    this.loading = false
    this.updateViews()
  }

  public isRecruiter(): boolean {
    return this.isRecruiterUser
  }

  public shouldDisplayAssessmentResponses(): boolean {
    if (!this.isRecruiter() || this.shareMode) {
      return false
    }
    return this.viewAssessmentResponsesView
  }

  public viewAssessmentResponses(): void {
    this.viewAssessmentResponsesView = true
    this.updateViews()
  }

  public closeAssessmentResponses(): void {
    this.viewAssessmentResponsesView = false
    this.updateViews()
  }

  public getAssessmentResponsesDto(): AssessmentPromptResponseDto[] {
    return this.candidate?.assessments?.[0]?.scoreDto?.prompts ?? []
  }

  public getCandidateNote(): string {
    return this.candidateNewNote !== null ? this.candidateNewNote : this.candidateNote?.note ?? ''
  }

  public handleCandidateNoteTextChange(newValue: string): void {
    this.candidateNewNote = newValue
    this.updateViews()
  }

  public async saveNote(): Promise<void> {
    this.loading = true
    this.updateViews()
    const body = this.getNoteDto()
    if (body === null) {
      this.loading = false
      this.updateViews()
      return
    }

    const result = await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}users/${this.userId}/candidateNotes`,
      body
    })

    if (result?.id && result?.note) {
      this.candidateNote = result
    }
    this.loading = false
    this.updateViews()
  }

  private getNoteDto(): CandidateNoteDto | NewCandidateNoteDto | null {
    const authorId = Cookies.get(userIdKey)
    const parsedAuthorId = authorId ? Number.parseInt(authorId, 10) : null
    const trimmedString = this.candidateNewNote ? this.candidateNewNote.trim() : null
    if (
      parsedAuthorId === null ||
      this.candidateNewNote === null ||
      trimmedString === null ||
      Number.isNaN(parsedAuthorId)
    ) {
      return null
    }
    if (this.candidateNote !== null && this.candidateNewNote) {
      return {
        ...this.candidateNote,
        note: trimmedString,
        authorId: parsedAuthorId
      }
    }

    return {
      note: trimmedString,
      authorId: parsedAuthorId,
      userId: this.userId
    }
  }

  public canSaveNote(): boolean {
    if (this.shareMode) {
      return false
    }
    return this.candidateNewNote !== null && this.candidateNote?.note !== this.candidateNewNote
  }

  public async handleShareProfile(idOfDiv: string): Promise<void> {
    const fileName = `Candidate: ${this.candidate?.userId ?? ''} - DataBased profile`
    await this.pageToFileConverter.convertToFile(idOfDiv, fileName)
    this.toggleShareMode(false)
    this.updateViews()
  }

  public isInShareMode(): boolean {
    return this.shareMode
  }

  public toggleShareMode(toggle: boolean): void {
    this.shareMode = toggle
    this.includeNotesInProfile = true
    this.questionnaireJobId = null
    if (this.shareMode) {
      this.heuristicEditMode = false
      this.isCardExpanded = true
    }
    this.updateViews()
  }

  public shouldIncludeNotes(): boolean {
    return this.includeNotesInProfile
  }

  public toggleIncludeNotesInShareMode(): void {
    this.includeNotesInProfile = !this.includeNotesInProfile
    this.updateViews()
  }
}

export default CandidateSummaryPresenter
