import './style.scss'

import React, { Component, ReactElement } from 'react'

import ContainedButton from '../ContainedButton'

type Props = {
  children: React.ReactNode
  id?: string
  isButtonDisabled?: boolean
  onClick?(event: React.MouseEvent): void
  type: 'submit' | 'reset' | 'button'
}

class ThemeButton extends Component<Props> {
  render(): ReactElement {
    const { children, id, isButtonDisabled, onClick, type } = this.props

    return (
      <div className='themeButton'>
        <ContainedButton id={id} onClick={onClick} type={type} isButtonDisabled={isButtonDisabled}>
          {children}
        </ContainedButton>
      </div>
    )
  }
}

export default ThemeButton
