import IAssessmentChecker from '../../../../APIs/AssessmentChecker/IAssessmentChecker'
import { assessments } from '../DashboardSteps'
import INextReadyToTakeAssessmentProvider from './INextReadyToTakeAssessmentProvider'

class NextReadyToTakeAssessmentProvider implements INextReadyToTakeAssessmentProvider {
  constructor(private readonly assessmentChecker: IAssessmentChecker) {}

  public async getIdOfNextAssessmentToTake(): Promise<number> {
    for (let i = 0; i < assessments.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const result = await this.assessmentChecker.hasFinishedThisAssessment(assessments[i])

      if (!result) {
        return assessments[i]
      }
    }

    return -1
  }
}

export default NextReadyToTakeAssessmentProvider
