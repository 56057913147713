import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IUserStageFetcher from './IUserStageFetcher'
import StageDto from './StageDto'

class UserStageFetcher implements IUserStageFetcher {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getUserStage(): Promise<StageDto> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/completedStages`,
      body: null
    })

    if (result?.hasOwnProperty('completedStages')) {
      return result
    }

    return { completedStages: 0 }
  }
}

export default UserStageFetcher
