import IUserAccountEditor from '../../../APIs/UserAccountEditor/IUserAccountEditor'
import IProfileImageHandler from '../../../Handlers/ProfileImageHandler/IProfileImageHandler'
import { okHttpCode } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import StringHelper from '../../../localCommon/StringHelper/StringHelper'
import { invalidEmailErrorMessage } from '../../SignUp/Presenter/SignUpPresenter'
import IEditAccountPresenter from './IEditAccountPresenter'

export const successfullyUpdatedAccountMessage = 'Successfully updated your account!'
class EditAccountPresenter implements IEditAccountPresenter {
  private view: ISubscribingView | null
  private email: string
  private name: string
  private error: string
  private emailErrorMessage: string
  private successMessage: string

  constructor(
    private readonly userAccountEditor: IUserAccountEditor,
    private readonly profileImageHandler: IProfileImageHandler,
    private readonly userId: number,
    email: string,
    name: string
  ) {
    this.emailErrorMessage = ''
    this.error = ''
    this.email = email
    this.successMessage = ''
    this.name = name
    this.view = null
  }

  public getSuccessMessage(): string {
    return this.successMessage
  }

  public getProfileImageHandler(): IProfileImageHandler {
    return this.profileImageHandler
  }

  public getName(): string {
    return this.name
  }

  public getEmail(): string {
    return this.email
  }

  public getErrorMessage(): string {
    return this.error
  }

  public shouldShowInvalidEmailMessage(): boolean {
    return !StringHelper.isValidEmail(this.email)
  }

  public async submit(): Promise<void> {
    const result = await this.userAccountEditor.editAccount({
      email: this.email,
      id: this.userId,
      name: this.name,
      profileImageId: this.profileImageHandler.getImageId()
    })

    if (result.code === okHttpCode) {
      this.error = ''
      this.successMessage = successfullyUpdatedAccountMessage
    } else {
      this.error = result.message
    }

    this.updateView()
  }

  public getInvalidEmailInlineMessage(): string {
    return this.emailErrorMessage
  }

  public updateEmail(input: string): void {
    this.email = input
    this.validateEmailInput()
    this.updateView()
  }

  private validateEmailInput(): void {
    if (StringHelper.isValidEmail(this.email)) {
      this.emailErrorMessage = ''
    } else {
      this.emailErrorMessage = invalidEmailErrorMessage
    }
    this.updateView()
  }

  public updateName(input: string): void {
    this.name = input
    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default EditAccountPresenter
