import './style.css'

import React, { Component, ReactElement } from 'react'

import images from '../../images'
import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedButton from '../../localCommon/Button/OutlinedButton'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import ILogInPresenter from './Presenters/LogInPresenter/ILogInPresenter'

type Props = {
  presenter: ILogInPresenter
}

class LogIn extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='login blank-page-padding'>
        <div className='center-text page-title'>
          <img alt='databased' src={images.databased} />
        </div>
        <p className='headline6'>Login</p>
        <p className='error'>{presenter.getErrorMessage()}</p>
        <div>
          <OutlinedInput
            caption=''
            id='email'
            isRequired
            label='Email'
            onChange={this.updateEmail}
            pattern='^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$'
            shouldShowErrorMessage={false}
            type='email'
            value={presenter.getEmail()}
          />
        </div>
        <div>
          <OutlinedInput
            caption=''
            id='password'
            isRequired
            label='Password'
            onChange={this.updatePassword}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$'
            shouldShowErrorMessage={false}
            value={presenter.getPassword()}
            type='password'
          />
        </div>
        <div className='buttons-group'>
          <div className='left-button'>
            <OutlinedButton onClick={this.redirectToLogInPage}>Back</OutlinedButton>
          </div>
          <div className='right-buttons'>
            <OutlinedButton onClick={this.redirectToForgotPassword}>Forgot Password</OutlinedButton>
            <ContainedButton onClick={() => this.login()} type='submit'>
              Sign in
            </ContainedButton>
          </div>
        </div>

        {/* <div className='buttons-group'>
          <OutlinedButton onClick={this.redirectToForgotPassword}>Back</OutlinedButton>
          <OutlinedButton onClick={this.redirectToForgotPassword}>Forgot Password</OutlinedButton>
          <ContainedButton onClick={() => this.login()} type='submit'>
            Sign in
          </ContainedButton>
        </div> */}
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  updateEmail = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateEmail(target.value?.toLowerCase())
  }

  updatePassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updatePassword(target.value)
  }

  login = (): void => {
    this.props.presenter.login()
  }

  redirectToForgotPassword = (): void => {
    window.location.href = '/forgotPassword'
  }

  redirectToLogInPage = (): void => {
    window.location.href = '/ssologins'
  }
}

export default LogIn
