import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import OutlinedInput, { outlinedInputId } from '../../../localCommon/OutlinedInput/OutlinedInput'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import NavigationButtonGroup from '../NavigationButtonGroup'
import IFuzzySearchPresenter from './IFuzzySearchPresenter'

type Props = {
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: IFuzzySearchPresenter
}

class FuzzySearch extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.navigationPresenter?.setView(this)
    this.props.presenter.setView(this)
    this.handleInputValueLabelPosition()
    this.focusOnInput()
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
    this.handleInputValueLabelPosition()
    this.focusOnInput()
  }

  componentWillUnmount(): void {
    this.props.navigationPresenter?.clearView()
  }

  render(): ReactElement {
    const { presenter, navigationPresenter } = this.props
    const input = presenter.getPromptValue()
    const universities = presenter.getUniversities()

    return (
      <div>
        <div className='fuzzySearch'>
          <div className='home-submit'>
            <p className='headline6'>{presenter.getTitle()}</p>
            <div className='body1'>{presenter.getDescription()}</div>
          </div>
          <div className='input-with-list'>
            <OutlinedInput
              type='text'
              value={input}
              onChange={this.handleInputChange}
              id='fuzzy-search'
              isRequired={presenter.isRequiredQuestion()}
              caption={''}
              error={presenter.getInlineError()}
              shouldShowErrorMessage={presenter.shouldShowError()}
              label={'University Attended'}
              step={undefined}
              unit={presenter.getUnit()}
            />
          </div>
          {input && universities.length > 0 && (
            <ul className='university-list'>
              {universities.map((university: string, index: number) => (
                <li
                  key={index}
                  className='university-item'
                  onClick={() => this.handleUniversityClick(university)}
                >
                  {university}
                </li>
              ))}
            </ul>
          )}
        </div>
        <NavigationButtonGroup
          canBacktrackToPreviousPrompt={false}
          hasValidInput
          isRequired
          presenter={navigationPresenter}
          subscriber={this}
        />
      </div>
    )
  }

  focusOnInput = (): void => {
    const input = document.getElementById('fuzzy-search') as HTMLElement

    if (input) {
      input.focus()
    }
  }

  handleInputValueLabelPosition = (): void => {
    const input = document.querySelector(`#${outlinedInputId}`)
    if (input) {
      if (this.props.presenter.getValue()) {
        const focusEvent = new Event('focus')
        input.dispatchEvent(focusEvent)
      } else {
        const blurEvent = new Event('blur')
        input.dispatchEvent(blurEvent)
      }
    }
  }

  handleUniversityClick = (university: string): void => {
    this.props.presenter.clickedUniversity(university)
  }

  handleInputChange = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateInput(target.value)
  }

  showErrorMessage = (): void => {
    this.props.presenter.showErrorMessage()
  }

  update(): void {
    this.setState({})
  }
}

export default FuzzySearch
