import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../../images'
import { getPercentileNumberGivenPercentile } from '../../Dashboard/AssessmentResult/Presenter/AssessmentResultPresenter'
import ISelectedMetricDisplayer from '../../Dashboard/AssessmentResult/Presenter/ISelectedMetricDisplayer'
import CoreAssessmentDto from './CoreAssessmentDto'

type Props = {
  assessment: CoreAssessmentDto
  presenter: ISelectedMetricDisplayer
  shouldShowDropdown: boolean
}

class AssessmentBar extends Component<Props> {
  render(): ReactElement {
    const { assessment } = this.props
    const { shouldShowDropdown } = this.props
    const { percentile } = assessment

    return (
      <div
        className='assessmentBar'
        key={assessment.title}
        onClick={() => this.showMetricDetail(assessment.id)}
      >
        <div className='assessmentBar-bar'>
          <div className='assessmentBar-titleRow'>
            <p
              className={`${assessment.isDisabled ? 'disabled' : ''} assessmentBar-title subtitle2`}
            >
              {assessment.title}
            </p>
            <p className='assessmentBar-percentile'>{`Top ${getPercentileNumberGivenPercentile(percentile)}%`}</p>
            <p
              className={`${assessment.isDisabled ? 'disabled' : ''} assessmentBar-upperTail subtitle2`}
            >
              {assessment?.upperTail ?? ''}
            </p>
          </div>

          <div className='assessmentBar-data'>
            <div className='assessmentBar-blankSections'>
              <div className='white-section'></div>
              <div className='white-section'></div>
              <div className='white-section'></div>
              <div className='white-section'></div>
            </div>
            <div className='assessmentBar-wrapper'>
              <div
                className='assessmentBar-progress assessmentBar-progressWithData'
                style={{
                  background: `linear-gradient(to right, ${this.getFirstSection(assessment)}${this.getSecondSection(assessment)})`
                }}
              />
              <div className='assessmentBar-progress assessmentBar-progressBackground'></div>
            </div>
            {shouldShowDropdown && (
              <div
                className='assessmentBar-arrow'
                onClick={() => this.showMetricDetail(assessment.id)}
              >
                <img alt='arrow-to-right' src={images.blackArrow} />
              </div>
            )}
          </div>
        </div>
        {shouldShowDropdown && (
          <p
            className='assessmentBar-details caption'
            onClick={() => this.showMetricDetail(assessment.id)}
          >
            View details
          </p>
        )}
      </div>
    )
  }

  showMetricDetail = (id: number): void => {
    this.props.presenter.selectAssessmentMetricToShow(id)
  }

  getFirstSection = (assessment: CoreAssessmentDto): string => {
    const progress = assessment.percentile

    if (progress > 0 && progress < 100) {
      return `#FF00D6 0%, #18A0FB ${progress}%`
    }

    if (progress === 0) {
      return 'rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%'
    }

    return '#FF00D6 0%, #18A0FB 100%'
  }

  getSecondSection = (assessment: CoreAssessmentDto): string => {
    const progress = assessment.percentile

    if (progress > 0 && progress < 100) {
      return `, rgba(0, 0, 0, 0) ${progress}%, rgba(0, 0, 0, 0) 100%`
    }

    return ''
  }
}

export default AssessmentBar
