import MarketCompensationDataDto from '../../../APIs/MarketCompensationDataGetter/MarketCompensationDataDto'
import ISubscriber from '../../../localCommon/Interfaces/ISubscriber'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import INumberFormatter from '../../../localCommon/NumberFormatter/INumberFormatter'
import StringHelper from '../../../localCommon/StringHelper/StringHelper'
import IPersonalDataStore from '../../../Models/Interfaces/IPersonalDataStore'
import { entAeAbbreviation, entAeRole } from '../Presenters/DropdownOptions'
import IDataDisplayRowPresenter from './IHomeDataRowsPresenter'

class HomeDataRowsPresenter implements IDataDisplayRowPresenter {
  private annualQuota: string
  private role: string
  private numOfYearsExperience: string
  private view: ISubscribingView | null
  private subscriber: ISubscriber | null
  private marketCompensationData: MarketCompensationDataDto | null

  constructor(
    private readonly numberFormatter: INumberFormatter,
    private readonly personalDataStore: IPersonalDataStore,
    private readonly title: string
  ) {
    this.annualQuota = this.personalDataStore.getAnnualQuota()
    this.marketCompensationData = this.personalDataStore.getMarketCompensationData()
    this.numOfYearsExperience = this.personalDataStore.getYearsOfExperience()
    this.role = this.personalDataStore.getRole()
    this.subscriber = null
    this.view = null
  }

  public setSubscriber(subscriber: ISubscriber): void {
    this.subscriber = subscriber
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public updateRole(role: string): void {
    this.role = role
    this.updateView()
  }

  public getTitle(): string {
    return this.title
  }

  public getFormattedAnnualQuota(): string {
    return this.annualQuota
  }

  public getNumOfYearsExperience(): string {
    return this.numOfYearsExperience
  }

  public showEditFormView(): void {
    if (this.subscriber) {
      this.subscriber.notifiedToUpdate()
    }
    this.updateView()
  }

  public updateFormattedAnnualQuota(quota: string): void {
    this.annualQuota = quota
    this.updateView()
  }

  public updateNumOfYearsExperience(numOfYears: string): void {
    this.numOfYearsExperience = numOfYears
    this.updateView()
  }

  public getRole(): string {
    if (this.isInternalRole()) {
      return this.role.split('-')[0]
    }

    if (StringHelper.getFirstChars(this.role) === entAeAbbreviation) {
      return entAeRole
    }

    return StringHelper.getFirstChars(this.role)
  }

  private isInternalRole(): boolean {
    return this.role?.includes('internal') ?? false
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getHighOte(): string {
    if (!this.marketCompensationData?.highOte) {
      return ''
    }

    return this.numberFormatter.formatLargeAmount(this.marketCompensationData.highOte.toString())
  }

  public getLowOte(): string {
    if (!this.marketCompensationData?.lowOte) {
      return ''
    }

    return this.numberFormatter.formatLargeAmount(this.marketCompensationData.lowOte.toString())
  }

  public getUserOte(): string {
    if (!this.marketCompensationData?.usersOte) {
      return ''
    }

    return this.numberFormatter.formatLargeAmount(this.marketCompensationData.usersOte.toString())
  }

  public getUserOtePercentile(): string {
    if (typeof this.marketCompensationData?.userOtePercentile !== 'number') {
      return ''
    }

    const userOtePercentile = this.marketCompensationData?.userOtePercentile
    return `${userOtePercentile < 1 ? '1' : Math.floor(this.marketCompensationData.userOtePercentile).toString()}%`
  }

  public getUserOtePercentileRanking(): string {
    if (typeof this.marketCompensationData?.userOtePercentile !== 'number') {
      return ''
    }

    const percentile = Math.floor(this.marketCompensationData.userOtePercentile)
    return percentile < 1 ? '1' : percentile.toString()
  }

  public setMarketCompensationData(dto: MarketCompensationDataDto): void {
    this.marketCompensationData = dto
  }
}

export default HomeDataRowsPresenter
