import INewUserCreator from '../../../APIs/NewUserCreator/INewUserCreator'
import IProfileImageHandler from '../../../Handlers/ProfileImageHandler/IProfileImageHandler'
import { pageRedirectWaitTime } from '../../../localCommon/constant'
import { authTokenKey, emailKey, nameKey, userIdKey } from '../../../localCommon/CookieKeys'
import ICookieSetter from '../../../localCommon/Interfaces/ICookieSetter'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IWindowPathUpdater from '../../../localCommon/Interfaces/IWindowPathUpdater'
import StringHelper from '../../../localCommon/StringHelper/StringHelper'
import { missingRequiredFieldsErrorMessage } from '../../PersonalInfoData/Presenters/HomePresenter'
import { loginRoute } from '../../RouteConstants'
import ISignUpPresenter from './ISignUpPresenter'

export const userAlreadyExistMessage = 'user already exists for given email'
export const invalidEmailErrorMessage = 'Please enter valid email address'
export const invalidPasswordErrorMessage = 'Please enter valid password'
export const passwordDoNotMatchMessage = 'Password do not match.'
class SignUpPresenter implements ISignUpPresenter {
  private email: string
  private errorMessage: string
  private firstName: string
  private lastName: string
  private password: string
  private confirmPassword: string
  private shouldShowInlineMessage: boolean
  private view: ISubscribingView | null
  private profileImage: File | null
  private loading: boolean

  constructor(
    private readonly newUserCreator: INewUserCreator,
    private readonly cookieSetter: ICookieSetter,
    private readonly windowPathUpdater: IWindowPathUpdater,
    private readonly profileImageHandler: IProfileImageHandler
  ) {
    this.confirmPassword = ''
    this.email = ''
    this.errorMessage = ''
    this.firstName = ''
    this.lastName = ''
    this.loading = false
    this.password = ''
    this.profileImage = null
    this.shouldShowInlineMessage = false
    this.view = null
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getImageFile(file: File): void {
    this.profileImage = file
  }

  public shouldShowInlineErrorMessage(): boolean {
    return this.shouldShowInlineMessage
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  public doPasswordsMatch(): boolean {
    return this.password === this.confirmPassword
  }

  public getConfirmPassword(): string {
    return this.confirmPassword
  }

  public getEmail(): string {
    return this.email
  }

  public getFirstName(): string {
    return this.firstName
  }

  public getLastName(): string {
    return this.lastName
  }

  public getPassword(): string {
    return this.password
  }

  public updateConfirmPassword(input: string): void {
    this.confirmPassword = input
    this.errorMessage = this.validatePassword()

    this.updateView()
  }

  private validatePassword(): string {
    if (this.password !== this.confirmPassword) {
      return passwordDoNotMatchMessage
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$/
    if (!regex.test(this.password)) {
      return invalidPasswordErrorMessage
    }

    return ''
  }

  public updateEmail(input: string): void {
    this.email = input
    if (StringHelper.isValidEmail(this.email)) {
      this.errorMessage = ''
    } else {
      this.errorMessage = invalidEmailErrorMessage
    }

    this.updateView()
  }

  public updateFistName(input: string): void {
    this.firstName = input
    this.updateView()
  }

  public updateLastName(input: string): void {
    this.lastName = input
    this.updateView()
  }

  public updatePassword(input: string): void {
    this.password = input
    this.errorMessage = this.validatePassword()

    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getEmailInlineErrorMessage(): string {
    return StringHelper.isValidEmail(this.email) ? '' : invalidEmailErrorMessage
  }

  public getFirstNameInlineErrorMessage(): string {
    return this.firstName ? '' : missingRequiredFieldsErrorMessage
  }

  public getLastNameInlineErrorMessage(): string {
    return this.lastName ? '' : missingRequiredFieldsErrorMessage
  }

  private hasMissingFields(): boolean {
    return !this.firstName || !this.lastName || !StringHelper.isValidEmail(this.email)
  }

  public async signUp(): Promise<void> {
    this.shouldShowInlineMessage = true
    if (this.hasMissingFields()) {
      this.errorMessage = 'Please fill out all required fields'
      this.updateView()
      return
    }

    this.loading = true
    this.updateView()

    const result = await this.newUserCreator.signUp({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password
    })

    if (typeof result === 'string') {
      if (result.includes(userAlreadyExistMessage)) {
        setTimeout(() => {
          this.windowPathUpdater.setPathName(loginRoute)
        }, pageRedirectWaitTime)
      }

      this.loading = false
      this.errorMessage = result
      this.updateView()
    } else {
      this.cookieSetter.set(authTokenKey, result.auth.token, { expires: 1 })
      this.cookieSetter.set(userIdKey, result.userData.userId?.toString(), { expires: 1 })
      this.cookieSetter.set(emailKey, this.email, { expires: 1 })
      this.cookieSetter.set(nameKey, `${this.firstName} ${this.lastName}`, { expires: 1 })

      if (this.profileImage) {
        await this.profileImageHandler.validateThenUploadFile(this.profileImage)
      }

      setTimeout(() => {
        this.windowPathUpdater.setPathName('/')
      }, pageRedirectWaitTime)
    }
  }
}

export default SignUpPresenter
