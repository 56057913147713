import ICommonObjectWithSortId from '../../../../localCommon/Interfaces/ICommonObjectWithSortId'
import MultipleChoiceQuestionDto from '../../Dtos/MultipleChoiceQuestionDto'
import AbstractResponsePresenter from '../../Presenters/AbstractResponsePresenter'
import IMultipleChoicePresenter from './Interfaces/IMultipleChoicePresenter'

class MultipleChoicePresenter
  extends AbstractResponsePresenter
  implements IMultipleChoicePresenter
{
  constructor(
    protected readonly question: MultipleChoiceQuestionDto,
    protected readonly questionIndex: number,
    protected readonly isRequired: boolean
  ) {
    super(question, questionIndex, isRequired)
  }

  public getPromptValue(): string {
    const list = this.question.options
    return list.find((option) => option.id === parseInt(this.input, 10))?.name ?? ''
  }

  public getResponseName(): string {
    return this.question.responseType
  }

  public getOptions(): ICommonObjectWithSortId[] {
    return this.question.options.sort((optionA, optionB) => optionA.orderIndex - optionB.orderIndex)
  }

  public getValue(): string {
    return this.input
  }
}

export default MultipleChoicePresenter
