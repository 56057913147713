import React, { Component, ReactElement } from 'react'

import images from '../../images'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import Progressbar from '../../localCommon/ProgressBar/Progressbar'
import IMenuVisibilityManager from '../../localCommon/SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import IDashboardPresenter from '../PersonalInfoData/Dashboard/Presenter/IDashboardPresenter'
import INavigationPresenter from '../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import onboardingQuestions from '../PersonalInfoData/OnboardingQuestions'
import IHomePresenter from '../PersonalInfoData/Presenters/Interfaces/IHomePresenter'
import INavigationTerminator from '../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import Welcome from '../PersonalInfoData/Welcome/Welcome'
import { dashboardRoute } from '../RouteConstants'
import IResponsePresenterFactory from './Presenters/Interfaces/IResponsePresenterFactory'
import ResponseComponentFactory from './ResponseComponentFactory/ResponseComponentFactory'

type Props = {
  dashboardPresenter: IDashboardPresenter
  factory: IResponsePresenterFactory
  menuVisibilityManager: IMenuVisibilityManager
  name: string
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: IHomePresenter
}

class Home extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.navigationPresenter.setView(this)
    this.props.presenter.initialize()
    this.props.dashboardPresenter.initialize()
    this.props.presenter.setView(this)
    this.props.menuVisibilityManager.setView(this)
  }

  componentDidUpdate(): void {
    this.props.navigationPresenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.navigationPresenter.clearView()
    this.props.presenter.clearView()
    this.props.menuVisibilityManager.clearView()
  }

  render(): ReactElement {
    const { factory, presenter, navigationPresenter } = this.props

    if (presenter.isLoading()) {
      return (
        <div className='home blank-page-padding'>
          <div className='loading'></div>
          <div className='loading'></div>
        </div>
      )
    }

    if (!presenter.hasExistingPersonalInfoData() && !presenter.hasStartedOnboardingQuestions()) {
      return <Welcome presenter={presenter} />
    }

    if (presenter.hasStartedOnboardingQuestions()) {
      return (
        <div className='blank-page-padding'>
          <div className='question-title'>
            <img alt='databased' src={images.databased} />
          </div>
          <div className='question-question'>
            <Progressbar
              progress={
                ((navigationPresenter.getCurrentQuestionIndex() + 1) /
                  navigationPresenter.getTotalNumberOfQuestions()) *
                100
              }
            />
            <ResponseComponentFactory
              description={
                onboardingQuestions[navigationPresenter.getCurrentQuestionIndex()].description
              }
              factory={factory}
              id={onboardingQuestions[navigationPresenter.getCurrentQuestionIndex()].id}
              navigationPresenter={factory.getNavigationPresenter()}
              title={onboardingQuestions[navigationPresenter.getCurrentQuestionIndex()].title}
              responseType={
                onboardingQuestions[navigationPresenter.getCurrentQuestionIndex()].responseType
              }
            />
          </div>
        </div>
      )
    }

    return this.doneWithOnboardingQuestions()
  }

  update = (): void => {
    this.setState({})
  }

  openDrawer = (): void => {
    this.props.menuVisibilityManager.open()
  }

  doneWithOnboardingQuestions = (): ReactElement => {
    window.location.href = dashboardRoute
    return <div></div>
  }
}

export default Home
