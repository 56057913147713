import './style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedThemeButton from '../../../../localCommon/Button/ThemeOutlinedButton/ThemeOutlinedButtons'
import { growCourseCatalog } from '../../../../localCommon/constant'

type Props = {
  hasFailedTheAssessment: boolean
}

class StageThree extends Component<Props> {
  render(): ReactElement {
    return (
      <div className='section-with-border'>
        <p className='subtitle2'>{this.getStageThreeTitle()}</p>
        {this.props.hasFailedTheAssessment ? (
          <ul>
            <li>
              Look for DataBased courses and materials that are geared towards helping people
              improve in your specific needs
            </li>
            <div className='nextStep-themeButton center-text'>
              <a href={growCourseCatalog} target='_blank' rel='noreferrer'>
                <OutlinedThemeButton type='button'>Visit course catalog</OutlinedThemeButton>
              </a>
            </div>
          </ul>
        ) : (
          <li>Receive final score & feedback</li>
        )}
      </div>
    )
  }

  getStageThreeTitle = (): string => {
    if (this.props.hasFailedTheAssessment) {
      return 'Work on improving your scores'
    }

    return 'Stage 3: Sales skills interview'
  }
}

export default StageThree
