export const stageOneHeuristicId = 50
export const stageTwoHeuristicId = 51
export const stageThreeHeuristicId = 52
export const stageFourHeuristicId = 53
export const stageFiveHeuristicId = 54

export const stageHeuristicIds = [
  stageOneHeuristicId,
  stageTwoHeuristicId,
  stageThreeHeuristicId,
  stageFourHeuristicId,
  stageFiveHeuristicId
]
