import ISignOutHelper from '../../SignOut/SignOutHelper/ISignOutHelper'
import AbstractVisibilityManager from '../VisibilityManager/AbstractVisibilityManager'

class MenuVisibilityManager extends AbstractVisibilityManager {
  constructor(private readonly signOutHelper: ISignOutHelper) {
    super()

    this.isVisible = false
    this.view = null
  }

  public signOut(): void {
    this.signOutHelper.signOut()
  }
}

export default MenuVisibilityManager
