import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'

type Props = {
  labels: { left: string; middle: string; right: string }
  title: string
}

export const roleRowId = 'emptyDisplayRow-role'
export const yearsOfExperienceRowId = 'emptyDisplayRow-yearsOfExperience'
export const quotaRowId = 'emptyDisplayRow-quota'
class EmptyDataDisplayRow extends Component<Props> implements ISubscribingView {
  render(): ReactElement {
    const { labels, title } = this.props

    return (
      <div className='home-market home-dataRow section-with-border'>
        <div className='home-title'>
          <p className='subtitle2'>{title}</p>
          <p className='caption'>
            <span id={roleRowId}>&ndash;</span> | <span id={yearsOfExperienceRowId}>&ndash;</span> |{' '}
            <span id={quotaRowId}>&ndash;</span>
          </p>
        </div>
        <div className='home-range'>
          <div>
            <p className='headline5'>
              $<span>&ndash;</span>
            </p>
            <p className='caption'>{labels.left}</p>
          </div>
          <div>
            <p className='headline5'>
              $<span>&ndash;</span>
            </p>
            <p className='caption'>{labels.middle}</p>
          </div>
          <div>
            <p className='headline5'>
              $<span>&ndash;</span>
            </p>
            <p className='caption'>{labels.middle}</p>
          </div>
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }
}

export default EmptyDataDisplayRow
