import Cookies from 'js-cookie'

import HeuristicsUpdateHandler from '../../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/HeuristicsUpdateHandler'
import HeuristicsDtoFactory from '../../../../APIs/HeuristicsUpdater/HeuristicsDtoFactory/HeuristicsDtoFactory'
import HeuristicsUpdater from '../../../../APIs/HeuristicsUpdater/HeuristicsUpdater'
import PersonalInfoPutter from '../../../../APIs/PersonalInfoPutter/PersonalInfoPutter'
import Fetcher from '../../../../Drivers/Fetcher'
import { userIdKey } from '../../../../localCommon/CookieKeys'
import NumberFormatter from '../../../../localCommon/NumberFormatter/NumberFormatter'
import DateRangeTypesGetter from '../../../Assessment/Presenter/DateRangeTypesGetter/DateRangeTypesGetter'
import NavigationPresenter from '../../../PersonalInfoData/NavigationPresenter/NavigationPresenter'
import onboardingQuestions from '../../../PersonalInfoData/OnboardingQuestions'
import DataRowDataUpdater from '../../DataRow/DataRowDataUpdater/DataRowDataUpdater'
import PersonalInfoDataCollector from '../../PersonalInfoDataCollector/PersonalInfoDataCollector'
import ResponsePresenterStore from '../../ResponsePresenterStore/ResponsePresenterStore'
import IResponsePresenterFactory from '../Interfaces/IResponsePresenterFactory'
import IResponsePresenterFactoryFactory from './IResponsePresenterFactoryFactory'
import ResponsePresenterFactory from './ResponsePresenterFactory'

class ResponsePresenterFactoryFactory implements IResponsePresenterFactoryFactory {
  public getResponsePresenterFactory(): IResponsePresenterFactory {
    const store = new ResponsePresenterStore()
    const userId = parseInt(Cookies.get(userIdKey) ?? '', 10) ?? null

    return new ResponsePresenterFactory(
      new NavigationPresenter(
        new PersonalInfoDataCollector(
          store,
          new PersonalInfoPutter(new Fetcher(), userId),
          new HeuristicsUpdateHandler(
            new HeuristicsDtoFactory(new DateRangeTypesGetter(), userId),
            new HeuristicsUpdater(new Fetcher(), userId)
          )
        ),
        {
          setPathName: (path: string) => (window.location.pathname = path)
        },
        onboardingQuestions.length
      ),
      store,
      new DataRowDataUpdater(new NumberFormatter(), document)
    )
  }
}

export default ResponsePresenterFactoryFactory
