import { completedOnboardQuestions } from '../../../Drivers/UsageTracker/AmplitudeEventConstants'
import AmplitudeUsageTracker from '../../../Drivers/UsageTracker/AmplitudeUsageTracker'
import IWindowPathUpdater from '../../../localCommon/Interfaces/IWindowPathUpdater'
import IPersonalInfoDataCollector from '../../Home/PersonalInfoDataCollector/IPersonalInfoDataCollector'
import { dashboardRoute } from '../../RouteConstants'
import INavigationTerminator from '../Presenters/Interfaces/INavigationTerminator'
import AbstractNavigationPresenter from './AbstractNavigationPresenter'

class NavigationPresenter extends AbstractNavigationPresenter implements INavigationTerminator {
  constructor(
    private readonly personalInfoDataCollector: IPersonalInfoDataCollector,
    private readonly windowPathUpdater: IWindowPathUpdater,
    protected readonly totalNumberOfQuestions: number
  ) {
    super()
  }

  public getTotalNumberOfQuestions(): number {
    return this.totalNumberOfQuestions
  }

  public async finishNavigation(): Promise<void> {
    if (this.personalInfoDataCollector) {
      await this.personalInfoDataCollector.updateOnboardingHeuristicsValue()
      const result = await this.personalInfoDataCollector.submitPersonalInfoData()

      if (result) {
        this.error = result
      } else {
        this.error = ''
        this.hasFinishedForm = true
        this.windowPathUpdater.setPathName(dashboardRoute)
        AmplitudeUsageTracker.track(completedOnboardQuestions)
      }
    }
  }
}

export default NavigationPresenter
