import Cookies from 'js-cookie'

import IMarketCompensationDataGetter from '../../../APIs/MarketCompensationDataGetter/IMarketCompensationDataGetter'
import MarketCompensationDataDto from '../../../APIs/MarketCompensationDataGetter/MarketCompensationDataDto'
import IPersonalInfoPutter from '../../../APIs/PersonalInfoPutter/IPersonalInfoPutter'
import PersonalInfoWithFactsDto from '../../../APIs/PersonalInfoPutter/PersonalInfoWithFactsDto'
import IPersonalInfoDataHandler from '../../../Handlers/PersonalInfoDataHandler/IPersonalInfoDataHandler'
import { serverBaseUrl } from '../../../localCommon/constant'
import { assessmentIdKey, profileImageKey, userIdKey } from '../../../localCommon/CookieKeys'
import ICookieGetter from '../../../localCommon/Interfaces/ICookieGetter'
import ICookieSetter from '../../../localCommon/Interfaces/ICookieSetter'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import INumberFormatter from '../../../localCommon/NumberFormatter/INumberFormatter'
import IAuthTokenVerifier from '../../../localCommon/SignOut/AuthTokenVerifier/IAuthTokenVerifier'
import StringHelper from '../../../localCommon/StringHelper/StringHelper'
import IPersonalDataStore from '../../../Models/Interfaces/IPersonalDataStore'
import IOnboardingHeuristicValuesUpdater from '../../Home/PersonalInfoDataCollector/OnboardingHeuristicValuesUpdater/IOnboardingHeuristicValuesUpdater'
import IDashboardPresenter from '../Dashboard/Presenter/IDashboardPresenter'
import IHomeDataRowsPresenter from '../OTEStanding/IHomeDataRowsPresenter'
import IPersonalInfoInputValidator from '../PersonalInfoInputValidator/IPersonalInfoInputValidator'
import { locations, rolesAbbreviations } from './DropdownOptions'
import IHomePresenter from './Interfaces/IHomePresenter'

export const annualQuotaInputPlaceholder = 'Enter your current annual quota'
export const oteInputPlaceholder = 'Enter your current OTE'
export const missingRequiredFieldsErrorMessage = 'This field is required'
class HomePresenter implements IHomePresenter {
  private annualQuotaInput: string
  private errorMessage: string
  private inlineErrorMessage: string
  private loading: boolean
  private location: string
  private oteInput: string
  private role: string
  private view: ISubscribingView | null
  private name: string
  private numOfYearExperience: string
  private profileImage: string
  private yearGraduated: string
  private locationSearchInput: string
  private numOfYearExperienceSearchInput: string
  private yearGraduateSearchInput: string
  private hasExistingPersonalData: boolean
  private inEditMode: boolean
  private startedOnboardingQuestions: boolean
  private hasTakenAssessment: boolean

  constructor(
    private readonly tokenVerifier: IAuthTokenVerifier,
    private readonly numberFormatter: INumberFormatter,
    private readonly inputValidator: IPersonalInfoInputValidator,
    private readonly homeDataRowsPresenter: IHomeDataRowsPresenter,
    private readonly personalDataPutter: IPersonalInfoPutter,
    private readonly compensationDataGetter: IMarketCompensationDataGetter,
    private readonly personalInfoDataHandler: IPersonalInfoDataHandler,
    private readonly onboardingHeuristicValuesUpdater: IOnboardingHeuristicValuesUpdater,
    private readonly cookieGetter: ICookieGetter,
    private readonly cookieSetter: ICookieSetter,
    private readonly dashboardPresenter: IDashboardPresenter,
    private readonly dataStore: IPersonalDataStore
  ) {
    this.annualQuotaInput = ''
    this.errorMessage = ''
    this.hasExistingPersonalData = false
    this.inlineErrorMessage = ''
    this.inEditMode = false
    this.loading = true
    this.location = ''
    this.locationSearchInput = ''
    this.yearGraduateSearchInput = ''
    this.name = ''
    this.numOfYearExperienceSearchInput = ''
    this.numOfYearExperience = ''
    this.oteInput = ''
    this.role = ''
    this.profileImage = ''
    this.view = null
    this.yearGraduated = ''
    this.startedOnboardingQuestions = false
    this.hasTakenAssessment = Cookies.get(assessmentIdKey) === 'true'
  }

  public async initialize(): Promise<void> {
    this.tokenVerifier.verifyAuth()

    this.homeDataRowsPresenter.setSubscriber(this)
    this.updateView()
    await this.handlePersonalInfoData()

    this.loading = false
    this.updateView()
  }

  public shouldTakeAssessment(): boolean {
    return this.hasTakenAssessment
  }

  private async handlePersonalInfoData(): Promise<void> {
    const result = await this.personalInfoDataHandler.getPersonalInfoData()

    if (typeof result !== 'string' && this.hasPersonalInfoData(result)) {
      this.hasExistingPersonalData = true

      this.setLocation(result?.location ?? '')
      this.setRole(result?.title ?? '')
      this.setImage(result?.profilePicture ?? '')
      this.annualQuotaInput = result?.currentAnnualQuota?.toString() ?? 0
      this.oteInput = result?.currentOte?.toString() ?? ''
      this.yearGraduated = result?.graduationYear?.toString() ?? ''
      this.numOfYearExperience = result?.yearsOfExperience ?? ''
      this.name = result?.name ?? ''

      await this.fetchMarketCompensationData()
    }
  }

  private hasPersonalInfoData(result: PersonalInfoWithFactsDto): boolean {
    return !!result?.name && !!result?.yearsOfExperience
  }

  public hasExistingPersonalInfoData(): boolean {
    return this.hasExistingPersonalData
  }

  private setLocation(location: string): void {
    this.location =
      locations.find((item) => item.name.toLowerCase().includes(location?.toLowerCase()))?.name ??
      ''
  }

  private setRole(title: string): void {
    if (title && rolesAbbreviations.includes(StringHelper.getFirstChars(title))) {
      this.role = title
    }
  }

  private setImage(profilePicture: string): void {
    if (profilePicture) {
      this.profileImage = profilePicture
      this.cookieSetter.set(profileImageKey, profilePicture, { expires: 1 })
    }
  }

  public getProfileImage(): string {
    if (this.cookieGetter.get(profileImageKey)) {
      const profileImage = this.cookieGetter.get(profileImageKey)
      return `${serverBaseUrl}profile/${profileImage}`
    }

    if (this.profileImage) {
      return `${serverBaseUrl}profile/${this.profileImage}`
    }

    return ''
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getLocation(): string {
    return this.location
  }

  public getName(): string {
    return this.name.replace(/\b\w/g, (match) => match.toUpperCase())
  }

  public getLocationSearchInput(): string {
    return this.locationSearchInput
  }

  public getNumOfYearsExperienceSearchInput(): string {
    return this.numOfYearExperienceSearchInput
  }

  public getYearOfGraduatedSearchInput(): string {
    return this.yearGraduateSearchInput
  }

  public updateLocationSearchInput(input: string): void {
    this.locationSearchInput = input
    this.location = input
    this.updateView()
  }

  public updateNumOfYearsExperienceSearchInput(input: string): void {
    this.numOfYearExperienceSearchInput = input
    this.numOfYearExperience = input
    this.updateView()
  }

  public updateYearOfGraduatedSearchInput(input: string): void {
    this.yearGraduateSearchInput = input
    this.yearGraduated = input

    this.updateView()
  }

  public updateOteInput(input: string): void {
    this.oteInput = input
    this.updateView()
  }

  public isInEditMode(): boolean {
    return this.inEditMode
  }

  public getOteInput(): string {
    return StringHelper.getFormattedDollarAmount(this.oteInput)
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
    this.homeDataRowsPresenter.setView(view)
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public shouldShowAnnualQuotaCaption(): boolean {
    return !this.annualQuotaInput
  }

  public shouldShowOteCaption(): boolean {
    return !this.oteInput
  }

  public getAnnualQuotaInput(): string {
    return StringHelper.getFormattedDollarAmount(this.annualQuotaInput)
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  public getInlineErrorMessage(): string {
    return this.inlineErrorMessage
  }

  public getInvalidYearsOfExperienceErrorMessage(): string {
    const message = this.inputValidator.getInvalidYearsOfExperienceMessage(this.numOfYearExperience)
    if (message) {
      this.inEditMode = true
    }

    return message
  }

  public updateAnnualQuotaInput(input: string): void {
    this.annualQuotaInput = input
    this.updateView()
  }

  public async submitPersonalInfo(): Promise<void> {
    this.loading = true
    this.updateView()
    await this.handlePersonalInfoResult()
    this.loading = false
    this.updateView()
  }

  private async handlePersonalInfoResult(): Promise<void> {
    const errorMessage = await this.personalDataPutter.sendPersonalInfo({
      jobTitle: this.role,
      location: this.location,
      yearGraduated: this.yearGraduated
    })
    await this.onboardingHeuristicValuesUpdater.updateOnboardingHeuristicValues({
      yearsOfExperience: this.numOfYearExperience,
      quota: parseFloat(StringHelper.filterOutCommaFromNumberString(this.annualQuotaInput)),
      ote: parseFloat(StringHelper.filterOutCommaFromNumberString(this.oteInput))
    })

    if (errorMessage) {
      this.errorMessage = errorMessage
      this.updateView()
    } else {
      await this.fetchMarketCompensationData()
    }
  }

  private async fetchMarketCompensationData(): Promise<void> {
    const marketData = await this.compensationDataGetter.getMarketCompensationData(
      parseInt(this.cookieGetter.get(userIdKey), 10)
    )
    this.handleMarketDataResult(marketData)
  }

  private handleMarketDataResult(marketData: any): void {
    if (typeof marketData === 'string') {
      this.errorMessage = marketData
    } else {
      this.updateRowData(marketData)
      this.setDataStore(marketData)
      this.inEditMode = false
    }
    this.updateView()
  }

  private updateRowData(marketData: MarketCompensationDataDto): void {
    this.homeDataRowsPresenter.setMarketCompensationData(marketData)
    this.homeDataRowsPresenter.updateRole(this.role)
    this.homeDataRowsPresenter.updateNumOfYearsExperience(this.numOfYearExperience)
    this.homeDataRowsPresenter.updateFormattedAnnualQuota(this.getFormattedAnnualQuota())
  }

  private setDataStore(marketData: MarketCompensationDataDto): void {
    this.dataStore.setMarketData(marketData)
    this.dataStore.setRole(this.role)
    this.dataStore.setAnnualQuota(this.getFormattedAnnualQuota())
    this.dataStore.setYearsOfExperience(this.numOfYearExperience)
  }

  private getFormattedAnnualQuota(): string {
    return this.numberFormatter.formatLargeAmount(this.annualQuotaInput)
  }

  public updateNumOfYearsExperience(input: string): void {
    this.numOfYearExperience = input
    this.updateView()
  }

  public updateLocation(input: string): void {
    this.location = input
    this.updateView()
  }

  public updateRole(input: string): void {
    this.role = input
    this.updateView()
  }

  public updateYearGraduated(input: string): void {
    this.yearGraduated = input
    this.updateView()
  }

  public getNumOfYearsExperience(): string {
    return this.numOfYearExperience
  }

  public getRole(): string {
    return this.role
  }

  public getYearGraduated(): string {
    return this.yearGraduated
  }

  public getDashboardPresenter(): IDashboardPresenter {
    return this.dashboardPresenter
  }

  public getDataRowsPresenter(): IHomeDataRowsPresenter {
    return this.homeDataRowsPresenter
  }

  public notifiedToUpdate(): void {
    this.inEditMode = true
    this.updateView()
  }

  public toggleShouldShowEditMode(): void {
    this.inEditMode = !this.inEditMode
    this.updateView()
  }

  public hasStartedOnboardingQuestions(): boolean {
    return this.startedOnboardingQuestions
  }

  public startOnboardingQuestions(): void {
    this.startedOnboardingQuestions = true
    this.updateView()
  }
}

export default HomePresenter
