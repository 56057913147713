import {
  cognitiveAggregateHeuristicId,
  mindsetAggregateHeuristicId,
  motorAggregateHeuristicId,
  socialAggregateHeuristicId,
  trajectoryAggregateHeuristicId
} from '../../APIs/AssessmentMetricsFetcher/HeuristicIdsForAssessmentResult'
import CourseResource from './CourseResource'

const heuristicIdToCourseResources: Map<number, CourseResource> = new Map()
  .set(cognitiveAggregateHeuristicId, {
    description: `In a world of overwhelming abundance, customers most often have the power to select or ignore salespeople based on their experience. Don't let that happen to you.`,
    isDataBasedOriginalContent: true,
    url: 'https://king-prawn-app-ysuye.ondigitalocean.app/how-sales-experience-has-changed/index.html',
    courseTitle: 'How Sales Experience Has Changed'
  })
  .set(socialAggregateHeuristicId, {
    description: `Want to learn how to politically navigate your career? Understand how to masterfully navigate the political landscape.`,
    isDataBasedOriginalContent: true,
    url: 'https://king-prawn-app-ysuye.ondigitalocean.app/navigate-the-political-landscape/index.html',
    courseTitle: 'Navigate the political landscape of your company'
  })
  .set(motorAggregateHeuristicId, {
    description: `Learn how to prospect with cold calling and how to craft an initial sales pitch.`,
    isDataBasedOriginalContent: true,
    url: 'https://king-prawn-app-ysuye.ondigitalocean.app/perfect-pitch/index.html',
    courseTitle: 'Build an outbound prospecting pitch'
  })
  .set(mindsetAggregateHeuristicId, {
    description: `Improve your outbound prospecting efforts with this five-step methodology. You will be able to set more discovery calls and qualifying meetings using this method.`,
    isDataBasedOriginalContent: true,
    url: 'https://king-prawn-app-ysuye.ondigitalocean.app/self-reflection/index.html',
    courseTitle: 'Get more people answering your calls'
  })
  .set(trajectoryAggregateHeuristicId, {
    description: `Most managers have access to a plethora of data; the challenge new leaders face is finding the time to analyze data and accurately understand which drivers are affecting their team the most.`,
    isDataBasedOriginalContent: true,
    url: 'https://king-prawn-app-ysuye.ondigitalocean.app/use-data-to-prioritize-what-matters-most/index.html',
    courseTitle: 'Use data to drive your sales career trajectory'
  })

export default heuristicIdToCourseResources
