import Cookies from 'js-cookie'
import { observable } from 'mobx'

import UserStageFetcher from '../APIs/UserStageFetcher/UserStageFetcher'
import Fetcher from '../Drivers/Fetcher'
import { userIdKey } from '../localCommon/CookieKeys'

const assessmentStore = observable({
  currentStage: -1,
  currentAssessmentId: -1,
  fetchCurrentStage: async (): Promise<void> => {
    const userId = parseInt(Cookies.get(userIdKey) ?? '', 10)

    if (typeof userId === 'number' && assessmentStore.currentStage === -1) {
      const fetcher = new UserStageFetcher(new Fetcher(), userId)
      assessmentStore.currentStage = (await fetcher.getUserStage())?.completedStages
    }
  },
  shouldShowAssessmentIntroPage: (): boolean => {
    return assessmentStore.currentAssessmentId !== -1
  },
  updateInProgressAssessmentId: (assessmentId: number): void => {
    assessmentStore.currentAssessmentId = assessmentId
  },
  updateCurrentStage: (stage: number): void => {
    assessmentStore.currentStage = stage
  }
})

export default assessmentStore
