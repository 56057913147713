import './style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedButton from '../../../localCommon/Button/OutlinedButton'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IResumeUploadPresenter from './Presenter/IResumeUploadPresenter'

type Props = {
  presenter: IResumeUploadPresenter
}

class ResumeUpload extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.initialize()
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const title = presenter.getButtonTitle()
    const errorMessage = presenter.getErrorMessage()
    const successMessage = presenter.getSuccessMessage()
    const isLoading = presenter.isLoading()

    return (
      <div className='profile-profileSectionWrapper'>
        {isLoading && <OutlinedButton>LOADING...</OutlinedButton>}
        {!isLoading && (
          <OutlinedButton>
            {title}
            <input
              accept='.pdf,.doc,.docx,.rtf,.odt,.txt'
              className='profile-resumeInput'
              onChange={this.validateThenUploadFile}
              type='file'
            />
          </OutlinedButton>
        )}
        {errorMessage && <span className='error mdc-text-field-helper-text'>{errorMessage}</span>}

        {successMessage && (
          <span className='success mdc-text-field-helper-text'>{successMessage}</span>
        )}
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  validateThenUploadFile = async ({ target }: { target: HTMLInputElement }): Promise<void> => {
    const { presenter } = this.props
    if (target?.validity?.valid && target.files) {
      const files = Array.from(target.files)

      if (files && files.length) {
        presenter.validateThenUploadFile(files[0] as any)
      }
    } else {
      presenter.setErrorMessage('Invalid file')
    }
  }
}

export default ResumeUpload
