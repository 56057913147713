import {
  PERFORMANCE_ASSESSMENT_ID,
  PSYCHOMETRIC_ASSESSMENT_ID
} from '../../../APIs/AssessmentStarter/AssessmentStarter'
import NextStepDto from './NextStepDto'

export const progressAfterFinishingOnboardingQuestions = 33
export const progressAfterFinishingPerformanceAssessment = 66
export const progressAfterFinishingPsychographicAssessment = 100
export const assessments = [PERFORMANCE_ASSESSMENT_ID, PSYCHOMETRIC_ASSESSMENT_ID]
const stepNumberToStep: Map<number, NextStepDto> = new Map()
  .set(PERFORMANCE_ASSESSMENT_ID, {
    id: PERFORMANCE_ASSESSMENT_ID,
    title: 'Strengthen your profile',
    label: 'finish performance profile',
    path: '/assessment',
    items: [
      'Finish the performance section of your profile',
      'view metric based percentile standings'
    ]
  })
  .set(PSYCHOMETRIC_ASSESSMENT_ID, {
    id: PSYCHOMETRIC_ASSESSMENT_ID,
    title: 'Find out how hirable you are',
    label: 'psychographic Assessment',
    path: '/psychometric',
    items: [
      'See how you measure up vs. what companies are looking for.',
      ' Identify gaps in your skillset.',
      'Get recommendations for how to improve.'
    ]
  })
export default stepNumberToStep
