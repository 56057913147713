import './style.css'

import React, { Component, ReactElement } from 'react'

import BackToPreviousPage from '../../localCommon/BackToPreviousPage/BackToPreviousPage'
import ContainedButton from '../../localCommon/Button/ContainedButton'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import { loginRoute } from '../RouteConstants'
import IPasswordResetPresenter from './Presenter/IPasswordResetPresenter'

type Props = {
  presenter: IPasswordResetPresenter
}

class PasswordReset extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='blank-page-padding'>
        <p className='headline6 passwordReset-textHeaders'>Reset password</p>
        <p className='passwordReset-textHeaders'>
          {`*Valid passwords are 8-40 characters 
      long with an upper case letter and a number`}
        </p>
        <p className='error'>{presenter.getErrorMessage()}</p>
        <p className='success'>{presenter.getSuccessMessage()}</p>
        <div>
          <OutlinedInput
            caption=''
            id='password'
            isRequired
            label='Password'
            onChange={this.updatePassword}
            shouldShowErrorMessage={false}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$'
            value={presenter.getPassword()}
            type='password'
          />
        </div>
        <div>
          <OutlinedInput
            caption=''
            id='confirm-password'
            isRequired
            label='Confirm Password'
            onChange={this.updateConfirmPassword}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$'
            shouldShowErrorMessage={false}
            value={presenter.getConfirmationPassword()}
            type='password'
          />
        </div>
        <div className='buttons-group'>
          <BackToPreviousPage route={loginRoute} label='Return to Login' />
          <ContainedButton
            onClick={() => this.sendPassword()}
            type='submit'
            isButtonDisabled={presenter.isSubmitButtonDisabled()}
          >
            Reset Password
          </ContainedButton>
        </div>
      </div>
    )
  }

  public update = (): void => {
    this.setState({})
  }

  updatePassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.setPassword(target.value)
  }

  updateConfirmPassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.setConfirmationPassword(target.value)
  }

  sendPassword = (): void => {
    this.props.presenter.sendPassword()
  }
}

export default PasswordReset
