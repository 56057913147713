import React, { Component, ReactElement } from 'react'

import ISsoLoginResponsePresenter from './Presenters/Interfaces/ISsoLoginResponsePresenter'

type Props = {
  presenter: ISsoLoginResponsePresenter
}

class SsoLoginResponse extends Component<Props> {
  render(): ReactElement {
    this.handleEncodedAuthDto()
    return <div></div>
  }

  handleEncodedAuthDto = (): void => {
    const { presenter } = this.props
    const urlParams = new URLSearchParams(window.location.search)

    presenter.decodeAndHandleAuthToken(
      urlParams.get('auth') as string,
      urlParams.get('name') as string
    )
  }
}

export default SsoLoginResponse
