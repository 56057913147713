import IHeuristicsGetter from '../../APIs/HeuristicGetter/IHeuristicGetter'
import INextPromptGetter from '../../APIs/NextPromptGetter/INextPromptGetter'
import FactResponseDto from '../../APIs/PromptResponseSubmitter/FactResponseDto'
import IPromptResponseSubmitter from '../../APIs/PromptResponseSubmitter/IPromptResponseSubmitter'
import MeasureResponseDto from '../../APIs/PromptResponseSubmitter/MeasureResponseDto'
import IRemainingNumberOfQuestionsGetter from '../../APIs/RemainingNumberOfQuestionsGetter/IRemainingNumberOfQuestionsGetter'
import PromptDtoWithUnit from '../../Routes/Assessment/Presenter/PromptDtoWithUnit'
import INextPromptHandler from './INextPromptHandler'

class NextPromptHandler implements INextPromptHandler {
  constructor(
    private readonly responseSubmitter: IPromptResponseSubmitter,
    private readonly nextPromptGetter: INextPromptGetter,
    private readonly heuristicGetter: IHeuristicsGetter,
    private readonly numTotalQuestionsGetter: IRemainingNumberOfQuestionsGetter
  ) {}

  public async getNextPrompt(
    assessmentId: number,
    promptId: number,
    responseDto: MeasureResponseDto | FactResponseDto,
    responseId: number | undefined
  ): Promise<string | PromptDtoWithUnit> {
    await this.responseSubmitter.submitResponse(assessmentId, promptId, responseDto)
    const nextPromptResult = await this.nextPromptGetter.getNextPromptByCurrentPromptId(
      assessmentId,
      promptId,
      responseId
    )

    if (typeof nextPromptResult === 'string') {
      return nextPromptResult
    }

    const heuristic = await this.heuristicGetter.getHeuristicById(nextPromptResult.heuristicId)
    const numRemainingQuestions = await this.numTotalQuestionsGetter.getNumberOfRemainingQuestions(
      assessmentId,
      nextPromptResult.id
    )

    return {
      ...nextPromptResult,
      numRemainingQuestions,
      unit: heuristic?.unit ?? ''
    }
  }
}

export default NextPromptHandler
