import './style.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  label: string
}

class Tag extends Component<Props> {
  render(): ReactElement {
    return <div className='caption tag'>{this.props.label}</div>
  }
}

export default Tag
