import Cookies from 'js-cookie'

import IAssessmentResultAdaptor from '../../Adaptors/AssessmentResultAdaptor/IAssessmentResultAdaptor'
import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import { userIdKey } from '../../localCommon/CookieKeys'
import CoreAssessmentDto from '../../Routes/PersonalInfoData/CoreAssessments/AssessmentBars/CoreAssessmentDto'
import UserAggregateHeuristicScorecardDto from './Dtos/UserAggregateHeuristicScorecardDto'
import IAssessmentResultHandler from './IAssessmentResultHandler'

class AssessmentResultHandler implements IAssessmentResultHandler {
  constructor(
    private readonly adaptor: IAssessmentResultAdaptor,
    private readonly fetcher: IFetcher
  ) {}

  public async getAssessmentMetrics(userId?: number): Promise<CoreAssessmentDto[]> {
    const userToRequest = userId ?? Cookies.get(userIdKey)
    const res = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${serverBaseUrl}user/${userToRequest}/scorecard`
    })

    if (!Array.isArray(res)) {
      return []
    }

    try {
      return res.map((dto: UserAggregateHeuristicScorecardDto) =>
        this.adaptor.getCoreAssessment(dto, dto?.score?.score)
      )
    } catch (err) {
      return []
    }
  }
}

export default AssessmentResultHandler
