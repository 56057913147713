import IAssessmentScoreFetcher from '../../../../../APIs/AssessmentScoreFetcher/IAssessmentScoreFetcher'
import ICurrentStageUpdater from '../../../../../APIs/CurrentStageUpdater/ICurrentStageUpdater'
import IAssessmentResultHandler from '../../../../../Handlers/AssessmentResultHandler/IAssessmentResultHandler'
import DateHelper from '../../../../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../../../../localCommon/Interfaces/ISubscribingView'
import ICurrentStageIndexHandler from '../../../../../Models/Interfaces/ICurrentStageIndexHandler'
import CoreAssessmentDto from '../../../CoreAssessments/AssessmentBars/CoreAssessmentDto'
import AppointmentDatesDto from '../../AppointmentDatesRetriever/AppointmentDatesDto'
import IAppointmentDatesRetriever from '../../AppointmentDatesRetriever/IAppointmentDatesRetriever'
import AssessmentStageDto from './AssessmentStageDto'
import IAssessmentResultPresenter from './IAssessmentResultPresenter'
import { stagePreScreen, stageTwo } from './StageIndexToMessageContent'

export const calendlyLink = 'https://calendly.com/databased-recruiting/30min'
export const assessmentMetricBenchmarkScore = 3.6
export const getPercentileNumberGivenPercentile = (givenPercentile: number): number => {
  const invertedPercentile = 100 - givenPercentile
  if (invertedPercentile <= 5) {
    return 5
  }
  if (invertedPercentile <= 10) {
    return 10
  }
  if (invertedPercentile <= 15) {
    return 15
  }
  if (invertedPercentile <= 20) {
    return 20
  }
  if (invertedPercentile <= 25) {
    return 25
  }

  if (invertedPercentile >= 75) {
    // For percentiles greater than or equal to 75, return the nearest multiple of 5
    return Math.round(invertedPercentile / 5) * 5
  }

  // For percentiles between 25 and 75, return the nearest multiple of 10
  return Math.round(invertedPercentile / 10) * 10
}

class AssessmentResultPresenter implements IAssessmentResultPresenter {
  private shouldDisplayNextStep: boolean
  private shouldLoadAppointmentLoadingState: boolean
  private hasScheduledMeeting: boolean
  private loading: boolean
  private score: number
  private view: ISubscribingView | null
  private selectedMetric: number | null
  private _isLoadingAppointments: boolean
  private appointmentDates: AppointmentDatesDto[]
  private coreAssessmentDtos: CoreAssessmentDto[]

  constructor(
    private readonly appointmentDatesRetriever: IAppointmentDatesRetriever,
    private readonly assessmentResultHandler: IAssessmentResultHandler,
    private readonly scoreFetcher: IAssessmentScoreFetcher,
    private readonly stageUpdater: ICurrentStageUpdater,
    private readonly stageHandler: ICurrentStageIndexHandler,
    private readonly assessmentId: number,
    private readonly userId: number
  ) {
    this.shouldLoadAppointmentLoadingState = false
    this.score = 0
    this.hasScheduledMeeting = false
    this.loading = true
    this.shouldDisplayNextStep = false
    this._isLoadingAppointments = true
    this.selectedMetric = null
    this.view = null

    this.appointmentDates = []
    this.coreAssessmentDtos = []
  }

  public shouldShowAppointmentLoadingState(): boolean {
    return this.shouldLoadAppointmentLoadingState
  }

  public toggleShowAppointmentLoadingState(): void {
    this.shouldLoadAppointmentLoadingState = !this.shouldLoadAppointmentLoadingState
  }

  public hasFailedAssessment(): boolean {
    return this.score < assessmentMetricBenchmarkScore
  }

  public isLoadingMetrics(): boolean {
    return this.loading
  }

  public async initialize(): Promise<void> {
    await this.stageHandler.fetchCurrentStage()
    this.coreAssessmentDtos = await this.assessmentResultHandler.getAssessmentMetrics(this.userId)
    this.score = await this.scoreFetcher.getAssessmentScore(this.assessmentId)
    if (this.hasFailedAssessment()) {
      this.stageHandler.updateCurrentStage(stagePreScreen)
    }
    this.loading = false
    this.updateView()
  }

  public getSelectedMetric(): CoreAssessmentDto | null {
    return this.coreAssessmentDtos?.find((dto) => dto.id === this.selectedMetric) ?? null
  }

  public shouldShowMetricDetail(): boolean {
    return this.selectedMetric !== null
  }

  public selectAssessmentMetricToShow(id: number | null): void {
    this.selectedMetric = id
    this.updateView()
  }

  public getAssessmentMetrics(): CoreAssessmentDto[] {
    return this.coreAssessmentDtos
  }

  public getMeetingTime(): string {
    if (this.getScheduleTime()) {
      return `Scheduled: ${this.getScheduleTime()}`
    }

    return ''
  }

  public getScheduledDateTime(): string {
    if (Array.isArray(this.appointmentDates) && this.appointmentDates.length) {
      return `${DateHelper.getShortMonthAndDate(new Date(this.appointmentDates[0]?.startTime))} at ${DateHelper.getShortTime(new Date(this.appointmentDates[0]?.startTime))}`
    }

    return ''
  }

  private getScheduleTime(): string {
    if (Array.isArray(this.appointmentDates) && this.appointmentDates.length) {
      return DateHelper.getShortMonthAndTime(new Date(this.appointmentDates[0]?.startTime))
    }

    return ''
  }

  public isLoadingMeetings(): boolean {
    return this._isLoadingAppointments
  }

  public getCurrentStage(): AssessmentStageDto | null {
    return {
      header: 'Finished! 🚀',
      title: 'Congratulations on completing the first interview!',
      content: `We are currently reviewing your responses to identify any roles that might be a good fit for you. We will follow up with you via email to discuss the next steps. If you have any questions, feel free to reach out to us at taly@databased.com.`,
      hasButton: false,
      stepItems: []
    }
  }

  public hasScheduledAMeeting(): boolean {
    return this.hasScheduledMeeting
  }

  public scheduleAMeeting(): void {
    window.open(calendlyLink, '_blank')
    this.stageHandler.updateCurrentStage(stageTwo)
    this.updateView()
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public shouldShowNextStepsPage(): boolean {
    return this.shouldDisplayNextStep
  }

  public toggleNextStepsPage(): void {
    this.shouldDisplayNextStep = !this.shouldDisplayNextStep
    this.updateView()
  }

  public async refocusedOnTab(): Promise<void> {
    this._isLoadingAppointments = true
    this.appointmentDates = []

    this.updateView()
    await this.getAppointments()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public async getAppointments(): Promise<void> {
    this._isLoadingAppointments = false
    this.updateView()
  }
}

export default AssessmentResultPresenter
