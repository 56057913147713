import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../../localCommon/constant'
import AppointmentDatesDto from './AppointmentDatesDto'
import IAppointmentDatesRetriever from './IAppointmentDatesRetriever'

class AppointmentDatesRetriever implements IAppointmentDatesRetriever {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getAppointmentDates(): Promise<AppointmentDatesDto[]> {
    return this.fetcher.fetch({
      url: `${serverBaseUrl}user/${this.userId}/appointments`,
      method: 'GET',
      body: null
    })
  }
}

export default AppointmentDatesRetriever
