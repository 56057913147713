import React, { Component, Fragment, ReactElement } from 'react'

import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedButton from '../../localCommon/Button/OutlinedButton'
import INavigationPresenter from '../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import IResponseView from './Presenters/Interfaces/IResponseView'

type Props = {
  canBacktrackToPreviousPrompt: boolean
  hasValidInput: boolean
  isRequired: boolean
  presenter: INavigationPresenter & INavigationTerminator
  subscriber: IResponseView
}

type State = {
  isSubmitting: boolean
}

class NavigationButtonGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isSubmitting: false
    }
  }

  render(): ReactElement {
    const { isRequired, presenter } = this.props

    return (
      <Fragment>
        <p className='error'>{presenter.getErrorMessage()}</p>
        <div className='navigation-buttons'>
          {this.renderPreviousButton()}
          <div className='home-nav'>
            {!isRequired && (
              <OutlinedButton onClick={this.proceedToNextQuestion}>Skip</OutlinedButton>
            )}
            {presenter.isLastQuestion() ? (
              <ContainedButton onClick={this.submitForm} type='button'>
                Continue
              </ContainedButton>
            ) : (
              <ContainedButton onClick={this.proceedToNextQuestion} type='button'>
                Next
              </ContainedButton>
            )}
          </div>
        </div>
      </Fragment>
    )
  }

  renderPreviousButton = (): ReactElement => {
    const { canBacktrackToPreviousPrompt, presenter } = this.props

    if (canBacktrackToPreviousPrompt && !presenter.isFirstQuestion()) {
      return <OutlinedButton onClick={this.backToPreviousQuestion}>Prev</OutlinedButton>
    }

    return <div></div>
  }

  backToPreviousQuestion = (): void => {
    if (this.props.canBacktrackToPreviousPrompt) {
      this.props.presenter.backToPreviousQuestion()
    }
  }

  proceedToNextQuestion = (): void => {
    if (!this.props.hasValidInput && this.props.subscriber) {
      this.notifySubscriberToShowErrorMessage()
    } else {
      this.props.presenter.proceedToNextQuestion()
    }
  }

  notifySubscriberToShowErrorMessage = (): void => {
    this.props.subscriber.showErrorMessage()
  }

  submitForm = (): void => {
    const { isSubmitting } = this.state
    if (isSubmitting) {
      return
    }
    if (!this.props.hasValidInput && this.props.subscriber) {
      this.notifySubscriberToShowErrorMessage()
    } else {
      this.setState({ isSubmitting: true })
      this.props.presenter.finishNavigation()
      const twoSeconds = 2000
      setTimeout(() => this.setState({ isSubmitting: false }), twoSeconds)
    }
  }
}

export default NavigationButtonGroup
