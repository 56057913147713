import { authTokenKey } from '../../CookieKeys'
import ICookieGetter from '../../Interfaces/ICookieGetter'
import ISignOutHelper from '../SignOutHelper/ISignOutHelper'
import IAuthTokenVerifier from './IAuthTokenVerifier'

class AuthTokenVerifier implements IAuthTokenVerifier {
  constructor(
    private readonly cookieGetter: ICookieGetter,
    private readonly signOutHelper: ISignOutHelper
  ) {}

  public verifyAuth(): void {
    if (!this.cookieGetter.get(authTokenKey)) {
      this.signOutHelper.signOut()
    }
  }
}

export default AuthTokenVerifier
