import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import AssessmentResultDto from '../Presenter/InterfacesAndDtos/AssessmentResultDto'
import IAssessmentRowPresenter from './InterfacesAndDtos/IAssessmentRowPresenter'

class AssessmentRowPresenter implements IAssessmentRowPresenter {
  public isOpen: boolean
  private view: ISubscribingView | null
  private title: string

  constructor(
    private readonly assessment: AssessmentResultDto,
    private readonly fetcher: IFetcher
  ) {
    this.view = null
    this.isOpen = false
    this.title = ''
  }

  public async initialize(): Promise<void> {
    await this.getAssessmentTitle()
    this.updateView()
  }

  public getTitle(): string {
    return this.title
  }

  private async getAssessmentTitle(): Promise<void> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}assessment/${this.assessment.assessmentId}`,
      body: null
    })

    if (result) {
      this.title = result.title
    }
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getAssessment(): AssessmentResultDto {
    return this.assessment
  }

  public handleClick(): void {
    this.isOpen = !this.isOpen
    this.updateView()
  }
}

export default AssessmentRowPresenter
