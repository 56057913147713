import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import DateHelper from '../../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IResumeUploadPresenter from '../../EditAccount/Resume/Presenter/IResumeUploadPresenter'
import { defaultButtonTitle } from '../../EditAccount/Resume/Presenter/ResumeUploadPresenter'
import { assessmentRoute, candidateViewJobRoute } from '../../RouteConstants'
import JobDto from '../Dtos/JobDto'
import IApplyToJobPresenter from './InterfacesAndDtos/IApplyToJobPresenter'
import JobApplicationPreRequisitesDto from './InterfacesAndDtos/JobApplicationPreRequisitesDto'
import JobPromptAndResponseDto from './InterfacesAndDtos/JobPromptAndResponseDto'
import IQuestionnaireFormPresenter from './JobSpecificQuestionnaire/IQuestionnaireFormPresenter'

class ApplyToJobPresenter
  implements IApplyToJobPresenter, IResumeUploadPresenter, IQuestionnaireFormPresenter
{
  private view: ISubscribingView | null
  private loading: boolean
  private jobDto: JobDto | null
  private preReqs: JobApplicationPreRequisitesDto | null
  private resumeLoading: boolean
  private showQuestionnaire: boolean
  private errMessageForApplication: string | null
  private hasAlreadyAppliedToJob: boolean
  private applySuccessMessage: string | null

  constructor(
    private readonly jobId: number,
    private readonly userId: number,
    private readonly fetcher: IFetcher,
    private readonly resumeHandler: IResumeUploadPresenter
  ) {
    this.view = null
    this.loading = false
    this.jobDto = null
    this.preReqs = null
    this.resumeLoading = false
    this.showQuestionnaire = false
    this.errMessageForApplication = null
    this.hasAlreadyAppliedToJob = false
    this.applySuccessMessage = null
  }

  public async initialize(): Promise<void> {
    this.loading = true
    this.updateView()

    const jobResult = await this.fetcher.fetch({
      body: null,
      url: `${serverBaseUrl}jobs/${this.jobId}`,
      method: 'GET'
    })
    if (this.isValidJobDto(jobResult)) {
      this.jobDto = jobResult
    }

    const preReqsResult = await this.fetcher.fetch({
      body: null,
      url: `${serverBaseUrl}job/${this.jobId}/preRequisites/user/${this.userId}`,
      method: 'GET'
    })

    if (this.isValidJobPrequisitesDto(preReqsResult)) {
      this.preReqs = preReqsResult
    }

    const userApplicationsResult = await this.fetcher.fetch({
      body: null,
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/applications`
    })
    this.setHasUserAppliedToJob(userApplicationsResult)

    await this.resumeHandler.initialize()

    this.loading = false
    this.updateView()
  }

  private setHasUserAppliedToJob(result: any): void {
    if (Array.isArray(result)) {
      if (result.some((app) => app.jobId === this.jobId)) {
        this.hasAlreadyAppliedToJob = true
      }
    }
  }

  public getJobLocation(): string {
    return this.jobDto?.location ?? ''
  }

  public getJobOTERange(): string {
    if (this.jobDto === null) {
      return ''
    }
    const low = this.jobDto.baseCompensationLow + this.jobDto.variableCompensation
    const high = this.jobDto.baseCompensationHigh + this.jobDto.variableCompensation
    return `$${low.toLocaleString()} - $${high.toLocaleString()}`
  }

  public getJobWorkType(): string {
    return this.jobDto?.jobType ?? ''
  }

  private isValidJobDto(result: any): result is JobDto {
    return !!result?.id
  }

  private isValidJobPrequisitesDto(result: any): result is JobApplicationPreRequisitesDto {
    return result?.assessment !== undefined && result?.prompts !== undefined
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getJobHeader(): string {
    return this.jobDto?.jobTitle ?? ''
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public hasRequiredPrompts(): boolean {
    if (this.preReqs) {
      return this.preReqs.prompts.prompts?.length > 0 ?? false
    }
    return false
  }

  public arePromptsCompleted(): boolean {
    if (!this.hasRequiredPrompts()) {
      return true
    }
    return this.preReqs?.prompts.completed ?? false
  }

  public isAssessmentCompleted(): boolean {
    if (this.preReqs) {
      return this.preReqs.assessment.completed
    }
    return false
  }

  public getResumeName(): string {
    const title = this.resumeHandler.getButtonTitle()
    return title === defaultButtonTitle ? '' : title
  }

  public hasResume(): boolean {
    return this.resumeHandler.getButtonTitle() !== defaultButtonTitle
  }

  public isReadyToApply(): boolean {
    return (
      this.arePromptsCompleted() &&
      this.hasResume() &&
      this.isAssessmentCompleted() &&
      !this.hasAlreadyAppliedToJob &&
      this.applySuccessMessage === null
    )
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public async validateThenUploadFile(file: any): Promise<void> {
    this.resumeLoading = true
    this.updateView()
    await this.resumeHandler.validateThenUploadFile(file)
    await this.resumeHandler.initialize()
    this.resumeLoading = false
    this.updateView()
  }

  public getButtonTitle(): string {
    return this.resumeHandler.getButtonTitle()
  }

  public getErrorMessage(): string {
    return this.resumeHandler.getErrorMessage()
  }

  public getSuccessMessage(): string {
    return this.resumeHandler.getSuccessMessage()
  }

  public setErrorMessage(message: string): void {
    this.resumeHandler.setErrorMessage(message)
  }

  public isResumeLoading(): boolean {
    return this.resumeLoading
  }

  public shouldShowQuestionnaire(): boolean {
    return this.showQuestionnaire
  }

  public toggleQuestionnaireForm(): void {
    this.showQuestionnaire = !this.showQuestionnaire
    this.updateView()
  }

  public getPrompts(): JobPromptAndResponseDto[] {
    return this.preReqs?.prompts.prompts ?? []
  }

  public handlePromptInput(promptId: number, input: string): void {
    if (this.preReqs === null) {
      return
    }

    for (let i = 0; i < this.preReqs?.prompts.prompts.length; i++) {
      if (this.preReqs?.prompts.prompts[i].id === promptId) {
        if (this.preReqs?.prompts.prompts[i]) {
          this.preReqs.prompts.prompts[i].response = input
        }
      }
    }
    this.updateView()
  }

  public canSubmitQuestionnaire(): boolean {
    if (!this.preReqs?.prompts.prompts) {
      return false
    }

    return this.preReqs.prompts.prompts.every((prompt) => {
      return prompt?.response !== null && prompt.response !== ''
    })
  }

  public async submitQuestionnaireResponses(): Promise<void> {
    if (!this.canSubmitQuestionnaire() || this.preReqs === null) {
      return
    }

    this.loading = true
    this.updateView()
    const { prompts } = this.preReqs.prompts
    const promises = prompts.map(async (prompt) => {
      return this.fetcher.fetch({
        body: {
          uploadMethodId: 4,
          value: prompt.response
        },
        method: 'PUT',
        url: `${serverBaseUrl}user/${this.userId}/heuristic/${prompt.heuristicId}`
      })
    })

    await Promise.all(promises)
    await this.initialize()
    this.loading = false
    this.toggleQuestionnaireForm()
    this.updateView()
  }

  public async submitApplication(): Promise<void> {
    if (!this.isReadyToApply()) {
      return
    }
    this.loading = true
    this.updateView()

    const result = await this.fetcher.fetch({
      body: this.preReqs,
      method: 'POST',
      url: `${serverBaseUrl}job/${this.jobId}/user/${this.userId}/apply`
    })

    if (this.wasSuccessfulApplication(result)) {
      this.errMessageForApplication = null
      this.applySuccessMessage =
        'Congratulations! You have successfully applied to this job. You should hear from us shortly.'
    } else {
      this.errMessageForApplication =
        'Unable to apply at this time. Please refresh and try again later.'
    }
    this.loading = false

    this.updateView()
  }

  public getErrorMessageForApplication(): string | null {
    return this.errMessageForApplication
  }

  private wasSuccessfulApplication(result: any): boolean {
    return !!result?.id
  }

  public hasUserAppliedToJob(): boolean {
    return this.hasAlreadyAppliedToJob
  }

  public getSuccessfulApplicationMessage(): string | null {
    return this.applySuccessMessage
  }

  public getQuestionnaireCompletedDateString(): string | null {
    if (this.preReqs?.prompts.completedDate !== null && this.preReqs?.prompts.completed === true) {
      return DateHelper.getDDMONYYYYDateString(new Date(this.preReqs?.prompts.completedDate))
    }
    return ''
  }

  public getAssessmentCompletedDateString(): string | null {
    if (
      this.preReqs?.assessment.completedDate !== null &&
      this.preReqs?.assessment.completed === true
    ) {
      return DateHelper.getDDMONYYYYDateString(new Date(this.preReqs?.assessment.completedDate))
    }
    return ''
  }

  public startAssessment(): void {
    window.location.href = `${assessmentRoute}/${this.preReqs?.assessment.assessmentId}?jobUrl=${candidateViewJobRoute}/${this.jobId}/apply`
  }
}

export default ApplyToJobPresenter
