import './style.scss'

import React, { Component, ReactElement } from 'react'

import CoreAssessmentDto from './CoreAssessmentDto'

type Props = {
  assessment: CoreAssessmentDto
}

class MetricDetailBar extends Component<Props> {
  render(): ReactElement {
    const { assessment } = this.props

    return (
      <div className='metricDetailBar' key={assessment.title}>
        <div className='assessmentBar-data'>
          <div
            className='metricDetailBar-progress'
            style={{
              background: `linear-gradient(to right, ${this.getFirstSection(assessment)}${this.getSecondSection(assessment)})`
            }}
          >
            <div className='white-section'></div>
            <div className='white-section'></div>
            <div className='white-section'></div>
            <div className='white-section'></div>
          </div>
        </div>
      </div>
    )
  }

  getFirstSection = (assessment: CoreAssessmentDto): string => {
    const progress = assessment.percentile

    if (progress > 0 && progress < 100) {
      return `rgba(192, 73, 248, 1) 0%, rgba(32, 80, 249, 1) ${progress}%`
    }

    if (progress === 0) {
      return 'rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, 1) 100%'
    }

    return 'rgba(192, 73, 248, 1) 0%, rgba(32, 80, 249, 1) 100%'
  }

  getSecondSection = (assessment: CoreAssessmentDto): string => {
    const progress = assessment.percentile

    if (progress > 0 && progress < 100) {
      return `, rgba(217, 217, 217, 1) ${progress}%, rgba(217, 217, 217, 1) 100%`
    }

    return ''
  }
}

export default MetricDetailBar
