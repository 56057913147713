import './style.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  id: string
  isChecked: boolean
  onChange: (input: string) => void
  title: string
}

class RadioButton extends Component<Props> {
  render(): ReactElement {
    const { id, isChecked, title } = this.props

    return (
      <div className='radioButton'>
        <div className='mdc-form-field'>
          <div className='mdc-radio'>
            <input
              className='mdc-radio__native-control'
              type='radio'
              id={id}
              name='radios'
              checked={isChecked}
              onChange={this.updateInput}
              value={id}
            />
            <div className='mdc-radio__background'>
              <div className='mdc-radio__outer-circle'></div>
              <div className='mdc-radio__inner-circle'></div>
            </div>
            <div className='mdc-radio__ripple'></div>
          </div>
          <label htmlFor={id} onClick={this.updateSelectedInput} id={id}>
            {title}
          </label>
        </div>
      </div>
    )
  }

  updateInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.onChange(target.value)
  }

  updateSelectedInput = (event: React.MouseEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.onChange(target.id)
  }
}

export default RadioButton
