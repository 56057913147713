/* eslint-disable no-restricted-syntax */

import Cookies from 'js-cookie'

import INonSerializableFetcher from '../../../../Drivers/Interfaces/INonSerializableFetcher'
import { serverBaseUrl } from '../../../../localCommon/constant'
import { authTokenKey, userIdKey } from '../../../../localCommon/CookieKeys'
import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import IResumeUploadPresenter from './IResumeUploadPresenter'

const validResumeFileTypes = ['pdf', 'doc', 'docx', 'rtf', 'odt', 'txt']
export const defaultButtonTitle = 'UPLOAD RESUMÉ'
class ResumeUploadPresenter implements IResumeUploadPresenter {
  private buttonTitle: string
  private view: ISubscribingView | null
  private errorMessage: string
  private file: File | null
  private successMessage: string
  private loading: boolean
  private userId: number

  constructor(private readonly fetcher: INonSerializableFetcher) {
    this.view = null
    this.buttonTitle = defaultButtonTitle
    this.errorMessage = ''
    this.successMessage = ''
    this.file = null
    this.loading = true
    this.userId = parseInt(Cookies.get(userIdKey) ?? '', 10)
  }

  public async initialize(): Promise<void> {
    this.loading = true
    await this.getResumeName()
    this.loading = false
    this.updateView()
  }

  public isLoading(): boolean {
    return this.loading
  }

  private async getResumeName(): Promise<void> {
    const response = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/resume/name`,
      body: null
    })
    if (response?.fileName !== null) {
      this.buttonTitle = response.fileName
    }
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getButtonTitle(): string {
    return this.buttonTitle
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  public setErrorMessage(message: string): void {
    this.errorMessage = message
    this.successMessage = ''
    this.updateView()
  }

  public getSuccessMessage(): string {
    return this.successMessage
  }

  private setSuccessMessage(message: string): void {
    this.successMessage = message
    this.errorMessage = ''
    this.updateView()
  }

  private setFile(file: File | null): void {
    if (file) {
      this.errorMessage = ''
      this.successMessage = ''
    }
    this.file = file
    this.buttonTitle = file?.name ?? defaultButtonTitle
    this.updateView()
  }

  public async validateThenUploadFile(file: any): Promise<void> {
    if (this.isValidFile(file)) {
      this.setFile(file)
      await this.uploadFile(file)
    } else {
      this.setFile(null)
    }
  }

  private isValidFile(file: any): file is File {
    if (!file) {
      this.setErrorMessage('Upload unsuccessful, please try again')
      return false
    }
    if (!this.isValidFileExtension(file?.type)) {
      this.setErrorMessage(
        `Invalid file format, please use any of the following file types: ${validResumeFileTypes.join(', ')}`
      )
      return false
    }
    return true
  }

  private isValidFileExtension(fileType: string): boolean {
    return validResumeFileTypes.some((type) => fileType.includes(type))
  }

  private async uploadFile(file: File): Promise<void> {
    const formData = new FormData()
    formData.append('resume', file as File)

    const response = await this.fetcher.fetch({
      url: `${serverBaseUrl}user/${this.userId}/resume`,
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get(authTokenKey)}`
      }
    })

    if (this.isValidResumeDto(response)) {
      this.setSuccessMessage('Successfully updated resumé')
    }
  }

  private isValidResumeDto(response: any): boolean {
    if (!response) {
      this.setErrorMessage('Unable to upload resumé, please try again later')
      return false
    }
    if (response.message) {
      this.setErrorMessage(response.message)
      return false
    }

    return true
  }
}

export default ResumeUploadPresenter
