import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import ICandidatePresenter from '../../CandidatePool/CandidateRow/Presenters/CandidatePresenter/ICandidatePresenter'

type Props = {
  id: number
  image: string
  name: string
  presenter: ICandidatePresenter
}

class CandidateUserProfile extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.setProgress()
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { image, presenter } = this.props
    const candidate = presenter.getCandidate()

    return (
      <div className='userProfile'>
        <div className='userProfile-content'>
          <div className='userProfile-progress'>
            <div className='userProfile-whiteBorder' />
            <img alt='user-profile' className='userProfile-image' src={image} />
          </div>
          <div>
            <div className='userProfile-nameWrapper'>
              <p className='headline5 userProfile-name'>{candidate?.name ?? 'No name entered'}</p>
            </div>
            <br></br>
            <p className='caption userProfile-strength'>
              Ideal Role: {candidate?.roleInterestedIn ?? 'Unknown'}
            </p>
            <p className='caption userProfile-strength'>
              Location: {candidate?.location ?? 'Unknown'}{' '}
            </p>
            {
              <p className='caption userProfile-strength'>
                Email: <span className='userProfile-email'>{candidate?.email ?? 'Unknown'}</span>
              </p>
            }
          </div>
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  getCandidateName = (): string => {
    const { name, id, presenter } = this.props

    if (presenter.shouldRevealCandidateInfo()) {
      return name
    }

    return `Candidate: ${id}`
  }

  setProgress = (): void => {
    const progressBar = document.querySelector('.userProfile-progress') as HTMLElement
    const endValue = 100
    const animationStartingPoint = 5
    const progressToDegree = 3.6
    const animationInterval = 30
    let currentValue = 0

    if (progressBar) {
      const progress = setInterval(() => {
        currentValue++

        progressBar.style.background = `conic-gradient(rgba(192, 73, 248, 1) ${animationStartingPoint * progressToDegree}deg, rgba(32, 80, 249, 1) ${currentValue * progressToDegree}deg, rgb(229, 229, 229) 0deg)`
        if (currentValue >= endValue) {
          clearInterval(progress)
        }
      }, animationInterval)
    }
  }
}

export default CandidateUserProfile
