import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

class WebPageToPdf {
  private async convertSvgToPng(imageSelector: string): Promise<string | null> {
    const imageElement = document.querySelector(imageSelector)

    if (imageElement instanceof HTMLImageElement) {
      const canvas = await html2canvas(imageElement, { useCORS: true, scale: 2 })
      return canvas.toDataURL('image/png')
    }

    console.error('The selected element is not an HTMLImageElement.')
    return null
  }

  /*
    This is meant only to be used with the CandidateSummaryPresenter. there is a hard coded image link and placement
    onto the pdf. If you want to extend this, make an abstract class and have two children. 
  */
  public async convertToFile(idOfElement: string, fileNameWithoutExt: string): Promise<void> {
    const profileElement = document.getElementById(idOfElement)
    if (profileElement === null) {
      console.error(`Element with id ${idOfElement} not found`)
      return
    }

    try {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()

      const canvas = await html2canvas(profileElement, { useCORS: true, scale: 2 })
      const imgData = canvas.toDataURL('image/png')

      const imgWidth = pdfWidth - 10
      const imgHeight = (canvas.height * pdfWidth) / canvas.width

      let heightLeft = imgHeight
      let position = 0

      while (heightLeft > 0) {
        pdf.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight)
        heightLeft -= pdfHeight
        position -= pdfHeight
        if (heightLeft > 0) pdf.addPage()
      }

      const profileImage = await this.convertSvgToPng('.userProfile-image')
      if (profileImage) {
        pdf.setPage(1)
        pdf.addImage(profileImage, 'PNG', 10, 10, 20, 20)
      } else {
        console.error('Failed to convert SVG to PNG.')
      }

      pdf.save(`${fileNameWithoutExt}.pdf`)
    } catch (error) {
      console.error('Failed to generate PDF:', error)
    }
  }
}

export default WebPageToPdf
