import AuthDto from '../../../APIs/Dtos/AuthDto'
import AdminHandler from '../../../Handlers/AdminHandler'
import {
  assessmentIdKey,
  authTokenKey,
  emailKey,
  nameKey,
  userIdKey
} from '../../../localCommon/CookieKeys'
import IAuthDecoder from '../../../localCommon/Interfaces/IAuthDecoder'
import ICookieSetter from '../../../localCommon/Interfaces/ICookieSetter'
import IWindowHistoryStateSetter from '../../../localCommon/Interfaces/IWindowHistoryStateSetter'
import { dashboardRoute } from '../../RouteConstants'
import ISsoLoginResponsePresenter from './Interfaces/ISsoLoginResponsePresenter'

class SsoLoginResponsePresenter implements ISsoLoginResponsePresenter {
  constructor(
    private readonly cookieSetter: ICookieSetter,
    private readonly windowHistoryStateSetter: IWindowHistoryStateSetter,
    private readonly authDecoder: IAuthDecoder
  ) {}

  public decodeAndHandleAuthToken(encodedAuthToken: string, userName: string): void {
    try {
      const authDto = JSON.parse(atob(encodedAuthToken))
      if (this.isValidResponse(authDto)) {
        this.cookieSetter.set(authTokenKey, authDto.auth.token, { expires: 1 })
        this.cookieSetter.set(userIdKey, authDto.userData.userId?.toString(), { expires: 1 })
        this.cookieSetter.set(emailKey, authDto.userData.email, { expires: 1 })
        this.cookieSetter.set(nameKey, this.authDecoder.decodeData(userName), { expires: 1 })
        this.cookieSetter.set(
          assessmentIdKey,
          authDto.userData.assessment.assessmentId?.toString() ?? null,
          {
            expires: 1
          }
        )
      }
    } catch (error) {
      // do nothing
    } finally {
      if (AdminHandler.isDataBasedAdmin()) {
        this.windowHistoryStateSetter.setState(dashboardRoute)
      } else {
        this.windowHistoryStateSetter.setState('/')
      }
    }
  }

  private isValidResponse(result: any): result is AuthDto {
    return (
      result.hasOwnProperty('auth') &&
      result.auth.hasOwnProperty('token') &&
      result.hasOwnProperty('userData')
    )
  }
}

export default SsoLoginResponsePresenter
