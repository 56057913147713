import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../../images'

type Props = {
  hasFailedTheAssessment: boolean
}

class StageOne extends Component<Props> {
  render(): ReactElement {
    return (
      <div className='section-with-border'>
        <p className='subtitle2'>{this.getStageOneTitle()}</p>
        <div className='nextSteps-verifiedStatus'>
          <img alt='assessment-verified' src={images.verifiedIcon} />
          <span className='body2'>{this.getStageOneMessage()}</span>
        </div>
      </div>
    )
  }

  getStageOneMessage = (): string => {
    if (this.props.hasFailedTheAssessment) {
      return 'Complete: Not a good match at this time'
    }

    return 'Complete: Passed!'
  }

  getStageOneTitle = (): string => {
    if (this.props.hasFailedTheAssessment) {
      return 'The pre-Screen'
    }

    return 'Stage 1: The pre-Screen'
  }
}

export default StageOne
