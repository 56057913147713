import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RecruitingRoleTypeDto from '../Candidate/InterfacesAndDtos/RecruitingRoleTypeDto'
import CheckBoxDto from './InterfacesAndDtos/CheckBoxDto'
import ICandidatePoolFilterPresenter from './InterfacesAndDtos/ICandidatePoolFilterPresenter'

class CandidatePoolFilterPresenter implements ICandidatePoolFilterPresenter {
  private view: ISubscribingView | null
  private scoreFilters: CheckBoxDto[]
  private roleFilters: CheckBoxDto[]
  private workSituationFilters: CheckBoxDto[]

  constructor(private readonly fetcher: IFetcher) {
    this.view = null
    this.scoreFilters = [
      {
        label: '3.75+',
        id: '3.75',
        isChecked: false
      },
      {
        label: '3.8+',
        id: '3.8',
        isChecked: false
      },
      {
        label: '3.85+',
        id: '3.85',
        isChecked: false
      },
      {
        label: '3.9+',
        id: '3.9',
        isChecked: false
      }
    ]

    this.roleFilters = []

    this.workSituationFilters = [
      {
        label: 'Willing to work remote',
        id: 'workRemote',
        isChecked: false
      },
      {
        label: 'Willing to work 100% in office',
        id: 'workInOffice',
        isChecked: false
      },
      {
        label: 'Willing to work hybrid',
        id: 'workHybrid',
        isChecked: false
      },
      {
        label: 'Willing to relocate',
        id: 'willingToRelocate',
        isChecked: false
      }
    ]
  }

  public async initialize(): Promise<void> {
    const res = await this.fetcher.fetch({
      url: `${serverBaseUrl}recruitingRoleTypes`,
      method: 'GET',
      body: null
    })

    if (Array.isArray(res)) {
      this.initializeRoleFilters(res)
    }
  }

  private initializeRoleFilters(dtos: RecruitingRoleTypeDto[]): void {
    this.roleFilters = dtos.map((dto) => {
      return {
        id: dto.role,
        isChecked: false,
        label: `${dto.role} (${dto.description})`
      }
    })
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getWorkSituationFilters(): CheckBoxDto[] {
    return this.workSituationFilters
  }

  public setWorkSituationFilters(label: string): void {
    for (let i = 0; i < this.workSituationFilters.length; i++) {
      const filter = this.workSituationFilters[i]
      if (filter.id === label) {
        this.workSituationFilters[i].isChecked = !this.workSituationFilters[i].isChecked
      }
    }

    this.updateView()
  }

  public getRoleFilters(): CheckBoxDto[] {
    return this.roleFilters
  }

  public setRoleFilters(label: string): void {
    for (let i = 0; i < this.roleFilters.length; i++) {
      const filter = this.roleFilters[i]
      if (filter.id === label) {
        this.roleFilters[i].isChecked = !this.roleFilters[i].isChecked
      }
    }

    this.updateView()
  }

  public getScoreFilters(): CheckBoxDto[] {
    return this.scoreFilters
  }

  public setScoreFilters(label: string): void {
    for (let i = 0; i < this.scoreFilters.length; i++) {
      const filter = this.scoreFilters[i]
      if (filter.id === label) {
        this.scoreFilters[i].isChecked = !this.scoreFilters[i].isChecked
      }
    }

    this.updateView()
  }
}

export default CandidatePoolFilterPresenter
