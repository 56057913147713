import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import HttpCodeAndMessageDto from '../Dtos/HttpCodeAndMessageDto'
import ILogInAuthenticator from './ILogInAuthenticator'

export const LOGIN_URL = `${serverBaseUrl}login`
class LogInAuthenticator implements ILogInAuthenticator {
  constructor(private readonly fetcher: IFetcher) {}

  public async login(email: string, password: string): Promise<HttpCodeAndMessageDto> {
    return this.fetcher.fetch({
      method: 'POST',
      body: { email, password },
      url: LOGIN_URL
    })
  }
}

export default LogInAuthenticator
