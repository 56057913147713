import { ssoLoginsRoute } from '../../../Routes/RouteConstants'
import {
  assessmentFeatureFlagKey,
  authTokenKey,
  emailKey,
  nameKey,
  profileImageKey,
  userIdKey
} from '../../CookieKeys'
import ICookieRemover from '../../Interfaces/ICookieRemover'
import IWindowPathUpdater from '../../Interfaces/IWindowPathUpdater'
import ISignOutHelper from './ISignOutHelper'

class SignOutHelper implements ISignOutHelper {
  constructor(
    private readonly cookieRemover: ICookieRemover,
    private readonly windowPathUpdater: IWindowPathUpdater
  ) {}

  public signOut(): void {
    this.cookieRemover.remove(authTokenKey)
    this.cookieRemover.remove(profileImageKey)
    this.cookieRemover.remove(emailKey)
    this.cookieRemover.remove(userIdKey)
    this.cookieRemover.remove(nameKey)
    this.cookieRemover.remove(assessmentFeatureFlagKey)

    this.windowPathUpdater.setPathName(ssoLoginsRoute)
  }
}

export default SignOutHelper
