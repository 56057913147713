import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import PromptDto from '../PromptGetter/PromptDto'
import INextPromptGetter from './INextPromptGetter'

export const noNextPromptMessage = 'No next prompt'
export const failedToGetNextPromptMessage = 'Failed to get next prompt. Please try again.'
class NextPromptGetter implements INextPromptGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getNextPromptByCurrentPromptId(
    assessmentId: number,
    currentPromptId: number,
    responseId?: number
  ): Promise<PromptDto | string> {
    const responseIdParam = responseId && !Number.isNaN(responseId) ? responseId : 1
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${serverBaseUrl}assessment/${assessmentId}/prompt/${currentPromptId}/next?responseId=${responseIdParam}`
    })

    if (this.isTheLastPrompt(result)) {
      return ''
    }

    if (this.isValidResponse(result)) {
      return result
    }

    return failedToGetNextPromptMessage
  }

  private isTheLastPrompt(response: any): boolean {
    return response?.hasOwnProperty('message') && response.message?.includes(noNextPromptMessage)
  }

  private isValidResponse(response: object): response is PromptDto {
    return (
      response?.hasOwnProperty('canBacktrackToPreviousPrompt') &&
      response?.hasOwnProperty('heuristicId') &&
      response?.hasOwnProperty('id') &&
      response?.hasOwnProperty('prompt') &&
      response?.hasOwnProperty('responseType')
    )
  }
}

export default NextPromptGetter
