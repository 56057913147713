import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { internalServerErrorHttpCode, serverBaseUrl } from '../../localCommon/constant'
import HttpCodeAndMessageDto from '../Dtos/HttpCodeAndMessageDto'
import UserAccountUpdateDto from '../Dtos/UserAccountUpdateDto'
import IUserAccountEditor from './IUserAccountEditor'

export const failedToUpdateUserAccountMessage = 'Failed to update user account. Please try again'
export const USER_ACCOUNT_UPDATE_URL = `${serverBaseUrl}user/account`
class UserAccountEditor implements IUserAccountEditor {
  constructor(private readonly fetcher: IFetcher) {}

  public async editAccount(dto: UserAccountUpdateDto): Promise<HttpCodeAndMessageDto> {
    const result = await this.fetcher.fetch({
      method: 'PUT',
      url: `${USER_ACCOUNT_UPDATE_URL}/${dto.id}`,
      body: dto
    })

    if (this.isValidResponse(result)) {
      return result
    }

    return {
      code: internalServerErrorHttpCode,
      message: failedToUpdateUserAccountMessage
    }
  }

  private isValidResponse(obj: any): obj is HttpCodeAndMessageDto {
    return obj?.hasOwnProperty('code') && obj?.hasOwnProperty('message')
  }
}

export default UserAccountEditor
