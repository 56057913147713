import IAssessmentFinisher from '../../../APIs/AssessmentFinisher/IAssessmentFinisher'
import IAssessmentScoreFetcher from '../../../APIs/AssessmentScoreFetcher/IAssessmentScoreFetcher'
import ICurrentStageUpdater from '../../../APIs/CurrentStageUpdater/ICurrentStageUpdater'
import { stageOneHeuristicId } from '../../../APIs/CurrentStageUpdater/StageHeuristicIds'
import IHeuristicsUpdateHandler from '../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/IHeuristicsUpdateHandler'
import { currentYear } from '../../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import IInternalRoleTriggerer from '../../../APIs/TemporaryInteralRoleTriggerer/IInternalRoleTriggerer'
import IInProgressAssessmentHandler from '../../../Handlers/InProgressAssessmentHandler/IInProgressAssessmentHandler'
import INextPromptHandler from '../../../Handlers/NextPromptHandler/INextPromptHandler'
import IPromptHandler from '../../../Handlers/PromptHandler/IPromptHandler'
import {
  oteHeuristicId,
  quotaHeuristicId,
  yearsInSalesHeuristicId
} from '../../../localCommon/constant'
import IWindowPathUpdater from '../../../localCommon/Interfaces/IWindowPathUpdater'
import IAuthTokenVerifier from '../../../localCommon/SignOut/AuthTokenVerifier/IAuthTokenVerifier'
import IAssessmentStore from '../../../Models/Interfaces/IAssessmentStore'
import AbstractNavigationPresenter from '../../PersonalInfoData/NavigationPresenter/AbstractNavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import INewAssessmentHandler from '../NewAssessmentHandler/INewAssessmentHandler'
import IAssessmentPresenter from './IAssessmentPresenter'
import PromptDtoWithUnit from './PromptDtoWithUnit'
import IPromptResponseDtoBuilder from './PromptResponseDtoBuilder/IPromptResponseDtoBuilder'
import IResponseGetter from './ResponseGetter/IResponseGetter'

export const DEFAULT_NUMBER_OF_QUESTIONS = 10
class AssessmentPresenter
  extends AbstractNavigationPresenter
  implements IAssessmentPresenter, INavigationTerminator
{
  private userAssessmentHistoryId: number | null
  private isBackToPreviousPromptAllowed: boolean
  private currentPrompt: PromptDtoWithUnit | null
  private heuristicId: number | null
  private questionIndexToPrompt: Map<number, PromptDtoWithUnit>
  private hasStartedAnAssessment: boolean
  private shouldRedirectToDashboard: boolean

  constructor(
    private readonly newAssessmentHandler: INewAssessmentHandler,
    private readonly promptHandler: IPromptHandler,
    private readonly nextPromptHandler: INextPromptHandler,
    private readonly promptResponseDtoBuilder: IPromptResponseDtoBuilder,
    private readonly responseGetter: IResponseGetter,
    private readonly tokenVerifier: IAuthTokenVerifier,
    private readonly assessmentFinisher: IAssessmentFinisher,
    private readonly assessmentStore: IAssessmentStore,
    private readonly inProgressAssessmentHandler: IInProgressAssessmentHandler,
    private readonly pathUpdater: IWindowPathUpdater,
    private readonly currentStageUpdater: ICurrentStageUpdater,
    private readonly internalRoleTriggerer: IInternalRoleTriggerer,
    private readonly heuristicsHandler: IHeuristicsUpdateHandler,
    private readonly scoreFetcher: IAssessmentScoreFetcher,
    private readonly assessmentId: number,
    private readonly pathToGoToAfterAssessment: string
  ) {
    super()

    this.currentPrompt = null
    this.heuristicId = null
    this.hasStartedAnAssessment = false
    this.isBackToPreviousPromptAllowed = true
    this.loading = true
    this.shouldRedirectToDashboard = false
    this.userAssessmentHistoryId = null
    this.questionIndexToPrompt = new Map()
  }

  public async initialize(): Promise<void> {
    this.tokenVerifier.verifyAuth()
    await this.inProgressAssessmentHandler.initialize(this.assessmentId)

    const score = await this.scoreFetcher.getAssessmentScore(this.assessmentId)
    const inProgressAssessment =
      await this.inProgressAssessmentHandler.getInProgressAssessmentInfo()

    if (
      typeof score === 'number' &&
      this.inProgressAssessmentHandler.hasCompletedGivenAssessmentId()
    ) {
      this.hasFinishedForm = true
      this.shouldRedirectToDashboard = true
      this.updateView()
    } else if (inProgressAssessment) {
      this.userAssessmentHistoryId = inProgressAssessment?.userAssessmentHistoryId
      await this.getPrompt(this.assessmentId, inProgressAssessment?.prompt?.id)
      this.hasStartedAnAssessment = true
      this.loading = false
      this.updateView()
    } else {
      await this.startNewAssessment()
    }
  }

  public shouldShowDashboard(): boolean {
    return this.shouldRedirectToDashboard
  }

  public getAssessmentId(): number {
    return this.assessmentId
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getTotalNumberOfQuestions(): number {
    return this.totalNumberOfQuestions
  }

  public proceedToAssessment(): void {
    this.hasStartedAnAssessment = true
    this.updateView()
  }

  public async startNewAssessment(): Promise<void> {
    this.loading = true
    const firstPrompt = await this.newAssessmentHandler.startNewAssessmentToGetFirstPromptId(
      this.assessmentId
    )

    if (typeof firstPrompt === 'string') {
      this.error = firstPrompt
    } else {
      await this.getPrompt(firstPrompt.assessmentId, firstPrompt.firstPromptId)
      this.userAssessmentHistoryId = firstPrompt.userAssessmentHistoryId
    }

    this.loading = false
    this.updateView()
  }

  private async getPrompt(assessmentId: number, promptId: number): Promise<void> {
    this.currentPrompt = await this.promptHandler.getPromptById(assessmentId, promptId)
    this.questionIndexToPrompt.set(this.currentIndex, this.currentPrompt)
    this.isBackToPreviousPromptAllowed = this.currentPrompt.canBacktrackToPreviousPrompt
    this.heuristicId = this.currentPrompt.heuristicId
    this.totalNumberOfQuestions = this.currentPrompt.numRemainingQuestions
  }

  public isBackTrackAllowed(): boolean {
    return this.isBackToPreviousPromptAllowed
  }

  public getCurrentPrompt(): PromptDtoWithUnit {
    return this.currentPrompt!
  }

  public backToPreviousQuestion(): void {
    this.currentIndex--
    this.currentPrompt = this.questionIndexToPrompt.get(this.currentIndex)!
    this.heuristicId = this.currentPrompt.heuristicId
    this.updateView()
  }

  public isLastQuestion(): boolean {
    return this.totalNumberOfQuestions === this.currentIndex
  }

  public hasFinishedAllQuestions(): boolean {
    return this.hasFinishedForm
  }

  public async proceedToNextQuestion(): Promise<void> {
    this.currentIndex++

    if (this.assessmentId && this.heuristicId && this.userAssessmentHistoryId) {
      this.loading = true
      this.updateView()

      const result = await this.getNextPrompt()
      await this.updateOnboardingHeuristicValues(
        this.heuristicId,
        this.responseGetter.getPromptValue(this.heuristicId!, this.currentPrompt!.responseType)
      )
      if (typeof result === 'string') {
        await this.handleNoNextPrompt(result)
      } else {
        this.currentPrompt = result
        this.heuristicId = result.heuristicId
        this.totalNumberOfQuestions = this.currentPrompt.numRemainingQuestions + this.currentIndex
        this.questionIndexToPrompt.set(this.currentIndex, result)
        this.loading = false
        this.updateView()
      }
    }
  }

  private async getNextPrompt(): Promise<string | PromptDtoWithUnit> {
    return this.nextPromptHandler.getNextPrompt(
      this.assessmentId,
      this.currentPrompt!.id,
      this.promptResponseDtoBuilder.buildPromptResponseDto(
        this.currentPrompt!,
        this.responseGetter.getPromptValue(this.heuristicId!, this.currentPrompt!.responseType),
        this.userAssessmentHistoryId!
      ),
      this.responseGetter.getResponseId(this.heuristicId!, this.currentPrompt!.responseType)
    )
  }

  private async updateOnboardingHeuristicValues(heuristicId: number, value: any): Promise<void> {
    if (heuristicId === yearsInSalesHeuristicId) {
      await this.updateYearsInSalesValue(value)
    } else if (heuristicId === oteHeuristicId) {
      await this.updateOteValue(value)
    } else if (heuristicId === quotaHeuristicId) {
      await this.updateQuotaValue(value)
    }
  }

  private async updateYearsInSalesValue(value: any): Promise<void> {
    await this.heuristicsHandler.updateFactHeuristicsValue(yearsInSalesHeuristicId, value)
  }

  private async updateOteValue(value: any): Promise<void> {
    await this.heuristicsHandler.updateMeasureHeuristicsValue(oteHeuristicId, value, currentYear)
  }

  private async updateQuotaValue(value: any): Promise<void> {
    await this.heuristicsHandler.updateMeasureHeuristicsValue(quotaHeuristicId, value, currentYear)
  }

  private async handleNoNextPrompt(result: string): Promise<void> {
    if (result) {
      this.error = result
    } else {
      await this.assessmentFinisher.finishAssessment(
        this.assessmentId!,
        this.userAssessmentHistoryId!
      )
      this.internalRoleTriggerer.triggerInternalRoleDeterminer()
      this.currentStageUpdater.updateUserStage(stageOneHeuristicId)
      this.pathUpdater.setPathName(this.pathToGoToAfterAssessment)
    }
  }

  public async finishNavigation(): Promise<void> {
    await this.proceedToNextQuestion()
  }
}

export default AssessmentPresenter
