import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import HeuristicDto from '../Dtos/HeuristicDto'
import IHeuristicsGetter from './IHeuristicGetter'

export const HEURISTICS_URL = `${serverBaseUrl}heuristic/`
class HeuristicGetter implements IHeuristicsGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getHeuristicById(id: number): Promise<HeuristicDto | null> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${HEURISTICS_URL}${id}`
    })

    if (this.isValidHeuristicDto(result)) {
      return result
    }

    return null
  }

  private isValidHeuristicDto(result: object): result is HeuristicDto {
    return (
      result?.hasOwnProperty('name') &&
      result?.hasOwnProperty('unit') &&
      result?.hasOwnProperty('valueType') &&
      result?.hasOwnProperty('unit') &&
      !!(result as HeuristicDto).name &&
      !!(result as HeuristicDto).valueType
    )
  }
}

export default HeuristicGetter
