import AbstractVisibilityManager from '../VisibilityManager/AbstractVisibilityManager'

class VisibilityManager extends AbstractVisibilityManager {
  constructor() {
    super()

    this.isVisible = false
    this.view = null
  }
}

export default VisibilityManager
