import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RadioButton from '../../../localCommon/RadioButton/RadioButton'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import NavigationButtonGroup from '../NavigationButtonGroup'
import IMultipleChoicePresenter from './Presenters/Interfaces/IMultipleChoicePresenter'

type Props = {
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: IMultipleChoicePresenter
}

class VerticalMultipleChoice extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { navigationPresenter, presenter } = this.props

    return (
      <div className='multipleChoice'>
        <div className='home-submit'>
          <p className='headline6'>{presenter.getTitle()}</p>
          <div className='body1'>{presenter.getDescription()}</div>
        </div>
        {presenter.getOptions().map((option) => {
          return (
            <RadioButton
              key={option.id}
              id={option.id?.toString()}
              isChecked={!!(option.id === parseInt(presenter.getValue(), 10))}
              onChange={this.updateValue}
              title={option.name}
            />
          )
        })}
        <div className='mdc-text-field-helper-line'>
          <div className='mdc-text-field-helper-text' id='outlinedInput' aria-hidden='true'>
            {presenter.shouldShowError() && !presenter.getValue() && (
              <span className='error'>{presenter.getInlineError()}</span>
            )}
          </div>
        </div>
        <NavigationButtonGroup
          canBacktrackToPreviousPrompt={navigationPresenter.isBackTrackAllowed()}
          hasValidInput={!!presenter.getValue()}
          isRequired={presenter.isRequiredQuestion()}
          presenter={navigationPresenter}
          subscriber={this}
        />
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  showErrorMessage = (): void => {
    this.props.presenter.showErrorMessage()
  }

  updateValue = (input: any): void => {
    this.props.presenter.updateInput(input)
  }
}

export default VerticalMultipleChoice
