import './style.scss'

import React, { Component, ReactElement } from 'react'

import IAssessmentMetricsProvider from '../../Dashboard/AssessmentResult/Presenter/IAssessmentMetricsProvider'
import ISelectedMetricDisplayer from '../../Dashboard/AssessmentResult/Presenter/ISelectedMetricDisplayer'
import AssessmentBar from './AssessmentBar'

type Props = {
  presenter: IAssessmentMetricsProvider & ISelectedMetricDisplayer
  shouldShowDropdown: boolean
}

class AssessmentBars extends Component<Props> {
  render(): ReactElement {
    const { presenter } = this.props
    const aggregateHeuristics = presenter.getAssessmentMetrics()

    return (
      <div className='assessmentBars'>
        {aggregateHeuristics.map((heuristic) => {
          return (
            <AssessmentBar
              assessment={heuristic}
              key={heuristic.id}
              presenter={presenter}
              shouldShowDropdown={this.props.shouldShowDropdown}
            />
          )
        })}
      </div>
    )
  }
}

export default AssessmentBars
