import { observable } from 'mobx'

import MarketCompensationDataDto from '../APIs/MarketCompensationDataGetter/MarketCompensationDataDto'

const personalDataStore = observable({
  marketCompensationData: {},
  role: '',
  yearsOfExperience: '',
  annualQuota: '',
  setAnnualQuota: (quota: string): void => {
    personalDataStore.annualQuota = quota
  },
  getAnnualQuota: (): string => {
    return personalDataStore.annualQuota
  },
  setMarketData: (data: MarketCompensationDataDto): void => {
    if (data) {
      personalDataStore.marketCompensationData = data
    }
  },
  getMarketCompensationData: (): MarketCompensationDataDto => {
    return personalDataStore.marketCompensationData as MarketCompensationDataDto
  },
  setRole: (role: string): void => {
    personalDataStore.role = role
  },
  getRole: (): string => {
    return personalDataStore.role
  },
  setYearsOfExperience: (yearsOfExperience: string): void => {
    personalDataStore.yearsOfExperience = yearsOfExperience
  },
  getYearsOfExperience: (): string => {
    return personalDataStore.yearsOfExperience
  }
})

export default personalDataStore
