import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IAssessmentScoreFetcher from './IAssessmentScoreFetcher'

class AssessmentScoreFetcher implements IAssessmentScoreFetcher {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getAssessmentScore(assessmentId: number): Promise<number> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/assessment/${assessmentId}/score`,
      body: null
    })

    return result?.averageScore ?? null
  }
}

export default AssessmentScoreFetcher
