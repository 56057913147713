import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import INavigationPresenter from './INavigationPresenter'

abstract class AbstractNavigationPresenter implements INavigationPresenter {
  protected hasFinishedForm: boolean
  protected currentIndex: number
  protected view: ISubscribingView | null
  protected error: string
  protected isBackTrackingAllowed: boolean
  protected totalNumberOfQuestions: number
  protected loading: boolean

  constructor() {
    this.currentIndex = 0
    this.error = ''
    this.loading = false
    this.hasFinishedForm = false
    this.isBackTrackingAllowed = true
    this.totalNumberOfQuestions = 0
    this.view = null
  }

  public isLoading(): boolean {
    return this.loading
  }

  public abstract getTotalNumberOfQuestions(): number

  public isBackTrackAllowed(): boolean {
    return this.isBackTrackingAllowed
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  public getCurrentQuestionIndex(): number {
    return this.currentIndex
  }

  public backToPreviousQuestion(): void {
    this.currentIndex--
    this.updateView()
  }

  public proceedToNextQuestion(): void {
    this.currentIndex++
    this.updateView()
  }

  public isFirstQuestion(): boolean {
    return this.currentIndex === 0
  }

  public isLastQuestion(): boolean {
    return this.currentIndex + 1 === this.totalNumberOfQuestions
  }

  protected updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getErrorMessage(): string {
    return this.error
  }

  public hasFinishedAllQuestions(): boolean {
    return this.hasFinishedForm
  }
}

export default AbstractNavigationPresenter
