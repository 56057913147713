import './style.scss'

import Cookies from 'js-cookie'
import React, { Component, Fragment, ReactElement } from 'react'

import images from '../../../images'
import ContainedButton from '../../../localCommon/Button/ContainedButton'
import { assessmentFeatureFlagKey, nameKey } from '../../../localCommon/CookieKeys'
import Tag from '../../../localCommon/Tag/Tag'
import IOnboardingQuestionsStarter from '../Presenters/Interfaces/IOnboardingQuestionsStarter'

type Props = {
  presenter: IOnboardingQuestionsStarter
}

class Welcome extends Component<Props> {
  render(): ReactElement {
    return (
      <div className='welcome blank-page-padding'>
        <div className='welcome-logo'>
          <img alt='databased' src={images.databased} />
        </div>
        <p className='overline'>Welcome</p>
        <p className='welcome-user'>{this.getName()}</p>
        {this.shouldShowOneAssessment() ? (
          <Fragment>
            <div className='body2'>
              To find out if we could land you a better job, we need to learn about you.
            </div>
            <div className='welcome-betaContainer'>
              <p className='body2 bold'>Take the ~15-20 min. Pre-screen Assessment</p>
              <Tag label='Beta' />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='body2'>
              Just a few questions to get started by benchmarking your current OTE.
            </div>
            <div>
              <img alt='example-data' src={images.exampleData} />
            </div>
          </Fragment>
        )}
        <div className={this.shouldShowOneAssessment() ? 'centered-welcome-text' : 'welcome-start'}>
          <ContainedButton onClick={this.startOnboardingQuestions} type='button'>
            Get Started
          </ContainedButton>
        </div>
        {this.shouldShowOneAssessment() && (
          <div className='welcome-additionalInfo'>
            <div className='welcome-previewImages'>
              <p className='title'>Once completed you will:</p>
              <li>
                Have a profile created that is shared with companies we partner with that match your
                preferences.
              </li>
              <li>
                See a high level overview of how you scored relative to others with similar
                experience
              </li>
              <li>Get free access to our training resources</li>
            </div>
            <div>
              <img alt='welcome' src={images.welcomeImage} />
            </div>
            <div className='centered-welcome-text'>
              <ContainedButton onClick={this.startOnboardingQuestions} type='button'>
                Get Started
              </ContainedButton>
            </div>
          </div>
        )}
      </div>
    )
  }

  shouldShowOneAssessment = (): boolean => {
    return Cookies.get(assessmentFeatureFlagKey) === 'true'
  }

  getName = (): string => {
    const name = this.props.presenter.getName() || Cookies.get(nameKey)
    return name?.split(' ')[0] ?? ''
  }

  startOnboardingQuestions = (): void => {
    this.props.presenter.startOnboardingQuestions()
  }
}

export default Welcome
