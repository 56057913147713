import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import FactDto from './Dtos/FactDto'
import MeasureDto from './Dtos/MeasureDto'
import IHeuristicsUpdater from './IHeuristicsUpdater'

class HeuristicsUpdater implements IHeuristicsUpdater {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async updateHeuristicsValue(id: number, dto: MeasureDto | FactDto): Promise<string> {
    const result = await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}user/${this.userId}/heuristic/${id}`,
      body: dto
    })

    return result
  }
}

export default HeuristicsUpdater
