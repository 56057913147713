import React, { Component, ReactElement } from 'react'

import HorizontalMultipleChoice from '../../Assessment/HorizontalMultipleChoice/HorizontalMultipleChoice'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import ValidResponseType from '../Dtos/ValidResponseType'
import FreeResponse from '../FreeResponse/FreeResponse'
import Picklist from '../Picklist/Picklist'
import IResponsePresenterFactory from '../Presenters/Interfaces/IResponsePresenterFactory'
import VerticalMultipleChoice from '../VerticalMultipleChoice/VerticalMultipleChoice'

type Props = {
  description: string
  factory: IResponsePresenterFactory
  id: number
  navigationPresenter: INavigationPresenter & INavigationTerminator
  responseType: ValidResponseType
  title: string
}

class ResponseComponentFactory extends Component<Props> {
  render(): ReactElement {
    return <div className='responseComponentFactory'>{this.renderResponseComponent()}</div>
  }

  renderResponseComponent = (): ReactElement | null => {
    const { description, factory, id, navigationPresenter, responseType, title } = this.props

    if (responseType.includes('free_response')) {
      return (
        <FreeResponse
          navigationPresenter={navigationPresenter}
          presenter={factory.getFreeResponsePresenter(
            navigationPresenter.getCurrentQuestionIndex()
          )}
        />
      )
    }

    if (responseType === 'picklist') {
      return (
        <Picklist
          elementId={`${responseType}-${id}`}
          navigationPresenter={navigationPresenter}
          presenters={factory.getPicklistResponsePresenters(
            navigationPresenter.getCurrentQuestionIndex()
          )}
          description={description}
          title={title}
        />
      )
    }

    if (responseType === 'multiple_choice_vertical') {
      return (
        <VerticalMultipleChoice
          navigationPresenter={navigationPresenter}
          presenter={factory.getMultipleChoicePresenter(
            navigationPresenter.getCurrentQuestionIndex()
          )}
        />
      )
    }

    if (responseType === 'multiple_choice_horizontal') {
      return (
        <HorizontalMultipleChoice
          navigationPresenter={navigationPresenter}
          presenter={factory.getMultipleChoicePresenter(
            navigationPresenter.getCurrentQuestionIndex()
          )}
        />
      )
    }

    return null
  }
}

export default ResponseComponentFactory
