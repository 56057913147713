import ISubscribingView from '../../Interfaces/ISubscribingView'
import IVisibilityManager from './IVisibilityManager'

abstract class AbstractVisibilityManager implements IVisibilityManager {
  protected isVisible: boolean
  protected view: ISubscribingView | null

  constructor() {
    this.isVisible = false
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  public close(): void {
    this.isVisible = false
    this.updateView()
  }

  public isOpen(): boolean {
    return this.isVisible
  }

  public open(): void {
    this.isVisible = true
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default AbstractVisibilityManager
