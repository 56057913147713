import './style.scss'

import React, { Component, Fragment, ReactElement } from 'react'

import images from '../../../../images'
import OutlinedThemeButton from '../../../../localCommon/Button/ThemeOutlinedButton/ThemeOutlinedButtons'
import BlackNavigation from '../../../../localCommon/NavigationChevron/BlackNavigationArrow'
import IAssessmentResultPresenter from '../../Dashboard/AssessmentResult/Presenter/IAssessmentResultPresenter'
import heuristicIdToCourseResources from '../../HeuristicIdToCourseResources'
import MetricDetailBar from '../AssessmentBars/MetricDetailBar'
import MetricBellCurve from './MetricBellCurve'

type Props = {
  presenter: IAssessmentResultPresenter
}

class MetricDetail extends Component<Props> {
  componentDidMount(): void {
    const image = document.getElementById('metric-detail-image')
    if (image) {
      image.addEventListener('load', this.showImageWhenFinishLoading)
    }
  }

  componentWillUnmount(): void {
    const image = document.getElementById('metric-detail-image')
    if (image) {
      image.removeEventListener('load', this.showImageWhenFinishLoading)
    }
    const userProfile = document.querySelector('.personalInfoData-header') as HTMLElement

    if (userProfile) {
      userProfile.style.display = 'flex'
    }
  }

  render(): ReactElement {
    const { presenter } = this.props
    const metric = presenter.getSelectedMetric()
    const resource = metric ? heuristicIdToCourseResources.get(metric?.id) : null

    if (!metric) {
      return <div></div>
    }

    return (
      <Fragment>
        <div className='metricDetail-nav'>
          <BlackNavigation direction='left' onClick={this.navigateToPreviousPage} />
        </div>
        <div className='metricDetail blank-page-padding'>
          <img
            alt='metric-detail'
            id='metric-detail-image'
            src={this.getMetricImage(metric.title?.toLowerCase() ?? '')}
          />
          <div className='loading loading-image' id='metric-detail-image-loading-state'></div>
          <div className='metricDetail-title section-with-border'>
            <p className='caption'>Sales metric</p>
            <p>{metric.title}</p>
            <MetricBellCurve percentile={metric.percentile} />
            <MetricDetailBar assessment={metric} />
            <p className='metricDetail-percentile'>
              You scored higher than {metric.percentile}% of people
            </p>
            <div>{metric?.description}</div>
          </div>
          {resource?.isDataBasedOriginalContent && (
            <Fragment>
              <p className='headline6'>Free Resource</p>
              <div className='section-with-border metricDetail-resources'>
                <p className='caption'>DataBased Resource</p>
                <p className='headline6 light-gradient'>{resource.courseTitle}</p>
                <p className='body1'>{resource?.description}</p>
                <a
                  href={resource?.url}
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noreferrer'
                >
                  <OutlinedThemeButton type='button'>VIEW COURSE</OutlinedThemeButton>
                </a>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }

  showImageWhenFinishLoading = (): void => {
    const loadingState = document.getElementById('metric-detail-image-loading-state') as HTMLElement

    if (loadingState) {
      loadingState.style.display = 'none'
    }
  }

  navigateToPreviousPage = (): void => {
    this.props.presenter.selectAssessmentMetricToShow(null)
  }

  getMetricImage = (title: string): string => {
    if (title === 'trajectory') {
      return images.performance
    }

    if (title === 'mindset') {
      return images.hunterMindesetMetric
    }

    if (title === 'cognitive') {
      return images.iqMetric
    }

    if (title === 'motor') {
      return images.motor
    }

    if (title === 'social') {
      return images.performance
    }

    return ''
  }
}

export default MetricDetail
