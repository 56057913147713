import './style.scss'

import Cookies from 'js-cookie'
import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import images from '../../../images'
import { serverBaseUrl } from '../../../localCommon/constant'
import { nameKey, profileImageKey } from '../../../localCommon/CookieKeys'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IUserProfilePresenter from './Interfaces/IUserProfilePresenter'

type Props = {
  presenter: IUserProfilePresenter
}

class UserProfile extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.getCompletedStages()
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    if (!presenter.hasRetrievedCompletedStages()) {
      return (
        <div className='userProfile'>
          <div className='userProfile-content'>
            <div className='userProfile-progress'>
              <div className='userProfile-whiteBorder' />
              <img
                alt='user-profile'
                className='userProfile-image'
                src={
                  Cookies.get(profileImageKey)
                    ? `${serverBaseUrl}profile/${Cookies.get(profileImageKey)}`
                    : images.defaultProfilePicture
                }
              />
            </div>
            <div>
              <p className='userProfile-name loading' id='loading-userProfile' />
              <p className='caption userProfile-strength loading' id='loading-strength' />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='userProfile'>
        <div className='userProfile-content'>
          <div className='userProfile-progress'>
            <div className='userProfile-whiteBorder' />
            <img
              alt='user-profile'
              className='userProfile-image'
              onClick={this.editProfile}
              src={
                Cookies.get(profileImageKey)
                  ? `${serverBaseUrl}profile/${Cookies.get(profileImageKey)}`
                  : images.defaultProfilePicture
              }
            />
          </div>
          <div>
            <Link
              to={{
                pathname: '/editAccount'
              }}
            >
              <div className='userProfile-nameWrapper'>
                <p className='userProfile-name'>{Cookies.get(nameKey) ?? ''}</p>
                <img className='userProfile-edit' src={images.editIcon} alt='edit-profile' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  public update(): void {
    this.setState({})
  }

  editProfile = (): void => {
    window.location.href = '/editAccount'
  }

  getCompletedStages = async (): Promise<void> => {
    await this.props.presenter.retrieveNumCompletedStages()
    this.setProgress()
  }

  setProgress = (): void => {
    const progressBar = document.querySelector('.userProfile-progress') as HTMLElement
    const endValue = this.props.presenter.getProfileCompletionPercentage()
    const animationStartingPoint = 5
    const progressToDegree = 3.6
    const animationInterval = 30
    let currentValue = 0

    if (progressBar) {
      const progress = setInterval(() => {
        currentValue++

        progressBar.style.background = `conic-gradient(rgba(192, 73, 248, 1) ${animationStartingPoint * progressToDegree}deg, rgba(32, 80, 249, 1) ${currentValue * progressToDegree}deg, rgb(229, 229, 229) 0deg)`
        if (currentValue >= endValue) {
          clearInterval(progress)
        }
      }, animationInterval)
    }
  }
}

export default UserProfile
