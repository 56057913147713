import './StyleSheets/CandidateQuestionnaireCard.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../images'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import ICandidateQuestionnaireCardPresenter from './InterfacesAndDtos/ICandidateQuestionnaireCardPresenter'
import JobRequirementPromptsAndValues from './InterfacesAndDtos/JobRequirementPromptValues'

type Props = {
  presenter: ICandidateQuestionnaireCardPresenter
}

class CandidateQuestionnaireCard extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const questionnaires = presenter.getQuestionnaireDtos()
    const editMode = presenter.isQuestionnaireInEditMode()
    const shareMode = presenter.isInShareMode()

    return (
      <div className='summary-questionnaire-card-container'>
        {!editMode && !shareMode && (
          <div className='edit-icon'>
            <img
              className='edit-icon-open'
              onClick={this.enterEditMode}
              src={images.editIcon}
              alt='Edit'
            />
          </div>
        )}
        {editMode && (
          <div className='edit-icon'>
            <img
              className='edit-mode-close'
              onClick={this.exitEditMode}
              src={images.closeIcon}
              alt='Close'
            />
          </div>
        )}
        {questionnaires.map((questionnaire: JobRequirementPromptsAndValues, index: number) => {
          return editMode
            ? this.renderEditableQuestionnaire(questionnaire, index)
            : this.renderQuestionnaire(questionnaire, index)
        })}

        {editMode && (
          <div className='heuristic-data-button'>
            <button type='button' onClick={this.handleSave}>
              {'SAVE'}
            </button>
          </div>
        )}
      </div>
    )
  }

  exitEditMode = (): void => {
    this.props.presenter.exitQuestionnaireEditMode()
  }

  enterEditMode = (): void => {
    this.props.presenter.enterQuestionnaireEditMode()
  }

  renderQuestionnaire(questionnaire: JobRequirementPromptsAndValues, index: number): ReactElement {
    const { presenter } = this.props
    return (
      <div className='summary-questionnaire'>
        <p className='summary-questionnaire-title'>
          {presenter.getQuestionnaireCardTitle(questionnaire.jobId)}
        </p>
        {questionnaire.promptResponseDtos.map((prompt, index) => {
          const unit = presenter.getUnit(prompt.heuristicId)
          return (
            <div className='summary-questionnaire-section' key={index}>
              <p className='summary-question-text'>Q: {prompt.prompt}</p>
              <p className='summary-answer-text'>
                A:
                {unit === '$' && '$'}
                {prompt.response}
                {unit === '%' && '%'}
              </p>
              <div className='summary-questionnaire-divider' />
            </div>
          )
        })}
      </div>
    )
  }

  renderEditableQuestionnaire(
    questionnaire: JobRequirementPromptsAndValues,
    index: number
  ): ReactElement {
    const { presenter } = this.props
    return (
      <div className='summary-questionnaire'>
        <p className='summary-questionnaire-title'>
          {presenter.getQuestionnaireCardTitle(questionnaire.jobId)}
        </p>
        {questionnaire.promptResponseDtos.map((prompt, promptIndex) => {
          const unit = presenter.getUnit(prompt.heuristicId)
          return (
            <div className='summary-questionnaire-section' key={promptIndex}>
              <p className='summary-question-text'>{prompt.prompt}</p>
              {unit === '$' && <span className='input-unit'>$</span>}
              <input
                className='summary-answer-input'
                type={presenter.getInputType(prompt.heuristicId)}
                value={
                  presenter.getEditedQuestionnaireText(index, prompt.id) ?? prompt.response ?? ''
                }
                onChange={(e) =>
                  presenter.editQuestionnaireText(
                    index,
                    prompt.id,
                    e.target.value,
                    prompt.heuristicId
                  )
                }
              />
              {unit === '%' && <span className='input-unit'>%</span>}
              <div className='summary-questionnaire-divider' />
            </div>
          )
        })}
      </div>
    )
  }

  handleSave = (): void => {
    this.props.presenter.saveNewQuestionnaireValues()
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateQuestionnaireCard
