import ICommonObject from '../../../localCommon/Interfaces/ICommonObject'
import ICommonObjectWithSortId from '../../../localCommon/Interfaces/ICommonObjectWithSortId'

export const currentSituation: ICommonObjectWithSortId[] = [
  { id: 1, orderIndex: 1, name: "I'm currently looking for a new job." },
  { id: 2, orderIndex: 2, name: "I'm happy about my OTE and role." },
  {
    id: 3,
    orderIndex: 3,
    name: "I'm happy with my current role, and interested in seeing what other jobs are out there."
  },
  { id: 4, orderIndex: 4, name: "I'm more interested in career growth than financial growth." },
  { id: 5, orderIndex: 5, name: "I like my company, but I'm struggling to promote." }
]

export const education: ICommonObject[] = [
  { id: 1, name: 'High School' },
  { id: 6, name: 'Currently enrolled in an undergraduate degree' },
  { id: 5, name: 'Associate Degree' },
  { id: 2, name: "Bachelor's Degree" },
  { id: 7, name: 'Currently enrolled in a post-graduate degree' },
  { id: 3, name: "Master's Degree" },
  { id: 4, name: 'Doctorate or higher' }
]

export const yearGraduated: ICommonObject[] = [
  { id: 58, name: '2028' },
  { id: 57, name: '2027' },
  { id: 59, name: '2026' },
  { id: 56, name: '2025' },
  { id: 55, name: '2024' },
  { id: 1, name: '2023' },
  { id: 2, name: '2022' },
  { id: 3, name: '2021' },
  { id: 4, name: '2020' },
  { id: 5, name: '2019' },
  { id: 6, name: '2018' },
  { id: 7, name: '2017' },
  { id: 8, name: '2016' },
  { id: 9, name: '2015' },
  { id: 10, name: '2014' },
  { id: 11, name: '2013' },
  { id: 12, name: '2012' },
  { id: 13, name: '2011' },
  { id: 14, name: '2010' },
  { id: 15, name: '2009' },
  { id: 16, name: '2008' },
  { id: 17, name: '2007' },
  { id: 18, name: '2006' },
  { id: 19, name: '2005' },
  { id: 20, name: '2004' },
  { id: 21, name: '2003' },
  { id: 22, name: '2002' },
  { id: 23, name: '2001' },
  { id: 24, name: '2000' },
  { id: 25, name: '1999' },
  { id: 26, name: '1998' },
  { id: 27, name: '1997' },
  { id: 28, name: '1996' },
  { id: 29, name: '1995' },
  { id: 30, name: '1994' },
  { id: 31, name: '1993' },
  { id: 32, name: '1992' },
  { id: 33, name: '1991' },
  { id: 34, name: '1990' },
  { id: 35, name: '1989' },
  { id: 36, name: '1988' },
  { id: 37, name: '1987' },
  { id: 38, name: '1986' },
  { id: 39, name: '1985' },
  { id: 40, name: '1984' },
  { id: 41, name: '1983' },
  { id: 42, name: '1982' },
  { id: 43, name: '1981' },
  { id: 44, name: '1980' },
  { id: 45, name: '1979' },
  { id: 46, name: '1978' },
  { id: 47, name: '1977' },
  { id: 48, name: '1976' },
  { id: 49, name: '1975' },
  { id: 50, name: '1974' },
  { id: 51, name: '1973' },
  { id: 52, name: '1972' },
  { id: 53, name: '1971' },
  { id: 54, name: '1970' }
]

export const numberOfYears: ICommonObject[] = [
  { id: 0, name: '<1' },
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20+' }
]
export const BDRRole = 'BDR'
export const SDRRole = 'SDR'
export const aeRole = 'AE'
export const entAeRole = 'ENT-AE'
export const entAeAbbreviation = 'EAE'
export const rolesAbbreviations = [BDRRole, SDRRole, entAeAbbreviation, aeRole]
export const roles: ICommonObject[] = [
  { id: 0, name: `Student` },
  { id: 1, name: `SDR (Pipeline generation role, 0 - 3 years experience)` },
  { id: 2, name: `Jr. Account Executive (1 - 3 years experience)` },
  { id: 3, name: `Account Executive (3 - 5 years experience)` },
  { id: 4, name: `Senior Account Executive (5 - 12 years experience)` },
  {
    id: 5,
    name: `Enterprise Account Executive (6 - 20+ years experience with larger average deal size)`
  },
  {
    id: 6,
    name: `Front Line Leader (Manager of reps)`
  },
  {
    id: 7,
    name: `Second Line Leader (Manager of managers)`
  }
]
export const locations: ICommonObject[] = [
  { id: 1, name: 'Birmingham, AL' },
  { id: 2, name: 'Montgomery, AL' },
  { id: 3, name: 'Mobile, AL' },
  { id: 4, name: 'Huntsville, AL' },
  { id: 5, name: 'Anchorage, AK' },
  { id: 6, name: 'Fairbanks, AK' },
  { id: 7, name: 'Juneau, AK' },
  { id: 8, name: 'Phoenix, AZ' },
  { id: 9, name: 'Tucson, AZ' },
  { id: 10, name: 'Mesa, AZ' },
  { id: 11, name: 'Little Rock, AR' },
  { id: 12, name: 'Fort Smith, AR' },
  { id: 13, name: 'Fayetteville, AR' },
  { id: 14, name: 'Los Angeles, CA' },
  { id: 15, name: 'San Diego, CA' },
  { id: 16, name: 'San Jose, CA' },
  { id: 17, name: 'San Francisco, CA' },
  { id: 18, name: 'Denver, CO' },
  { id: 19, name: 'Colorado Springs, CO' },
  { id: 20, name: 'Aurora, CO' },
  { id: 21, name: 'Bridgeport, CT' },
  { id: 22, name: 'New Haven, CT' },
  { id: 23, name: 'Hartford, CT' },
  { id: 24, name: 'Wilmington, DE' },
  { id: 25, name: 'Dover, DE' },
  { id: 26, name: 'Newark, DE' },
  { id: 27, name: 'Jacksonville, FL' },
  { id: 28, name: 'Miami, FL' },
  { id: 29, name: 'Tampa, FL' },
  { id: 30, name: 'Orlando, FL' },
  { id: 31, name: 'Atlanta, GA' },
  { id: 32, name: 'Augusta, GA' },
  { id: 33, name: 'Savannah, GA' },
  { id: 34, name: 'Honolulu, HI' },
  { id: 35, name: 'Hilo, HI' },
  { id: 36, name: 'Kailua, HI' },
  { id: 37, name: 'Boise, ID' },
  { id: 38, name: 'Meridian, ID' },
  { id: 39, name: 'Nampa, ID' },
  { id: 40, name: 'Chicago, IL' },
  { id: 41, name: 'Aurora, IL' },
  { id: 42, name: 'Rockford, IL' },
  { id: 43, name: 'Indianapolis, IN' },
  { id: 44, name: 'Fort Wayne, IN' },
  { id: 45, name: 'Evansville, IN' },
  { id: 46, name: 'Des Moines, IA' },
  { id: 47, name: 'Cedar Rapids, IA' },
  { id: 48, name: 'Davenport, IA' },
  { id: 49, name: 'Wichita, KS' },
  { id: 50, name: 'Overland Park, KS' },
  { id: 51, name: 'Kansas City, KS' },
  { id: 52, name: 'Louisville, KY' },
  { id: 53, name: 'Lexington, KY' },
  { id: 54, name: 'Bowling Green, KY' },
  { id: 55, name: 'New Orleans, LA' },
  { id: 56, name: 'Baton Rouge, LA' },
  { id: 57, name: 'Shreveport, LA' },
  { id: 58, name: 'Portland, ME' },
  { id: 59, name: 'Lewiston, ME' },
  { id: 60, name: 'Bangor, ME' },
  { id: 61, name: 'Baltimore, MD' },
  { id: 62, name: 'Columbia, MD' },
  { id: 63, name: 'Germantown, MD' },
  { id: 64, name: 'Boston, MA' },
  { id: 65, name: 'Worcester, MA' },
  { id: 66, name: 'Springfield, MA' },
  { id: 67, name: 'Detroit, MI' },
  { id: 68, name: 'Grand Rapids, MI' },
  { id: 69, name: 'Warren, MI' },
  { id: 70, name: 'Minneapolis, MN' },
  { id: 71, name: 'Saint Paul, MN' },
  { id: 72, name: 'Rochester, MN' },
  { id: 73, name: 'Jackson, MS' },
  { id: 74, name: 'Gulfport, MS' },
  { id: 75, name: 'Southaven, MS' },
  { id: 76, name: 'Kansas City, MO' },
  { id: 77, name: 'Saint Louis, MO' },
  { id: 78, name: 'Springfield, MO' },
  { id: 79, name: 'Billings, MT' },
  { id: 80, name: 'Missoula, MT' },
  { id: 81, name: 'Great Falls, MT' },
  { id: 82, name: 'Omaha, NE' },
  { id: 83, name: 'Lincoln, NE' },
  { id: 84, name: 'Bellevue, NE' },
  { id: 85, name: 'Las Vegas, NV' },
  { id: 86, name: 'Henderson, NV' },
  { id: 87, name: 'Reno, NV' },
  { id: 88, name: 'Manchester, NH' },
  { id: 89, name: 'Nashua, NH' },
  { id: 90, name: 'Concord, NH' },
  { id: 91, name: 'Newark, NJ' },
  { id: 92, name: 'Jersey City, NJ' },
  { id: 93, name: 'Paterson, NJ' },
  { id: 94, name: 'Albuquerque, NM' },
  { id: 95, name: 'Las Cruces, NM' },
  { id: 96, name: 'Rio Rancho, NM' },
  { id: 97, name: 'New York City, NY' },
  { id: 98, name: 'Buffalo, NY' },
  { id: 99, name: 'Rochester, NY' },
  { id: 100, name: 'Charlotte, NC' },
  { id: 101, name: 'Raleigh, NC' },
  { id: 102, name: 'Greensboro, NC' },
  { id: 103, name: 'Fargo, ND' },
  { id: 104, name: 'Bismarck, ND' },
  { id: 105, name: 'Grand Forks, ND' },
  { id: 106, name: 'Columbus, OH' },
  { id: 107, name: 'Cleveland, OH' },
  { id: 108, name: 'Cincinnati, OH' },
  { id: 109, name: 'Oklahoma City, OK' },
  { id: 110, name: 'Tulsa, OK' },
  { id: 111, name: 'Norman, OK' },
  { id: 112, name: 'Portland, OR' },
  { id: 113, name: 'Salem, OR' },
  { id: 114, name: 'Eugene, OR' },
  { id: 115, name: 'Philadelphia, PA' },
  { id: 116, name: 'Pittsburgh, PA' },
  { id: 117, name: 'Allentown, PA' },
  { id: 118, name: 'Providence, RI' },
  { id: 119, name: 'Warwick, RI' },
  { id: 120, name: 'Cranston, RI' },
  { id: 121, name: 'Columbia, SC' },
  { id: 122, name: 'Charleston, SC' },
  { id: 123, name: 'North Charleston, SC' },
  { id: 124, name: 'Sioux Falls, SD' },
  { id: 125, name: 'Rapid City, SD' },
  { id: 126, name: 'Aberdeen, SD' },
  { id: 127, name: 'Nashville, TN' },
  { id: 128, name: 'Memphis, TN' },
  { id: 129, name: 'Knoxville, TN' },
  { id: 130, name: 'Houston, TX' },
  { id: 131, name: 'San Antonio, TX' },
  { id: 132, name: 'Dallas, TX' },
  { id: 133, name: 'Salt Lake City, UT' },
  { id: 134, name: 'West Valley City, UT' },
  { id: 135, name: 'Provo, UT' },
  { id: 136, name: 'Burlington, VT' },
  { id: 137, name: 'South Burlington, VT' },
  { id: 138, name: 'Rutland, VT' },
  { id: 139, name: 'Virginia Beach, VA' },
  { id: 140, name: 'Norfolk, VA' },
  { id: 141, name: 'Chesapeake, VA' },
  { id: 142, name: 'Seattle, WA' },
  { id: 143, name: 'Spokane, WA' },
  { id: 144, name: 'Tacoma, WA' },
  { id: 145, name: 'Charleston, WV' },
  { id: 146, name: 'Huntington, WV' },
  { id: 147, name: 'Parkersburg, WV' },
  { id: 148, name: 'Milwaukee, WI' },
  { id: 149, name: 'Madison, WI' },
  { id: 150, name: 'Green Bay, WI' },
  { id: 151, name: 'Cheyenne, WY' },
  { id: 152, name: 'Casper, WY' },
  { id: 153, name: 'Laramie, WY' }
]
