import './style.scss'

import React, { Component, Fragment, ReactElement } from 'react'

class FinalStages extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <p className='body center-text'>We take it from here</p>
        <div className='section-with-border'>
          <p className='subtitle2'>We Showcase your profile to our companies</p>
          <li>You relax, we act as your agent to find you the best opportunity we can</li>
          <li>Note: 87% of the reps who make it to this stage of the process get hired!</li>
        </div>
        <div className='section-with-border'>
          <p className='subtitle2'>Company intros and handoff</p>
          <li>
            We hand you off to companies with our guarantee and proof that you are as good as we
            know you are!
          </li>
        </div>
      </Fragment>
    )
  }
}

export default FinalStages
