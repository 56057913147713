import IHeuristicsGetter from '../../APIs/HeuristicGetter/IHeuristicGetter'
import IPromptGetter from '../../APIs/PromptGetter/IPromptGetter'
import IRemainingNumberOfQuestionsGetter from '../../APIs/RemainingNumberOfQuestionsGetter/IRemainingNumberOfQuestionsGetter'
import PromptDtoWithUnit from '../../Routes/Assessment/Presenter/PromptDtoWithUnit'
import IPromptHandler from './IPromptHandler'

class PromptHandler implements IPromptHandler {
  constructor(
    private readonly promptGetter: IPromptGetter,
    private readonly heuristicGetter: IHeuristicsGetter,
    private readonly numTotalQuestionsGetter: IRemainingNumberOfQuestionsGetter
  ) {}

  public async getPromptById(assessmentId: number, promptId: number): Promise<PromptDtoWithUnit> {
    const prompt = await this.promptGetter.getPromptById(promptId)
    const heuristic = await this.heuristicGetter.getHeuristicById(prompt.heuristicId)
    const numRemainingQuestions = await this.numTotalQuestionsGetter.getNumberOfRemainingQuestions(
      assessmentId,
      promptId
    )

    return {
      ...prompt,
      numRemainingQuestions,
      unit: heuristic?.unit ?? ''
    }
  }
}

export default PromptHandler
