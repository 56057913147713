import Cookies from 'js-cookie'

import IAssessmentScoreFetcher from '../../../APIs/AssessmentScoreFetcher/IAssessmentScoreFetcher'
import IUserStageFetcher from '../../../APIs/UserStageFetcher/IUserStageFetcher'
import { assessmentIdKey } from '../../../localCommon/CookieKeys'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import { assessmentMetricBenchmarkScore } from '../../PersonalInfoData/Dashboard/AssessmentResult/Presenter/AssessmentResultPresenter'
import IUserProfilePresenter from './Interfaces/IUserProfilePresenter'
import UserCompletedStagesDto from './UserCompletedStagesDto'

export const numUserStages = 3
class UserProfilePresenter implements IUserProfilePresenter {
  private view: ISubscribingView | null
  private hasFinishedFetch: boolean
  private completedStagesDto: UserCompletedStagesDto
  private score: number

  constructor(
    private readonly userStageFetcher: IUserStageFetcher,
    private readonly scoreFetcher: IAssessmentScoreFetcher
  ) {
    this.score = 0
    this.hasFinishedFetch = false
    this.view = null
    this.completedStagesDto = { completedStages: 0 }
  }

  public hasFailedOnTheAssessment(): boolean {
    return this.score < assessmentMetricBenchmarkScore
  }

  public async retrieveNumCompletedStages(): Promise<void> {
    this.score = await this.scoreFetcher.getAssessmentScore(
      parseInt(Cookies.get(assessmentIdKey) ?? '-1', 10)
    )
    if (this.hasFailedOnTheAssessment()) {
      this.completedStagesDto.completedStages = numUserStages
    } else {
      this.completedStagesDto = await this.userStageFetcher.getUserStage()
    }
    this.hasFinishedFetch = true
    this.updateView()
  }

  public getCompletedStages(): string {
    return this.hasFinishedFetch ? `${this.completedStagesDto.completedStages}` : '-'
  }

  public getTotalStages(): string {
    return numUserStages.toString()
  }

  public getProfileCompletionPercentage(): number {
    return 100
  }

  public hasRetrievedCompletedStages(): boolean {
    return this.hasFinishedFetch
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default UserProfilePresenter
