import IResponsePresenter from '../Presenters/Interfaces/IResponsePresenter'
import IResponsePresenterStore from './IResponsePresenterStore'

class ResponsePresenterStore implements IResponsePresenterStore {
  private presenterIdToPresenter: Map<string, IResponsePresenter>
  private heuristicIdToPresenter: Map<number, IResponsePresenter>

  constructor() {
    this.heuristicIdToPresenter = new Map()
    this.presenterIdToPresenter = new Map()
  }

  public getPresenterByHeuristicId(id: number): IResponsePresenter | null {
    return this.heuristicIdToPresenter.get(id) ?? null
  }

  public getPresenterById(id: string): IResponsePresenter | null {
    return this.presenterIdToPresenter.get(id) ?? null
  }

  public setPresenter(title: string, presenter: IResponsePresenter): void {
    this.presenterIdToPresenter.set(title, presenter)
    this.setPresenterWithHeuristicId(presenter.getHeuristicsId(), presenter)
  }

  public setPresenterWithHeuristicId(heuristicId: number, presenter: IResponsePresenter): void {
    this.heuristicIdToPresenter.set(heuristicId, presenter)
  }

  public has(id: string): boolean {
    return this.presenterIdToPresenter.has(id)
  }

  public hasPresenterWithHeuristicId(id: number): boolean {
    return this.heuristicIdToPresenter.has(id)
  }
}

export default ResponsePresenterStore
