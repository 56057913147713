type ValidResponseType =
  | 'free_response_long'
  | 'free_response_numerical'
  | 'free_response_short'
  | 'free_response_percentage'
  | 'fuzzy_search'
  | 'multiple_choice_horizontal'
  | 'multiple_choice_vertical'
  | 'num_entries'
  | 'picklist'

export const freeResponsePercentageType = 'free_response_percentage'
export const shortFreeResponseType = 'free_response_short'
export const numericFreeResponseType = 'free_response_numerical'
export const horizontalMultipleChoiceType = 'multiple_choice_horizontal'
export const verticalMultipleChoiceType = 'multiple_choice_vertical'
export const picklistType = 'picklist'
export const longFreeResponseType = 'free_response_long'
export const fuzzySearch = 'fuzzy_search'
export const numEntriesResponseType = 'num_entries'

export const validResponseTypes = [
  numEntriesResponseType,
  fuzzySearch,
  horizontalMultipleChoiceType,
  longFreeResponseType,
  numericFreeResponseType,
  picklistType,
  verticalMultipleChoiceType
]
export default ValidResponseType
