import Cookies from 'js-cookie'

import { emailKey } from '../localCommon/CookieKeys'

class AdminHandler {
  public static isDataBasedAdmin(): boolean {
    return Cookies.get(emailKey)?.includes('databased') ?? false
  }
}

export default AdminHandler
