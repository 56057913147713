import IHeuristicsUpdateHandler from '../../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/IHeuristicsUpdateHandler'
import { currentYear } from '../../../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import {
  oteHeuristicId,
  quotaHeuristicId,
  yearsInSalesHeuristicId
} from '../../../../localCommon/constant'
import IOnboardingHeuristicValuesUpdater from './IOnboardingHeuristicValuesUpdater'
import OnboardingHeuristicValuesDto from './OnboardingHeuristicValuesDto'

class OnboardingHeuristicValuesUpdater implements IOnboardingHeuristicValuesUpdater {
  constructor(private readonly heuristicsHandler: IHeuristicsUpdateHandler) {}

  public async updateOnboardingHeuristicValues(
    updateDto: OnboardingHeuristicValuesDto
  ): Promise<string> {
    const YearsInSalesUpdateResult = await this.heuristicsHandler.updateFactHeuristicsValue(
      yearsInSalesHeuristicId,
      updateDto.yearsOfExperience
    )
    const oteUpdateResult = await this.heuristicsHandler.updateMeasureHeuristicsValue(
      oteHeuristicId,
      updateDto.ote,
      currentYear
    )
    const quotaUpdateResult = await this.heuristicsHandler.updateMeasureHeuristicsValue(
      quotaHeuristicId,
      updateDto.quota,
      currentYear
    )

    return YearsInSalesUpdateResult ?? oteUpdateResult ?? quotaUpdateResult
  }
}

export default OnboardingHeuristicValuesUpdater
