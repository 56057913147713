import './style.scss'

import React, { Component, ReactElement } from 'react'

import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedButton from '../../localCommon/Button/OutlinedButton'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import List from '../../localCommon/List/List'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import { dashboardRoute } from '../RouteConstants'
import { invalidYearsOfExperienceMessage } from './PersonalInfoInputValidator/PersonalInfoInputValidator'
import { locations, numberOfYears, roles, yearGraduated } from './Presenters/DropdownOptions'
import { annualQuotaInputPlaceholder, oteInputPlaceholder } from './Presenters/HomePresenter'
import IPersonalInfoEditPresenter from './Presenters/Interfaces/IPersonalInfoEditPresenter'

type Props = {
  presenter: IPersonalInfoEditPresenter
}

class PersonalDataManualInputSection extends Component<Props> implements ISubscribingView {
  render(): ReactElement {
    const { presenter } = this.props

    if (presenter.isLoading()) {
      return (
        <div className='section-with-border home-inputSection'>
          <p className='home-inputSectionTitle'>Add info to update your market range</p>
          <div className='loading'></div>
          <div className='buttons-group'>
            <OutlinedButton isButtonDisabled onClick={this.backToPreviousPage}>
              Cancel
            </OutlinedButton>
            <ContainedButton isButtonDisabled onClick={this.submitPersonalInfo} type='submit'>
              Update
            </ContainedButton>
          </div>
        </div>
      )
    }

    return (
      <div className='section-with-border home-inputSection'>
        <p className='home-inputSectionTitle'>Add info to update your market range</p>
        <p className='home-errorMessage error'>{presenter.getErrorMessage()}</p>
        <div>
          <OutlinedInput
            caption='Current annual quota'
            id='current-annual-quota'
            isRequired
            label={annualQuotaInputPlaceholder}
            onChange={this.updateAnnualQuotaInput}
            shouldShowErrorMessage={!!presenter.getInlineErrorMessage()}
            type='text'
            unit='$'
            value={presenter.getAnnualQuotaInput()}
          />
        </div>
        <div>
          <OutlinedInput
            caption='On-Target-Earnings'
            isRequired
            id='on-target-earnings'
            label={oteInputPlaceholder}
            onChange={this.updateOteInput}
            shouldShowErrorMessage={!!presenter.getInlineErrorMessage()}
            type='text'
            unit='$'
            value={presenter.getOteInput()}
          />
          {presenter.shouldShowOteCaption() && (
            <p className='home-note caption'>
              Annual base salary + annual commission earned at 100% of quota = On-Target Earnings
              (OTE)
            </p>
          )}
        </div>
        <List
          elementId='location'
          id='location'
          isTypingAllowed
          isRequired
          label='Location'
          list={locations}
          searchInput={presenter.getLocationSearchInput()}
          selectedValue={presenter.getLocation()}
          shouldShowErrorMessage={!!presenter.getInlineErrorMessage() && !presenter.getLocation()}
          type='text'
          updateSelectedOption={this.updateLocation}
          updateSearchInput={this.updateLocationSearchInput}
        />
        <div className='home-manualInputSection'>
          <List
            elementId='years-in-sales'
            error={invalidYearsOfExperienceMessage}
            id='years-in-sales'
            isTypingAllowed
            isRequired
            label='Years in sales'
            list={numberOfYears}
            searchInput={presenter.getNumOfYearsExperienceSearchInput()}
            selectedValue={presenter.getNumOfYearsExperience()}
            shouldShowErrorMessage={
              !!presenter.getInvalidYearsOfExperienceErrorMessage() ||
              !!presenter.getInlineErrorMessage()
            }
            type='number'
            updateSelectedOption={this.updateNumOfYearsExperience}
            updateSearchInput={this.updateNumOfYearsExperienceSearchInput}
          />
          <List
            elementId='year-graduated'
            id='year-graduated'
            isTypingAllowed
            isRequired
            label='Year Graduated'
            list={yearGraduated}
            searchInput={presenter.getYearOfGraduatedSearchInput()}
            selectedValue={presenter.getYearGraduated()}
            shouldShowErrorMessage={
              !!presenter.getInlineErrorMessage() && !presenter.getYearGraduated()
            }
            type='number'
            updateSelectedOption={this.updateYearGraduated}
            updateSearchInput={this.updateYearOfGraduatedSearchInput}
          />
          <List
            elementId='current-business-role'
            id='current-business-role'
            isTypingAllowed={false}
            isRequired
            label='Select one'
            list={roles}
            searchInput={''}
            selectedValue={presenter.getRole()}
            shouldShowErrorMessage={!!presenter.getInlineErrorMessage() && !presenter.getRole()}
            type='text'
            updateSelectedOption={this.updateRole}
            updateSearchInput={() => null}
          />
        </div>
        <div className='buttons-group'>
          <OutlinedButton onClick={this.backToPreviousPage}>Cancel</OutlinedButton>
          <ContainedButton onClick={this.submitPersonalInfo} type='submit'>
            Update
          </ContainedButton>
        </div>
      </div>
    )
  }

  public update = (): void => {
    this.setState({})
  }

  updateOteInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateOteInput(target.value)
  }

  updateAnnualQuotaInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateAnnualQuotaInput(target.value)
  }

  updateLocation = (input: string): void => {
    this.props.presenter.updateLocation(input)
  }

  updateNumOfYearsExperience = (input: string): void => {
    this.props.presenter.updateNumOfYearsExperience(input)
  }

  updateYearGraduated = (input: string): void => {
    this.props.presenter.updateYearGraduated(input)
  }

  updateRole = (input: string): void => {
    this.props.presenter.updateRole(input)
  }

  updateLocationSearchInput = (input: string): void => {
    this.props.presenter.updateLocationSearchInput(input)
  }

  updateNumOfYearsExperienceSearchInput = (input: string): void => {
    this.props.presenter.updateNumOfYearsExperienceSearchInput(input)
  }

  updateYearOfGraduatedSearchInput = (input: string): void => {
    this.props.presenter.updateYearOfGraduatedSearchInput(input)
  }

  backToPreviousPage = (): void => {
    window.location.href = dashboardRoute
  }

  submitPersonalInfo = (): void => {
    this.props.presenter.submitPersonalInfo()
  }
}

export default PersonalDataManualInputSection
