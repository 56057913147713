import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import OutlinedInput from '../../../localCommon/OutlinedInput/OutlinedInput'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import NavigationButtonGroup from '../NavigationButtonGroup'
import INumEntriesPresenter from './INumEntriesPresenter'
import { defaultNumEntries } from './NumEntriesPresenter'

type Props = {
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: INumEntriesPresenter
}

class NumEntries extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.navigationPresenter?.setView(this)
    this.props.presenter.setView(this)
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.navigationPresenter?.clearView()
  }

  render(): ReactElement {
    const { navigationPresenter, presenter } = this.props
    const inputs = []
    const shouldShowError = presenter.shouldShowError()
    for (let i = 1; i <= defaultNumEntries; i++) {
      inputs.push(
        <OutlinedInput
          key={i}
          elementId={`word-${i}`}
          isRequired={presenter.isRequiredQuestion()}
          label={presenter.getPlaceholder(`${i}`)}
          onChange={this.updateInput}
          shouldShowErrorMessage={presenter.shouldShowError()}
          value={presenter.getWordValueByIdx(`${i}`)}
          type={'text'}
        />
      )
    }

    return (
      <div className='num-entries'>
        <p className='headline6'>{presenter.getTitle()}</p>
        <div className='body1'>{presenter.getDescription()}</div>
        {inputs}
        <div>
          <NavigationButtonGroup
            canBacktrackToPreviousPrompt={false}
            hasValidInput={presenter.hasValidResponse()}
            isRequired={presenter.isRequiredQuestion()}
            presenter={navigationPresenter}
            subscriber={this}
          />
        </div>
        {shouldShowError ? (
          <span className='error mdc-text-field-helper-text'>
            Please enter a - z characters only. No numbers or special characters.
          </span>
        ) : (
          ''
        )}
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  showErrorMessage = (): void => {
    this.props.presenter.showErrorMessage()
  }

  updateInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    const { id, value } = target
    const wordIdx = id.split('-')[1]
    this.props.presenter.updateWordInput(value, wordIdx)
  }
}

export default NumEntries
