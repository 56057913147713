import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import IAssessmentDetailsMailer from './IAssessmentDetailsMailer'

class AssessmentDetailsMailer implements IAssessmentDetailsMailer {
  constructor(private readonly fetcher: IFetcher) {}

  public async sendAssessmentsCompleteEmail(userId: number): Promise<void> {
    if (typeof userId === 'number') {
      await this.fetcher.fetch({
        body: null,
        method: 'POST',
        url: `${serverBaseUrl}user/${userId}/assessment/resultEmail`
      })
    }
  }
}

export default AssessmentDetailsMailer
