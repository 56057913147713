import './style.scss'

import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import AdminHandler from '../../Handlers/AdminHandler'
import images from '../../images'
import { candidateViewJobBoardRoute, dashboardRoute } from '../../Routes/RouteConstants'
import IMenuVisibilityManager from './MenuVisibilityManager/IMenuVisibilityManager'

type Props = {
  children?: React.ReactNode
  visibilityManager: IMenuVisibilityManager
}

class SlidingMenu extends Component<Props> {
  render(): ReactElement {
    const { children, visibilityManager } = this.props

    return (
      <div className={`menu ${visibilityManager.isOpen() ? 'open' : ''}`}>
        <div className='menu-content'>
          <img
            alt='close-drawer-icon'
            className='menu-close'
            onClick={this.closeMenu}
            src={images.closeIcon}
          />
          {this.renderLinks()}
          {children}
          <Link
            to={{
              pathname: '/editAccount'
            }}
          >
            <div className='subtitle2'>Edit Profile</div>
          </Link>
          <div className='subtitle2' onClick={this.signOut}>
            Log Out
          </div>
        </div>
      </div>
    )
  }

  closeMenu = (): void => {
    this.props.visibilityManager.close()
  }

  signOut = (): void => {
    this.props.visibilityManager.signOut()
  }

  renderLink = (to: string, label: string): ReactElement => {
    return (
      <Link
        to={to}
        onClick={(e) => {
          if (window.location.pathname === to) {
            e.preventDefault()
            window.location.reload()
          }
        }}
      >
        <div className='subtitle2'>{label}</div>
      </Link>
    )
  }

  renderLinks = (): ReactElement => {
    return (
      <div>
        {this.renderLink(dashboardRoute, 'Home')}
        {this.renderLink(
          candidateViewJobBoardRoute,
          AdminHandler.isDataBasedAdmin() ? 'Candidate Jobs' : 'Jobs'
        )}
      </div>
    )
  }
}

export default SlidingMenu
