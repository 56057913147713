import Cookies from 'js-cookie'

import { authTokenKey } from '../localCommon/CookieKeys'
import INonSerializableFetcher from './Interfaces/INonSerializableFetcher'

class NonSerializableFetcher implements INonSerializableFetcher {
  public async fetch(params: {
    auth?: string
    body: any
    headers?: any
    method: string
    url: string
  }): Promise<any> {
    const { auth, body, headers, method, url } = params

    const result = await fetch(url, {
      method,
      headers: headers ?? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth ?? Cookies.get(authTokenKey)}`
      },
      body: method === 'GET' ? undefined : body
    })

    if (result) {
      return result.json()
    }

    return null
  }
}

export default NonSerializableFetcher
