import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import AuthDto from '../Dtos/AuthDto'
import HttpCodeAndMessageDto from '../Dtos/HttpCodeAndMessageDto'
import NewUserDto from '../Dtos/NewUserDto'
import INewUserCreator from './INewUserCreator'

export const failedToSignUpErrorMessage = 'Failed to create new user. Please try again.'
export const SIGN_UP_URL = `${serverBaseUrl}user`
class NewUserCreator implements INewUserCreator {
  constructor(private readonly fetcher: IFetcher) {}

  public async signUp(dto: NewUserDto): Promise<AuthDto | string> {
    const result = await this.fetcher.fetch({
      method: 'POST',
      body: dto,
      url: SIGN_UP_URL
    })

    if (this.isValidToken(result)) {
      return result
    }

    if (this.isValidResponse(result)) {
      return result.message
    }

    return failedToSignUpErrorMessage
  }

  private isValidToken(obj: any): obj is AuthDto {
    return (
      obj &&
      obj.hasOwnProperty('auth') &&
      obj.auth.hasOwnProperty('token') &&
      obj.hasOwnProperty('userData')
    )
  }

  private isValidResponse(obj: any): obj is HttpCodeAndMessageDto {
    return obj && obj.hasOwnProperty('code') && obj.hasOwnProperty('message')
  }
}

export default NewUserCreator
