import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import OutlinedInput, { outlinedInputId } from '../../../localCommon/OutlinedInput/OutlinedInput'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import NavigationButtonGroup from '../NavigationButtonGroup'
import IFreeResponsePresenter from './Presenters/Interfaces/IFreeResponsePresenter'

type Props = {
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: IFreeResponsePresenter
}

class FreeResponse extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.handleInputValueLabelPosition()
    this.focusOnInput()
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
    this.handleInputValueLabelPosition()
    this.focusOnInput()
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { navigationPresenter, presenter } = this.props

    return (
      <div className='freeResponse'>
        <div>
          <div className='home-submit'>
            <p className='headline6'>{presenter.getTitle()}</p>
            <div className='body1'>{presenter.getDescription()}</div>
          </div>
          <OutlinedInput
            caption={presenter.getCaption()}
            error={this.getError()}
            id='free-response'
            isRequired={presenter.isRequiredQuestion()}
            label={presenter.getPlaceholder()}
            onChange={this.updateFreeResponseInput}
            shouldShowErrorMessage={presenter.shouldShowError()}
            value={presenter.getValue()}
            type={presenter.getInputType()}
            step={presenter.getInputType() === 'number' ? 0.1 : undefined}
            unit={presenter.getUnit()}
          />
          <NavigationButtonGroup
            canBacktrackToPreviousPrompt={navigationPresenter.isBackTrackAllowed()}
            hasValidInput={presenter.hasValidResponse()}
            isRequired={presenter.isRequiredQuestion()}
            presenter={navigationPresenter}
            subscriber={this}
          />
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  getError = (): string => {
    return this.props.presenter.getInlineError()
  }

  showErrorMessage = (): void => {
    this.props.presenter.showErrorMessage()
  }

  updateFreeResponseInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateInput(target.value)
  }

  focusOnInput = (): void => {
    const input = document.getElementById('free-response') as HTMLElement

    if (input) {
      input.focus()
    }
  }

  handleInputValueLabelPosition = (): void => {
    const input = document.querySelector(`#${outlinedInputId}`)
    if (input) {
      if (this.props.presenter.getValue()) {
        const focusEvent = new Event('focus')
        input.dispatchEvent(focusEvent)
      } else {
        const blurEvent = new Event('blur')
        input.dispatchEvent(blurEvent)
      }
    }
  }
}

export default FreeResponse
