import ICandidateProfile from './Interfaces/ICandidateProfile'

class CandidateStore {
  private static userIdToProfile = new Map<number, ICandidateProfile>()

  public static getProfileById(userId: number): ICandidateProfile | null {
    return CandidateStore.userIdToProfile.get(userId) ?? null
  }

  public static setProfile(userId: number, profile: ICandidateProfile): void {
    CandidateStore.userIdToProfile.set(userId, profile)
  }
}

export default CandidateStore
