import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import AssessmentDto from './AssessmentDto'
import IFirstPromptGetter from './IFirstPromptGetter'

export const failedToGetFirstPromptMessage = 'Failed to get assessment info. Please try again.'
export const ASSESSMENT_URL = `${serverBaseUrl}assessment/`
class FirstPromptGetter implements IFirstPromptGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getFirstPromptIdByAssessmentId(id: number): Promise<AssessmentDto | string> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${ASSESSMENT_URL}${id}`
    })

    if (this.isValidAssessment(result)) {
      return {
        assessmentId: result.id,
        firstPromptId: result.firstPromptId
      }
    }

    return failedToGetFirstPromptMessage
  }

  private isValidAssessment(obj: any): boolean {
    return obj?.hasOwnProperty('firstPromptId') && obj?.hasOwnProperty('id')
  }
}

export default FirstPromptGetter
