import StringHelper from '../../../../localCommon/StringHelper/StringHelper'
import { percentageUnit } from '../../../../localCommon/ValidHeuristicUnit'
import IFreeResponseDataUpdater from '../../DataRow/DataRowDataUpdater/Interfaces/IFreeResponseDataUpdater'
import FreeResponseQuestionDto from '../../Dtos/FreeResponseQuestionDto'
import AbstractResponsePresenter from '../../Presenters/AbstractResponsePresenter'
import IFreeResponsePresenter from './Interfaces/IFreeResponsePresenter'
import { quotaFreeResponse } from './Interfaces/ValidFreeResponseType'

class FreeResponsePresenter extends AbstractResponsePresenter implements IFreeResponsePresenter {
  constructor(
    protected readonly question: FreeResponseQuestionDto,
    protected readonly questionIndex: number,
    private readonly rowDataUpdater?: IFreeResponseDataUpdater
  ) {
    super(question, questionIndex, question.isRequired)
  }

  public getPromptValue(): string {
    if (this.question.unit === '$') {
      return StringHelper.filterOutCommaFromNumberString(this.input)
    }

    if (this.question.unit === '%') {
      const input = parseFloat(StringHelper.filterOutCommaFromNumberString(this.input)) / 100
      return input.toString()
    }

    return this.input
  }

  public getCaption(): string {
    return this.question.caption
  }

  public getPlaceholder(): string {
    return this.question.placeholder
  }

  public getInputType(): 'number' | 'text' {
    return this.question.inputType
  }

  public hasValidResponse(): boolean {
    if (this.question.unit === percentageUnit) {
      return parseFloat(this.input) >= 0 && parseFloat(this.input) <= 100
    }

    return !!this.input
  }

  public getValue(): string {
    if (this.question.uniqueId === quotaFreeResponse && this.rowDataUpdater) {
      this.rowDataUpdater.updateQuota(this.input)
    }

    if (this.question.unit === '$') {
      return StringHelper.getFormattedDollarAmount(this.input)
    }

    return this.input
  }

  public getResponseName(): string {
    return this.question?.uniqueId ?? ''
  }
}

export default FreeResponsePresenter
