import UserAggregateHeuristicScorecardDto from '../../Handlers/AssessmentResultHandler/Dtos/UserAggregateHeuristicScorecardDto'
import IAssessmentScoreCalculator from '../../Handlers/AssessmentScoreCalculator/IAssessmentScoreCalculator'
import CoreAssessmentDto from '../../Routes/PersonalInfoData/CoreAssessments/AssessmentBars/CoreAssessmentDto'
import IAssessmentResultAdaptor from './IAssessmentResultAdaptor'

export const assessmentBarBenchmark = 5
class AssessmentResultAdaptor implements IAssessmentResultAdaptor {
  constructor(private readonly calculator: IAssessmentScoreCalculator) {}

  public getCoreAssessment(
    response: UserAggregateHeuristicScorecardDto,
    score: number
  ): CoreAssessmentDto {
    return {
      percentile: this.calculator.getScorePercentile(score),
      description: response.description,
      id: response.aggregateHeuristicId,
      isDisabled: false,
      title: response.name
    }
  }
}

export default AssessmentResultAdaptor
