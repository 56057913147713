import './StyleSheets/CandidateNotes.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import ICandidateNotesPresenter from './InterfacesAndDtos/ICandidateNotesPresenter'

type Props = {
  presenter: ICandidateNotesPresenter
}

class CandidateNotes extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const note: string = presenter.getCandidateNote()
    const showSaveButton: boolean = presenter.canSaveNote()
    const shareMode = presenter.isInShareMode()

    return (
      <div className='notes-container'>
        <label htmlFor='publicNotes' className='notes-label'>
          Notes
        </label>
        <textarea
          id='publicNotes'
          className='notes-input'
          readOnly={shareMode}
          value={note}
          onChange={this.handleTextChange}
        />
        {showSaveButton && (
          <div className='notes-save-button-container'>
            <button className='save-notes-button' type='button' onClick={this.handleSaveNote}>
              SAVE
            </button>
          </div>
        )}
      </div>
    )
  }

  handleSaveNote = (): void => {
    this.props.presenter.saveNote()
  }

  handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.props.presenter.handleCandidateNoteTextChange(event.target.value)
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateNotes
