import ICommonObject from '../../../../localCommon/Interfaces/ICommonObject'
import ICommonObjectWithSortId from '../../../../localCommon/Interfaces/ICommonObjectWithSortId'
import { missingRequiredFieldsErrorMessage } from '../../../PersonalInfoData/Presenters/HomePresenter'
import IPicklistDataUpdater from '../../DataRow/DataRowDataUpdater/Interfaces/IPicklistDataUpdater'
import PickListDto from '../../Dtos/PickListDto'
import PicklistQuestionDto from '../../Dtos/PicklistQuestionDto'
import ValidPicklistResponseType, {
  rolesPicklistResponse,
  yearsInSalesListId
} from '../../Dtos/ValidPicklistResponseType'
import AbstractResponsePresenter from '../../Presenters/AbstractResponsePresenter'
import IPicklistResponsePresenter from './Interfaces/IPicklistResponsePresenter'

class PicklistResponsePresenter
  extends AbstractResponsePresenter
  implements IPicklistResponsePresenter
{
  private searchInput: string

  constructor(
    protected readonly question: PicklistQuestionDto,
    private readonly id: ValidPicklistResponseType,
    protected readonly questionIndex: number,
    private readonly picklist: PickListDto,
    private readonly rowDataUpdater?: IPicklistDataUpdater
  ) {
    super(question, questionIndex, question.isRequired)

    this.searchInput = ''
  }

  public isTypingAllowed(): boolean {
    return this.question?.isTypingAllowed ?? true
  }

  public getPromptValue(): ICommonObject {
    return this.picklist.list.find(
      (option) => option.name?.toLowerCase() === this.input.toLowerCase()
    )!
  }

  public getSearchInput(): string {
    return this.searchInput
  }

  public updateSearchInput(input: string): void {
    this.input = input
    this.searchInput = input
    this.updateView()
  }

  public getInlineError(): string {
    if (this.shouldShowError() && !this.input) {
      return missingRequiredFieldsErrorMessage
    }

    return ''
  }

  public getPicklist(): PickListDto {
    let { list } = this.picklist

    if (this.hasOrderIndexAvailable(list[0])) {
      list = (list as ICommonObjectWithSortId[]).sort(
        (listA, listB) => listA.orderIndex - listB.orderIndex
      )
    }

    this.picklist.list = list
    return this.picklist
  }

  private hasOrderIndexAvailable(item: object): item is ICommonObjectWithSortId {
    return item.hasOwnProperty('orderIndex')
  }

  public getValue(): string {
    if (this.id === yearsInSalesListId && this.rowDataUpdater) {
      this.rowDataUpdater.updateYearsOfExperience(this.input)
    }

    if (this.id === rolesPicklistResponse && this.rowDataUpdater) {
      this.rowDataUpdater.updateRole(this.input)
    }

    return this.input
  }

  public getResponseName(): string {
    return this.id
  }
}

export default PicklistResponsePresenter
