import './style.scss'

import React, { Component, Fragment, ReactElement } from 'react'

import IUserProfilePresenter from '../../Routes/Home/UserProfile/Interfaces/IUserProfilePresenter'
import UserProfile from '../../Routes/Home/UserProfile/UserProfile'
import IMenuVisibilityManager from '../SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import Header from './Header'

type Props = {
  children?: React.ReactNode
  menuVisibilityManager: IMenuVisibilityManager
  userProfilePresenter: IUserProfilePresenter
}

class HeaderWithProfile extends Component<Props> {
  render(): ReactElement {
    const { children, menuVisibilityManager, userProfilePresenter } = this.props

    return (
      <Fragment>
        <Header menuVisibilityManager={menuVisibilityManager}>{children}</Header>
        <div className='header'>
          <UserProfile presenter={userProfilePresenter} />
        </div>
      </Fragment>
    )
  }
}

export default HeaderWithProfile
