import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import HttpCodeAndMessageDto from '../Dtos/HttpCodeAndMessageDto'
import IPersonalInfoPutter from './IPersonalInfoPutter'
import PersonalInfoPutDto from './PersonalInfoPutDto'

export const PERSONAL_INFO_URL = `${serverBaseUrl}user/`
class PersonalInfoPutter implements IPersonalInfoPutter {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async sendPersonalInfo(dto: PersonalInfoPutDto): Promise<string> {
    const result = await this.fetcher.fetch({
      method: 'PUT',
      url: `${PERSONAL_INFO_URL}${this.userId}`,
      body: dto
    })

    if (this.failedToSubmittedPersonalInfo(result)) {
      return result.message
    }

    return ''
  }

  private failedToSubmittedPersonalInfo(obj: any): obj is HttpCodeAndMessageDto {
    return obj.hasOwnProperty('message') && obj.message
  }
}

export default PersonalInfoPutter
