import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import AbstractResponsePresenter from '../Presenters/AbstractResponsePresenter'
import FuzzySearchQuestionDto from './FuzzySearchQuestionDto'
import IFuzzySearchPresenter from './IFuzzySearchPresenter'

class FuzzySearchPresenter extends AbstractResponsePresenter implements IFuzzySearchPresenter {
  private universities: string[]
  constructor(
    protected readonly question: FuzzySearchQuestionDto,
    protected readonly questionIndex: number,
    private readonly fetcher: IFetcher
  ) {
    super(question, questionIndex, question.isRequired)
    this.universities = []
  }

  public getUniversities(): string[] {
    return this.universities
  }

  public clickedUniversity(university: string): void {
    this.input = university
    this.universities = []
    this.updateView()
  }

  private async getMatchingUniversities(): Promise<void> {
    if (this.input.length >= 3) {
      try {
        const result = await this.fetcher.fetch({
          method: 'GET',
          url: `${serverBaseUrl}universities?search=${this.input}`,
          body: null
        })
        if (Array.isArray(result)) {
          this.universities = result
        }
      } catch (err) {
        this.universities = []
      }
    }
    this.updateView()
  }

  public getPromptValue() {
    return this.input
  }

  public getResponseName(): string {
    return ''
  }

  public getValue(): string {
    return this.input
  }

  public updateInput(input: string): void {
    this.input = input
    this.getMatchingUniversities()
    this.updateView()
  }
}

export default FuzzySearchPresenter
