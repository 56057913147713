import IPersonalInfoGetter from '../../APIs/PersonalInfoGetter/IPersonalInfoGetter'
import PersonalInfoWithFactsDto from '../../APIs/PersonalInfoPutter/PersonalInfoWithFactsDto'
import { nameKey } from '../../localCommon/CookieKeys'
import ICookieSetter from '../../localCommon/Interfaces/ICookieSetter'
import IHomeMetricPerformanceHandler from '../HomeMetricPerformanceHandler/IHomeMetricPerformanceHandler'
import IPersonalInfoDataHandler from './IPersonalInfoDataHandler'

class PersonalInfoDataHandler implements IPersonalInfoDataHandler {
  constructor(
    private readonly personalInfoGetter: IPersonalInfoGetter,
    private readonly homeMetricPerformanceHandler: IHomeMetricPerformanceHandler,
    private readonly cookieSetter: ICookieSetter
  ) {}

  public async getPersonalInfoData(): Promise<string | PersonalInfoWithFactsDto> {
    const userInfoData = await this.personalInfoGetter.getUserInfoData()
    const performance = await this.homeMetricPerformanceHandler.getHomeMetricData()

    if (typeof userInfoData === 'string') {
      return userInfoData
    }

    this.cookieSetter.set(nameKey, userInfoData.name, {
      expires: 1
    })
    return {
      ...userInfoData,
      ...performance
    }
  }
}

export default PersonalInfoDataHandler
