/* eslint-disable react/no-unstable-nested-components */
import './index.scss'
import './reset.css'
import './theme.css'

import * as amplitude from '@amplitude/analytics-browser'
// eslint-disable-next-line import/no-extraneous-dependencies
import flagsmith from 'flagsmith'
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie'
import React, { Component, ReactElement } from 'react'
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom'

import AssessmentResultAdaptor from './Adaptors/AssessmentResultAdaptor/AssessmentResultAdaptor'
import PromptAdaptor from './Adaptors/PromptAdaptor/PromptAdaptor'
import AssessmentScoreFetcher from './APIs/AssessmentScoreFetcher/AssessmentScoreFetcher'
import { PSYCHOMETRIC_ASSESSMENT_ID } from './APIs/AssessmentStarter/AssessmentStarter'
import CandidateProfileFetcher from './APIs/CandidateProfileFetcher/CandidateProfileFetcher'
import CurrentStageUpdater from './APIs/CurrentStageUpdater/CurrentStageUpdater'
import HeuristicsUpdateHandler from './APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/HeuristicsUpdateHandler'
import HeuristicsDtoFactory from './APIs/HeuristicsUpdater/HeuristicsDtoFactory/HeuristicsDtoFactory'
import HeuristicsUpdater from './APIs/HeuristicsUpdater/HeuristicsUpdater'
import LogInAuthenticator from './APIs/LogInAuthenticator/LogInAuthenticator'
import MarketCompensationDataGetter from './APIs/MarketCompensationDataGetter/MarketCompensationDataGetter'
import NewUserCreator from './APIs/NewUserCreator/NewUserCreator'
import ProfileImagePoster from './APIs/ProfileImagePoster/ProfileImagePoster'
import UserAccountEditor from './APIs/UserAccountEditor/UserAccountEditor'
import Fetcher from './Drivers/Fetcher'
import NonSerializableFetcher from './Drivers/NonSerializableFetcher'
import AdminHandler from './Handlers/AdminHandler'
import AssessmentResultHandler from './Handlers/AssessmentResultHandler/AssessmentResultHandler'
import AssessmentScoreCalculator from './Handlers/AssessmentScoreCalculator/AssessmentScoreCalculator'
import HomeMetricPerformanceHandlerFactory from './Handlers/HomeMetricPerformanceHandler/HomeMetricPerformanceHandlerFactory/HomeMetricPerformanceHandlerFactory'
import ProfileImageHandler from './Handlers/ProfileImageHandler/ProfileImageHandler'
import WebPageToPdf from './Handlers/WebPageToFileConverters/WebPageToPdf'
import { AMPLITUDE_APP_KEY, FLAGSMITH_ENV_ID } from './localCommon/constant'
import {
  assessmentFeatureFlagKey,
  assessmentIdKey,
  emailKey,
  jobUrlKey,
  nameKey,
  userIdKey
} from './localCommon/CookieKeys'
import ICookieGetter from './localCommon/Interfaces/ICookieGetter'
import NumberFormatter from './localCommon/NumberFormatter/NumberFormatter'
import SignOutHelper from './localCommon/SignOut/SignOutHelper/SignOutHelper'
import MenuVisibilityManager from './localCommon/SlidingMenu/MenuVisibilityManager/MenuVisibilityManager'
import VisibilityManager from './localCommon/SlidingMenu/VisibilityManager/VisibilityManager'
import assessmentStore from './Models/AssessmentStore'
import candidateStore from './Models/CandidateStore'
import personalDataStore from './Models/PersonalDataStore'
import Assessment from './Routes/Assessment/Assessment'
import AssessmentPresenterFactory from './Routes/Assessment/Factory/AssessmentPresenterFactory'
import DateRangeTypesGetter from './Routes/Assessment/Presenter/DateRangeTypesGetter/DateRangeTypesGetter'
import Candidate from './Routes/CandidatePool/Candidate/Candidate'
import CandidatePool from './Routes/CandidatePool/CandidatePool'
import CandidateDataHandler from './Routes/CandidatePool/CandidateRow/CandidateDataHandler/CandidateDataHandler'
import OTEStandingPresenterFactory from './Routes/CandidatePool/CandidateRow/OTEStandingPresenterProvider/OTEStandingPresenterFactory'
import CandidatePresenter from './Routes/CandidatePool/CandidateRow/Presenters/CandidatePresenter/CandidatePresenter'
import CandidatePoolFilterPresenter from './Routes/CandidatePool/Filter/CandidatePoolFilterPresenter'
import CandidatePoolPresenter from './Routes/CandidatePool/Presenter/CandidatePoolPresenter'
import CandidateSummary from './Routes/CandidateSummary/CandidateSummary'
import CandidateSummaryPresenter from './Routes/CandidateSummary/CandidateSummaryPresenter'
import EditAccount from './Routes/EditAccount/EditAccount'
import EditAccountPresenter from './Routes/EditAccount/Presenter/EditAccountPresenter'
import ResumeUploadPresenter from './Routes/EditAccount/Resume/Presenter/ResumeUploadPresenter'
import ForgotPassword from './Routes/ForgotPassword/ForgotPassword'
import ForgotPasswordPresenter from './Routes/ForgotPassword/Presenter/ForgotPasswordPresenter'
import Home from './Routes/Home/Home'
import ResponsePresenterFactoryFactory from './Routes/Home/Presenters/PresenterFactory/ResponsePresenterFactoryFactory'
import PromptResponsePresenterFactory from './Routes/Home/Presenters/PromptPresenterFactory/PromptResponsePresenterFactory'
import ResponsePresenterStore from './Routes/Home/ResponsePresenterStore/ResponsePresenterStore'
import UserProfilePresenterFactory from './Routes/Home/UserProfile/UserProfilePresenterFactory'
import ApplyToJob from './Routes/Jobs/Applications/ApplyToJob'
import ApplyToJobPresenter from './Routes/Jobs/Applications/ApplyToJobPresenter'
import CandidateViewJobs from './Routes/Jobs/CandidateView/CandidateViewJobs'
import CandidateViewJobsPresenter from './Routes/Jobs/CandidateView/CandidateViewJobsPresenter'
import JobListing from './Routes/Jobs/JobListing'
import JobListings from './Routes/Jobs/JobListings'
import NewJobListing from './Routes/Jobs/NewJobListing'
import JobListingPresenter from './Routes/Jobs/Presenters/JobListingPresenter'
import JobListingsPresenter from './Routes/Jobs/Presenters/JobListingsPresenter'
import NewJobListingPresenter from './Routes/Jobs/Presenters/NewJobListingPresenter'
import LogIn from './Routes/LogIn/LogIn'
import LogInPresenter from './Routes/LogIn/Presenters/LogInPresenter/LogInPresenter'
import SsoLogInPresenter from './Routes/LogIn/Presenters/SsoLogInPresenter/SsoLogInPresenter'
import PasswordReset from './Routes/PasswordReset/PasswordReset'
import PasswordResetPresenter from './Routes/PasswordReset/Presenter/PasswordResetPresenter'
import AppointmentDatesRetriever from './Routes/PersonalInfoData/Dashboard/AppointmentDatesRetriever/AppointmentDatesRetriever'
import AssessmentResultPresenter from './Routes/PersonalInfoData/Dashboard/AssessmentResult/Presenter/AssessmentResultPresenter'
import Dashboard from './Routes/PersonalInfoData/Dashboard/Dashboard'
import HomePresenterFactory from './Routes/PersonalInfoData/Factory/HomePresenterFactory'
import HomeDataRowsPresenter from './Routes/PersonalInfoData/OTEStanding/HomeDataRowsPresenter'
import PrivateRoute from './Routes/PrivateRoute'
import {
  assessmentRoute,
  candidateProfileRoute,
  candidateSummaryRoute,
  candidateViewJobBoardRoute,
  candidateViewJobRoute,
  dashboardRoute,
  jobManagementRoute,
  jobsManagementRoute,
  loginRoute,
  ssoLoginsRoute,
  summaryRoutePostfix
} from './Routes/RouteConstants'
import SignUpPresenter from './Routes/SignUp/Presenter/SignUpPresenter'
import SignUp from './Routes/SignUp/SignUp'
import SsoLoginResponsePresenter from './Routes/SsoLogin/Presenters/SsoLoginResponsePresenter'
import SsoLoginResponse from './Routes/SsoLogin/SsoLoginResponse'
import SsoLogins from './Routes/SsoLogin/SsoLogins'

export const createMenuVisibilityManager = (): MenuVisibilityManager => {
  return new MenuVisibilityManager(
    new SignOutHelper(Cookies, {
      setPathName: (path: string) => {
        window.location.pathname = path
      }
    })
  )
}
class App extends Component {
  componentDidMount(): void {
    amplitude.init(AMPLITUDE_APP_KEY)
    this.fetchFlags()
  }

  render(): ReactElement {
    const assessmentId = Cookies.get(assessmentIdKey) ?? null

    const userId = parseInt(Cookies.get(userIdKey) ?? '', 10) ?? null

    return (
      <Router>
        <Switch>
          <Route
            path={candidateViewJobBoardRoute}
            component={() => (
              <CandidateViewJobs
                menuVisibilityManager={createMenuVisibilityManager()}
                presenter={new CandidateViewJobsPresenter(new Fetcher())}
              />
            )}
          />
          <PrivateRoute
            path={`${candidateSummaryRoute}/:candidateId${summaryRoutePostfix}`}
            component={(props) => {
              const isDataBasedAdmin = AdminHandler.isDataBasedAdmin()
              const loggedInUserId = parseInt(Cookies.get(userIdKey) ?? '', 10)

              const { candidateId } = props.match.params
              const parsedCandidateId = Number.parseInt(candidateId, 10)

              if (!isDataBasedAdmin && loggedInUserId !== parsedCandidateId) {
                props.history.replace(
                  `${candidateSummaryRoute}/${loggedInUserId}${summaryRoutePostfix}`
                )
                return null
              }
              const assessmentId = Cookies.get(assessmentIdKey) ?? null

              const parsedInt =
                assessmentId === null || isNaN(Number.parseInt(assessmentId, 10))
                  ? -1
                  : Number.parseInt(assessmentId, 10)

              return (
                <CandidateSummary
                  menuVisibilityManager={createMenuVisibilityManager()}
                  presenter={
                    new CandidateSummaryPresenter(
                      parsedCandidateId,
                      new Fetcher(),
                      new HeuristicsUpdateHandler(
                        new HeuristicsDtoFactory(new DateRangeTypesGetter(), parsedCandidateId),
                        new HeuristicsUpdater(new Fetcher(), parsedCandidateId)
                      ),
                      new NumberFormatter(),
                      new AssessmentResultPresenter(
                        new AppointmentDatesRetriever(new Fetcher(), parsedCandidateId),
                        new AssessmentResultHandler(
                          new AssessmentResultAdaptor(new AssessmentScoreCalculator()),
                          new Fetcher()
                        ),
                        new AssessmentScoreFetcher(new Fetcher(), parsedCandidateId),
                        new CurrentStageUpdater(new Fetcher(), parsedCandidateId),
                        assessmentStore,
                        parsedInt,
                        parsedCandidateId
                      ),
                      new WebPageToPdf(),
                      isDataBasedAdmin
                    )
                  }
                />
              )
            }}
          />
          <PrivateRoute
            exact
            path={`${candidateViewJobRoute}/:jobId/apply`}
            component={(props) => {
              const { jobId } = props.match.params
              return (
                <ApplyToJob
                  menuVisibilityManager={createMenuVisibilityManager()}
                  presenter={
                    new ApplyToJobPresenter(
                      Number.parseInt(jobId, 10),
                      userId,
                      new Fetcher(),
                      new ResumeUploadPresenter(new NonSerializableFetcher())
                    )
                  }
                />
              )
            }}
          />
          <PrivateRoute
            exact
            path='/'
            component={() => {
              const factory = new ResponsePresenterFactoryFactory().getResponsePresenterFactory()
              const homePresenterFactory = new HomePresenterFactory()
              const homePresenter = homePresenterFactory.buildPresenter()

              return (
                <Home
                  dashboardPresenter={homePresenter.getDashboardPresenter()}
                  factory={factory}
                  menuVisibilityManager={createMenuVisibilityManager()}
                  name={Cookies.get(nameKey) ?? ''}
                  navigationPresenter={factory.getNavigationPresenter()}
                  presenter={homePresenter}
                />
              )
            }}
          />
          <Route
            path={ssoLoginsRoute}
            component={() => {
              return <SsoLogins presenter={new SsoLogInPresenter()} />
            }}
          />
          <Route
            path='/ssoLogin/response'
            component={() => {
              return (
                <SsoLoginResponse
                  presenter={
                    new SsoLoginResponsePresenter(
                      Cookies,
                      {
                        setState: (newPath: string) => {
                          const baseUrl = window.location.origin

                          window.history.pushState({}, document.title, `${baseUrl}${newPath}`)
                          window.dispatchEvent(new PopStateEvent('popstate'))
                        }
                      },
                      {
                        decodeData: (data: string) => atob(data)
                      }
                    )
                  }
                />
              )
            }}
          />
          <Route
            path='/signup'
            component={() => {
              return (
                <SignUp
                  presenter={
                    new SignUpPresenter(
                      new NewUserCreator(new Fetcher()),
                      Cookies,
                      {
                        setPathName: (path: string) => (window.location.pathname = path)
                      },
                      new ProfileImageHandler(
                        'profile',
                        'profile-picture',
                        new ProfileImagePoster(new NonSerializableFetcher()),
                        document,
                        Cookies,
                        {
                          get: Cookies.get
                        } as ICookieGetter,
                        Cookies.get(nameKey) ?? ''
                      )
                    )
                  }
                />
              )
            }}
          />
          <PrivateRoute
            path='/editAccount'
            component={() => {
              return (
                <EditAccount
                  presenter={
                    new EditAccountPresenter(
                      new UserAccountEditor(new Fetcher()),
                      new ProfileImageHandler(
                        'profile',
                        'profile-picture',
                        new ProfileImagePoster(new NonSerializableFetcher()),
                        document,
                        Cookies,
                        {
                          get: Cookies.get
                        } as ICookieGetter,
                        Cookies.get(nameKey) ?? ''
                      ),
                      parseInt(Cookies.get(userIdKey) ?? '', 10) ?? null,
                      Cookies.get(emailKey) ?? '',
                      Cookies.get(nameKey) ?? ''
                    )
                  }
                />
              )
            }}
          />
          <Route
            path={loginRoute}
            component={() => {
              return (
                <LogIn
                  presenter={
                    new LogInPresenter(new LogInAuthenticator(new Fetcher()), Cookies, {
                      setPathName: (path: string) => (window.location.pathname = path)
                    })
                  }
                />
              )
            }}
          />
          {AdminHandler.isDataBasedAdmin() && (
            <PrivateRoute
              path={`${candidateProfileRoute}/:candidateId`}
              component={() => {
                const presenter = new CandidatePresenter(
                  new CandidateDataHandler(
                    new MarketCompensationDataGetter(new Fetcher()),
                    new HomeMetricPerformanceHandlerFactory(),
                    new CandidateProfileFetcher(new Fetcher()),
                    candidateStore
                  ),
                  new OTEStandingPresenterFactory(
                    new HomeDataRowsPresenter(
                      new NumberFormatter(),
                      personalDataStore,
                      'Current OTE Standing'
                    ),
                    new NumberFormatter()
                  ),
                  new Fetcher(),
                  new AssessmentResultHandler(
                    new AssessmentResultAdaptor(new AssessmentScoreCalculator()),
                    new Fetcher()
                  )
                )

                return (
                  <Candidate
                    menuVisibilityManager={createMenuVisibilityManager()}
                    presenter={presenter}
                  />
                )
              }}
            />
          )}
          <PrivateRoute
            path={dashboardRoute}
            component={() => {
              const homePresenterFactory = new HomePresenterFactory()
              const presenter = homePresenterFactory.buildPresenter()
              if (AdminHandler.isDataBasedAdmin()) {
                return (
                  <CandidatePool
                    menuVisibilityManager={createMenuVisibilityManager()}
                    presenter={
                      new CandidatePoolPresenter(
                        new Fetcher(),
                        new CandidatePoolFilterPresenter(new Fetcher())
                      )
                    }
                    filterVisibilityManager={new VisibilityManager()}
                  />
                )
              }

              return (
                <Dashboard
                  homePresenter={presenter}
                  menuVisibilityManager={createMenuVisibilityManager()}
                  presenter={presenter.getDashboardPresenter()}
                  userProfilePresenter={UserProfilePresenterFactory.buildPresenter(userId)}
                />
              )
            }}
          />
          <PrivateRoute
            path='/psychometric'
            component={() => {
              const factory = new ResponsePresenterFactoryFactory().getResponsePresenterFactory()
              const store = new ResponsePresenterStore()
              const homePresenterFactory = new HomePresenterFactory()
              const assessmentPresenterFactory = new AssessmentPresenterFactory(userId)

              return (
                <Assessment
                  factory={
                    new PromptResponsePresenterFactory(
                      new PromptAdaptor(),
                      factory.getNavigationPresenter(),
                      store
                    )
                  }
                  homePresenter={homePresenterFactory.buildPresenter()}
                  presenter={assessmentPresenterFactory.getAssessmentPresenter(
                    PSYCHOMETRIC_ASSESSMENT_ID,
                    store
                  )}
                  userId={userId}
                />
              )
            }}
          />
          <PrivateRoute
            path={`${assessmentRoute}/:id`}
            component={() => {
              const factory = new ResponsePresenterFactoryFactory().getResponsePresenterFactory()
              const store = new ResponsePresenterStore()
              const homePresenterFactory = new HomePresenterFactory()
              const assessmentPresenterFactory = new AssessmentPresenterFactory(userId)
              const { id } = useParams<{ id: string }>()
              const params = new URLSearchParams(window.location.search)
              const jobUrl = params.get(jobUrlKey)
              const jobUrlFromStorage = sessionStorage.getItem(jobUrlKey)
              const jobUrlToUse = jobUrl ?? jobUrlFromStorage ?? dashboardRoute
              const assessmentIdFromSessionStorage = sessionStorage.getItem(assessmentIdKey)
              const assessmentIdToUse =
                assessmentIdFromSessionStorage ?? id
                  ? Number.parseInt(id, 10)
                  : Number.parseInt(assessmentId ?? '-1', 10)

              sessionStorage.removeItem(assessmentIdKey)
              sessionStorage.removeItem(jobUrlKey)

              return (
                <Assessment
                  factory={
                    new PromptResponsePresenterFactory(
                      new PromptAdaptor(),
                      factory.getNavigationPresenter(),
                      store
                    )
                  }
                  homePresenter={homePresenterFactory.buildPresenter()}
                  presenter={assessmentPresenterFactory.getAssessmentPresenter(
                    assessmentIdToUse,
                    store,
                    jobUrlToUse
                  )}
                  userId={userId}
                />
              )
            }}
          />
          <Route
            path='/resetPassword'
            component={() => {
              return <PasswordReset presenter={new PasswordResetPresenter(new Fetcher())} />
            }}
          />
          <Route
            path='/forgotPassword'
            component={() => {
              return <ForgotPassword presenter={new ForgotPasswordPresenter(new Fetcher())} />
            }}
          />
          <PrivateRoute
            path={jobsManagementRoute}
            component={() => {
              return (
                <JobListings
                  presenter={new JobListingsPresenter(new Fetcher())}
                  menuVisibilityManager={createMenuVisibilityManager()}
                />
              )
            }}
          />
          <PrivateRoute
            path={`${jobManagementRoute}/new`}
            component={() => {
              return <NewJobListing presenter={new NewJobListingPresenter(new Fetcher())} />
            }}
          />
          <PrivateRoute
            path={`${jobManagementRoute}/:jobId`}
            component={() => {
              const { jobId } = useParams<{ jobId: string }>()
              return (
                <JobListing
                  presenter={new JobListingPresenter(parseInt(jobId, 10), new Fetcher())}
                />
              )
            }}
          />
        </Switch>
      </Router>
    )
  }

  fetchFlags = (): void => {
    flagsmith
      .init({
        environmentID: FLAGSMITH_ENV_ID
      })
      .then(() => {
        const shouldShowOneAssessment: boolean = flagsmith.getValue('show_one_assessment')
        Cookies.set(assessmentFeatureFlagKey, shouldShowOneAssessment.toString())
      })
  }
}

export default App
