import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RecruitingRoleTypeDto from '../../CandidatePool/Candidate/InterfacesAndDtos/RecruitingRoleTypeDto'
import NewJobDto from '../Dtos/NewJobDto'
import INewJobListingPresenter from './Interfaces/INewJobListingPresenter'

class NewJobListingPresenter implements INewJobListingPresenter {
  public resultMessage: string
  public wasSuccessfulCreate: boolean | null
  private view: ISubscribingView | null
  private job: NewJobDto
  private recruitingRoleTypes: RecruitingRoleTypeDto[]
  private loading: boolean

  constructor(private readonly fetcher: IFetcher) {
    this.resultMessage = ''
    this.wasSuccessfulCreate = null
    this.view = null
    this.job = {
      baseCompensationHigh: null,
      baseCompensationLow: null,
      companyName: null,
      jobDescription: null,
      jobTitle: null,
      jobType: null,
      location: null,
      variableCompensation: null,
      recruitingRoleTypeId: null,
      isActive: true
    }
    this.recruitingRoleTypes = []
    this.loading = false
  }

  public isLoading(): boolean {
    return this.loading
  }

  public async initialize(): Promise<void> {
    this.loading = true
    this.updateView()
    try {
      const recruitingTypesResult = await this.fetcher.fetch({
        method: 'GET',
        url: `${serverBaseUrl}recruitingRoleTypes`,
        body: null
      })

      if (this.isValidRecruitingTypesResponse(recruitingTypesResult)) {
        this.recruitingRoleTypes = recruitingTypesResult
      }
    } catch (error) {
      this.loading = false
      this.updateView()
    } finally {
      this.loading = false
      this.updateView()
    }
  }

  private isValidRecruitingTypesResponse(result: any): result is RecruitingRoleTypeDto[] {
    return Array.isArray(result)
  }

  public getSelectedRecruitingRoleLabel(): string {
    if (this.job.recruitingRoleTypeId) {
      const selectedRecruitingRole = this.recruitingRoleTypes.find(
        (role) => role.id === this.job.recruitingRoleTypeId
      )
      if (selectedRecruitingRole) {
        return `${selectedRecruitingRole.role} (${selectedRecruitingRole.description})`
      }
    }
    return ''
  }

  public getJob(): NewJobDto {
    return this.job
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public handleTextChange<Key extends keyof NewJobDto>(
    property: Key,
    newVal: NewJobDto[Key]
  ): void {
    if (property === 'recruitingRoleTypeId' && typeof newVal === 'string') {
      const role = this.recruitingRoleTypes.find(
        (role) => newVal.includes(role.description) && newVal.includes(role.role)
      )
      if (role) {
        this.job[property] = role.id as NewJobDto[Key]
      }
    } else if (property in this.job !== undefined) {
      if (property.includes('Compensation')) {
        // @ts-ignore
        this.job[property] = Number.parseInt(newVal, 10)
      } else {
        this.job[property] = newVal
      }
    }
    this.updateView()
  }

  public async createJob(): Promise<void> {
    this.loading = true
    this.updateView()
    const url = `${serverBaseUrl}job`
    const result = await this.fetcher.fetch({
      method: 'PUT',
      url,
      body: this.job
    })

    if (this.isValidResponse(result)) {
      const successfulUpdateMessage = 'Successfully created job!'

      this.wasSuccessfulCreate = true
      this.resultMessage = successfulUpdateMessage
    } else {
      const errorUpdateMessage = `Unable to create job... ${result?.message ?? ''}`

      this.wasSuccessfulCreate = false
      this.resultMessage = errorUpdateMessage
    }
    this.loading = false
    this.updateView()
  }

  private isValidResponse(response: any): boolean {
    if (response === null || response?.message !== undefined) {
      return false
    }

    return true
  }

  public getRecruitingRoleTypes(): string[] {
    return this.recruitingRoleTypes.map((roleType) => `${roleType.role} (${roleType.description})`)
  }
}

export default NewJobListingPresenter
