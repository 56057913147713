import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IInternalRoleTriggerer from './IInternalRoleTriggerer'

class InternalRoleTriggerer implements IInternalRoleTriggerer {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async triggerInternalRoleDeterminer(): Promise<void> {
    await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}user/${this.userId}/determineInternalRole`,
      body: null
    })
  }
}

export default InternalRoleTriggerer
