import './StyleSheets/style.scss'

import React, { Component, ReactElement } from 'react'

import Header from '../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../localCommon/LoadingSpinner/LoadingSpinner'
import MenuVisibilityManager from '../../localCommon/SlidingMenu/MenuVisibilityManager/MenuVisibilityManager'
import AssessmentPromptResponseDto from '../CandidatePool/Presenter/InterfacesAndDtos/AssessmentPromptResponseDto'
import CandidateAssessmentView from './CandidateAssessmentView'
import CandidateHeuristicData from './CandidateHeuristicData'
import CandidateJobPreferencesCard from './CandidateJobPreferencesCard'
import CandidateNotes from './CandidateNotes'
import CandidateQuestionnaireCard from './CandidateQuestionnaireCard'
import CandidateSummaryOverview from './CandidateSummaryOverview'
import ICandidateSummaryPresenter from './InterfacesAndDtos/ICandidateSummaryPresenter'

type Props = {
  menuVisibilityManager: MenuVisibilityManager
  presenter: ICandidateSummaryPresenter
}

export const shareableCandidateSummaryPageId = 'shareable-candidate-summary-page-id'
const nullString = 'null'

class CandidateSummary extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  render(): ReactElement {
    const { presenter, menuVisibilityManager } = this.props
    if (presenter.isLoading()) {
      return <LoadingSpinner />
    }

    const isInShareMode = presenter.isInShareMode()
    const includeNotes = presenter.shouldIncludeNotes()
    const questionnaireOptions = presenter.getQuestionnaireDropDownLabels()
    const questionnaires = presenter.getQuestionnaireDtos()
    return (
      <div className='candidate-summary-page'>
        <div className='menu-wrapper'>
          <Header menuVisibilityManager={menuVisibilityManager} />
        </div>
        {isInShareMode && (
          <div className='shareable-candidate-profile-section'>
            <div className='profile-button-section'>
              <button
                type='button'
                className='back-to-profile-button'
                onClick={() => this.handleShareModeToggle(false)}
              >
                BACK
              </button>
              <button
                type='button'
                className='toggle-notes-in-share-mode-button'
                onClick={this.toggleIncludeNotesInShareMode}
              >
                {includeNotes ? 'EXCLUDE' : 'INCLUDE'} NOTES
              </button>
              <button
                type='button'
                className='download-profile-button'
                onClick={this.handleDownloadProfileClick}
              >
                DOWNLOAD SHAREABLE PDF
              </button>
            </div>
            <br></br>
            <select
              className='questionnaire-dropdown'
              onChange={(e) => this.handleQuestionnaireSelect(e.target.value)}
            >
              <option value={nullString}>Don't Share Questionnaire</option>
              {questionnaireOptions.map((option, index) => (
                <option key={index} value={option.jobId}>
                  {option.label}
                </option>
              ))}
            </select>
            <p className='download-disclaimer'>Everything below will be in the "Shareable PDF".</p>
          </div>
        )}
        <div
          className='shareable-candidate-profile-section'
          id='shareable-candidate-summary-page-id'
        >
          <div className='candidate-header'>{this.getCandidateHeader()}</div>
          <div className='candidate-heuristic-data'>{this.getHeuristicCandidateDataView()}</div>
          {presenter.shouldDisplayAssessmentCard() && (
            <div className='candidate-assessment-card'>
              {this.getAssessmentCard()}
              {!isInShareMode && presenter.isRecruiter() && this.renderAssessmentResponsesSection()}
            </div>
          )}
          <div className='job-preferences-card'>{this.getJobPreferencesCard()}</div>
          {presenter.shouldDisplayQuestionnaireCard() && questionnaires.length > 0 && (
            <div className='questionnaire-card-container'>{this.getQuestionnaireCard()}</div>
          )}
          {presenter.isRecruiter() && includeNotes && this.renderCandidateNotes()}
        </div>
      </div>
    )
  }

  handleQuestionnaireSelect = (value: string): void => {
    this.props.presenter.handleSelectedQuestionnaire(
      value === nullString ? null : Number.parseInt(value, 10)
    )
  }

  renderAssessmentResponsesSection = (): ReactElement => {
    const { presenter } = this.props
    const displayAssessmentResponses = presenter.shouldDisplayAssessmentResponses()

    return displayAssessmentResponses ? (
      this.renderAssessmentResponses()
    ) : (
      <div className='button-container'>
        <button
          onClick={this.handleViewAssessmentResponsesClick}
          className='view-assessment-responses-button'
          type='button'
        >
          VIEW ASSESSMENT
        </button>
      </div>
    )
  }

  toggleIncludeNotesInShareMode = (): void => {
    this.props.presenter.toggleIncludeNotesInShareMode()
  }

  handleDownloadProfileClick = (): void => {
    this.props.presenter.handleShareProfile(shareableCandidateSummaryPageId)
  }

  handleShareModeToggle = (toggle: boolean): void => {
    this.props.presenter.toggleShareMode(toggle)
  }

  renderCandidateNotes(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='candidate-notes-card'>
        <CandidateNotes presenter={presenter.getCandidatesNotesPresenter()} />
      </div>
    )
  }

  handleViewAssessmentResponsesClick = (): void => {
    this.props.presenter.viewAssessmentResponses()
  }

  handleCloseAssessmentResponsesClick = (): void => {
    this.props.presenter.closeAssessmentResponses()
  }

  renderAssessmentResponses(): ReactElement {
    const prompts: AssessmentPromptResponseDto[] = this.props.presenter.getAssessmentResponsesDto()

    if (!prompts?.length) {
      return (
        <div className='assessment-responses-view'>
          <p>No assessment responses available.</p>
        </div>
      )
    }

    return (
      <div className='assessment-responses-view'>
        {prompts.map((prompt) => (
          <div key={prompt.promptId} className='assessment-response-item'>
            <p className='prompt-text'>
              <strong>{prompt.prompt}</strong>
            </p>
            <p className='response-text'>{prompt.response.responseValue}</p>
            <p className='response-text'>Score: {prompt.score ?? 'Not Scored'}</p>
          </div>
        ))}
        <div className='close-button-container'>
          <button
            className='close-assessment-responses-button'
            type='button'
            onClick={this.handleCloseAssessmentResponsesClick}
          >
            CLOSE
          </button>
        </div>
      </div>
    )
  }

  getCandidateHeader(): ReactElement {
    return <CandidateSummaryOverview presenter={this.props.presenter.getOverviewPresenter()} />
  }

  getHeuristicCandidateDataView(): ReactElement {
    return <CandidateHeuristicData presenter={this.props.presenter.getHeuristicDataPresenter()} />
  }

  getAssessmentCard(): ReactElement {
    const { presenter } = this.props
    return (
      <CandidateAssessmentView
        presenter={presenter.getAssessmentPresenter()}
        title={presenter.getAssessmentCardTitle()}
      />
    )
  }

  getJobPreferencesCard(): ReactElement {
    return (
      <CandidateJobPreferencesCard presenter={this.props.presenter.getJobPreferencesPresenter()} />
    )
  }

  getQuestionnaireCard(): ReactElement {
    return (
      <CandidateQuestionnaireCard presenter={this.props.presenter.getQuestionnairePresenter()} />
    )
  }

  handleHeuristicValueUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    heuristicId: number
  ): void => {
    const { value } = e.target
    this.props.presenter.handleTextChange(value, heuristicId)
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateSummary
