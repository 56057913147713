import './style.scss'

import React, { Component, ReactElement } from 'react'

import BlackNavigation from '../../../../localCommon/NavigationChevron/BlackNavigationArrow'
import IAssessmentResultPresenter from '../AssessmentResult/Presenter/IAssessmentResultPresenter'
import FinalStages from './FinalStages'
import StageOne from './StageOne'
import StageThree from './StageThree'
import StageTwo from './StageTwo'

type Props = {
  presenter: IAssessmentResultPresenter
}

class NextSteps extends Component<Props> {
  componentDidMount(): void {
    window.addEventListener('focus', this.tabFocus)

    if (!this.props.presenter.hasFailedAssessment()) {
      this.props.presenter.getAppointments()
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('focus', this.tabFocus)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='nextSteps'>
        <BlackNavigation direction='left' onClick={this.navigateToPreviousPage} />
        <p className='body center-text'>Next steps</p>
        <StageOne hasFailedTheAssessment={presenter.hasFailedAssessment()} />
        <StageTwo presenter={presenter} />
        <StageThree hasFailedTheAssessment={presenter.hasFailedAssessment()} />
        {presenter.hasFailedAssessment() ? null : <FinalStages />}
      </div>
    )
  }

  navigateToPreviousPage = (): void => {
    this.props.presenter.toggleNextStepsPage()
  }

  getStageThreeTitle = (): string => {
    if (this.props.presenter.hasFailedAssessment()) {
      return 'Work on improving your scores'
    }

    return 'Stage 3: Sales skills interview'
  }

  tabFocus = (): void => {
    const { presenter } = this.props

    if (!presenter.hasFailedAssessment()) {
      this.props.presenter.refocusedOnTab()
    }
  }
}

export default NextSteps
