import ValidResponseType, { picklistType } from '../../../Home/Dtos/ValidResponseType'
import IAssessmentResponsePresenterStore from '../../../Home/ResponsePresenterStore/IAssessmentResponsePresenterStore'
import IResponseGetter from './IResponseGetter'

export const DEFAULT_RESPONSE_ID = 1
class ResponseGetter implements IResponseGetter {
  constructor(private readonly presenterStore: IAssessmentResponsePresenterStore) {}

  public getPromptValue(heuristicId: number, responseType: ValidResponseType): string {
    const responsePresenter = this.presenterStore.getPresenterByHeuristicId(heuristicId!)
    if (responseType === picklistType) {
      return responsePresenter?.getPromptValue()?.name ?? ''
    }

    return responsePresenter?.getPromptValue() ?? ''
  }

  public getResponseId(heuristicId: number, responseType: ValidResponseType): number {
    const responsePresenter = this.presenterStore.getPresenterByHeuristicId(heuristicId!)

    if (responseType.includes('free_response')) {
      return DEFAULT_RESPONSE_ID
    }

    if (responseType === 'picklist') {
      return responsePresenter?.getPromptValue().id
    }

    return responsePresenter?.getValue() ? parseInt(responsePresenter.getValue(), 10) : 0
  }
}

export default ResponseGetter
