import DateRangeDto from '../../../../APIs/HeuristicsUpdater/Dtos/DateRangeDto'
import ValidMeasureDateRangeType, {
  currentYear,
  MTDDateRange,
  pastYearDateRange,
  QTDDateRange,
  YTDDateRange
} from '../../../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import DateHelper from '../../../../localCommon/DateHelper/DateHelper'
import IDateRangeTypesGetter from './IDateRangeTypesGetter'

class DateRangeTypesGetter implements IDateRangeTypesGetter {
  public getDateRange(dateRangeType: ValidMeasureDateRangeType): DateRangeDto {
    if (dateRangeType === currentYear) {
      return DateHelper.getYearDateRange(new Date().getFullYear())
    }

    if (dateRangeType === pastYearDateRange) {
      return DateHelper.getYearDateRange(new Date().getFullYear() - 1)
    }

    if (dateRangeType === YTDDateRange) {
      return DateHelper.getYearToDateDateRange()
    }

    if (dateRangeType === QTDDateRange) {
      return DateHelper.getQuarterToDateDateRange()
    }

    if (dateRangeType === MTDDateRange) {
      return DateHelper.getMonthToDateDatRange()
    }

    return {
      endDate: '',
      startDate: ''
    }
  }
}

export default DateRangeTypesGetter
