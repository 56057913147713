import './style.scss'

import Lottie from 'lottie-react'
import React, { Component, ReactElement } from 'react'

import images from '../../../images'

class AnimatedRocket extends Component {
  componentDidMount() {
    this.setAnimationStyling()
  }

  componentDidUpdate() {
    this.setAnimationStyling()
  }

  render(): ReactElement {
    return (
      <div className='js-lottie-container lottie-container resultInProgress-animatedLoadingState'>
        <Lottie animationData={images.animatedRocket} />
      </div>
    )
  }

  setAnimationStyling = () => {
    const bgPathId = '25'
    const svgPaths = document.querySelectorAll('path')
    let gradient

    svgPaths.forEach((path: SVGPathElement, key: number) => {
      // eslint-disable-next-line no-param-reassign
      path.style.stroke = 'var(--gradient-purple)'
      // eslint-disable-next-line no-param-reassign
      path.id = key.toString()
      gradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
      gradient.setAttribute('id', 'gradient')
      gradient.setAttribute('x1', '0%')
      gradient.setAttribute('y1', '0%')
      gradient.setAttribute('x2', '100%')
      gradient.setAttribute('y2', '100%')

      const stop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
      stop1.setAttribute('offset', '0%')
      stop1.setAttribute('stop-color', '#6200EE')

      const stop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
      stop2.setAttribute('offset', '100%')
      stop2.setAttribute('stop-color', '#FF00D6')

      gradient.appendChild(stop1)
      gradient.appendChild(stop2)

      path.setAttribute('fill', 'url(#gradient)')
    })

    if (gradient) {
      const svg = document.querySelector('svg')
      svg!.appendChild(gradient)
    }

    const bgPath = document.getElementById(bgPathId)
    if (bgPath) {
      bgPath.setAttribute('d', '')
    }
  }
}

export default AnimatedRocket
