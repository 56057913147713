import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IMarketCompensationDataGetter from './IMarketCompensationDataGetter'
import MarketCompensationDataDto from './MarketCompensationDataDto'

export const failedToGetMarketDataErrorMessage =
  'Failed to fetch market compensation data. Please try again.'
export const MARKET_COMPENSATION_DATA_URL = `${serverBaseUrl}marketCompensationData`
class MarketCompensationDataGetter implements IMarketCompensationDataGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getMarketCompensationData(
    userId: number
  ): Promise<MarketCompensationDataDto | string> {
    const result = await this.fetcher.fetch({
      url: `${MARKET_COMPENSATION_DATA_URL}/${userId}`,
      method: 'GET',
      body: null
    })

    if (this.isValidMarketCompensationData(result)) {
      return result
    }

    return failedToGetMarketDataErrorMessage
  }

  private isValidMarketCompensationData(data: any): data is MarketCompensationDataDto {
    return (
      data?.hasOwnProperty('difFromMedian') &&
      data?.hasOwnProperty('highOte') &&
      data?.hasOwnProperty('lowOte') &&
      data?.hasOwnProperty('medianOte') &&
      data?.hasOwnProperty('userOtePercentile') &&
      data?.hasOwnProperty('usersOte')
    )
  }
}

export default MarketCompensationDataGetter
