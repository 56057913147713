export const mindsetAggregateHeuristicId = 25
export const socialAggregateHeuristicId = 26
export const motorAggregateHeuristicId = 22
export const cognitiveAggregateHeuristicId = 21
export const trajectoryAggregateHeuristicId = 23
export const assessmentMetricIds = [
  mindsetAggregateHeuristicId,
  socialAggregateHeuristicId,
  motorAggregateHeuristicId,
  cognitiveAggregateHeuristicId,
  trajectoryAggregateHeuristicId
]
