import './list.scss'

/* eslint-disable no-new */
import { MDCTextField } from '@material/textfield/index'
import React, { Component, FocusEvent, Fragment, ReactElement } from 'react'

import { missingRequiredFieldsErrorMessage } from '../../Routes/PersonalInfoData/Presenters/HomePresenter'
import ICommonObject from '../Interfaces/ICommonObject'

type Props = {
  elementId: string
  error?: string
  id: string
  isRequired: boolean
  isTypingAllowed: boolean
  label: string
  list: ICommonObject[]
  searchInput: string
  selectedValue: string
  shouldShowErrorMessage: boolean
  type: 'number' | 'text'
  updateSearchInput: (input: string, elementId: string) => void
  updateSelectedOption: (input: string, elementId: string) => void
}

class List extends Component<Props> {
  componentDidMount(): void {
    const element = document.querySelector(`#${this.props.elementId}`)

    if (element) {
      new MDCTextField(element)
    }
    document.addEventListener('mousedown', this.handleClickingOutsideOfDropdownList)
    this.handleInputValueLabelPosition()
  }

  componentDidUpdate(): void {
    const element = document.querySelector(`#${this.props.elementId}`)

    if (element) {
      new MDCTextField(element)
    }
    document.addEventListener('mousedown', this.handleClickingOutsideOfDropdownList)
    this.handleInputValueLabelPosition()
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickingOutsideOfDropdownList)
  }

  render(): ReactElement {
    const { elementId, error, label, selectedValue, shouldShowErrorMessage } = this.props

    return (
      <div className='list'>
        <div className='list-label'>
          <label
            className='mdc-text-field mdc-text-field--outlined'
            id={elementId}
            onClick={this.toggleShowOptions}
          >
            <span className='mdc-notched-outline'>
              <span className='mdc-notched-outline__leading'></span>
              <span
                className='mdc-notched-outline__notch'
                style={{ display: label ? 'inline-block' : 'none' }}
              >
                <span className='mdc-floating-label' id={`outlinedInputLabel-${elementId}`}>
                  {label}
                </span>
              </span>
              <span className='mdc-notched-outline__trailing'></span>
            </span>
            {this.renderInputField()}
          </label>
          <span className='dropdown-toggle'></span>
        </div>
        <div className='mdc-text-field-helper-line'>
          <div className='mdc-text-field-helper-text' id='outlinedInput' aria-hidden='true'>
            {shouldShowErrorMessage && !selectedValue && (
              <span className='error'>{error || missingRequiredFieldsErrorMessage}</span>
            )}
          </div>
        </div>
        {this.renderList()}
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  renderList = (): ReactElement => {
    const { id, isTypingAllowed, list } = this.props

    if (isTypingAllowed) {
      return this.getFilteredList().length ? (
        <ul className='mdc-list' id={`list-${id}`}>
          {this.getFilteredList().map((item) => {
            return (
              <Fragment key={item.id}>
                <li
                  className='mdc-list-item'
                  id={item.name}
                  onClick={this.selectOption}
                  tabIndex={item.id}
                >
                  <span className='mdc-list-item__ripple'></span>
                  <span className='mdc-list-item__text' id={item.name}>
                    {item.name}
                  </span>
                </li>
              </Fragment>
            )
          })}
        </ul>
      ) : (
        <span></span>
      )
    }

    return (
      <ul className='mdc-list' id={`list-${id}`}>
        {list.map((item) => {
          return (
            <Fragment key={item.id}>
              <li
                className='mdc-list-item'
                id={item.name}
                onClick={this.selectOption}
                tabIndex={item.id}
              >
                <span className='mdc-list-item__ripple'></span>
                <span className='mdc-list-item__text' id={item.name}>
                  {item.name}
                </span>
              </li>
            </Fragment>
          )
        })}
      </ul>
    )
  }

  getFilteredList = (): ICommonObject[] => {
    const { searchInput, list } = this.props

    return list.filter((option) => option?.name?.toLowerCase().includes(searchInput?.toLowerCase()))
  }

  toggleShowOptions = (): void => {
    const { id } = this.props
    const options = document.getElementById(`list-${id}`) as HTMLElement

    if (options) {
      options.style.display = 'block'
    }
  }

  selectOption = (event: React.MouseEvent): void => {
    const target = event.target as HTMLInputElement

    this.props.updateSelectedOption(target.id, this.props?.id)
    this.closeOption()
  }

  closeOption = (): void => {
    const { id } = this.props
    const options = document.getElementById(`list-${id}`) as HTMLElement

    if (options) {
      options.style.display = 'none'
    }
  }

  handleClickingOutsideOfDropdownList = (event: MouseEvent): void => {
    const target = event.target as HTMLElement

    if (!target.className.includes('mdc-list')) {
      const lists = document.querySelectorAll('.mdc-list') as any

      if (lists) {
        const listElements = Array.from(lists) as HTMLElement[]
        // eslint-disable-next-line no-return-assign, no-param-reassign
        listElements.forEach((element: HTMLElement) => (element.style.display = 'none'))
      }
    }
  }

  handleInputValueLabelPosition = (): void => {
    const { elementId, selectedValue } = this.props
    const input = document.querySelector(`#${elementId}`)

    if (input) {
      if (selectedValue) {
        const focusEvent = new Event('focus')
        input.dispatchEvent(focusEvent)
      } else {
        const blurEvent = new Event('blur')
        input.dispatchEvent(blurEvent)
      }
    }
  }

  renderInputField = (): ReactElement => {
    const { elementId, isTypingAllowed, searchInput, selectedValue, type } = this.props

    return (
      <span className='list-inputWrapper'>
        <input
          aria-labelledby='outlinedInputLabel'
          aria-controls='outlinedInput'
          aria-describedby='outlinedInput'
          className='mdc-text-field__input'
          id={elementId}
          onClick={this.toggleShowOptions}
          onChange={isTypingAllowed ? this.updateSearchInput : () => null}
          onFocus={this.hidePlaceholder}
          value={selectedValue || searchInput}
          type={type}
        />
      </span>
    )
  }

  updateSearchInput = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.updateSearchInput(target.value, this.props?.id)
    this.toggleShowOptions()
  }

  showDropdown = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLElement
    const list = target.closest('.mdc-list') as HTMLElement

    if (list) {
      list.style.display = 'block'
    }
  }

  hidePlaceholder = (event: FocusEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLElement
    const placeholder = target.previousElementSibling as HTMLElement

    if (placeholder) {
      placeholder.style.display = 'none'
    }
  }
}

export default List
