import MetricPerformanceGetter from '../../../APIs/MetricPerformanceGetter/MetricPerformanceGetter'
import Fetcher from '../../../Drivers/Fetcher'
import HomeMetricPerformanceHandler from '../HomeMetricPerformanceHandler'
import IHomeMetricPerformanceHandler from '../IHomeMetricPerformanceHandler'
import IHomeMetricPerformanceHandlerFactory from './IHomeMetricPerformanceHandlerFactory'

class HomeMetricPerformanceHandlerFactory implements IHomeMetricPerformanceHandlerFactory {
  public getPerformanceHandler(userId: number): IHomeMetricPerformanceHandler {
    return new HomeMetricPerformanceHandler(new MetricPerformanceGetter(new Fetcher(), userId))
  }
}

export default HomeMetricPerformanceHandlerFactory
