import AggregateHeuristicScoreDto from '../../../../../APIs/CandidateProfileFetcher/AggregateHeuristicScoreDto'
import BaseAssessmentDto from '../../../../../APIs/Dtos/BaseAssessmentDto'
import Fetcher from '../../../../../Drivers/Fetcher'
import IFetcher from '../../../../../Drivers/Interfaces/IFetcher'
import IAssessmentResultHandler from '../../../../../Handlers/AssessmentResultHandler/IAssessmentResultHandler'
import { serverBaseUrl } from '../../../../../localCommon/constant'
import ISubscribingView from '../../../../../localCommon/Interfaces/ISubscribingView'
import CoreAssessmentDto from '../../../../PersonalInfoData/CoreAssessments/AssessmentBars/CoreAssessmentDto'
import IHomeDataRowsPresenter from '../../../../PersonalInfoData/OTEStanding/IHomeDataRowsPresenter'
import { dashboardRoute } from '../../../../RouteConstants'
import AssessmentRowPresenter from '../../../Candidate/AssessmentRowPresenter'
import HeuristicSectionPresenter from '../../../Candidate/HeuristicSectionPresenter'
import IAssessmentRowPresenter from '../../../Candidate/InterfacesAndDtos/IAssessmentRowPresenter'
import IHeuristicSectionPresenter from '../../../Candidate/InterfacesAndDtos/IHeuristicSectionPresenter'
import AssessmentResultDto from '../../../Presenter/InterfacesAndDtos/AssessmentResultDto'
import CandidatePoolDto from '../../../Presenter/InterfacesAndDtos/CandidatePoolDto'
import ICandidateDataHandler from '../../CandidateDataHandler/ICandidateDataHandler'
import IOTEStandingPresenterFactory from '../../OTEStandingPresenterProvider/IOTEStandingPresenterFactory'
import ICandidatePresenter from './ICandidatePresenter'

class CandidatePresenter implements ICandidatePresenter {
  private loading: boolean
  private userId: number
  private view: ISubscribingView | null
  private shouldRevealInfo: boolean
  private scores: AggregateHeuristicScoreDto[]
  private candidate: CandidatePoolDto | null
  private resumeFileName: string | null
  private assessmentIdToAssessmentDto: Map<number, BaseAssessmentDto>
  private aggregateHeuristicDtos: CoreAssessmentDto[]
  public showDeleteModal: boolean

  constructor(
    private readonly candidateDataHandler: ICandidateDataHandler,
    private readonly oteStandingPresenterFactory: IOTEStandingPresenterFactory,
    private readonly fetcher: IFetcher,
    private readonly assessmentResultHandler: IAssessmentResultHandler
  ) {
    this.scores = []
    this.loading = true
    this.shouldRevealInfo = false
    this.userId = -1
    this.view = null
    this.candidate = null
    this.resumeFileName = null
    this.assessmentIdToAssessmentDto = new Map()
    this.aggregateHeuristicDtos = []
    this.showDeleteModal = false
  }

  public async sendAssessmentEmail(): Promise<void> {
    await this.fetcher.fetch({
      method: 'POST',
      url: `${serverBaseUrl}user/${this.userId}/assessment/resultEmail`,
      body: null
    })
  }

  public async deleteUser(): Promise<void> {
    await this.fetcher.fetch({
      method: 'DELETE',
      url: `${serverBaseUrl}user/${this.userId}`,
      body: null
    })

    window.location.pathname = `${dashboardRoute}`
  }

  public toggleDeleteModal(): void {
    this.showDeleteModal = !this.showDeleteModal
    this.updateView()
  }

  public getResumeFileName(): string {
    return this.resumeFileName ?? 'No Resumé Uploaded 😔'
  }

  public handleResumeClick(): void {
    if (this.resumeFileName) {
      this.fetcher.fetch({
        method: 'GET',
        url: `${serverBaseUrl}user/${this.userId}/resume`,
        body: null,
        headers: {
          'Content-Type': `application/${this.getExt()}`
        }
      })
    }
  }

  private getExt(): string {
    const splits = this.resumeFileName?.split('.')
    return splits?.[splits.length - 1] ?? ''
  }

  public setCandidate(candidate: CandidatePoolDto): void {
    this.candidate = candidate
  }

  public getCandidate(): CandidatePoolDto | null {
    return this.candidate
  }

  private async setAssessmentPresenter(): Promise<void> {
    const result = await this.assessmentResultHandler.getAssessmentMetrics(this.userId)
    this.aggregateHeuristicDtos = Array.isArray(result) ? result : []
  }

  public getCandidatePerformance(): CoreAssessmentDto[] {
    return this.aggregateHeuristicDtos
  }

  public getAssessmentTitle(assessmentId: number): string {
    return this.assessmentIdToAssessmentDto.get(assessmentId)?.title ?? String(assessmentId)
  }

  public setIsCandidateInfoVisible(isInfoVisible: boolean): void {
    this.shouldRevealInfo = isInfoVisible
    this.updateView()
  }

  public shouldRevealCandidateInfo(): boolean {
    return this.shouldRevealInfo
  }

  public isLoading(): boolean {
    return this.loading
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getUserId(): number {
    return this.userId
  }

  public setUserId(userId: number): void {
    this.userId = userId
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public async initialize(userId: number): Promise<void> {
    const profile = await this.candidateDataHandler.fetchCandidateData(userId)
    await this.getAssessments()
    await this.getResume()
    await this.setAssessmentPresenter()
    this.scores = profile?.profile?.aggregateHeuristicScores ?? []
    this.oteStandingPresenterFactory.setPresenterData(profile ?? null)
    this.loading = false
    this.updateView()
  }

  private async getResume(): Promise<void> {
    const response = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/resume/name`,
      body: null
    })

    if (response?.fileName) {
      this.resumeFileName = response.fileName
    }
  }

  private async getAssessments(): Promise<void> {
    const assessments = this.candidate?.assessments
    if (assessments) {
      await Promise.all(
        assessments.map(async (assessment) => {
          const result = await this.fetcher.fetch({
            method: 'GET',
            url: `${serverBaseUrl}assessment/${assessment.assessmentId}`,
            body: null
          })

          if (result) {
            this.assessmentIdToAssessmentDto.set(assessment.assessmentId, result)
          }
        })
      )
    }
  }

  public getAssessmentRowPresenter(assessment: AssessmentResultDto): IAssessmentRowPresenter {
    return new AssessmentRowPresenter(assessment, this.fetcher)
  }

  public getOTEStandingPresenter(): IHomeDataRowsPresenter {
    return this.oteStandingPresenterFactory.getOTEStandingPresenter()
  }

  public getHeuristicSectionPresenter(): IHeuristicSectionPresenter {
    return new HeuristicSectionPresenter(new Fetcher(), this.candidate?.userId ?? -1)
  }
}

export default CandidatePresenter
