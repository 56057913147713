import PromptDto from '../../APIs/PromptGetter/PromptDto'
import { oteHeuristicId, pastOteHeuristicId, w2IncomeHeuristicId } from '../../localCommon/constant'
import { dollarUnit } from '../../localCommon/ValidHeuristicUnit'
import PromptDtoWithUnit from '../../Routes/Assessment/Presenter/PromptDtoWithUnit'
import BasicInfoQuestionDto from '../../Routes/Home/Dtos/BasicInfoQuestionDto'
import FreeResponseQuestionDto from '../../Routes/Home/Dtos/FreeResponseQuestionDto'
import MultipleChoiceQuestionDto from '../../Routes/Home/Dtos/MultipleChoiceQuestionDto'
import PickListDto from '../../Routes/Home/Dtos/PickListDto'
import PicklistQuestionDto from '../../Routes/Home/Dtos/PicklistQuestionDto'
import FuzzySearchQuestionDto from '../../Routes/Home/FuzzySearch/FuzzySearchQuestionDto'
import IPromptAdaptor from './IPromptAdaptor'

export const currentRolePrompt = 'What is your current role?'
class PromptAdaptor implements IPromptAdaptor {
  public getBasicInfoQuestionDto(prompt: PromptDto): BasicInfoQuestionDto {
    return {
      description: '',
      heuristicId: prompt.heuristicId,
      id: prompt.heuristicId,
      responseType: prompt.responseType,
      title: prompt.prompt,
      isRequired: true,
      unit: ''
    }
  }

  public getFuzzyQuestionFromPrompt(prompt: PromptDto): FuzzySearchQuestionDto {
    return {
      description: '',
      heuristicId: prompt.heuristicId,
      id: prompt.heuristicId,
      responseType: prompt.responseType,
      title: prompt.prompt,
      isRequired: true,
      unit: ''
    }
  }

  public getFreeResponseQuestionDtoFromPrompt(prompt: PromptDtoWithUnit): FreeResponseQuestionDto {
    return {
      description: '',
      heuristicId: prompt.heuristicId,
      id: prompt.heuristicId,
      responseType: prompt.responseType,
      title: prompt.prompt,
      placeholder: prompt.inputLabel ?? '',
      uniqueId: null as any,
      caption: this.getCaption(prompt.heuristicId),
      inputType: this.getInputType(prompt),
      isRequired: true,
      unit: prompt.unit
    }
  }

  private getCaption(heuristicId: number): string {
    if (heuristicId === pastOteHeuristicId || heuristicId === oteHeuristicId) {
      return 'Annual base salary + annual commission earned at 100% of quota = On-Target Earnings (OTE)'
    }

    if (heuristicId === w2IncomeHeuristicId) {
      return 'Only use the income from your primary sales job'
    }

    return ''
  }

  public getMultipleChoiceQuestionDtoFromPrompt(prompt: PromptDto): MultipleChoiceQuestionDto {
    return {
      description: '',
      heuristicId: prompt.heuristicId,
      id: prompt.heuristicId,
      responseType: prompt.responseType,
      title: prompt.prompt,
      isRequired: true,
      options: prompt.responseOptions.map((option) => {
        return {
          id: option.id,
          orderIndex: option.orderIndex,
          name: option.value
        }
      }),
      uniqueId: null,
      unit: ''
    }
  }

  public getPicklistQuestionDtoFromPrompt(prompt: PromptDto): PicklistQuestionDto {
    return {
      description: '',
      heuristicId: prompt.heuristicId,
      id: prompt.heuristicId,
      isTypingAllowed: prompt.prompt !== currentRolePrompt,
      lists: this.getOptions(prompt),
      responseType: prompt.responseType,
      title: prompt.prompt,
      isRequired: true,
      unit: ''
    }
  }

  private getOptions(prompt: PromptDto): PickListDto[] {
    const dto = {
      label: prompt.prompt,
      listId: prompt.id?.toString(),
      list: prompt.responseOptions.map((option) => {
        return {
          id: option.id,
          orderIndex: option.orderIndex,
          name: option.value
        }
      })
    }

    return [dto]
  }

  private getInputType(prompt: PromptDtoWithUnit): 'number' | 'text' {
    if (prompt.unit === dollarUnit || !prompt.responseType.includes('numeric')) {
      return 'text'
    }

    return 'number'
  }
}

export default PromptAdaptor
