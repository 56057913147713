import IMetricPerformanceGetter from '../../APIs/MetricPerformanceGetter/IMetricPerformanceGetter'
import {
  annualQuotaMetricId,
  oteMetricId,
  yearsOfExperienceMetricId
} from '../../localCommon/MetricsId'
import HomeMetricPerformanceDto from './HomeMetricPerformanceDto'
import IHomeMetricPerformanceHandler from './IHomeMetricPerformanceHandler'

class HomeMetricPerformanceHandler implements IHomeMetricPerformanceHandler {
  constructor(private readonly metricPerformanceGetter: IMetricPerformanceGetter) {}

  public async getHomeMetricData(): Promise<HomeMetricPerformanceDto> {
    const currentAnnualQuota = await this.fetchAnnualQuotaPerformance()
    const currentOte = await this.fetchOtePerformance()
    const yearsOfExperience = await this.fetchYearsOfExperienceData()

    return {
      currentAnnualQuota,
      currentOte,
      yearsOfExperience
    }
  }

  private async fetchAnnualQuotaPerformance(): Promise<number> {
    const result = await this.metricPerformanceGetter.getMetricPerformance(annualQuotaMetricId)
    return parseFloat(result?.value ?? 0)
  }

  private async fetchOtePerformance(): Promise<number> {
    const result = await this.metricPerformanceGetter.getMetricPerformance(oteMetricId)
    return parseFloat(result?.value ?? 0)
  }

  private async fetchYearsOfExperienceData(): Promise<string> {
    const result =
      await this.metricPerformanceGetter.getMetricPerformance(yearsOfExperienceMetricId)
    return result?.value ?? ''
  }
}

export default HomeMetricPerformanceHandler
