import './StyleSheets/style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedButton from '../../localCommon/Button/OutlinedButton'
import { linkedInUrlHeuristicId } from '../../localCommon/constant'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import ResumeUpload from '../EditAccount/Resume/ResumeUpload'
import ICandidateSummaryOverviewPresenter from './InterfacesAndDtos/ICandidateSummaryOverviewPresenter'

type Props = {
  presenter: ICandidateSummaryOverviewPresenter
}

class CandidateSummaryOverview extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const image = presenter.getCandidateImage()
    console.log(image, 'image')
    const name = presenter.getCandidateName()
    const roleInterestedIn = presenter.getCandidateRoleInterestedIn()
    const location = presenter.getCandidateLocation()
    const email = presenter.getCandidateEmail()
    const scoreLabel = presenter.getScoreLabel()
    const scorePercentileLabel = presenter.getScorePercentileLabel()
    const isRecruiter = presenter.isRecruiter()
    const isInShareMode = presenter.isInShareMode()

    return (
      <div className='userProfile'>
        <div className='userProfile-content'>
          <div className='userProfile-progress'>
            <div className='userProfile-whiteBorder' />
            <img alt='user-profile' className='userProfile-image' src={image} />
          </div>
          <div>
            <div className='userProfile-nameWrapper'>
              <p className='headline5 userProfile-name'>{name ?? 'No name entered'}</p>
            </div>
            <br />
            <p className='caption userProfile-strength'>
              Ideal Role: {roleInterestedIn ?? 'Unknown'}
            </p>
            <p className='caption userProfile-strength'>Location: {location ?? 'Unknown'}</p>
            {!isInShareMode && (
              <p className='caption userProfile-strength'>
                Email: <span className='userProfile-email'>{email ?? 'Unknown'}</span>
              </p>
            )}
            {isInShareMode && (
              <p className='caption userProfile-strength' style={{ fontWeight: 'bold' }}>
                Assessment Score: <span style={{ color: 'var(--theme-color)' }}>{scoreLabel}</span>{' '}
                {scorePercentileLabel}
              </p>
            )}
            {!isInShareMode && this.renderLinkedInUrl()}
          </div>
        </div>
        <div className='user-resume-section'>
          <div className='resume-button'>{!isInShareMode && this.renderResumeButton()}</div>
        </div>
        {isRecruiter && !isInShareMode && (
          <div className='share-profile-button-2'>
            <button className='share-profile-button' onClick={this.enterShareProfileMode}>
              SHARE PROFILE
            </button>
          </div>
        )}
      </div>
    )
  }

  renderLinkedInUrl(): ReactElement {
    const { presenter } = this.props
    const linkedInUrl = presenter.getLinkedInUrl()
    return (
      <p className='caption userProfile-strength linkedIn'>
        LinkedIn:
        {linkedInUrl ? (
          <a
            className='userProfile-email'
            href={linkedInUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {` ${linkedInUrl}`}
          </a>
        ) : (
          <React.Fragment>
            <input
              id='linkedin-url-input'
              type='url'
              className='userProfile-linkedin-input'
              value={presenter.getNewValueForHeuristicId(linkedInUrlHeuristicId) ?? ''}
              onChange={(e) => this.handleHeuristicValueUpdate(e, linkedInUrlHeuristicId)}
              placeholder='Enter LinkedIn URL'
            />
            <button
              type='button'
              onClick={() => this.handleHeuristicSaveValue(linkedInUrlHeuristicId)}
              className='save-button'
            >
              Save
            </button>
          </React.Fragment>
        )}
      </p>
    )
  }

  enterShareProfileMode = (): void => {
    this.props.presenter.toggleShareMode(true)
  }

  renderResumeButton(): ReactElement {
    const { presenter } = this.props
    const resumeName = presenter.getResumeName()
    const isRecruiter = presenter.isRecruiter()
    if (resumeName) {
      return <OutlinedButton onClick={this.handleResumeClick}>{resumeName}</OutlinedButton>
    }

    if (isRecruiter) {
      return <p>No resumé uploaded 😔</p>
    }

    return <ResumeUpload presenter={presenter.getResumePresenter()} />
  }

  handleResumeClick = (): void => {
    this.props.presenter.handleResumeClick()
  }

  handleHeuristicValueUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    heuristicId: number
  ): void => {
    const { value } = e.target
    this.props.presenter.handleTextChange(value, heuristicId)
  }

  handleHeuristicSaveValue = (heuristicId: number) => {
    this.props.presenter.saveHeuristicValue(heuristicId)
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateSummaryOverview
