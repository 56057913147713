import './style.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  percentile: number
}

const totalNumberOfBarsInBellCurve = 33
class MetricBellCurve extends Component<Props> {
  render(): ReactElement {
    const { percentile } = this.props
    const dataPoints = this.getBellCurveData(totalNumberOfBarsInBellCurve)

    return (
      <div className='metricBellCurve'>
        {dataPoints.map((point, index) => {
          const currentBarPercentage = (index / totalNumberOfBarsInBellCurve) * 100
          const barColor =
            percentile >= currentBarPercentage ? 'rgba(98, 0, 238, 1)' : 'rgba(217, 217, 217, 1)'

          return (
            <div
              key={index}
              className='metricBellCurve-bar'
              style={{ backgroundColor: `${barColor}`, height: `${point}%` }}
            />
          )
        })}
      </div>
    )
  }

  getBellCurveData = (numBars: number): number[] => {
    const dataPoints = []
    for (let i = 0; i < numBars; i++) {
      const value = Math.exp(-((i - numBars / 2) ** 2) / (2 * (numBars / 6) ** 2))
      const percentage = value * 100
      dataPoints.push(percentage)
    }

    return dataPoints
  }
}

export default MetricBellCurve
