import IPromptAdaptor from '../../../../Adaptors/PromptAdaptor/IPromptAdaptor'
import PromptDto from '../../../../APIs/PromptGetter/PromptDto'
import Fetcher from '../../../../Drivers/Fetcher'
import PromptDtoWithUnit from '../../../Assessment/Presenter/PromptDtoWithUnit'
import INavigationPresenter from '../../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import FreeResponsePresenter from '../../FreeResponse/Presenters/FreeResponsePresenter'
import IFreeResponsePresenter from '../../FreeResponse/Presenters/Interfaces/IFreeResponsePresenter'
import FuzzySearchPresenter from '../../FuzzySearch/FuzzySearchPresenter'
import IFuzzySearchPresenter from '../../FuzzySearch/IFuzzySearchPresenter'
import INumEntriesPresenter from '../../NumEntries/INumEntriesPresenter'
import NumEntriesPresenter from '../../NumEntries/NumEntriesPresenter'
import IPicklistResponsePresenter from '../../Picklist/Presenters/Interfaces/IPicklistResponsePresenter'
import PicklistResponsePresenter from '../../Picklist/Presenters/PicklistResponsePresenter'
import IResponsePresenterStore from '../../ResponsePresenterStore/IResponsePresenterStore'
import IMultipleChoicePresenter from '../../VerticalMultipleChoice/Presenters/Interfaces/IMultipleChoicePresenter'
import MultipleChoicePresenter from '../../VerticalMultipleChoice/Presenters/MultipleChoicePresenter'
import IPromptResponsePresenterFactory from './IPromptResponsePresenterFactory'

class PromptResponsePresenterFactory implements IPromptResponsePresenterFactory {
  constructor(
    private readonly promptAdaptor: IPromptAdaptor,
    private readonly navigationPresenter: INavigationPresenter & INavigationTerminator,
    private readonly presenterStore: IResponsePresenterStore
  ) {}

  public getNumEntriesPresenter(prompt: PromptDto): INumEntriesPresenter {
    const dto = this.promptAdaptor.getBasicInfoQuestionDto(prompt)
    if (this.hasPresenterWithSamePrompt(prompt)) {
      return this.presenterStore.getPresenterByHeuristicId(dto.heuristicId) as NumEntriesPresenter
    }
    const presenter = new NumEntriesPresenter(
      dto,
      this.navigationPresenter.getCurrentQuestionIndex()
    )

    this.presenterStore.setPresenterWithHeuristicId(prompt.heuristicId, presenter)
    return presenter
  }

  public getFuzzySearchPresenter(prompt: PromptDtoWithUnit): IFuzzySearchPresenter {
    const dto = this.promptAdaptor.getFuzzyQuestionFromPrompt(prompt)

    if (this.hasPresenterWithSamePrompt(prompt)) {
      return this.presenterStore.getPresenterByHeuristicId(dto.heuristicId) as FuzzySearchPresenter
    }

    const presenter = new FuzzySearchPresenter(
      dto,
      this.navigationPresenter.getCurrentQuestionIndex(),
      new Fetcher()
    )

    this.presenterStore.setPresenterWithHeuristicId(prompt.heuristicId, presenter)
    return presenter
  }

  public getFreeResponsePresenter(prompt: PromptDtoWithUnit): IFreeResponsePresenter {
    const dto = this.promptAdaptor.getFreeResponseQuestionDtoFromPrompt(prompt)

    if (this.hasPresenterWithSamePrompt(prompt)) {
      return this.presenterStore.getPresenterByHeuristicId(dto.heuristicId) as FreeResponsePresenter
    }

    const presenter = new FreeResponsePresenter(
      dto,
      this.navigationPresenter.getCurrentQuestionIndex()
    )

    this.presenterStore.setPresenterWithHeuristicId(prompt.heuristicId, presenter)
    return presenter
  }

  private hasPresenterWithSamePrompt(prompt: PromptDtoWithUnit | PromptDto): boolean {
    return (
      this.presenterStore.hasPresenterWithHeuristicId(prompt.heuristicId) &&
      this.presenterStore.getPresenterByHeuristicId(prompt.heuristicId)?.getTitle() ===
        prompt.prompt
    )
  }

  public getMultipleChoicePresenter(prompt: PromptDtoWithUnit): IMultipleChoicePresenter {
    const dto = this.promptAdaptor.getMultipleChoiceQuestionDtoFromPrompt(prompt)

    if (this.hasPresenterWithSamePrompt(prompt)) {
      return this.presenterStore.getPresenterByHeuristicId(
        dto.heuristicId
      ) as MultipleChoicePresenter
    }

    const presenter = new MultipleChoicePresenter(
      dto,
      this.navigationPresenter.getCurrentQuestionIndex(),
      true
    )
    this.presenterStore.setPresenterWithHeuristicId(prompt.heuristicId, presenter)
    return presenter
  }

  public getNavigationPresenter(): INavigationPresenter & INavigationTerminator {
    return this.navigationPresenter
  }

  public getPicklistResponsePresenters(
    prompt: PromptDtoWithUnit
  ): Map<string, IPicklistResponsePresenter> {
    const dto = this.promptAdaptor.getPicklistQuestionDtoFromPrompt(prompt)
    let presenter: IPicklistResponsePresenter

    if (this.hasPresenterWithSamePrompt(prompt)) {
      presenter = this.presenterStore.getPresenterByHeuristicId(
        dto.heuristicId
      ) as IPicklistResponsePresenter
    } else {
      presenter = new PicklistResponsePresenter(
        dto,
        prompt.heuristicId as any,
        this.navigationPresenter.getCurrentQuestionIndex(),
        {
          label: prompt.prompt,
          list: prompt.responseOptions.map((option) => {
            return {
              id: option.id,
              orderIndex: option.orderIndex,
              name: option.value
            }
          }),
          listId: prompt.heuristicId as any
        }
      )
      this.presenterStore.setPresenterWithHeuristicId(prompt.heuristicId, presenter)
    }

    return new Map().set(1, presenter)
  }
}

export default PromptResponsePresenterFactory
