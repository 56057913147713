import HttpCodeAndMessageDto from '../../../APIs/Dtos/HttpCodeAndMessageDto'
import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { okHttpCode, serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import {
  invalidPasswordErrorMessage,
  passwordDoNotMatchMessage
} from '../../SignUp/Presenter/SignUpPresenter'
import IPasswordResetPresenter from './IPasswordResetPresenter'

const resetPasswordPath = '/password/reset'
export const resetPasswordUrl = `${serverBaseUrl}${resetPasswordPath}`
export const successfulResetMessage = 'Password successfully reset!'
export const failedToResetMessage = 'Could not reset password, please try again.'

class PasswordResetPresenter implements IPasswordResetPresenter {
  private view: ISubscribingView | null
  private password: string
  private confirmationPassword: string
  private errorMessage: string
  private successMessage: string

  constructor(private readonly fetcher: IFetcher) {
    this.password = ''
    this.confirmationPassword = ''
    this.errorMessage = ''
    this.successMessage = ''
    this.view = null
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public setConfirmationPassword(password: string): void {
    this.confirmationPassword = password
    this.errorMessage = this.validatePassword()

    this.updateView()
  }

  public setPassword(password: string): void {
    this.password = password
    this.errorMessage = this.validatePassword()

    this.updateView()
  }

  public getConfirmationPassword(): string {
    return this.confirmationPassword
  }

  public getPassword(): string {
    return this.password
  }

  public isSubmitButtonDisabled(): boolean {
    if (this.errorMessage !== '') {
      return true
    }

    if (this.password === '' && this.confirmationPassword === '') {
      return true
    }

    return false
  }

  public getSuccessMessage(): string {
    return this.successMessage
  }

  public async sendPassword(): Promise<void> {
    if (this.validatePassword() === '') {
      const params = {
        auth: this.getAuthToken(),
        body: {
          password: this.password
        },
        method: 'PUT',
        url: resetPasswordUrl
      }
      const result = (await this.fetcher.fetch(params)) as HttpCodeAndMessageDto

      if (result?.code === okHttpCode) {
        this.successMessage = successfulResetMessage
        this.errorMessage = ''
      } else {
        this.errorMessage = failedToResetMessage
      }

      this.updateView()
    }
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  private validatePassword(): string {
    if (this.password !== this.confirmationPassword) {
      return passwordDoNotMatchMessage
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,40}$/
    if (!regex.test(this.password)) {
      return invalidPasswordErrorMessage
    }

    return ''
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  private getAuthToken(): string {
    const urlParams = window.location.search
    const searchParams = new URLSearchParams(urlParams)

    return searchParams.get('token') as string
  }
}

export default PasswordResetPresenter
