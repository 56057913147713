import ISubscriber from '../../../localCommon/Interfaces/ISubscriber'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import { missingRequiredFieldsErrorMessage } from '../../PersonalInfoData/Presenters/HomePresenter'
import BasicInfoQuestionDto from '../Dtos/BasicInfoQuestionDto'
import IResponsePresenter from './Interfaces/IResponsePresenter'

abstract class AbstractResponsePresenter implements IResponsePresenter, ISubscriber {
  protected input: string
  protected shouldShowInlineError: boolean
  protected view: ISubscribingView | null

  constructor(
    protected readonly question: BasicInfoQuestionDto,
    protected readonly questionIndex: number,
    protected readonly isRequired: boolean
  ) {
    this.input = ''
    this.shouldShowInlineError = false
    this.view = null
  }

  public getUnit(): '%' | '' | '$' {
    return this.question.unit
  }

  public abstract getPromptValue(): any

  public getHeuristicsId(): number {
    return this.question.heuristicId
  }

  public getDescription(): string {
    return this.question.description
  }

  public getTitle(): string {
    return this.question.title
  }

  public showErrorMessage(): void {
    this.shouldShowInlineError = true
    this.updateView()
  }

  public abstract getResponseName(): string

  public getInlineError(): string {
    if (this.shouldShowInlineError && !this.input) {
      return missingRequiredFieldsErrorMessage
    }

    return ''
  }

  public abstract getValue(): string

  public notifiedToUpdate(): void {
    this.shouldShowInlineError = true
    this.updateView()
  }

  public isRequiredQuestion(): boolean {
    return this.isRequired
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  public shouldShowError(): boolean {
    return this.shouldShowInlineError
  }

  public updateInput(input: string): void {
    this.input = input
    this.updateView()
  }

  public getCurrentQuestionIndex(): number {
    return this.questionIndex
  }

  protected updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default AbstractResponsePresenter
