import React, { Component, Fragment, ReactElement } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import images from '../../../images'
import OutlinedButton from '../../../localCommon/Button/OutlinedButton'
import { serverBaseUrl } from '../../../localCommon/constant'
import Header from '../../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IMenuVisibilityManager from '../../../localCommon/SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import SlidingMenu from '../../../localCommon/SlidingMenu/SlidingMenu'
import CandidateUserProfile from '../../Home/UserProfile/CandidateUserProfile'
import AssessmentBars from '../../PersonalInfoData/CoreAssessments/AssessmentBars/AssessmentBars'
import OTEStanding from '../../PersonalInfoData/OTEStanding/OTEStanding'
import { candidateSummaryRoute, summaryRoutePostfix } from '../../RouteConstants'
import ICandidatePresenter from '../CandidateRow/Presenters/CandidatePresenter/ICandidatePresenter'
import AssessmentRow from './AssessmentRow'
import HeuristicSection from './HeuristicSection'

interface LocationState {
  state?: string | undefined
}

interface Props extends RouteComponentProps<LocationState> {
  menuVisibilityManager: IMenuVisibilityManager
  presenter: ICandidatePresenter
}

class Candidate extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    const { candidate } = this.props.location.state as any
    this.props.presenter.setCandidate(candidate)
    this.props.presenter.setUserId(candidate?.userId)
    this.props.presenter.initialize(candidate?.userId)
    this.props.presenter.setIsCandidateInfoVisible(candidate?.shouldShowCandidateDetails)
    this.props.menuVisibilityManager.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { menuVisibilityManager, presenter } = this.props
    const candidate = presenter.getCandidate()
    const profilePicture = candidate?.profilePicture
    const profileSrc = profilePicture?.name
      ? `${serverBaseUrl}profile/${profilePicture.name}`
      : images.candidate
    const resumeName = presenter.getResumeFileName()
    const { showDeleteModal } = presenter

    if (presenter.isLoading()) {
      return (
        <div className='blank-page-padding'>
          <div className='userProfile'>
            <div className='userProfile-content'>
              <div className='userProfile-progress'>
                <div className='userProfile-whiteBorder' />
                <img alt='user-profile' className='userProfile-image' src={profileSrc} />
              </div>
              <div>
                <p className='userProfile-name loading' id='loading-userProfile' />
                <p className='caption userProfile-strength loading' id='loading-strength' />
              </div>
            </div>
          </div>
          <div className='candidate loading'></div>
        </div>
      )
    }

    return (
      <Fragment>
        <SlidingMenu visibilityManager={menuVisibilityManager} />
        <div className='candidate blank-page-padding'>
          {this.renderHeader()}
          <div className='header'>
            <CandidateUserProfile
              id={presenter.getUserId()}
              image={profileSrc}
              name={presenter.getCandidate()?.name ?? ''}
              presenter={presenter}
            />
            <button onClick={this.handleSendAssessmentEmailClick} className='send-email-button'>
              Send Assessment Email
            </button>
            <button onClick={this.handleDeleteClick} className='delete-button'>
              Delete
            </button>
          </div>
          <br></br>
          <div className='candidate-profile-button-container'>
            <OutlinedButton onClick={this.handleResumeClick}>{resumeName}</OutlinedButton>
            <OutlinedButton onClick={this.handleViewShareableProfileClick}>
              View Shareable Profile
            </OutlinedButton>
          </div>
          <br></br>
          <div className='section-with-border'>
            <p className='subtitle2'>Assessments</p>
            {candidate?.assessments.map((assessment) => (
              <AssessmentRow
                key={assessment.id}
                presenter={presenter.getAssessmentRowPresenter(assessment)}
              />
            ))}
          </div>
          <HeuristicSection presenter={presenter.getHeuristicSectionPresenter()} />
          <div className='section-with-border'>
            <p className='subtitle2'>Category scores</p>
            <AssessmentBars
              presenter={{
                getAssessmentMetrics: () => presenter.getCandidatePerformance(),
                selectAssessmentMetricToShow: () => null
              }}
              shouldShowDropdown={false}
            />
          </div>
          <OTEStanding
            isLoading={presenter.isLoading()}
            presenter={presenter.getOTEStandingPresenter()}
          />
          {showDeleteModal && this.renderDeleteModal()}
        </div>
      </Fragment>
    )
  }

  update = (): void => {
    this.setState({})
  }

  handleResumeClick = (): void => {
    this.props.presenter.handleResumeClick()
  }

  handleDeleteClick = (): void => {
    this.props.presenter.toggleDeleteModal()
    this.update()
  }

  handleViewShareableProfileClick = (): void => {
    const { presenter } = this.props
    presenter.getUserId()
    window.location.href = `${candidateSummaryRoute}/${presenter.getUserId()}${summaryRoutePostfix}`
  }

  renderHeader = (): ReactElement => {
    const { menuVisibilityManager } = this.props

    return <Header menuVisibilityManager={menuVisibilityManager} />
  }

  renderDeleteModal = (): ReactElement => (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <p>
          This will <strong>PERMANENTLY</strong> delete this user's data. It is <strong>NOT</strong>{' '}
          recoverable. Are you sure you want to delete?
        </p>
        <div className='modal-actions'>
          <button
            type='button'
            className='delete-confirm-button'
            onClick={this.handleConfirmDelete}
          >
            Delete
          </button>
          <button type='button' className='delete-cancel-button' onClick={this.handleCancelDelete}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )

  handleConfirmDelete = (): void => {
    this.props.presenter.deleteUser()
  }

  handleSendAssessmentEmailClick = async (): Promise<void> => {
    alert('Email triggered, can take 30 - 60 seconds!')
    await this.props.presenter.sendAssessmentEmail()
  }

  handleCancelDelete = (): void => {
    this.props.presenter.toggleDeleteModal()
    this.update()
  }
}

export default withRouter(Candidate)
