import React, { Component, ReactElement } from 'react'

import HorizontalMultipleChoice from '../../Assessment/HorizontalMultipleChoice/HorizontalMultipleChoice'
import PromptDtoWithUnit from '../../Assessment/Presenter/PromptDtoWithUnit'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import ValidResponseType, {
  fuzzySearch,
  numEntriesResponseType,
  picklistType
} from '../Dtos/ValidResponseType'
import FreeResponse from '../FreeResponse/FreeResponse'
import FuzzySearch from '../FuzzySearch/FuzzySearch'
import NumEntries from '../NumEntries/NumEntries'
import Picklist from '../Picklist/Picklist'
import IPromptResponsePresenterFactory from '../Presenters/PromptPresenterFactory/IPromptResponsePresenterFactory'
import VerticalMultipleChoice from '../VerticalMultipleChoice/VerticalMultipleChoice'

type Props = {
  description: string
  factory: IPromptResponsePresenterFactory
  navigationPresenter: INavigationPresenter & INavigationTerminator
  prompt: PromptDtoWithUnit
  responseType: ValidResponseType
  title: string
}

class PromptResponseComponentFactory extends Component<Props> {
  render(): ReactElement {
    const { description, factory, navigationPresenter, prompt, responseType, title } = this.props

    if (responseType.includes(numEntriesResponseType)) {
      return (
        <NumEntries
          navigationPresenter={navigationPresenter}
          presenter={factory.getNumEntriesPresenter(prompt)}
        />
      )
    }
    if (responseType.includes(fuzzySearch)) {
      return (
        <FuzzySearch
          navigationPresenter={navigationPresenter}
          presenter={factory.getFuzzySearchPresenter(prompt)}
        />
      )
    }
    if (responseType.includes('free_response')) {
      return (
        <FreeResponse
          navigationPresenter={navigationPresenter}
          presenter={factory.getFreeResponsePresenter(prompt)}
        />
      )
    }

    if (responseType === picklistType) {
      return (
        <Picklist
          elementId={`${responseType}-${prompt.id}`}
          navigationPresenter={navigationPresenter}
          presenters={factory.getPicklistResponsePresenters(prompt)}
          description={description}
          title={title}
        />
      )
    }

    if (responseType === 'multiple_choice_vertical') {
      return (
        <VerticalMultipleChoice
          navigationPresenter={navigationPresenter}
          presenter={factory.getMultipleChoicePresenter(prompt)}
        />
      )
    }

    if (responseType === 'multiple_choice_horizontal') {
      return (
        <HorizontalMultipleChoice
          navigationPresenter={navigationPresenter}
          presenter={factory.getMultipleChoicePresenter(prompt)}
        />
      )
    }

    return <div></div>
  }
}

export default PromptResponseComponentFactory
