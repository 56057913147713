import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../images'
import IToggleEditModePresenter from '../Presenters/Interfaces/IEditModeToggler'
import IDataDisplayRowPresenter from './IHomeDataRowsPresenter'

type Props = {
  editModeToggler?: IToggleEditModePresenter
  isLoading: boolean
  presenter: IDataDisplayRowPresenter
}

class OTEStanding extends Component<Props> {
  render(): ReactElement {
    const { editModeToggler, isLoading, presenter } = this.props

    if (isLoading) {
      return (
        <div className='dataRows'>
          <div className='loading'></div>
        </div>
      )
    }

    const role = presenter.getRole() ?? ''

    return (
      <div className='dataRows'>
        <div className='home-dataRow section-with-border'>
          <div className='dataRows-title'>
            <div className='dataRows-titleRow'>
              <p className='subtitle2'>{presenter.getTitle()}</p>
              {editModeToggler && (
                <img
                  alt='edit-button'
                  className='dataRows-editIcon'
                  onClick={this.editPersonalData}
                  src={images.editIcon}
                />
              )}
            </div>
            <p className='caption'>
              {role} {role && '|'} {this.getNumOfYearsExperience()} | {this.getAnnualQuota()}
            </p>
          </div>
          <div className='dataRows-content'>
            <p className='dataRows-userPercentile'>{presenter.getUserOtePercentile()}</p>
            <p className='caption dataRows-userPercentileLabel'>
              Your current OTE is higher than {presenter.getUserOtePercentileRanking()}% of people
              with similar backgrounds
            </p>
            <p className='dataRows-divider'></p>
            <p className='headline5 dataRows-range'>
              ${presenter.getLowOte()} - ${presenter.getHighOte()}
            </p>
            <p className='caption'>Market OTE range</p>
          </div>
        </div>
        <div className='dataRows-gradientLayer'></div>
        <div className='dataRows-shadowLayer'></div>
      </div>
    )
  }

  editPersonalData = (): void => {
    this.props.editModeToggler?.toggleShouldShowEditMode()
  }

  getRole = (): ReactElement => {
    const { presenter } = this.props

    if (presenter.getRole()) {
      return <span>{presenter.getRole()}</span>
    }

    return <span>&ndash;</span>
  }

  getNumOfYearsExperience = (): ReactElement => {
    const { presenter } = this.props

    if (presenter.getNumOfYearsExperience()) {
      return (
        <span>
          {`${presenter.getNumOfYearsExperience()} 
          ${parseInt(presenter.getNumOfYearsExperience(), 10) > 1 ? 'yrs' : 'yr'} experience`}
        </span>
      )
    }

    return <span>&ndash;</span>
  }

  getAnnualQuota = (): ReactElement => {
    const { presenter } = this.props

    if (presenter.getFormattedAnnualQuota()) {
      return <span>${presenter.getFormattedAnnualQuota()} Quota</span>
    }

    return <span>&ndash;</span>
  }
}

export default OTEStanding
