import HeuristicDto from '../../../APIs/Dtos/HeuristicDto'
import FactDto from '../../../APIs/HeuristicsUpdater/Dtos/FactDto'
import FactDtoWithName from '../../../APIs/HeuristicsUpdater/Dtos/FactDtoWithName'
import MeasureDto from '../../../APIs/HeuristicsUpdater/Dtos/MeasureDto'
import MeasureDtoWithName from '../../../APIs/HeuristicsUpdater/Dtos/MeasureDtoWithName'
import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IHeuristicSectionPresenter from './InterfacesAndDtos/IHeuristicSectionPresenter'

class HeuristicSectionPresenter implements IHeuristicSectionPresenter {
  public isOpen: boolean
  private view: ISubscribingView | null
  private facts: FactDto[]
  private measures: MeasureDto[]
  private heuristics: HeuristicDto[]
  private heuristicIdToHeuristic: Map<number, HeuristicDto>

  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {
    this.view = null
    this.facts = []
    this.measures = []
    this.heuristics = []
    this.heuristicIdToHeuristic = new Map()
    this.isOpen = false
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public async initialize(): Promise<void> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/heuristics`,
      body: null
    })
    if (result && result?.facts && result?.measures) {
      this.facts = result.facts
      this.measures = result.measures
    }

    const heuristicResults = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}heuristics`,
      body: null
    })

    if (Array.isArray(heuristicResults)) {
      this.heuristics = heuristicResults
      heuristicResults.forEach((dto) => this.heuristicIdToHeuristic.set(dto.id, dto))
    }

    this.updateView()
  }

  public getValues(): Array<FactDtoWithName | MeasureDtoWithName> {
    return [...this.getFacts(), ...this.getMeasures()].sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  public handleToggle(): void {
    this.isOpen = !this.isOpen
    this.updateView()
  }

  private getFacts(): FactDtoWithName[] {
    return this.facts
      .map((fact) => {
        const heuristic = this.heuristicIdToHeuristic.get(fact.heuristicId)
        return {
          name: heuristic ? heuristic.name : '',
          ...fact
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  private getMeasures(): MeasureDtoWithName[] {
    return this.measures
      .map((measure) => {
        const heuristic = this.heuristicIdToHeuristic.get(measure.heuristicId)
        return {
          name: heuristic ? heuristic.name : '',
          ...measure
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  private updateView(): void {
    this.view?.update()
  }
}

export default HeuristicSectionPresenter
