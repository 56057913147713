import Cookies from 'js-cookie'

import INonSerializableFetcher from '../../Drivers/Interfaces/INonSerializableFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import { authTokenKey } from '../../localCommon/CookieKeys'
import ImageUploadDto from '../Dtos/ImageUploadDto'
import ProfilePictureDto from '../Dtos/ProfilePictureDto'
import IProfileImagePoster from './IProfileImagePoster'

export const failedToUploadMessage = 'Failed to upload image. Please try again'
export const UPLOAD_PROFILE_URL = `${serverBaseUrl}profile/profilePicture`
class ProfileImagePoster implements IProfileImagePoster {
  constructor(private readonly fetcher: INonSerializableFetcher) {}

  public async uploadProfileImage(param: ImageUploadDto): Promise<ProfilePictureDto | string> {
    return this.uploadFormData(param)
  }

  private async uploadFormData(params: object): Promise<ProfilePictureDto | string> {
    const request = {
      body: (params as any).formData,
      method: 'POST',
      url: UPLOAD_PROFILE_URL,
      headers: this.getHeaders(Cookies.get(authTokenKey) ?? '')
    }

    const result = await this.fetcher.fetch(request)

    if (this.isValidResponse(result)) {
      return result
    }

    return failedToUploadMessage
  }

  private isValidResponse(obj: any): obj is ProfilePictureDto {
    return obj?.hasOwnProperty('profilePicture') && obj?.hasOwnProperty('message')
  }

  private getHeaders(authToken: string): Headers {
    const headers = new Headers()

    headers.append('Accept', 'application/json')

    if (authToken) {
      headers.append('Authorization', `Bearer ${authToken}`)
    }

    return headers
  }
}

export default ProfileImagePoster
