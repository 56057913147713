import './style.scss'

import React, { Component, ReactElement } from 'react'

import IProfileImageHandler from '../../../Handlers/ProfileImageHandler/IProfileImageHandler'
import OutlinedButton from '../../../localCommon/Button/OutlinedButton'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'

type Props = {
  presenter: IProfileImageHandler
  text: string
}

class ProfilePicture extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter, text } = this.props

    return (
      <div className='profile-profileSectionWrapper'>
        <div className='profile-profileSection'>
          <div className='profile-profilePicture'>
            {presenter.getImage() ? (
              <img
                alt='user-profile'
                id={presenter.getImageElementId()}
                src={presenter.getImage()}
              />
            ) : (
              <span className='headline3'>{presenter.getName()}</span>
            )}
          </div>
        </div>
        <OutlinedButton>
          {text}
          <input
            accept='image/*'
            className='profile-photoInput'
            onChange={this.validateThenUploadFile}
            type='file'
          />
        </OutlinedButton>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  validateThenUploadFile = async ({ target }: { target: HTMLInputElement }): Promise<void> => {
    const { presenter } = this.props

    if (target?.validity?.valid && target.files) {
      const files = Array.from(target.files)

      if (files && files.length) {
        presenter.validateThenUploadFile(files[0] as any)
      }
    } else {
      presenter.setErrorMessage('Invalid file')
    }
  }
}

export default ProfilePicture
