import React, { ChangeEvent, Component, ReactElement } from 'react'

type Props = {
  checked: boolean
  id: string
  label: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

class CheckBox extends Component<Props> {
  render(): ReactElement {
    const { id, label, checked, onChange } = this.props

    return (
      <div className='checkbox'>
        <div className='mdc-form-field'>
          <div className='mdc-checkbox'>
            <input
              type='checkbox'
              className='mdc-checkbox__native-control'
              id={id}
              checked={checked}
              onChange={onChange}
            />
            <div className='mdc-checkbox__background'>
              <svg className='mdc-checkbox__checkmark' viewBox='0 0 24 24'>
                <path
                  className='mdc-checkbox__checkmark-path'
                  fill='none'
                  d='M1.73,12.91 8.1,19.28 22.79,4.59'
                />
              </svg>
              <div className='mdc-checkbox__mixedmark'></div>
            </div>
            <div className='mdc-checkbox__ripple'></div>
          </div>
          <label htmlFor={id} className='body1'>
            {label}
          </label>
        </div>
      </div>
    )
  }
}

export default CheckBox
