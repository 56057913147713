import Cookies from 'js-cookie'

import AssessmentChecker from '../../../APIs/AssessmentChecker/AssessmentChecker'
import HeuristicsUpdateHandler from '../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/HeuristicsUpdateHandler'
import HeuristicsDtoFactory from '../../../APIs/HeuristicsUpdater/HeuristicsDtoFactory/HeuristicsDtoFactory'
import HeuristicsUpdater from '../../../APIs/HeuristicsUpdater/HeuristicsUpdater'
import MarketCompensationDataGetter from '../../../APIs/MarketCompensationDataGetter/MarketCompensationDataGetter'
import MetricPerformanceGetter from '../../../APIs/MetricPerformanceGetter/MetricPerformanceGetter'
import PersonalInfoGetter from '../../../APIs/PersonalInfoGetter/PersonalInfoGetter'
import PersonalInfoPutter from '../../../APIs/PersonalInfoPutter/PersonalInfoPutter'
import Fetcher from '../../../Drivers/Fetcher'
import HomeMetricPerformanceHandler from '../../../Handlers/HomeMetricPerformanceHandler/HomeMetricPerformanceHandler'
import PersonalInfoDataHandler from '../../../Handlers/PersonalInfoDataHandler/PersonalInfoDataHandler'
import { userIdKey } from '../../../localCommon/CookieKeys'
import ICookieGetter from '../../../localCommon/Interfaces/ICookieGetter'
import NumberFormatter from '../../../localCommon/NumberFormatter/NumberFormatter'
import AuthTokenVerifier from '../../../localCommon/SignOut/AuthTokenVerifier/AuthTokenVerifier'
import SignOutHelper from '../../../localCommon/SignOut/SignOutHelper/SignOutHelper'
import assessmentStore from '../../../Models/AssessmentStore'
import personalDataStore from '../../../Models/PersonalDataStore'
import DateRangeTypesGetter from '../../Assessment/Presenter/DateRangeTypesGetter/DateRangeTypesGetter'
import OnboardingHeuristicValuesUpdater from '../../Home/PersonalInfoDataCollector/OnboardingHeuristicValuesUpdater/OnboardingHeuristicValuesUpdater'
import NextReadyToTakeAssessmentProvider from '../Dashboard/NextReadyToTakeAssessmentProvider/NextReadyToTakeAssessmentProvider'
import DashboardPresenter from '../Dashboard/Presenter/DashboardPresenter'
import HomeDataRowsPresenter from '../OTEStanding/HomeDataRowsPresenter'
import PersonalInfoInputValidator from '../PersonalInfoInputValidator/PersonalInfoInputValidator'
import HomePresenter from '../Presenters/HomePresenter'
import IHomePresenter from '../Presenters/Interfaces/IHomePresenter'
import IHomePresenterFactory from './IHomePresenterFactory'

class HomePresenterFactory implements IHomePresenterFactory {
  public buildPresenter(): IHomePresenter {
    const userId = parseInt(Cookies.get(userIdKey) ?? '', 10)

    return new HomePresenter(
      new AuthTokenVerifier(
        {
          get: Cookies.get
        } as ICookieGetter,
        new SignOutHelper(Cookies, {
          setPathName: (path: string) => (window.location.pathname = path)
        })
      ),
      new NumberFormatter(),
      new PersonalInfoInputValidator(),
      new HomeDataRowsPresenter(new NumberFormatter(), personalDataStore, 'Your OTE Percentile'),
      new PersonalInfoPutter(new Fetcher(), userId),
      new MarketCompensationDataGetter(new Fetcher()),
      new PersonalInfoDataHandler(
        new PersonalInfoGetter(new Fetcher(), userId),
        new HomeMetricPerformanceHandler(new MetricPerformanceGetter(new Fetcher(), userId)),
        Cookies
      ),
      new OnboardingHeuristicValuesUpdater(
        new HeuristicsUpdateHandler(
          new HeuristicsDtoFactory(new DateRangeTypesGetter(), userId),
          new HeuristicsUpdater(new Fetcher(), userId)
        )
      ),
      {
        get: Cookies.get
      } as ICookieGetter,
      Cookies,
      new DashboardPresenter(
        new NextReadyToTakeAssessmentProvider(new AssessmentChecker(new Fetcher(), userId)),
        assessmentStore,
        new Fetcher()
      ),
      personalDataStore
    )
  }
}

export default HomePresenterFactory
