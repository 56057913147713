import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../../images'
import ThemeButton from '../../../../localCommon/Button/ThemeButton/ThemeButton'
import OutlinedThemeButton from '../../../../localCommon/Button/ThemeOutlinedButton/ThemeOutlinedButtons'
import LoadingSpinner from '../../../../localCommon/LoadingSpinner/LoadingSpinner'
import IAssessmentResultPresenter from '../AssessmentResult/Presenter/IAssessmentResultPresenter'

type Props = {
  presenter: IAssessmentResultPresenter
}

class StageTwo extends Component<Props> {
  render(): ReactElement {
    const { presenter } = this.props
    const items = this.getStageTwoItems()

    return (
      <div
        className={`section-with-border ${presenter.hasFailedAssessment() ? '' : 'nextSteps-meetingSection'}`}
      >
        <p className='subtitle2'>{this.getStageTwoTitle()}</p>
        <ul className='stageTwo-items'>
          {items?.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        {presenter.hasFailedAssessment() && (
          <div className='center-text'>
            <img alt='failed-assessment' src={images.failedAssessmentImage} />
          </div>
        )}
        {this.renderStepTwoButton()}
      </div>
    )
  }

  getStageTwoItems = (): string[] => {
    const { presenter } = this.props

    if (presenter.hasScheduledAMeeting()) {
      return [
        'Validate performance data',
        `Learn what's important to you in your job search`,
        'Complete psychographic video responses'
      ]
    }

    if (presenter.hasFailedAssessment()) {
      return [
        'Read about what they are and why they are important to the companies we hire for',
        'Evaluate your percentile score in the details of each metric'
      ]
    }

    return [
      `Learn what's important to you in your job search`,
      'Help you gather validated performance data',
      'Complete 15 min. off-line video interview'
    ]
  }

  getStageTwoTitle = (): string => {
    const { presenter } = this.props

    if (presenter.hasFailedAssessment()) {
      return 'Review each of your metric results'
    }

    if (presenter.hasScheduledAMeeting()) {
      return 'Stage 2: Meet with a recruiting expert'
    }

    return 'Stage 2: Meet with a recruiting expert to:'
  }

  renderStepTwoButton = (): ReactElement => {
    const { presenter } = this.props

    if (presenter.hasFailedAssessment()) {
      return (
        <div className='nextStep-themeButton center-text'>
          <OutlinedThemeButton onClick={this.navigateToPreviousPage} type='button'>
            View Dashboard
          </OutlinedThemeButton>
        </div>
      )
    }

    return presenter.isLoadingMeetings() && presenter.shouldShowAppointmentLoadingState()
      ? this.handleDisplayAppointmentLoadingState()
      : this.renderMeetingTime()
  }

  renderMeetingTime = (): ReactElement => {
    const { presenter } = this.props

    return presenter.hasScheduledAMeeting() ? (
      <p className='nextSteps-scheduledMeeting'>Scheduled: {presenter.getScheduledDateTime()}</p>
    ) : (
      <ThemeButton onClick={this.scheduleAMeeting} type='button'>
        Schedule Time
      </ThemeButton>
    )
  }

  navigateToPreviousPage = (): void => {
    this.props.presenter.toggleNextStepsPage()
  }

  scheduleAMeeting = (): void => {
    const { presenter } = this.props

    presenter.scheduleAMeeting()
    presenter.toggleShowAppointmentLoadingState()
  }

  handleDisplayAppointmentLoadingState = (): ReactElement => {
    this.props.presenter.toggleShowAppointmentLoadingState()
    return this.renderAppointmentLoadingState()
  }

  renderAppointmentLoadingState = (): ReactElement => {
    return (
      <div className='nextStep-loadingAppointmentsWrapper'>
        <LoadingSpinner />
      </div>
    )
  }
}

export default StageTwo
