import BasicInfoQuestionDto from '../Dtos/BasicInfoQuestionDto'
import AbstractResponsePresenter from '../Presenters/AbstractResponsePresenter'
import INumEntriesPresenter from './INumEntriesPresenter'

export const defaultNumEntries = 10
const lowercaseOnlyRegex = /^[a-z]+$/
class NumEntriesPresenter extends AbstractResponsePresenter implements INumEntriesPresenter {
  private wordIdxToWord: Map<string, string>

  constructor(
    protected readonly question: BasicInfoQuestionDto,
    protected readonly questionIndex: number
  ) {
    super(question, questionIndex, question.isRequired)
    this.wordIdxToWord = new Map()
  }

  public isRequiredQuestion(): boolean {
    return this.question.isRequired
  }

  public hasValidResponse(): boolean {
    if (this.wordIdxToWord.size !== defaultNumEntries) {
      return false
    }
    if (!this.areAllWordsUnique()) {
      return false
    }
    if (!this.containsNoInvalidCharacters()) {
      return false
    }
    return true
  }

  private areAllWordsUnique(): boolean {
    const keys = Array.from(this.wordIdxToWord.keys())
    const set = new Set()
    keys.forEach((key) => {
      const word = this.wordIdxToWord.get(key)?.toLowerCase()
      set.add(word)
    })

    return set.size === this.wordIdxToWord.size
  }

  private containsNoInvalidCharacters(): boolean {
    const keys = Array.from(this.wordIdxToWord.keys())

    return keys.every((key) => {
      const word = this.wordIdxToWord.get(key)?.toLowerCase() ?? ''
      return lowercaseOnlyRegex.test(word)
    })
  }

  public getWordValueByIdx(idx: string): string {
    return this.wordIdxToWord.get(idx) ?? ''
  }

  public updateWordInput(input: string, wordIdx: string): void {
    const sanitizedInput = input.replace(/[^a-z]/gi, '') // Allow only a-z characters (case-insensitive)

    this.wordIdxToWord.set(wordIdx, sanitizedInput)
    this.input = this.getCommaSeparatedStringOfWords()
    this.checkWord(wordIdx)
    this.updateView()
  }

  private checkWord(wordIdx: string): void {
    const word = this.wordIdxToWord.get(wordIdx)?.toLowerCase() ?? ''

    if (!lowercaseOnlyRegex.test(word)) {
      this.shouldShowInlineError = true
      return
    }

    this.shouldShowInlineError = false
  }

  public getPlaceholder(idx: string): string {
    if (this.wordIdxToWord.get(idx) === undefined) {
      return `Word ${idx}`
    }

    return ''
  }

  public getPromptValue() {
    return this.input
  }

  public getResponseName(): string {
    return this.question.responseType
  }

  public getValue(): string {
    return this.input
  }

  private getCommaSeparatedStringOfWords(): string {
    const keys = Array.from(this.wordIdxToWord.keys())
    return keys.reduce((str: string, key: string, idx: number) => {
      const word = this.wordIdxToWord.get(key) ?? ''
      return idx === 0 ? word : str.concat(`, ${word}`)
    }, '')
  }
}

export default NumEntriesPresenter
