export const AMPLITUDE_APP_KEY = 'de05e0f6838e1b7c6439e952009a62da'
export const FLAGSMITH_ENV_ID = 'jRxtPB5LuLCMmCBPpK96BV'
export const serverBaseUrl = 'https://www.server.databased.com/'
export const growCourseCatalog = 'https://grow.databased.com/all-courses'
export const internalServerErrorHttpCode = 500
export const okHttpCode = 200
export const unauthorizedErrorHttpCode = 401
export const maxImageSize = 200000
export const pageRedirectWaitTime = 2000

export const oteHeuristicId = 1
export const quotaHeuristicId = 2
export const yearsInSalesHeuristicId = 4
export const w2IncomeHeuristicId = 10
export const educationHeuristicId = 14
export const currentSituationHeuristicId = 15
export const pastOteHeuristicId = 16
export const averageDealSizeHeuristicId = 23
export const winRateHeuristicId = 24
export const selfSourcedDealWinRateHeuristicId = 25
export const outboundPipeGenPercentageHeuristicId = 26
export const inboundPipeGenPercentageHeuristicId = 27
export const averageDealLengthHeuristicId = 31
export const cumulativeGpaHeuristicId = 32
export const currentRoleHeuristicId = 45
export const willingToWorkRemotelyHeuristicId = 73
export const willingToWorkInOfficeHeuristicId = 74
export const willingToHybridHeuristicId = 75
export const willingToRelocateHeuristicId = 76
export const desiredRoleHeuristicId = 88
export const linkedInUrlHeuristicId = 126
export const quotaAttainmentHeuristicId = 136
