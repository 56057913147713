import StringHelper from '../StringHelper/StringHelper'
import INumberFormatter from './INumberFormatter'

class NumberFormatter implements INumberFormatter {
  public formatLargeAmount(amount: string): string {
    const formattedAmountToString = StringHelper.filterOutCommaFromNumberString(amount.toString())
    if (!amount) {
      return ''
    }

    return this.formatNumber(parseFloat(formattedAmountToString))
  }

  private formatNumber(amount: number): string {
    const thresholds = [
      { limit: 1e9, suffix: 'B' },
      { limit: 1e6, suffix: 'M' },
      { limit: 1e3, suffix: 'K' }
    ]

    // eslint-disable-next-line no-restricted-syntax
    for (const threshold of thresholds) {
      if (Math.abs(amount) >= threshold.limit) {
        return (amount / threshold.limit).toFixed(0) + threshold.suffix
      }
    }

    return amount.toString()
  }
}

export default NumberFormatter
