import './style.scss'

import Cookies from 'js-cookie'
import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import AdminHandler from '../../Handlers/AdminHandler'
import images from '../../images'
import {
  candidateSummaryRoute,
  candidateViewJobBoardRoute,
  dashboardRoute,
  jobsManagementRoute,
  summaryRoutePostfix
} from '../../Routes/RouteConstants'
import { userIdKey } from '../CookieKeys'
import IMenuVisibilityManager from '../SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import { adminJobManagementTitle } from './HeaderConstants'

type Props = {
  children?: React.ReactNode
  menuVisibilityManager: IMenuVisibilityManager
}

const renderLinks = (): ReactElement => {
  if (AdminHandler.isDataBasedAdmin()) {
    return (
      <div className='header-links'>
        <Link
          to={dashboardRoute}
          onClick={(e) => {
            if (window.location.pathname === dashboardRoute) {
              e.preventDefault()
              window.location.reload()
            }
          }}
        >
          <div className='header-horizontalNav button-text'>Candidate Pool</div>
        </Link>
        <Link
          to={jobsManagementRoute}
          onClick={(e) => {
            if (window.location.pathname === jobsManagementRoute) {
              e.preventDefault()
              window.location.reload()
            }
          }}
        >
          <div className='header-horizontalNav button-text'>{adminJobManagementTitle}</div>
        </Link>
        <Link
          to={candidateViewJobBoardRoute}
          onClick={(e) => {
            if (window.location.pathname === candidateViewJobBoardRoute) {
              e.preventDefault()
              window.location.reload()
            }
          }}
        >
          <div className='header-horizontalNav button-text'>Job Board</div>
        </Link>
      </div>
    )
  }

  return (
    <div className='header-links'>
      <Link
        to={dashboardRoute}
        onClick={(e) => {
          if (window.location.pathname === dashboardRoute) {
            e.preventDefault()
            window.location.reload()
          }
        }}
      >
        <div className='header-horizontalNav button-text'>Home</div>
      </Link>
      <Link
        to={candidateViewJobBoardRoute}
        onClick={(e) => {
          if (window.location.pathname === candidateViewJobBoardRoute) {
            e.preventDefault()
            window.location.reload()
          }
        }}
      >
        <div className='header-horizontalNav button-text'>Jobs</div>
      </Link>
      <Link
        to={`${candidateSummaryRoute}/${Cookies.get(userIdKey)}${summaryRoutePostfix}`}
        onClick={(e: React.MouseEvent) => {
          if (window.location.pathname.includes(summaryRoutePostfix)) {
            e.preventDefault()
            window.location.reload()
          }
        }}
      >
        <div className='header-horizontalNav button-text'>Profile</div>
      </Link>
    </div>
  )
}

class Header extends Component<Props> {
  render(): ReactElement {
    const { children } = this.props

    return (
      <div className='header-icon'>
        <img alt='databased' src={images.databased} />
        {renderLinks()}
        {children}
        <div className='header-horizontalNav button-text' onClick={this.signOut}>
          LOG OUT
        </div>
        <img
          alt='close-drawer-icon'
          className='header-hamburgerMenu'
          onClick={this.openDrawer}
          src={images.hamburgerIcon}
        />
      </div>
    )
  }

  openDrawer = (): void => {
    this.props.menuVisibilityManager.open()
  }

  signOut = (): void => {
    this.props.menuVisibilityManager.signOut()
  }
}

export default Header
