import './style.css'
import 'react-quill/dist/quill.snow.css'

import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { Component, ReactElement } from 'react'
import ReactQuill from 'react-quill'
import { Link } from 'react-router-dom'

import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../localCommon/LoadingSpinner/LoadingSpinner'
import { jobsManagementRoute } from '../RouteConstants'
import NewJobDto from './Dtos/NewJobDto'
import INewJobListingPresenter from './Presenters/Interfaces/INewJobListingPresenter'

type Props = {
  presenter: INewJobListingPresenter
}

class NewJobListing extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  public render(): ReactElement {
    const { presenter } = this.props
    const { resultMessage, wasSuccessfulCreate } = presenter
    const job = presenter.getJob()
    const recruitingTypes = presenter.getRecruitingRoleTypes()

    if (presenter.isLoading()) {
      return <LoadingSpinner />
    }
    return (
      <Container className='container' maxWidth='sm'>
        <div className='header'>
          <Typography variant='h4' component='h1' gutterBottom>
            Create New Job
          </Typography>
          <Link to={jobsManagementRoute}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Link>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} className='formItem'>
              <TextField
                fullWidth
                label='Job Title'
                name='jobTitle'
                value={job.jobTitle ?? ''}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} className='formItem'>
              <TextField
                fullWidth
                label='Company Name'
                name='companyName'
                value={job.companyName ?? ''}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} className='formItem'>
              <TextField
                fullWidth
                label='Location'
                name='location'
                value={job.location ?? ''}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} className='formItem'>
              <ReactQuill
                value={job.jobDescription ?? ''}
                onChange={this.handleQuillChange}
                theme='snow'
                placeholder='Enter job description here...'
              />
            </Grid>
            <Grid item xs={12} sm={6} className='formItem'>
              <TextField
                fullWidth
                label='Base Compensation Low'
                name='baseCompensationLow'
                value={job.baseCompensationLow ?? ''}
                onChange={this.handleChange}
                type='number'
              />
            </Grid>
            <Grid item xs={12} sm={6} className='formItem'>
              <TextField
                fullWidth
                label='Base Compensation High'
                name='baseCompensationHigh'
                value={job.baseCompensationHigh ?? ''}
                onChange={this.handleChange}
                type='number'
              />
            </Grid>
            <Grid item xs={12} sm={6} className='formItem'>
              <TextField
                fullWidth
                label='Variable Compensation'
                name='variableCompensation'
                value={job.variableCompensation}
                onChange={this.handleChange}
                type='number'
              />
            </Grid>
            <Grid item xs={12} className='formItem'>
              <FormControl fullWidth>
                <InputLabel id='jobType-label'>Job Type</InputLabel>
                <Select
                  labelId='jobType-label'
                  name='jobType'
                  value={job.jobType ?? ''}
                  onChange={this.handlePicklistChange}
                >
                  <MenuItem value='On-site'>On-site</MenuItem>
                  <MenuItem value='Hybrid'>Hybrid</MenuItem>
                  <MenuItem value='Remote'>Remote</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className='formItem'>
              <FormControl fullWidth>
                <InputLabel id='recruitingType-label'>Job Level</InputLabel>
                <Select
                  labelId='recruitingType-label'
                  name='recruitingRoleTypeId'
                  value={presenter.getSelectedRecruitingRoleLabel()}
                  onChange={this.handlePicklistChange}
                >
                  {recruitingTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {resultMessage && wasSuccessfulCreate === false && (
            <Typography variant='body2' color='error' className='errorMessage'>
              {resultMessage}
            </Typography>
          )}
          {resultMessage && wasSuccessfulCreate && (
            <Typography variant='body2' color='primary' className='successMessage'>
              {resultMessage}
            </Typography>
          )}
          <br></br>
          <button className='saveJobButton'>Create Job</button>
        </form>
      </Container>
    )
  }

  handleQuillChange = (value: string) => {
    this.props.presenter.handleTextChange('jobDescription' as keyof NewJobDto, value)
  }

  handlePicklistChange = (event: any) => {
    const { name, value } = event.target
    this.props.presenter.handleTextChange(name as keyof NewJobDto, value)
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    this.props.presenter.handleTextChange(name as keyof NewJobDto, value)
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await this.props.presenter.createJob()
  }

  update(): void {
    this.setState({})
  }
}

export default NewJobListing
