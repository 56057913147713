export const userLoginEvent = 'User login'
export const clickGoogleSso = 'Clicked Google SSO'
export const clickLinkedInSso = 'Clicked LinkedIn SSO'
export const completedOnboardQuestions = 'Completed onboard questions'
export const completedPerformanceAssessment = 'Completed performance assessment'
export const completedPsychographicAssessment = 'Completed pyschographic assessment'
export const completedAllInOneAssessment = 'Completed all-in-one assessment'
export const startedPsychographicAssessment = 'Started psychographic assessment'
export const startedPerformanceAssessment = 'Started performance assessment'
export const startedAllInOneAssessment = 'Started all-in-one assessment'
