import DateRangeDto from '../../APIs/HeuristicsUpdater/Dtos/DateRangeDto'

class DateHelper {
  public static daysInDecember = 31
  public static readonly q1StartMonth = 0
  public static readonly q1EndMonth = 2
  public static readonly q2StartMonth = 3
  public static readonly q2EndMonth = 5
  public static readonly q3StartMonth = 6
  public static readonly q3EndMonth = 8
  public static readonly q4StartMonth = 9
  public static readonly q4EndMonth = 11

  public static getYyyyMmDdString(date = new Date(Date.now())): string {
    const paddingFillString = '0'
    const month = (date.getUTCMonth() + 1).toString().padStart(2, paddingFillString)
    const day = date.getUTCDate().toString().padStart(2, paddingFillString)

    return [date.getUTCFullYear(), month, day].join('-')
  }

  /* FORMATTED LIKE: 09.SEP.2024 */
  public static getDDMONYYYYDateString(date = new Date()): string {
    const day = date.getDate().toString().padStart(2, '0')
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase()
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }

  public static getCurrentYearAndMonth(date = new Date(Date.now())): string {
    const paddingFillString = '0'
    const month = (date.getUTCMonth() + 1).toString().padStart(2, paddingFillString)

    return `${date.getUTCFullYear()}-${month}`
  }

  public static getYearDateRange(year: number): DateRangeDto {
    const startDate = new Date(year, 0, 1)
    const startDateString = startDate.toISOString().split('T')[0]

    const endDate = new Date(year, DateHelper.q4EndMonth, DateHelper.daysInDecember)
    const endDateString = endDate.toISOString().split('T')[0]

    return {
      endDate: endDateString,
      startDate: startDateString
    }
  }

  public static getShortMonthAndDate(date: Date): string {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric'
    }).format(date)

    return `${formattedDate}`
  }

  public static getShortTime(date: Date): string {
    const localTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
    return localTime
  }

  public static getShortMonthAndTime(date: Date): string {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date)

    return `${formattedDate}`
  }

  public static getYearToDateDateRange(): DateRangeDto {
    const currentDate = DateHelper.getYyyyMmDdString()
    const startDate = DateHelper.getYyyyMmDdString(new Date(new Date().getFullYear(), 0, 1))

    return {
      startDate,
      endDate: currentDate
    }
  }

  public static getMonthToDateDatRange(): DateRangeDto {
    const todayDate = new Date(Date.now())

    return {
      startDate: DateHelper.getYyyyMmDdString(
        new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
      ),
      endDate: DateHelper.getYyyyMmDdString()
    }
  }

  public static getQuarterToDateDateRange(): DateRangeDto {
    const quarterToDateRange = DateHelper.getQuarterStartEndMonths()
    const todayDate = new Date(Date.now())
    const quarter = DateHelper.getQuarterNumber(todayDate)

    return {
      startDate: DateHelper.getYyyyMmDdString(
        new Date(todayDate.getFullYear(), quarterToDateRange.get(quarter)!.startMonth, 1)
      ),
      endDate: DateHelper.getYyyyMmDdString()
    }
  }

  private static getQuarterNumber(date: Date): number {
    const month = date.getMonth()
    return Math.ceil((month + 1) / 3)
  }

  private static getQuarterStartEndMonths(): Map<number, { endMonth: number; startMonth: number }> {
    const map = new Map()
      .set(1, {
        startMonth: this.q1StartMonth,
        endMonth: this.q1EndMonth
      })
      .set(2, {
        startMonth: this.q2StartMonth,
        endMonth: this.q2EndMonth
      })
      .set(3, {
        startMonth: this.q3StartMonth,
        endMonth: this.q3EndMonth
      })
      .set(4, {
        startMonth: this.q4StartMonth,
        endMonth: this.q4EndMonth
      })

    return map
  }
}

export default DateHelper
