import './style.scss'

import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import NonSerializableFetcher from '../../Drivers/NonSerializableFetcher'
import images from '../../images'
import BackToPreviousPage from '../../localCommon/BackToPreviousPage/BackToPreviousPage'
import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedButton from '../../localCommon/Button/OutlinedButton'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import { invalidEmailErrorMessage } from '../SignUp/Presenter/SignUpPresenter'
import ProfilePicture from '../SignUp/ProfilePicture/ProfilePicture'
import IEditAccountPresenter from './Presenter/IEditAccountPresenter'
import ResumeUploadPresenter from './Resume/Presenter/ResumeUploadPresenter'
import ResumeUpload from './Resume/ResumeUpload'

type Props = {
  presenter: IEditAccountPresenter
}

class EditAccount extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    return (
      <div className='editAccount blank-page-padding'>
        <p className='center-text page-title'>
          <img alt='databased' src={images.databased} />
        </p>
        <p className='headline6'>Edit Account</p>
        <ProfilePicture text='Change photo' presenter={presenter.getProfileImageHandler()} />
        <p className='error'>{presenter.getErrorMessage()}</p>
        <p className='success'>{presenter.getSuccessMessage()}</p>
        <ResumeUpload presenter={new ResumeUploadPresenter(new NonSerializableFetcher())} />
        <div>
          <OutlinedInput
            caption='Your Name'
            id='name'
            isRequired
            label='Your Name'
            onChange={this.updateName}
            shouldShowErrorMessage={presenter.shouldShowInvalidEmailMessage()}
            type='text'
            value={presenter.getName()}
          />
        </div>
        <div>
          <OutlinedInput
            caption='Email'
            id='email'
            error={invalidEmailErrorMessage}
            isRequired
            label='Email'
            onChange={this.updateEmail}
            shouldShowErrorMessage={presenter.shouldShowInvalidEmailMessage()}
            pattern='^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$'
            type='email'
            value={presenter.getEmail()}
          />
        </div>
        <Link
          to={{
            pathname: '/forgotPassword'
          }}
        >
          <OutlinedButton className='editAccount-changePassword'>Change password</OutlinedButton>
        </Link>
        <div className='buttons-group'>
          <BackToPreviousPage
            label={presenter.getSuccessMessage() ? 'Back To Dashboard' : 'Cancel'}
          />
          <ContainedButton onClick={() => this.submit()} type='submit'>
            Save changes
          </ContainedButton>
        </div>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  updateName = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateName(target.value)
  }

  updateEmail = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateEmail(target.value?.toLowerCase())
  }

  submit = (): void => {
    this.props.presenter.submit()
  }
}

export default EditAccount
