import './style.scss'

import React, { Component, Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import images from '../../../images'
import { serverBaseUrl } from '../../../localCommon/constant'
import { candidateProfileRoute } from '../../RouteConstants'
import AssessmentResultDto from '../Presenter/InterfacesAndDtos/AssessmentResultDto'
import CandidatePoolDto from '../Presenter/InterfacesAndDtos/CandidatePoolDto'

type Props = {
  candidate: CandidatePoolDto
}

class CandidateRow extends Component<Props> {
  render(): ReactElement {
    const { name, roleInterestedIn, assessments, profilePicture } = this.props.candidate
    const currentAssessment = this.getMostRecentAssessment(assessments)
    const score = currentAssessment?.scoreDto?.averageScore ?? -1

    return (
      <tr className='candidateRow'>
        <td className='pool-leftCol'>
          {this.buildColumn(
            <div className='candidate-info'>
              <img
                alt='candidate'
                className='pool-candidateImage'
                src={
                  profilePicture?.name
                    ? `${serverBaseUrl}profile/${profilePicture.name}`
                    : images.candidate
                }
              />
              <p>{name}</p>
              <div className='pool-colDividerBody'></div>
            </div>
          )}
        </td>
        <td className='pool-middleCol pool-col'>
          {this.buildColumn(
            <Fragment>
              <p className='pool-ColContent'>{this.getDisplayName(roleInterestedIn)}</p>
              <div className='pool-colDividerBody'></div>
            </Fragment>
          )}
        </td>
        <td className='pool-rightCol pool-col'>
          {this.buildColumn(
            <p className='pool-ColContent'>{score === -1 ? 'No Score' : score.toFixed(2)}</p>
          )}
        </td>
      </tr>
    )
  }

  private getDisplayName(name: string): string {
    if (name) {
      return name.split('(')[0]
    }
    return ''
  }

  private getMostRecentAssessment(assessments: AssessmentResultDto[]): AssessmentResultDto {
    const sortedAssessments = assessments.sort((a, b) => {
      return new Date(a.scoreCalculationTimestamp).getTime() >
        new Date(b.scoreCalculationTimestamp).getTime()
        ? 1
        : -1
    })

    return sortedAssessments[0] ?? null
  }

  buildColumn = (children: React.ReactNode): ReactElement => {
    const { candidate } = this.props

    return (
      <Link
        to={{
          state: { candidate },
          pathname: `${candidateProfileRoute}/${candidate.userId}`
        }}
      >
        {children}
      </Link>
    )
  }
}

export default CandidateRow
