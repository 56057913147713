import './style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedButton from '../../../localCommon/Button/OutlinedButton'
import INewUserProfilePictureHandler from './INewUserProfilePictureHandler'

type Props = {
  handler: INewUserProfilePictureHandler
}

export const imageInputElementId = 'imageInput'
export const imageContainerId = 'imageContainer'
class NewUserProfilePicture extends Component<Props> {
  componentDidMount(): void {
    this.addFileUploadListener()
  }

  render(): ReactElement {
    return (
      <div className='profile-profileSectionWrapper'>
        <div className='profile-profileSection'>
          <div id='imageContainer' className='profile-profilePicture'></div>
        </div>
        <OutlinedButton>
          Add photo
          <input accept='image/*' id='imageInput' className='profile-photoInput' type='file' />
        </OutlinedButton>
      </div>
    )
  }

  addFileUploadListener = (): void => {
    document.getElementById(imageInputElementId)?.addEventListener('change', this.handleImageSelect)
  }

  handleImageSelect = (event: Event) => {
    const imageContainer = document.getElementById(imageContainerId)
    if (!imageContainer) {
      return
    }

    imageContainer.innerHTML = ''

    const fileInput = event.target as HTMLInputElement
    const file = fileInput?.files?.[0]
    if (file) {
      const reader = new FileReader()

      reader.onload = (e: Event): void => {
        if (e.target) {
          const imageUrl = (e.target as FileReader)?.result as string
          const imageElement = document.createElement('img')

          imageElement.src = imageUrl
          imageContainer.appendChild(imageElement)
        }
      }

      reader.readAsDataURL(file)
      this.props.handler.getImageFile(file)
    }
  }
}

export default NewUserProfilePicture
