import PromptDto from '../../../APIs/PromptGetter/PromptDto'
import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { okHttpCode, serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RecruitingRoleTypeDto from '../../CandidatePool/Candidate/InterfacesAndDtos/RecruitingRoleTypeDto'
import ValidResponseType, { shortFreeResponseType } from '../../Home/Dtos/ValidResponseType'
import JobDto from '../Dtos/JobDto'
import IJobListingPresenter from './Interfaces/IJobListingPresenter'

interface NewPromptDto {
  canBacktrackToPreviousPrompt: boolean
  canSkipPrompt: boolean
  dateRangeType: null
  heuristicId: null
  inputLabel: string
  prompt: string
  responseType: ValidResponseType
}
interface NewHeuristicDto {
  description: string
  name: string
  scoringMethod: null
  unit: string | null
  valueType: 'fact' // Only allowing facts, no measures for now
}
class JobListingPresenter implements IJobListingPresenter {
  public resultMessage: string
  public wasSuccessfulSave: boolean | null
  public wasSuccessfulDelete: boolean | null
  public promptSearchQuery: string
  public failedPromptCreation: boolean | null

  private loading: boolean
  private view: ISubscribingView | null
  private job: JobDto
  private recruitingRoleTypes: RecruitingRoleTypeDto[]
  private jobPrompts: PromptDto[]
  private eligiblePrompts: PromptDto[]
  private isAddPromptWindowOpen: boolean
  private showNewPromptForm: boolean
  // @ts-ignore set in the set function called in the constructor
  private newPromptDto: NewPromptDto
  // @ts-ignore set in the set function called in the constructor
  private newHeuristicDto: NewHeuristicDto

  constructor(
    private jobId: number,
    private readonly fetcher: IFetcher
  ) {
    this.failedPromptCreation = null
    this.wasSuccessfulSave = null
    this.wasSuccessfulDelete = null
    this.resultMessage = ''
    this.view = null
    this.loading = true
    // @ts-ignore
    this.job = {}
    this.recruitingRoleTypes = []
    this.jobPrompts = []
    this.eligiblePrompts = []
    this.isAddPromptWindowOpen = false
    this.promptSearchQuery = ''
    this.showNewPromptForm = false
    this.setNewPromptDtoAndHeuristicDtoToDefault()
  }

  public async initialize(): Promise<void> {
    const jobsResult = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}jobs/${this.jobId}`,
      body: null
    })

    if (this.isValidJobDtoResponse(jobsResult)) {
      this.job = jobsResult
    }

    const recruitingTypesResult = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}recruitingRoleTypes`,
      body: null
    })

    if (this.isValidRecruitingTypesResponse(recruitingTypesResult)) {
      this.recruitingRoleTypes = recruitingTypesResult
    }

    const jobQuestionsResult = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}job/${this.jobId}/requiredPrompts`,
      body: null
    })

    if (this.isValidJobPromptsResponse(jobQuestionsResult)) {
      this.jobPrompts = jobQuestionsResult
    }

    const eligiblePromptsResult = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}job/${this.jobId}/eligiblePrompts`,
      body: null
    })

    if (Array.isArray(eligiblePromptsResult)) {
      this.eligiblePrompts = eligiblePromptsResult
    }

    this.loading = false
    this.updateView()
  }

  private isValidJobPromptsResponse(result: any): result is PromptDto[] {
    return Array.isArray(result)
  }

  public getJobPrompts(): PromptDto[] {
    return this.jobPrompts
  }

  public getSelectedRecruitingRoleLabel(): string {
    if (this.job.recruitingRoleTypeId) {
      const selectedRecruitingRole = this.recruitingRoleTypes.find(
        (role) => role.id === this.job.recruitingRoleTypeId
      )
      if (selectedRecruitingRole) {
        return `${selectedRecruitingRole.role} (${selectedRecruitingRole.description})`
      }
    }
    return ''
  }

  private isValidRecruitingTypesResponse(result: any): result is RecruitingRoleTypeDto[] {
    return Array.isArray(result)
  }

  private isValidJobDtoResponse(result: any): result is JobDto {
    if (!result) {
      return false
    }

    return (
      result?.id === this.jobId &&
      [
        'jobDescription',
        'baseCompensationHigh',
        'baseCompensationLow',
        'companyName',
        'jobTitle',
        'jobType',
        'location',
        'recruitingRoleTypeId'
      ].every((property) => property in result)
    )
  }

  public isLoading(): boolean {
    return this.loading
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getJob(): JobDto {
    return this.job
  }

  public handleTextChange<Key extends keyof JobDto>(property: Key, newVal: JobDto[Key]): void {
    if (property === 'recruitingRoleTypeId' && typeof newVal === 'string') {
      const role = this.recruitingRoleTypes.find(
        (role) => newVal.includes(role.description) && newVal.includes(role.role)
      )
      if (role) {
        this.job[property] = role.id as JobDto[Key]
      }
    } else if (property in this.job !== undefined) {
      if (property.includes('Compensation')) {
        // @ts-ignore
        this.job[property] = Number.parseInt(newVal, 10)
      } else {
        this.job[property] = newVal
      }
    }

    this.updateView()
  }

  public async updateJob(): Promise<void> {
    const url = `${serverBaseUrl}job/${this.job.id}`
    const result = await this.fetcher.fetch({
      method: 'PUT',
      url,
      body: this.job
    })

    if (this.isValidResponse(result)) {
      const successfulUpdateMessage = 'Successfully saved job!'

      this.wasSuccessfulSave = true
      this.resultMessage = successfulUpdateMessage
    } else {
      const errorUpdateMessage = `Unable to save job... ${result?.message ?? ''}`

      this.wasSuccessfulSave = false
      this.resultMessage = errorUpdateMessage
    }

    this.updateView()
  }

  private isValidResponse(result: any): boolean {
    if (result === null) {
      return false
    }
    return result?.id === this.jobId
  }

  public async deleteJob(): Promise<void> {
    const url = `${serverBaseUrl}jobs/${this.job.id}`
    const result = await this.fetcher.fetch({
      method: 'DELETE',
      url,
      body: null
    })

    if (result === okHttpCode) {
      this.resultMessage = 'Successfully deleted job. Please exit this page.'
      this.wasSuccessfulDelete = true
    } else {
      this.resultMessage = 'Unable to delete job at this time, try logging out and back in.'
      this.wasSuccessfulDelete = false
    }

    this.updateView()
  }

  public getRecruitingRoleTypes(): string[] {
    return this.recruitingRoleTypes.map((roleType) => `${roleType.role} (${roleType.description})`)
  }

  public async deleteJobPrompt(promptId: number): Promise<void> {
    this.loading = true
    this.updateView()

    await this.fetcher.fetch({
      method: 'DELETE',
      url: `${serverBaseUrl}job/${this.job.id}/requiredPrompt/promptId/${promptId}`,
      body: null
    })

    await this.initialize()
  }

  public isAddPromptOpen(): boolean {
    return this.isAddPromptWindowOpen
  }

  public handleCloseAddPromptWindow(): void {
    this.promptSearchQuery = ''
    this.isAddPromptWindowOpen = false
    this.showNewPromptForm = false
    this.updateView()
  }

  public handleAddPromptClicked(): void {
    this.isAddPromptWindowOpen = true
    this.updateView()
  }

  public updatePromptSearchQuery(value: string): void {
    this.promptSearchQuery = value
    this.updateView()
  }

  public getEligiblePrompts(): PromptDto[] {
    return this.eligiblePrompts.filter((prompt) =>
      prompt.prompt.toLowerCase().includes(this.promptSearchQuery.toLowerCase())
    )
  }

  public handlePromptSelection(prompt: PromptDto): void {
    this.promptSearchQuery = ''
    this.isAddPromptWindowOpen = false
    this.showNewPromptForm = false
    this.updateView()
    this.addPromptToJob(prompt)
    this.initialize()
  }

  private async addPromptToJob(prompt: PromptDto): Promise<void> {
    await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}job/${this.job.id}/requiredPrompt/${prompt.id}`,
      body: null
    })
  }

  public shouldShowNewPromptForm(): boolean {
    return this.showNewPromptForm
  }

  public openCreateNewPromptForm(): void {
    this.showNewPromptForm = true
    this.updateView()
  }

  public handleCloseNewPromptForm(): void {
    this.showNewPromptForm = false
    this.updateView()
  }

  public handlePromptValueUpdate(value: string): void {
    this.newPromptDto.prompt = value
    this.updateView()
  }

  public getNewPrompt(): string {
    return this.newPromptDto.prompt
  }

  public handleNicknameUpdate(value: string): void {
    this.newHeuristicDto.name = value
    this.updateView()
  }

  public getNewPromptNickname(): string {
    return this.newHeuristicDto.name
  }

  public updateNewPromptResponseType(value: ValidResponseType): void {
    this.newPromptDto.responseType = value
    this.updateView()
  }

  public getDisplayValueForNewPromptResponseType(): string {
    return this.newPromptDto.responseType
  }

  public async createNewPrompt(): Promise<void> {
    this.loading = true
    this.updateView()
    if (this.isInvalidInputForNewPrompt()) {
      this.loading = false
      this.failedPromptCreation = true
      this.updateView()
      return
    }

    this.newHeuristicDto.description = this.newPromptDto.prompt

    const eligiblePromptResult = await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}job/eligiblePrompt/add`,
      body: {
        heuristicDto: this.newHeuristicDto,
        promptDto: this.newPromptDto
      }
    })

    if (!eligiblePromptResult || !eligiblePromptResult.id) {
      this.loading = false
      this.failedPromptCreation = true
      this.updateView()
    } else {
      this.isAddPromptWindowOpen = false
      this.setNewPromptDtoAndHeuristicDtoToDefault()
      await this.addPromptToJob(eligiblePromptResult)
      this.initialize()
    }
  }

  private isInvalidInputForNewPrompt(): boolean {
    return (
      !this.newPromptDto.prompt ||
      this.newPromptDto.prompt === '' ||
      !this.newHeuristicDto.name ||
      this.newHeuristicDto.name === ''
    )
  }

  public updateUnit(value: string): void {
    if (value === 'no unit') {
      this.newHeuristicDto.unit = null
    } else {
      this.newHeuristicDto.unit = value
    }
    this.updateView()
  }

  public getDisplayLabelForUnit(): string {
    if (this.newHeuristicDto.unit === null) {
      return 'no unit'
    }
    return this.newHeuristicDto.unit
  }

  private setNewPromptDtoAndHeuristicDtoToDefault(): void {
    this.newPromptDto = {
      canBacktrackToPreviousPrompt: false,
      canSkipPrompt: false,
      dateRangeType: null,
      heuristicId: null,
      inputLabel: '',
      prompt: '',
      responseType: shortFreeResponseType
    }
    this.newHeuristicDto = {
      description: '',
      name: '',
      scoringMethod: null,
      unit: null,
      valueType: 'fact'
    }
  }
}

export default JobListingPresenter
