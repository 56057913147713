import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IMetricPerformanceGetter from './IMetricPerformanceGetter'
import MetricDto from './MetricDto'

export const METRICS_URL = `${serverBaseUrl}`
class MetricPerformanceGetter implements IMetricPerformanceGetter {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getMetricPerformance(metricId: number): Promise<any> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${METRICS_URL}user/${this.userId}/heuristic/${metricId}?type=latest`
    })

    if (this.isValidMetrics(result)) {
      return result
    }

    return null
  }

  private isValidMetrics(object: any): object is MetricDto {
    return object !== null && object !== undefined
  }
}

export default MetricPerformanceGetter
