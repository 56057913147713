import Cookies from 'js-cookie'

import AssessmentFinisher from '../../../APIs/AssessmentFinisher/AssessmentFinisher'
import AssessmentScoreFetcher from '../../../APIs/AssessmentScoreFetcher/AssessmentScoreFetcher'
import AssessmentStarter from '../../../APIs/AssessmentStarter/AssessmentStarter'
import CurrentStageUpdater from '../../../APIs/CurrentStageUpdater/CurrentStageUpdater'
import FirstPromptGetter from '../../../APIs/FirstPromptGetter/FirstPromptGetter'
import HeuristicGetter from '../../../APIs/HeuristicGetter/HeuristicGetter'
import HeuristicsUpdateHandler from '../../../APIs/HeuristicsUpdater/Dtos/HeuristicsUpdateHandler/HeuristicsUpdateHandler'
import HeuristicsDtoFactory from '../../../APIs/HeuristicsUpdater/HeuristicsDtoFactory/HeuristicsDtoFactory'
import HeuristicsUpdater from '../../../APIs/HeuristicsUpdater/HeuristicsUpdater'
import InProgressAssessmentGetter from '../../../APIs/InProgressAssessmentGetter/InProgressAssessmentGetter'
import NextPromptGetter from '../../../APIs/NextPromptGetter/NextPromptGetter'
import PromptGetter from '../../../APIs/PromptGetter/PromptGetter'
import PromptResponseSubmitter from '../../../APIs/PromptResponseSubmitter/PromptResponseSubmitter'
import RemainingNumberOfQuestionsGetter from '../../../APIs/RemainingNumberOfQuestionsGetter/RemainingNumberOfQuestionsGetter'
import InternalRoleTriggerer from '../../../APIs/TemporaryInteralRoleTriggerer/InternalRoleTriggerer'
import Fetcher from '../../../Drivers/Fetcher'
import InProgressAssessmentHandler from '../../../Handlers/InProgressAssessmentHandler/InProgressAssessmentHandler'
import NextPromptHandler from '../../../Handlers/NextPromptHandler/NextPromptHandler'
import PromptHandler from '../../../Handlers/PromptHandler/PromptHandler'
import ICookieGetter from '../../../localCommon/Interfaces/ICookieGetter'
import AuthTokenVerifier from '../../../localCommon/SignOut/AuthTokenVerifier/AuthTokenVerifier'
import SignOutHelper from '../../../localCommon/SignOut/SignOutHelper/SignOutHelper'
import assessmentStore from '../../../Models/AssessmentStore'
import IResponsePresenterStore from '../../Home/ResponsePresenterStore/IResponsePresenterStore'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import { dashboardRoute } from '../../RouteConstants'
import AssessmentDetailsMailer from '../AssessmentDetailsMailer/AssessmentDetailsMailer'
import NewAssessmentHandler from '../NewAssessmentHandler/NewAssessmentHandler'
import AssessmentPresenter from '../Presenter/AssessmentPresenter'
import DateRangeTypesGetter from '../Presenter/DateRangeTypesGetter/DateRangeTypesGetter'
import IAssessmentPresenter from '../Presenter/IAssessmentPresenter'
import PromptResponseDtoBuilder from '../Presenter/PromptResponseDtoBuilder/PromptResponseDtoBuilder'
import ResponseGetter from '../Presenter/ResponseGetter/ResponseGetter'
import IAssessmentPresenterFactory from './IAssessmentPresenterFactory'

class AssessmentPresenterFactory implements IAssessmentPresenterFactory {
  constructor(private readonly userId: number) {}

  public getAssessmentPresenter(
    assessmentId: number,
    store: IResponsePresenterStore,
    pathToGoToAfterAssessment: string = dashboardRoute
  ): IAssessmentPresenter & INavigationTerminator {
    return new AssessmentPresenter(
      new NewAssessmentHandler(
        new AssessmentStarter(new Fetcher(), this.userId),
        new FirstPromptGetter(new Fetcher())
      ),
      new PromptHandler(
        new PromptGetter(new Fetcher()),
        new HeuristicGetter(new Fetcher()),
        new RemainingNumberOfQuestionsGetter(new Fetcher())
      ),
      new NextPromptHandler(
        new PromptResponseSubmitter(new Fetcher()),
        new NextPromptGetter(new Fetcher()),
        new HeuristicGetter(new Fetcher()),
        new RemainingNumberOfQuestionsGetter(new Fetcher())
      ),
      new PromptResponseDtoBuilder(new DateRangeTypesGetter()),
      new ResponseGetter(store),
      new AuthTokenVerifier(
        {
          get: Cookies.get
        } as ICookieGetter,
        new SignOutHelper(Cookies, {
          setPathName: (path: string) => (window.location.pathname = path)
        })
      ),
      new AssessmentFinisher(
        new Fetcher(),
        this.userId,
        new AssessmentDetailsMailer(new Fetcher())
      ),
      assessmentStore,
      new InProgressAssessmentHandler(new InProgressAssessmentGetter(new Fetcher(), this.userId)),
      {
        setPathName: (path: string) => (window.location.pathname = path)
      },
      new CurrentStageUpdater(new Fetcher(), this.userId),
      new InternalRoleTriggerer(new Fetcher(), this.userId),
      new HeuristicsUpdateHandler(
        new HeuristicsDtoFactory(new DateRangeTypesGetter(), this.userId),
        new HeuristicsUpdater(new Fetcher(), this.userId)
      ),
      new AssessmentScoreFetcher(new Fetcher(), this.userId),
      assessmentId,
      pathToGoToAfterAssessment
    )
  }
}

export default AssessmentPresenterFactory
