import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import FactResponseDto from './FactResponseDto'
import IPromptResponseSubmitter from './IPromptResponseSubmitter'
import MeasureResponseDto from './MeasureResponseDto'
import PromptResultDto from './PromptResultDto'

export const failedToSubmitResponseMessage = 'Failed to submit response. Please try again.'
class PromptResponseSubmitter implements IPromptResponseSubmitter {
  constructor(private readonly fetcher: IFetcher) {}

  public async submitResponse(
    assessmentId: number,
    promptId: number,
    responseDto: MeasureResponseDto | FactResponseDto
  ): Promise<any> {
    const result = await this.fetcher.fetch({
      method: 'POST',
      body: responseDto,
      url: `${serverBaseUrl}assessment/${assessmentId}/prompt/${promptId}/response`
    })

    if (this.successfullySubmittedResponse(result, responseDto.userAssessmentHistoryId)) {
      return ''
    }

    return failedToSubmitResponseMessage
  }

  private successfullySubmittedResponse(
    result: object,
    assessmentHistoryId: number
  ): result is PromptResultDto {
    return (
      result?.hasOwnProperty('userAssessmentHistoryId') &&
      (result as PromptResultDto).userAssessmentHistoryId === assessmentHistoryId
    )
  }
}

export default PromptResponseSubmitter
