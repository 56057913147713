import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import AssessmentHistoryDto from './AssessmentHistoryDto'
import IInProgressAssessmentGetter from './IInProgressAssessmentGetter'

class InProgressAssessmentGetter implements IInProgressAssessmentGetter {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async getInProgressAssessment(assessmentId: number): Promise<AssessmentHistoryDto | null> {
    return this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${serverBaseUrl}user/${this.userId}/assessment/${assessmentId}/continue`
    })
  }
}

export default InProgressAssessmentGetter
