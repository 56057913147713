type ValidFreeResponseType = 'quota' | 'currOte' | 'pastOte' | 'income' | 'location' | 'linkedInUrl'
export const quotaFreeResponse = 'quota'
export const currentOteFreeResponse = 'currOte'
export const pastOteFreeResponse = 'pastOte'
export const incomeFreeResponse = 'income'
export const linkedInUrlFreeResponse = 'linkedInUrl'
export const locationFreeResponse = 'location'
export const validFreeResponseTypes = [
  quotaFreeResponse,
  currentOteFreeResponse,
  pastOteFreeResponse,
  locationFreeResponse,
  linkedInUrlFreeResponse
]
export default ValidFreeResponseType
