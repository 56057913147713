import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IAssessmentStarter from './IAssessmentStarter'
import StartAssessmentDto from './StartAssessmentDto'

export const failedToStartAssessmentMessage = 'Failed to start assessment. Please try again.'
export const PERFORMANCE_ASSESSMENT_ID = 4
export const PSYCHOMETRIC_ASSESSMENT_ID = 5
export const ALL_IN_ONE_ASSESSMENT_ID = 6
class AssessmentStarter implements IAssessmentStarter {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async startAssessment(assessmentId: number): Promise<StartAssessmentDto | string> {
    const result = await this.fetcher.fetch({
      method: 'PUT',
      body: null,
      url: `${serverBaseUrl}user/${this.userId}/assessment/${assessmentId}/start`
    })

    if (this.hasValidResponse(result)) {
      return {
        assessmentId: result.assessmentId,
        userAssessmentHistoryId: result.id
      }
    }

    return result?.message ?? failedToStartAssessmentMessage
  }

  private hasValidResponse(obj: any): boolean {
    return obj?.hasOwnProperty('assessmentId') && obj?.hasOwnProperty('id')
  }
}

export default AssessmentStarter
