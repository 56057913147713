import './style.scss'

import React, { Component, Fragment, ReactElement } from 'react'

import images from '../../images'
import Header from '../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import IMenuVisibilityManager from '../../localCommon/SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import IVisibilityManager from '../../localCommon/SlidingMenu/VisibilityManager/IVisibilityManager'
import CandidateRow from './CandidateRow/CandidateRow'
import CandidateFilter from './Filter/CandidateFilter'
import ICandidatePoolPresenter from './Presenter/ICandidatePoolPresenter'
import CandidatePoolDto from './Presenter/InterfacesAndDtos/CandidatePoolDto'

type Props = {
  filterVisibilityManager: IVisibilityManager
  menuVisibilityManager: IMenuVisibilityManager
  presenter: ICandidatePoolPresenter
}

class CandidatePool extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.initialize()
    this.props.menuVisibilityManager.setView(this)
    this.props.filterVisibilityManager.setView(this)
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.menuVisibilityManager.clearView()
    this.props.filterVisibilityManager.clearView()
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter, filterVisibilityManager } = this.props
    const candidates: CandidatePoolDto[] = presenter.getFilteredCandidates()
    const queryString = presenter.getSearchQueryString()

    return (
      <Fragment>
        <CandidateFilter
          visibilityManager={filterVisibilityManager}
          presenter={presenter.getFilterPresenter()}
        />
        <div className='pool blank-page-padding'>
          {this.renderHeader()}
          <p className='title-bold center-text'>{`Pool of ${candidates.length} candidates`}</p>
          <div className='search-bar'>
            <input
              type='text'
              placeholder='Search by candidate name...'
              value={queryString}
              onChange={(e) => this.setSearchQuery(e.target.value)}
              className='search-input'
            />
          </div>
          <table className='pool-table'>
            <thead>
              <tr className='pool-tableHeader'>
                <th className='pool-leftHeader'>
                  <div className='table-headerContentWithIcon'>
                    <div onClick={this.openFilter}>
                      <img alt='filter' className='pool-filter' src={images.filter} />
                    </div>
                    <p>Name</p>
                    <div className='pool-colDivider'></div>
                  </div>
                </th>
                <th className='pool-middleHeader'>
                  <div className='table-headerContent'>
                    <div className='pool-colDivider'></div>
                    <p>Role</p>
                  </div>
                </th>
                <th className='pool-rightHeader'>
                  <div className='table-headerContent'>
                    <p>Score</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='pool-tbody'>
              {candidates.map((candidate, idx) => (
                <CandidateRow candidate={candidate} key={idx} />
              ))}
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  }

  update = (): void => {
    this.setState({})
  }

  setSearchQuery = (query: string): void => {
    this.props.presenter.handleSearchQuery(query)
  }

  renderHeader = (): ReactElement => {
    const { menuVisibilityManager } = this.props

    return <Header menuVisibilityManager={menuVisibilityManager} />
  }

  filterCandidates = (): void => {
    this.props.menuVisibilityManager.close()
  }

  openFilter = (): void => {
    this.props.filterVisibilityManager.open()
  }

  hideCandidateInfo = (): void => {
    const { presenter, menuVisibilityManager } = this.props

    presenter.hideCandidateInfo()
    menuVisibilityManager.close()
  }

  showCandidateInfo = (): void => {
    const { presenter, menuVisibilityManager } = this.props

    presenter.showCandidateInfo()
    menuVisibilityManager.close()
  }
}

export default CandidatePool
