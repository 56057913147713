import INavigationPresenter from '../../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import onboardingQuestions from '../../../PersonalInfoData/OnboardingQuestions'
import INavigationTerminator from '../../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import IDataRowDataUpdater from '../../DataRow/DataRowDataUpdater/Interfaces/IDataRowDataUpdater'
import FreeResponseQuestionDto from '../../Dtos/FreeResponseQuestionDto'
import MultipleChoiceQuestionDto from '../../Dtos/MultipleChoiceQuestionDto'
import PicklistQuestionDto from '../../Dtos/PicklistQuestionDto'
import ValidPicklistResponseType from '../../Dtos/ValidPicklistResponseType'
import FreeResponsePresenter from '../../FreeResponse/Presenters/FreeResponsePresenter'
import IFreeResponsePresenter from '../../FreeResponse/Presenters/Interfaces/IFreeResponsePresenter'
import IPicklistResponsePresenter from '../../Picklist/Presenters/Interfaces/IPicklistResponsePresenter'
import PicklistResponsePresenter from '../../Picklist/Presenters/PicklistResponsePresenter'
import IResponsePresenterStore from '../../ResponsePresenterStore/IResponsePresenterStore'
import IMultipleChoicePresenter from '../../VerticalMultipleChoice/Presenters/Interfaces/IMultipleChoicePresenter'
import MultipleChoicePresenter from '../../VerticalMultipleChoice/Presenters/MultipleChoicePresenter'
import IResponsePresenterFactory from '../Interfaces/IResponsePresenterFactory'

class ResponsePresenterFactory implements IResponsePresenterFactory {
  constructor(
    private readonly navigationPresenter: INavigationPresenter & INavigationTerminator,
    private readonly presenterStore: IResponsePresenterStore,
    private readonly dataRowDataUpdater: IDataRowDataUpdater
  ) {}

  public getNavigationPresenter(): INavigationPresenter & INavigationTerminator {
    return this.navigationPresenter
  }

  public getPicklistResponsePresenters(
    questionIndex: number
  ): Map<string, IPicklistResponsePresenter> {
    const question = onboardingQuestions[questionIndex] as PicklistQuestionDto

    const idToPresenter = new Map()

    if (question?.lists?.length) {
      question.lists.forEach((list, index) => {
        if (this.presenterStore.has(list.listId)) {
          idToPresenter.set(index.toString(), this.presenterStore.getPresenterById(list.listId))
        } else {
          const presenter = new PicklistResponsePresenter(
            question,
            list.listId as ValidPicklistResponseType,
            questionIndex,
            list,
            this.dataRowDataUpdater
          )
          this.presenterStore.setPresenter(list.listId, presenter)

          idToPresenter.set(index.toString(), presenter)
        }
      })
    }

    return idToPresenter
  }

  public getFreeResponsePresenter(questionIndex: number): IFreeResponsePresenter {
    const question = onboardingQuestions[questionIndex] as FreeResponseQuestionDto
    if (this.presenterStore.has(question.uniqueId)) {
      return this.presenterStore.getPresenterById(question.uniqueId) as IFreeResponsePresenter
    }

    const presenter = new FreeResponsePresenter(question, questionIndex, this.dataRowDataUpdater)
    this.presenterStore.setPresenter(question.uniqueId, presenter)
    return presenter
  }

  public getMultipleChoicePresenter(questionIndex: number): IMultipleChoicePresenter {
    const question = onboardingQuestions[questionIndex] as MultipleChoiceQuestionDto
    if (this.presenterStore.has(question.title)) {
      return this.presenterStore.getPresenterById(question.title) as IMultipleChoicePresenter
    }

    const presenter = new MultipleChoicePresenter(question, questionIndex, question?.isRequired)
    this.presenterStore.setPresenter(question.title, presenter)
    return presenter
  }
}

export default ResponsePresenterFactory
