import ICandidateProfileFetcher from '../../../../APIs/CandidateProfileFetcher/ICandidateProfileFetcher'
import IMarketCompensationDataGetter from '../../../../APIs/MarketCompensationDataGetter/IMarketCompensationDataGetter'
import IHomeMetricPerformanceHandlerFactory from '../../../../Handlers/HomeMetricPerformanceHandler/HomeMetricPerformanceHandlerFactory/IHomeMetricPerformanceHandlerFactory'
import ICandidateProfile from '../../../../Models/Interfaces/ICandidateProfile'
import ICandidateStore from '../../../../Models/Interfaces/ICandidateStore'
import ICandidateDataHandler from './ICandidateDataHandler'

class CandidateDataHandler implements ICandidateDataHandler {
  constructor(
    private readonly marketCompensationDataGetter: IMarketCompensationDataGetter,
    private readonly metricHandlerFactory: IHomeMetricPerformanceHandlerFactory,
    private readonly profileFetcher: ICandidateProfileFetcher,
    private readonly store: ICandidateStore
  ) {}

  public async fetchCandidateData(userId: number): Promise<ICandidateProfile> {
    const cachedProfile = this.store.getProfileById(userId)
    if (cachedProfile) {
      return cachedProfile
    }

    const marketData = await this.marketCompensationDataGetter.getMarketCompensationData(userId)
    const performanceHandler = this.metricHandlerFactory.getPerformanceHandler(userId)
    const performance = await performanceHandler.getHomeMetricData()
    const profile = await this.profileFetcher.getCandidateProfile(userId)
    const candidate = {
      marketData: typeof marketData === 'string' ? null : marketData,
      profile,
      metrics: performance
    }
    this.store.setProfile(userId, candidate)

    return candidate
  }
}

export default CandidateDataHandler
