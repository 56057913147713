import IHeuristicsDtoFactory from '../../HeuristicsDtoFactory/IHeuristicsDtoFactory'
import IHeuristicsUpdater from '../../IHeuristicsUpdater'
import ValidMeasureDateRangeType from '../ValidMeasureDateRangeTypes'
import IHeuristicsUpdateHandler from './IHeuristicsUpdateHandler'

class HeuristicsUpdateHandler implements IHeuristicsUpdateHandler {
  constructor(
    private readonly dtoFactory: IHeuristicsDtoFactory,
    private readonly heuristicsUpdater: IHeuristicsUpdater
  ) {}

  public async updateFactHeuristicsValue(id: number, value: string): Promise<string> {
    return this.heuristicsUpdater.updateHeuristicsValue(id, this.dtoFactory.getFactDto(id, value))
  }

  public async updateMeasureHeuristicsValue(
    id: number,
    value: number,
    dateRangeType: ValidMeasureDateRangeType
  ): Promise<string> {
    return this.heuristicsUpdater.updateHeuristicsValue(
      id,
      this.dtoFactory.getMeasureDto(id, value, dateRangeType)
    )
  }
}

export default HeuristicsUpdateHandler
