import AssessmentScoreFetcher from '../../../APIs/AssessmentScoreFetcher/AssessmentScoreFetcher'
import UserStageFetcher from '../../../APIs/UserStageFetcher/UserStageFetcher'
import Fetcher from '../../../Drivers/Fetcher'
import IUserProfilePresenter from './Interfaces/IUserProfilePresenter'
import UserProfilePresenter from './UserProfilePresenter'

class UserProfilePresenterFactory {
  public static buildPresenter(userId: number): IUserProfilePresenter {
    return new UserProfilePresenter(
      new UserStageFetcher(new Fetcher(), userId),
      new AssessmentScoreFetcher(new Fetcher(), userId)
    )
  }
}

export default UserProfilePresenterFactory
