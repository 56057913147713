import IAssessmentStarter from '../../../APIs/AssessmentStarter/IAssessmentStarter'
import AssessmentDtoWithUserAssessmentHistory from '../../../APIs/FirstPromptGetter/AssessmentDtoWithUserAssessmentHistory'
import IFirstPromptGetter from '../../../APIs/FirstPromptGetter/IFirstPromptGetter'
import INewAssessmentHandler from './INewAssessmentHandler'

class NewAssessmentHandler implements INewAssessmentHandler {
  constructor(
    private readonly assessmentStarter: IAssessmentStarter,
    private readonly firstPromptGetter: IFirstPromptGetter
  ) {}

  public async startNewAssessmentToGetFirstPromptId(
    assessmentId: number
  ): Promise<string | AssessmentDtoWithUserAssessmentHistory> {
    const result = await this.assessmentStarter.startAssessment(assessmentId)

    if (typeof result === 'string') {
      return result
    }

    const firstPrompt = await this.firstPromptGetter.getFirstPromptIdByAssessmentId(
      result.assessmentId
    )
    if (typeof firstPrompt === 'string') {
      return firstPrompt
    }

    return {
      ...firstPrompt,
      userAssessmentHistoryId: result.userAssessmentHistoryId
    }
  }
}

export default NewAssessmentHandler
