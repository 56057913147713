import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import { manualUploadId } from '../../localCommon/ValidUploadMethod'
import ICurrentStageUpdater from './ICurrentStageUpdater'

class CurrentStageUpdater implements ICurrentStageUpdater {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async updateUserStage(heuristicId: number): Promise<string> {
    const result = await this.fetcher.fetch({
      method: 'PUT',
      body: {
        heuristicId,
        uploadMethodId: manualUploadId,
        userId: this.userId,
        value: 'completed'
      },
      url: `${serverBaseUrl}user/${this.userId}/heuristic/${heuristicId}`
    })

    if (result?.hasOwnProperty('message')) {
      return result.message
    }

    return ''
  }
}

export default CurrentStageUpdater
