import './style.scss'

import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Header from '../../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../../localCommon/LoadingSpinner/LoadingSpinner'
import MenuVisibilityManager from '../../../localCommon/SlidingMenu/MenuVisibilityManager/MenuVisibilityManager'
import IResumeUploadPresenter from '../../EditAccount/Resume/Presenter/IResumeUploadPresenter'
import {
  freeResponsePercentageType,
  numericFreeResponseType
} from '../../Home/Dtos/ValidResponseType'
import { candidateViewJobBoardRoute } from '../../RouteConstants'
import IApplyToJobPresenter from './InterfacesAndDtos/IApplyToJobPresenter'
import IQuestionnaireFormPresenter from './JobSpecificQuestionnaire/IQuestionnaireFormPresenter'

type Props = {
  menuVisibilityManager: MenuVisibilityManager
  presenter: IApplyToJobPresenter & IResumeUploadPresenter & IQuestionnaireFormPresenter
}

class ApplyToJob extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  render(): ReactElement {
    const { presenter, menuVisibilityManager } = this.props
    const jobHeader = presenter.getJobHeader()
    const ote = presenter.getJobOTERange()
    const location = presenter.getJobLocation()
    const workType = presenter.getJobWorkType()
    const assessmentIsCompleted = presenter.isAssessmentCompleted()
    const promptsAreCompleted = presenter.arePromptsCompleted()
    const hasResume = presenter.hasResume()
    const isReadyToApply = presenter.isReadyToApply()
    const errorMessage = presenter.getErrorMessage()
    const successMessage = presenter.getSuccessMessage()
    const isResumeLoading = presenter.isResumeLoading()
    const showQuestionnaire = presenter.shouldShowQuestionnaire()
    const applicationErrMessage = presenter.getErrorMessageForApplication()
    const userHasAlreadyApplied = presenter.hasUserAppliedToJob()
    const applySuccessMessage = presenter.getSuccessfulApplicationMessage()

    if (presenter.isLoading()) {
      return this.renderLoading()
    }

    return (
      <div className='apply-to-job-page'>
        <div className='header-wrapper'>
          <Header menuVisibilityManager={menuVisibilityManager} />
        </div>

        <div className='back-button'>
          <Link to={candidateViewJobBoardRoute}>
            <div className='back-to-job-board'>
              <span className='chevron-left'>{'<'}</span>
              <span className='back-text'>Back to Job Board</span>
            </div>
          </Link>
        </div>

        {showQuestionnaire && this.renderQuestionnaireForm()}

        <div className='job-header'>
          <h1>{jobHeader}</h1>
          <div className='sub-header'>
            <div className='ote-range'>OTE: {ote}</div>
            <div className='location-work-type'>
              {location} &#183; {workType}
            </div>
          </div>
          <br />
          <div className='gradient-line'></div>
        </div>

        <div className='application-steps'>
          <h3>Application Process</h3>
          <div className='steps'>
            <div className={`step ${assessmentIsCompleted ? 'completed-step' : ''}`}>
              <span className='step-title'>Online Assessment</span>
              {assessmentIsCompleted ? (
                <span className='step-completed-text'>
                  Completed {presenter.getAssessmentCompletedDateString()}
                </span>
              ) : (
                <button type='button' className='step-action-button' onClick={this.startAssessment}>
                  Start
                </button>
              )}
            </div>
            {presenter.hasRequiredPrompts() && (
              <div className={`step ${promptsAreCompleted ? 'completed-step' : ''}`}>
                <span className='step-title'>Questionnaire</span>
                {promptsAreCompleted ? (
                  <span className='step-completed-text'>
                    Completed {presenter.getQuestionnaireCompletedDateString()}
                  </span>
                ) : (
                  <button
                    type='button'
                    className='step-action-button'
                    onClick={this.toggleQuestionnaireModal}
                  >
                    Start
                  </button>
                )}
              </div>
            )}
            <div className={`step ${hasResume ? 'completed-step' : ''}`}>
              <span className='step-title'>
                Resumé {hasResume ? `- ${presenter.getResumeName()}` : ''}
              </span>
              {isResumeLoading ? this.renderLoading() : this.renderResumeUpload()}
            </div>
            {hasResume && errorMessage && (
              <span className='error mdc-text-field-helper-text'>{errorMessage}</span>
            )}

            {hasResume && (
              <span className='success mdc-text-field-helper-text'>{successMessage}</span>
            )}
            {applicationErrMessage !== null && (
              <span className='error mdc-text-field-helper-text'>{applicationErrMessage}</span>
            )}
            {userHasAlreadyApplied && (
              <span className='success mdc-text-field-helper-text'>
                {'You have already applied for this job!'}
              </span>
            )}
            {applySuccessMessage && (
              <span className='success mdc-text-field-helper-text'>{applySuccessMessage}</span>
            )}
            <div className='apply-now-button-container'>
              <button
                type='button'
                className={`apply-now-button ${!isReadyToApply ? 'disabled' : ''}`}
                disabled={!isReadyToApply}
                onClick={this.submitApplication}
              >
                Submit application
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderLoading(): ReactElement {
    return <LoadingSpinner />
  }

  private renderResumeUpload(): ReactElement {
    const { presenter } = this.props
    const hasResume = presenter.hasResume()
    const resumeName = presenter.getResumeName()

    return hasResume ? (
      <React.Fragment>
        <span className='resume-name'>{resumeName}</span>
        <div className='upload-wrapper'>
          <label className='upload-new-resume' htmlFor='resume-upload'>
            Upload new
          </label>
          <span className='step-completed-text'>Uploaded</span>
          <input
            type='file'
            id='resume-upload'
            className='resume-upload-input'
            onChange={this.uploadResume}
            accept='.pdf,.doc,.docx,.rtf,.odt,.txt'
          />
        </div>
      </React.Fragment>
    ) : (
      <div className='upload-wrapper'>
        <label className='upload-new-resume' htmlFor='resume-upload'>
          Upload
        </label>
        <input
          type='file'
          id='resume-upload'
          className='resume-upload-input'
          onChange={this.uploadResume}
          accept='.pdf,.doc,.docx,.rtf,.odt,.txt'
        />
      </div>
    )
  }

  private renderQuestionnaireForm(): ReactElement {
    const { presenter } = this.props
    const prompts = presenter.getPrompts()
    const canSubmit = presenter.canSubmitQuestionnaire()

    return (
      <div className='modal'>
        <div className='modal-content'>
          <span className='close-button' onClick={this.toggleQuestionnaireModal}>
            &times;
          </span>
          <h2>Questionnaire</h2>
          <form className='questionnaire-form' onSubmit={this.submitQuestionnaireResponses}>
            {prompts.map((prompt, index) => (
              <div key={index} className='form-group'>
                <label htmlFor={`prompt-${index}`}>{prompt.prompt}</label>
                {this.renderInputField(prompt)}
              </div>
            ))}
            <button
              type='submit'
              className={`submit-button ${!canSubmit ? 'disabled' : ''}`}
              disabled={!canSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  }

  private submitQuestionnaireResponses = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    this.props.presenter.submitQuestionnaireResponses()
  }

  private renderInputField(prompt: any): ReactElement {
    switch (prompt.responseType) {
      case 'free_response_long':
        return (
          <textarea
            id={`prompt-${prompt.id}`}
            name={`prompt-${prompt.id}`}
            className='form-control form-control-long'
            value={prompt.response ?? ''}
            onChange={(e) => this.handleModalTextAreaInput(prompt.id, e)}
          />
        )
      case numericFreeResponseType:
        return (
          <div className='numeric-input-wrapper'>
            {prompt.unit !== null && <span className='unit-symbol'>{prompt.unit}</span>}
            <input
              type='number'
              id={`prompt-${prompt.id}`}
              name={`prompt-${prompt.id}`}
              className='form-control'
              value={prompt.response ?? ''}
              onChange={(e) => this.handleModalInput(prompt.id, e)}
            />
          </div>
        )
      case freeResponsePercentageType:
        return (
          <div className='numeric-input-wrapper'>
            <input
              type='number'
              id={`prompt-${prompt.id}`}
              name={`prompt-${prompt.id}`}
              className='form-control percentage-input'
              value={prompt.response ?? ''}
              onChange={(e) => this.handleModalInput(prompt.id, e)}
            />
            <span className='unit-symbol'>%</span>
          </div>
        )

      default:
        // 'free_response_short' or fallback
        return (
          <input
            type='text'
            id={`prompt-${prompt.id}`}
            name={`prompt-${prompt.id}`}
            className='form-control'
            value={prompt.response ?? ''}
            onChange={(e) => this.handleModalInput(prompt.id, e)}
          />
        )
    }
  }

  private uploadResume = ({ target }: { target: HTMLInputElement }): void => {
    const { presenter } = this.props
    if (target?.validity?.valid && target.files) {
      const files = Array.from(target.files)

      if (files && files.length) {
        presenter.validateThenUploadFile(files[0] as any)
      }
    } else {
      presenter.setErrorMessage('Invalid file')
    }
  }

  private toggleQuestionnaireModal = (): void => {
    const { presenter } = this.props
    presenter.toggleQuestionnaireForm()
  }

  private handleModalTextAreaInput = (
    promptId: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const { value } = event.target
    this.props.presenter.handlePromptInput(promptId, value)
  }

  private handleModalInput = (
    promptId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target

    this.props.presenter.handlePromptInput(promptId, value)
  }

  private submitApplication = (): void => {
    this.props.presenter.submitApplication()
  }

  private startAssessment = (): void => {
    this.props.presenter.startAssessment()
  }

  public update(): void {
    this.setState({})
  }
}

export default ApplyToJob
