import React, { Component, ReactElement } from 'react'

import OutlinedButton from '../Button/OutlinedButton'

type Props = {
  label: string
  route?: string
}

class BackToPreviousPage extends Component<Props> {
  render(): ReactElement {
    const { label } = this.props

    return <OutlinedButton onClick={this.backToPreviousPage}>{label}</OutlinedButton>
  }

  backToPreviousPage = (): void => {
    if (this.props.route) {
      window.location.href = this.props.route
    } else {
      window.history.back()
    }
  }
}

export default BackToPreviousPage
