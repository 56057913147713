/* eslint-disable import/no-extraneous-dependencies */
import './index.scss'

import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import { FLAGSMITH_ENV_ID } from './localCommon/constant'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <FlagsmithProvider
    options={{
      environmentID: FLAGSMITH_ENV_ID
    }}
    flagsmith={flagsmith}
  >
    <App />
  </FlagsmithProvider>
)
