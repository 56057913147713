import './ContainedButton.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  id?: string
  isButtonDisabled?: boolean
  onClick?(event: React.MouseEvent): void
  type: 'submit' | 'reset' | 'button'
}

class ContainedButton extends Component<Props> {
  render(): ReactElement {
    const { children, className, id, onClick, type, isButtonDisabled } = this.props

    return (
      <button
        className={`mdc-button mdc-button--raised ${className}`}
        id={id}
        onClick={onClick}
        type={type}
        disabled={isButtonDisabled || false}
      >
        <span className='mdc-button__label'>{children}</span>
      </button>
    )
  }
}

export default ContainedButton
