import './style.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  progress: number
}

class Progressbar extends Component<Props> {
  render(): ReactElement {
    const { progress } = this.props

    return (
      <div className='progressBar-container'>
        <div className='progressBar' style={{ width: `${progress}%` }}></div>
      </div>
    )
  }
}

export default Progressbar
