/* eslint-disable no-restricted-syntax */
import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import List from '../../../localCommon/List/List'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import PickListDto from '../Dtos/PickListDto'
import NavigationButtonGroup from '../NavigationButtonGroup'
import IPicklistResponsePresenter from './Presenters/Interfaces/IPicklistResponsePresenter'

type Props = {
  description: string
  elementId: string
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenters: Map<string, IPicklistResponsePresenter>
  title: string
}

class Picklist extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    for (const [, presenter] of this.props.presenters) {
      presenter.setView(this)
    }
  }

  componentDidUpdate(): void {
    for (const [, presenter] of this.props.presenters) {
      presenter.setView(this)
    }
  }

  componentWillUnmount(): void {
    for (const [, presenter] of this.props.presenters) {
      presenter.clearView()
    }
  }

  render(): ReactElement {
    const { description, navigationPresenter, title } = this.props
    const firstPresenter = Array.from(this.props.presenters.values())[0]

    return (
      <div className='picklist'>
        <div className='home-submit'>
          <p className='headline6'>{title}</p>
          <div className='body1'>{description}</div>
        </div>
        <div className='picklist-listWrapper'>{this.renderLists()}</div>
        <NavigationButtonGroup
          canBacktrackToPreviousPrompt={navigationPresenter.isBackTrackAllowed()}
          hasValidInput={this.hasValidInput()}
          isRequired={firstPresenter.isRequiredQuestion()}
          presenter={navigationPresenter}
          subscriber={this}
        />
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  renderLists = (): ReactElement[] => {
    const { elementId, presenters } = this.props
    const listElements = []

    for (const [index, presenter] of presenters) {
      const list: PickListDto = presenter.getPicklist()

      listElements.push(
        <List
          elementId={`${elementId}-${index}`}
          error={presenter.getValue() ? '' : presenter.getInlineError()}
          key={list.listId}
          id={index}
          isTypingAllowed={presenter.isTypingAllowed()}
          isRequired={presenter.isRequiredQuestion()}
          label={list.label}
          list={list.list}
          searchInput={this.getSearchInput(index)}
          selectedValue={this.getSelectedValue(index)}
          shouldShowErrorMessage={presenter.shouldShowError()}
          type='text'
          updateSelectedOption={this.updateSearchInput}
          updateSearchInput={this.updateSearchInput}
        />
      )
    }

    return listElements
  }

  getSearchInput = (elementId: string): string => {
    const presenter = this.props.presenters.get(elementId)
    return presenter?.getSearchInput() ?? ''
  }

  getSelectedValue = (elementId: string): string => {
    const presenter = this.props.presenters.get(elementId)
    return presenter?.getValue() ?? ''
  }

  hasValidInput = (): boolean => {
    for (const [, presenter] of this.props.presenters) {
      if (!presenter.getValue()) {
        return false
      }
    }

    return true
  }

  showErrorMessage = (): void => {
    for (const [, presenter] of this.props.presenters) {
      presenter.showErrorMessage()
    }
  }

  updateSearchInput = (input: string, elementId: string): void => {
    const presenter = this.props.presenters.get(elementId)
    if (presenter) {
      presenter.updateSearchInput(input ?? '')
    }
  }

  updateValue = (input: string, elementId: string): void => {
    const presenter = this.props.presenters.get(elementId)
    if (presenter) {
      presenter.updateInput(input ?? '')
    }
  }
}

export default Picklist
