import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IAssessmentRowPresenter from './InterfacesAndDtos/IAssessmentRowPresenter'

type Props = {
  presenter: IAssessmentRowPresenter
}

class AssessmentRow extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  render(): ReactElement {
    const { presenter } = this.props
    const assessment = presenter.getAssessment()
    const { scoreDto } = assessment
    const { isOpen } = presenter

    return (
      <div className='assessment-row-container'>
        <div className='assessment-row' onClick={this.handleClick}>
          <div>
            Assessment: {presenter.getTitle() || assessment.assessmentId} | Score:{' '}
            {scoreDto.averageScore.toFixed(2)} | Completed:{' '}
            {assessment.scoreCalculationTimestamp.split('T')[0]}
          </div>
        </div>
        {isOpen && (
          <div className='prompts'>
            {scoreDto.prompts.map((prompt, index) => (
              <div key={index} className='prompt'>
                <div className='prompt-question'>{prompt.prompt}</div>
                <div className='prompt-response'>Response: {prompt.response.responseValue}</div>
                <div className='prompt-score'>Score: {prompt.score}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  handleClick = () => {
    this.props.presenter.handleClick()
  }
}

export default AssessmentRow
