import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { Component, ReactElement } from 'react'

import DateHelper from '../../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IHeuristicSectionPresenter from './InterfacesAndDtos/IHeuristicSectionPresenter'

type Props = {
  presenter: IHeuristicSectionPresenter
}

class HeuristicSection extends Component<Props> implements ISubscribingView {
  public componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  public render(): ReactElement {
    const { presenter } = this.props
    const values = presenter.getValues()

    return (
      <div className='section-with-border'>
        <div className='heuristics-section' onClick={this.handleClick}>
          <p className='subtitle2'>All Candidate Data </p>
          <br></br>
          {presenter.isOpen && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Last Modified Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value) => (
                  <TableRow key={value.heuristicId ?? 'No Data'}>
                    <TableCell>{value.name ?? 'No Data'}</TableCell>
                    <TableCell>{value.value ?? 'No Data'}</TableCell>
                    <TableCell>{this.getLastModifiedDate(value.lastModifiedDate)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    )
  }

  public update(): void {
    this.setState({})
  }

  handleClick = () => {
    this.props.presenter.handleToggle()
  }

  private getLastModifiedDate(date: string | null): string {
    return date ? DateHelper.getYyyyMmDdString(new Date(date)) : 'No Date'
  }
}

export default HeuristicSection
