import * as amplitude from '@amplitude/analytics-browser'
import Cookies from 'js-cookie'

import { AMPLITUDE_APP_KEY } from '../../localCommon/constant'
import { emailKey } from '../../localCommon/CookieKeys'
import IFetcher from '../Interfaces/IFetcher'
import UsageDto from './AmplitudeUsageDto'
import IAmplitudeUsageTracker from './IAmplitudeUsageTracker'

export const USAGE_REPORT_URL = 'https://api2.amplitude.com/2/httpapi'
export const dbUserIdLength = 1

class AmplitudeUsageTracker implements IAmplitudeUsageTracker {
  constructor(private readonly fetcher: IFetcher) {}

  public static track(eventName: string): void {
    amplitude.init(AMPLITUDE_APP_KEY)
    const email = Cookies.get(emailKey)
    if (email) {
      amplitude.setUserId(email)
    }
    amplitude.track(eventName)
  }

  public static setUserId(email: string): void {
    amplitude.setUserId(email)
  }

  public async sendUsageReport(events: UsageDto[]): Promise<void> {
    await this.fetcher.fetch({
      method: 'POST',
      body: {
        api_key: AMPLITUDE_APP_KEY,
        events,
        options: {
          min_id_length: dbUserIdLength
        }
      },
      url: USAGE_REPORT_URL
    })
  }
}

export default AmplitudeUsageTracker
