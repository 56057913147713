import React, { Component, ReactElement } from 'react'

type Props = {
  onClick(event: React.MouseEvent): void
  text: string
}

class TextButton extends Component<Props> {
  render(): ReactElement {
    const { onClick, text } = this.props

    return (
      <button className='mdc-button textButton' onClick={onClick}>
        <span className='mdc-button__label' id='textButton'>
          {text}
        </span>
      </button>
    )
  }
}

export default TextButton
