import DateHelper from '../../../localCommon/DateHelper/DateHelper'
import { manualUploadId } from '../../../localCommon/ValidUploadMethod'
import IDateRangeTypesGetter from '../../../Routes/Assessment/Presenter/DateRangeTypesGetter/IDateRangeTypesGetter'
import FactDto from '../Dtos/FactDto'
import MeasureDto from '../Dtos/MeasureDto'
import ValidMeasureDateRangeType from '../Dtos/ValidMeasureDateRangeTypes'
import IHeuristicsDtoFactory from './IHeuristicsDtoFactory'

class HeuristicsDtoFactory implements IHeuristicsDtoFactory {
  constructor(
    private readonly dateRangeTypesHandler: IDateRangeTypesGetter,
    private readonly userId: number
  ) {}

  public getFactDto(heuristicId: number, value: string): FactDto {
    return {
      heuristicId,
      lastModifiedDate: DateHelper.getYyyyMmDdString(),
      uploadDate: DateHelper.getYyyyMmDdString(),
      uploadMethodId: manualUploadId,
      userId: this.userId,
      value
    }
  }

  public getMeasureDto(
    heuristicId: number,
    value: number,
    dateRangeType: ValidMeasureDateRangeType
  ): MeasureDto {
    const dateRange = this.dateRangeTypesHandler.getDateRange(dateRangeType)

    return {
      endDate: dateRange.endDate,
      startDate: dateRange.startDate,
      heuristicId,
      lastModifiedDate: DateHelper.getYyyyMmDdString(),
      uploadDate: DateHelper.getYyyyMmDdString(),
      uploadMethodId: manualUploadId,
      userId: this.userId,
      value
    }
  }
}

export default HeuristicsDtoFactory
