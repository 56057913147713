import AuthDto from '../../../../APIs/Dtos/AuthDto'
import ILogInAuthenticator from '../../../../APIs/LogInAuthenticator/ILogInAuthenticator'
import { userLoginEvent } from '../../../../Drivers/UsageTracker/AmplitudeEventConstants'
import AmplitudeUsageTracker from '../../../../Drivers/UsageTracker/AmplitudeUsageTracker'
import AdminHandler from '../../../../Handlers/AdminHandler'
import {
  assessmentIdKey,
  authTokenKey,
  emailKey,
  userIdKey
} from '../../../../localCommon/CookieKeys'
import ICookieSetter from '../../../../localCommon/Interfaces/ICookieSetter'
import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import IWindowPathUpdater from '../../../../localCommon/Interfaces/IWindowPathUpdater'
import StringHelper from '../../../../localCommon/StringHelper/StringHelper'
import { dashboardRoute } from '../../../RouteConstants'
import { invalidEmailErrorMessage } from '../../../SignUp/Presenter/SignUpPresenter'
import ILogInPresenter from './ILogInPresenter'

class LogInPresenter implements ILogInPresenter {
  private email: string
  private errorMessage: string
  private password: string
  private view: ISubscribingView | null

  constructor(
    private readonly authenticator: ILogInAuthenticator,
    private readonly cookieSetter: ICookieSetter,
    private readonly windowPathUpdater: IWindowPathUpdater
  ) {
    this.email = ''
    this.errorMessage = ''
    this.password = ''
    this.view = null
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getEmail(): string {
    return this.email
  }

  public getPassword(): string {
    return this.password
  }

  public updateEmail(input: string): void {
    this.email = input
    if (StringHelper.isValidEmail(this.email)) {
      this.errorMessage = ''
    } else {
      this.errorMessage = invalidEmailErrorMessage
    }

    this.updateView()
  }

  public updatePassword(input: string): void {
    this.password = input
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public async login(): Promise<void> {
    const result = await this.authenticator.login(this.email, this.password)

    if (this.isValidResponse(result)) {
      this.errorMessage = ''
      this.cookieSetter.set(authTokenKey, result.auth.token, { expires: 1 })
      this.cookieSetter.set(userIdKey, result.userData.userId?.toString(), { expires: 1 })
      this.cookieSetter.set(emailKey, result.userData.email, { expires: 1 })
      this.cookieSetter.set(
        assessmentIdKey,
        result.userData.assessment.assessmentId?.toString() ?? null,
        {
          expires: 1
        }
      )

      if (AdminHandler.isDataBasedAdmin()) {
        this.windowPathUpdater.setPathName(dashboardRoute)
      } else {
        this.windowPathUpdater.setPathName('/')
      }
      AmplitudeUsageTracker.track(userLoginEvent)
    } else {
      this.errorMessage = result?.message
      this.updateView()
    }
  }

  private isValidResponse(result: any): result is AuthDto {
    return (
      result.hasOwnProperty('auth') &&
      result.auth.hasOwnProperty('token') &&
      result.hasOwnProperty('userData')
    )
  }
}

export default LogInPresenter
