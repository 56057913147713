import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../images'

type Props = {
  direction: 'right' | 'left'
  onClick(): void
}

class BlackNavigation extends Component<Props> {
  render(): ReactElement {
    const { direction } = this.props

    return (
      <div className='navigationChevron'>
        <img
          alt='navigation-chevron'
          src={images.blackArrow}
          style={{
            transform: direction === 'left' ? 'scaleX(-1)' : ''
          }}
          onClick={this.clickButton}
        />
      </div>
    )
  }

  clickButton = (): void => {
    this.props.onClick()
  }
}

export default BlackNavigation
