import ValidMeasureDateRangeType, {
  currentYear,
  MTDDateRange,
  pastYearDateRange,
  QTDDateRange,
  YTDDateRange
} from '../../../../APIs/HeuristicsUpdater/Dtos/ValidMeasureDateRangeTypes'
import PromptDto from '../../../../APIs/PromptGetter/PromptDto'
import FactResponseDto from '../../../../APIs/PromptResponseSubmitter/FactResponseDto'
import MeasureResponseDto from '../../../../APIs/PromptResponseSubmitter/MeasureResponseDto'
import { manualUploadId } from '../../../../localCommon/ValidUploadMethod'
import IDateRangeTypesGetter from '../DateRangeTypesGetter/IDateRangeTypesGetter'
import IPromptResponseDtoBuilder from './IPromptResponseDtoBuilder'

class PromptResponseDtoBuilder implements IPromptResponseDtoBuilder {
  constructor(private readonly dateRangeHandler: IDateRangeTypesGetter) {}

  public buildPromptResponseDto(
    currentPrompt: PromptDto,
    value: string,
    userAssessmentHistoryId: number
  ): MeasureResponseDto | FactResponseDto {
    const responseDto: FactResponseDto = {
      value,
      heuristicId: currentPrompt.heuristicId,
      uploadMethodId: manualUploadId,
      userAssessmentHistoryId
    }

    if (this.isValidDateRangeType(currentPrompt.dateRangeType)) {
      const dateRange = this.dateRangeHandler.getDateRange(
        currentPrompt.dateRangeType as ValidMeasureDateRangeType
      )

      return {
        endDate: dateRange.endDate as string,
        startDate: dateRange.startDate as string,
        ...responseDto
      }
    }

    return responseDto
  }

  private isValidDateRangeType(dateRangeType: any): boolean {
    return !![currentYear, YTDDateRange, pastYearDateRange, MTDDateRange, QTDDateRange].includes(
      dateRangeType
    )
  }
}

export default PromptResponseDtoBuilder
