import './style.scss'

import React, { Component, ReactElement } from 'react'

import ICommonObject from '../../../localCommon/Interfaces/ICommonObject'
import ICommonObjectWithSortId from '../../../localCommon/Interfaces/ICommonObjectWithSortId'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import NavigationButtonGroup from '../../Home/NavigationButtonGroup'
import IMultipleChoicePresenter from '../../Home/VerticalMultipleChoice/Presenters/Interfaces/IMultipleChoicePresenter'
import INavigationPresenter from '../../PersonalInfoData/NavigationPresenter/INavigationPresenter'
import INavigationTerminator from '../../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'

type Props = {
  navigationPresenter: INavigationPresenter & INavigationTerminator
  presenter: IMultipleChoicePresenter
}

class HorizontalMultipleChoice extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { navigationPresenter, presenter } = this.props
    const options = presenter.getOptions()

    return (
      <div>
        <div className='home-submit'>
          <p className='headline6'>{presenter.getTitle()}</p>
          <div className='body1'>{presenter.getDescription()}</div>
        </div>
        <div className='horizontal-multipleChoice'>
          {options.map((option: ICommonObjectWithSortId) => (
            <div key={option.id}>
              <div
                className={`${this.isSelectedOption(option) ? 'horizontal-selectedOption ' : ''}
                  horizontal-option`}
                onClick={this.updateValue}
                id={option.id?.toString()}
              />
              <p>
                {option.orderIndex === 1 || option.orderIndex === options.length ? option.name : ''}
              </p>
            </div>
          ))}
        </div>
        <NavigationButtonGroup
          canBacktrackToPreviousPrompt={navigationPresenter.isBackTrackAllowed()}
          hasValidInput={!!presenter.getValue()}
          isRequired={presenter.isRequiredQuestion()}
          presenter={navigationPresenter}
          subscriber={this}
        />
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  isSelectedOption = (option: ICommonObject): boolean => {
    return option.id === parseInt(this.props.presenter.getValue(), 10)
  }

  updateValue = (event: React.MouseEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateInput(target.id)
  }

  showErrorMessage = (): void => {
    this.props.presenter.showErrorMessage()
  }
}

export default HorizontalMultipleChoice
