import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IRemainingNumberOfQuestionsGetter from './IRemainingNumberOfQuestionsGetter'

class RemainingNumberOfQuestionsGetter implements IRemainingNumberOfQuestionsGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getNumberOfRemainingQuestions(
    assessmentId: number,
    promptId: number
  ): Promise<number> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${serverBaseUrl}assessment/${assessmentId}/prompt/${promptId}/remainingQuestions`
    })

    return result?.numRemainingQuestions ?? -1
  }
}

export default RemainingNumberOfQuestionsGetter
