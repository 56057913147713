import {
  currentRoleHeuristicId,
  currentSituationHeuristicId,
  desiredRoleHeuristicId,
  educationHeuristicId,
  linkedInUrlHeuristicId,
  oteHeuristicId,
  pastOteHeuristicId,
  quotaHeuristicId,
  w2IncomeHeuristicId,
  yearsInSalesHeuristicId
} from '../../localCommon/constant'
import QuestionDto from '../Home/Dtos/QuestionDto'
import { goalResponse } from '../Home/Dtos/ValidMultipleChoiceResponseType'
import {
  degreeListId,
  rolesPicklistResponse,
  yearGraduatedListId,
  yearsInSalesListId
} from '../Home/Dtos/ValidPicklistResponseType'
import { shortFreeResponseType, verticalMultipleChoiceType } from '../Home/Dtos/ValidResponseType'
import {
  currentOteFreeResponse,
  incomeFreeResponse,
  linkedInUrlFreeResponse,
  locationFreeResponse,
  pastOteFreeResponse,
  quotaFreeResponse
} from '../Home/FreeResponse/Presenters/Interfaces/ValidFreeResponseType'
import {
  currentSituation,
  education,
  numberOfYears,
  roles,
  yearGraduated
} from './Presenters/DropdownOptions'

const onboardingQuestions: QuestionDto[] = [
  {
    id: 7,
    heuristicId: currentRoleHeuristicId,
    title: 'What best describes your current role?',
    description: '',
    isRequired: true,
    unit: '',
    responseType: 'picklist',
    isTypingAllowed: false,
    lists: [
      {
        label: 'Current role in sales',
        list: roles,
        listId: rolesPicklistResponse
      }
    ]
  },
  {
    id: 9,
    heuristicId: desiredRoleHeuristicId,
    title: 'Which of the following are you most interested in if you were to get a new job?',
    description: '',
    isRequired: true,
    unit: '',
    responseType: 'picklist',
    isTypingAllowed: false,
    lists: [
      {
        label: 'Desired role in sales',
        list: roles.slice(1),
        listId: '-1'
      }
    ]
  },
  {
    id: 0,
    heuristicId: quotaHeuristicId,
    caption: 'Enter 0 if not applicable',
    title: 'What is your current annual quota?',
    description: '',
    placeholder: 'Annual quota',
    responseType: shortFreeResponseType,
    inputType: 'text',
    unit: '$',
    uniqueId: quotaFreeResponse,
    isRequired: true
  },
  {
    id: 1,
    heuristicId: oteHeuristicId,
    title: 'What is your current OTE?',
    caption:
      'Annual base salary + annual commission earned at 100% of quota = On-Target Earnings (OTE)',
    description: '',
    placeholder: 'Current OTE',
    responseType: shortFreeResponseType,
    inputType: 'text',
    unit: '$',
    uniqueId: currentOteFreeResponse,
    isRequired: true
  },
  {
    id: 2,
    heuristicId: pastOteHeuristicId,
    caption:
      'Annual base salary + annual commission earned at 100% of quota = On-Target Earnings (OTE)',
    title: 'What was your OTE last year?',
    placeholder: "Last year's OTE",
    description: '',
    inputType: 'text',
    responseType: shortFreeResponseType,
    unit: '$',
    uniqueId: pastOteFreeResponse,
    isRequired: true
  },
  {
    id: 3,
    heuristicId: w2IncomeHeuristicId,
    caption: 'Only use income from your primary sales job',
    title: 'How much did you actually earn last year?',
    placeholder: "Last year's earnings",
    description: '',
    inputType: 'text',
    unit: '$',
    uniqueId: incomeFreeResponse,
    isRequired: true,
    responseType: shortFreeResponseType
  },
  {
    id: 4,
    heuristicId: -1,
    caption: 'City, State or City, Country for outside the United States',
    title: 'Where are you located?',
    placeholder: 'City, State',
    description: '',
    inputType: 'text',
    unit: '',
    uniqueId: locationFreeResponse,
    isRequired: true,
    responseType: shortFreeResponseType
  },
  {
    id: 5,
    heuristicId: educationHeuristicId,
    title: 'What is your highest level of formal education?',
    description: '',
    isRequired: true,
    unit: '',
    responseType: 'picklist',
    lists: [
      {
        label: 'Degree',
        list: education,
        listId: degreeListId
      },
      {
        label: 'Year graduated or expected graduation',
        list: yearGraduated,
        listId: yearGraduatedListId
      }
    ]
  },
  {
    id: 6,
    heuristicId: yearsInSalesHeuristicId,
    caption: '',
    placeholder: 'Years in sales',
    title: 'How many years of sales experience do you have post college?',
    description: '',
    inputType: 'text',
    isRequired: true,
    lists: [
      {
        label: 'Years in sales',
        list: numberOfYears,
        listId: yearsInSalesListId
      }
    ],
    unit: '',
    responseType: 'picklist'
  },
  {
    id: 8,
    heuristicId: currentSituationHeuristicId,
    title: 'How would you describe your current situation?',
    description: '',
    isRequired: true,
    unit: '',
    uniqueId: goalResponse,
    responseType: verticalMultipleChoiceType,
    options: currentSituation
  },
  {
    id: 10,
    heuristicId: linkedInUrlHeuristicId,
    caption: '',
    title: "Please paste your LinkedIn URL. If you would prefer not to, type 'No thank you'.",
    placeholder: 'LinkedIn URL',
    description: '',
    inputType: 'text',
    unit: '',
    uniqueId: linkedInUrlFreeResponse,
    isRequired: true,
    responseType: shortFreeResponseType
  }
]

export default onboardingQuestions
