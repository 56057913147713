import AssessmentHistoryDto from '../../APIs/InProgressAssessmentGetter/AssessmentHistoryDto'
import IInProgressAssessmentGetter from '../../APIs/InProgressAssessmentGetter/IInProgressAssessmentGetter'
import IInProgressAssessmentHandler from './IInProgressAssessmentHandler'
import InProgressAssessmentDto from './InProgressAssessmentDto'

class InProgressAssessmentHandler implements IInProgressAssessmentHandler {
  private inProgressAssessment: AssessmentHistoryDto | null

  constructor(private readonly inProgressAssessmentGetter: IInProgressAssessmentGetter) {
    this.inProgressAssessment = null
  }

  public async initialize(assessmentId: number): Promise<void> {
    this.inProgressAssessment =
      await this.inProgressAssessmentGetter.getInProgressAssessment(assessmentId)
  }

  public hasCompletedGivenAssessmentId(): boolean {
    return !this.inProgressAssessment
  }

  public getInProgressAssessmentInfo(): InProgressAssessmentDto | null {
    if (this.inProgressAssessment && this.inProgressAssessment?.assessmentHistoryId) {
      return {
        userAssessmentHistoryId: this.inProgressAssessment.assessmentHistoryId,
        prompt: this.inProgressAssessment.prompt
      }
    }

    return null
  }
}

export default InProgressAssessmentHandler
