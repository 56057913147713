import './style.scss'

import Cookies from 'js-cookie'
import React, { Component, ReactElement } from 'react'

import images from '../../images'
import { assessmentFeatureFlagKey } from '../../localCommon/CookieKeys'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import Progressbar from '../../localCommon/ProgressBar/Progressbar'
import IPromptResponsePresenterFactory from '../Home/Presenters/PromptPresenterFactory/IPromptResponsePresenterFactory'
import PromptResponseComponentFactory from '../Home/ResponseComponentFactory/PromptResponseComponentFactory'
import IHomePresenter from '../PersonalInfoData/Presenters/Interfaces/IHomePresenter'
import INavigationTerminator from '../PersonalInfoData/Presenters/Interfaces/INavigationTerminator'
import { dashboardRoute } from '../RouteConstants'
import IAssessmentPresenter from './Presenter/IAssessmentPresenter'
import ResultInProgress from './ResultInProgress/ResultInProgress'

type Props = {
  factory: IPromptResponsePresenterFactory
  homePresenter: IHomePresenter
  presenter: IAssessmentPresenter & INavigationTerminator
  userId: number
}

class Assessment extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { factory, presenter } = this.props

    if (presenter.shouldShowDashboard()) {
      window.location.href = dashboardRoute
      return <div></div>
    }

    if (presenter.isLoading() || !presenter.getCurrentPrompt()) {
      return (
        <div className='assessment blank-page-padding'>
          <div className='loading'></div>
        </div>
      )
    }

    if (presenter.hasFinishedAllQuestions()) {
      return <ResultInProgress message={this.getLoadingResultMessage()} />
    }

    return (
      <div className='assessment blank-page-padding'>
        <div className='assessment-title'>
          <img alt='databased' src={images.databased} />
        </div>
        <div className='assessment-question'>
          <Progressbar
            progress={
              (presenter.getCurrentQuestionIndex() / presenter.getTotalNumberOfQuestions()) * 100
            }
          />
          <PromptResponseComponentFactory
            description={presenter.getCurrentPrompt()?.prompt}
            factory={factory}
            navigationPresenter={presenter}
            prompt={presenter.getCurrentPrompt()}
            responseType={presenter.getCurrentPrompt()?.responseType}
            title=''
          />
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  getLoadingResultMessage = (): string => {
    if (Cookies.get(assessmentFeatureFlagKey) === 'true') {
      return 'Analyzing Responses'
    }
    return 'Calculating hire-ability score…'
  }
}

export default Assessment
