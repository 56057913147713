import './style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedButton from '../OutlinedButton'

type Props = {
  children: React.ReactNode
  id?: string
  isButtonDisabled?: boolean
  onClick?(event: React.MouseEvent): void
  type?: 'submit' | 'reset' | 'button'
}

class OutlinedThemeButton extends Component<Props> {
  render(): ReactElement {
    const { children, id, onClick, type } = this.props

    return (
      <div className='outlinedThemeButton'>
        <OutlinedButton id={id} onClick={onClick} type={type || 'button'}>
          {children}
        </OutlinedButton>
      </div>
    )
  }
}

export default OutlinedThemeButton
