import Cookies from 'js-cookie'

import { authTokenKey } from '../localCommon/CookieKeys'
import IFetcher from './Interfaces/IFetcher'

class Fetcher implements IFetcher {
  public async fetch(params: {
    auth?: string
    body: any
    headers?: any
    method: string
    url: string
  }): Promise<any> {
    const { auth, body, headers, method, url } = params

    const result = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth ?? Cookies.get(authTokenKey)}`,
        ...(headers || {})
      },
      body: this.getRequestBody(method, body)
    })
    const contentType = result.headers.get('Content-Type')

    if (result && contentType?.includes('json')) {
      return result.json()
    }

    return this.tryHandlingResponseAsIfItIsAFile(result, contentType)
  }

  private async tryHandlingResponseAsIfItIsAFile(
    response: any,
    contentType: string | null
  ): Promise<null> {
    const contentTypes = ['pdf', 'doc', 'docx', 'rtf', 'odt', 'txt']

    if (contentType !== null && contentTypes.some((type) => contentType.includes(type))) {
      try {
        const blob = await response.blob()
        const currentUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = currentUrl

        const disposition = response.headers.get('content-disposition')

        const filename = disposition?.includes('filename=')
          ? disposition.split('filename=')[1]?.replace(/["']/g, '')
          : 'resumé'

        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(currentUrl)
        return null
      } catch (err) {
        return null
      }
    }

    return null
  }

  private getRequestBody(method: string, body: any): any {
    if (method.toUpperCase() === 'GET') {
      return undefined
    }

    return body ? JSON.stringify(body) : JSON.stringify({})
  }
}

export default Fetcher
