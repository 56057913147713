import './StyleSheets/CandidateHeuristicData.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../images'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import HeuristicDataViewDto from './InterfacesAndDtos/HeuristicDataViewDto'
import ICandidateHeuristicDataPresenter from './InterfacesAndDtos/ICandidateHeuristicDataPresenter'

type Props = {
  presenter: ICandidateHeuristicDataPresenter
}

class CandidateHeuristicData extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const defaultHeuristicDtos = presenter.getDefaultViewHeuristicsToDisplay()
    const allMetrics = presenter.getAllHeuristicsToDisplay()
    const expandedCard = presenter.shouldShowAllHeuristics()
    const dtosToDisplay = expandedCard ? allMetrics : defaultHeuristicDtos
    const editMode = presenter.isInEditMode()
    const shareMode = presenter.isInShareMode()

    return (
      <div className='heuristic-data-card'>
        <div className='heuristic-data-header-line'>
          <span className='heuristic-title'>Comp & Performance</span>
          <span className='edit-icon'>{!shareMode && this.renderOpenOrCloseEditModeButtons()}</span>
        </div>
        <br></br>
        <div className='data-box'>
          {dtosToDisplay
            .map((_, idx) => {
              return editMode
                ? this.buildDataRowEditMode(dtosToDisplay, idx)
                : this.buildDataRow(dtosToDisplay, idx)
            })
            .filter((element) => element !== null)}
        </div>
        <div className='heuristic-data-button'>
          {!shareMode && this.renderExpandedCardCloseOrSaveButton()}
        </div>
      </div>
    )
  }

  renderExpandedCardCloseOrSaveButton = (): ReactElement => {
    const { presenter } = this.props
    const editMode = presenter.isInEditMode()
    const expandedCard = presenter.shouldShowAllHeuristics()
    return editMode ? (
      <button type='button' onClick={this.handleSave}>
        {'SAVE'}
      </button>
    ) : (
      <button type='button' onClick={this.toggleCardExpansion}>
        {expandedCard ? 'CLOSE' : 'VIEW MORE'}
      </button>
    )
  }

  renderOpenOrCloseEditModeButtons = (): ReactElement => {
    const { presenter } = this.props
    const editMode = presenter.isInEditMode()

    return editMode ? (
      <img
        className='edit-mode-close'
        onClick={this.exitEditMode}
        src={images.closeIcon}
        alt='Close'
      />
    ) : (
      <img
        className='edit-icon-open'
        onClick={this.enterEditMode}
        src={images.editIcon}
        alt='Edit'
      />
    )
  }

  handleSave = (): void => {
    this.props.presenter.saveNewHeuristics()
  }

  enterEditMode = (): void => {
    const { presenter } = this.props
    if (presenter.isRecruiter()) {
      alert(
        "Editing will permanently change the data for this candidate. Please only edit if you have the candidate's permission."
      )
    }
    presenter.enterEditMode()
  }

  exitEditMode = (): void => {
    this.props.presenter.exitEditMode()
  }

  toggleCardExpansion = (): void => {
    this.props.presenter.toggleCardExpansion()
  }

  buildDataRow = (dtos: HeuristicDataViewDto[], idx: number): ReactElement | null => {
    const left = dtos[idx]
    if (idx % 2 === 0) {
      const right = dtos[idx + 1]

      return (
        <div key={idx}>
          <div className='heuristic-data-header'>
            <span>{left.title}</span>
            {right && <span>{right?.title}</span>}
          </div>
          <div className='heuristic-data-values'>
            <strong>{left.value}</strong>
            {right && <strong>{right?.value}</strong>}
          </div>
        </div>
      )
    }
    return null
  }

  buildDataRowEditMode = (dtos: HeuristicDataViewDto[], idx: number): ReactElement | null => {
    const { presenter } = this.props
    const left = dtos[idx]

    const renderInputWithUnit = (dto: HeuristicDataViewDto) => {
      const unit = dto.unit || ''
      const inputValue = presenter.getHeuristicValueInEditMode(dto.heuristicId)

      const inputType = dto.valueType === 'measure' ? 'number' : 'text'

      if (unit === '$') {
        return (
          <div className='input-wrapper'>
            <span className='input-unit'>$</span>
            <input
              type={inputType}
              defaultValue={inputValue}
              onChange={(e) =>
                this.props.presenter.handleTextChange(e.target.value, dto.heuristicId)
              }
            />
          </div>
        )
      }

      if (unit === '%') {
        return (
          <div className='input-wrapper'>
            <input
              type={inputType}
              defaultValue={inputValue}
              onChange={(e) =>
                this.props.presenter.handleTextChange(e.target.value, dto.heuristicId)
              }
            />
            <span className='input-unit'>%</span>
          </div>
        )
      }

      return (
        <input
          type={inputType}
          defaultValue={inputValue}
          onChange={(e) => this.props.presenter.handleTextChange(e.target.value, dto.heuristicId)}
        />
      )
    }

    if (idx % 2 === 0) {
      const right = dtos[idx + 1]

      return (
        <div key={idx}>
          <div className='heuristic-data-header'>
            <span>{left.title}</span>
            {right && <span>{right?.title}</span>}
          </div>
          <div className='heuristic-data-values'>
            {renderInputWithUnit(left)}
            {right && renderInputWithUnit(right)}
          </div>
        </div>
      )
    }

    return null
  }

  update(): void {
    this.setState({})
  }
}

export default CandidateHeuristicData
