import React, { Component, ReactElement } from 'react'

import images from '../../../images'
import CheckBox from '../../../localCommon/CheckBox/CheckBox'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RadioButton from '../../../localCommon/RadioButton/RadioButton'
import { noMinimumOTE } from './CandidateViewJobsPresenter'
import ICandidateViewJobsFilterPresenter from './ICandidateViewJobsFilterPresenter'

type Props = {
  presenter: ICandidateViewJobsFilterPresenter
}

class CandidateViewJobsFilter extends Component<Props> implements ISubscribingView {
  render(): ReactElement {
    const { presenter } = this.props
    const roles = presenter.getRoleTypeFilters()
    const oteFilters = presenter.getOTEFilters()
    const workSituationFilters = presenter.getWorkSituationFilters()

    return (
      <div className='filter-container'>
        <div className='filter-header'>
          <img
            alt='close-drawer-icon'
            className='menu-close'
            onClick={this.toggleFilterMenu}
            src={images.closeIcon}
          />
        </div>
        <div className='job-filter-content'>
          <div className='filter-section'>
            <p className='body1'>
              <strong>Job Level</strong>
            </p>
            <button type='button' className='clear-filter' onClick={this.clearRoleFilters}>
              Clear
            </button>
          </div>
          {roles.map((role, idx) => {
            return (
              <CheckBox
                key={idx}
                id={role.id}
                label={role.label}
                checked={role.isChecked}
                onChange={this.handleRoleCheckBoxChange}
              />
            )
          })}
          <p className='body1'>
            <strong>Minimum OTE</strong>
          </p>
          {oteFilters.map((option) => {
            const label =
              option.name === noMinimumOTE
                ? option.name
                : `$${Number(option.name).toLocaleString()}`
            return (
              <RadioButton
                key={option.id}
                id={option.id?.toString()}
                isChecked={option.isChecked}
                onChange={this.handleOteSelection}
                title={label}
              />
            )
          })}
          <div className='filter-section'>
            <p className='body1'>
              <strong>Work Situation</strong>
            </p>
            <button type='button' className='clear-filter' onClick={this.clearWorkSituationFilters}>
              Clear
            </button>
          </div>
          {workSituationFilters.map((filter, idx) => {
            return (
              <CheckBox
                key={idx}
                id={filter.id}
                label={filter.label}
                checked={filter.isChecked}
                onChange={this.handleWorkSituationCheckBoxChange}
              />
            )
          })}
        </div>
        <button className='apply-button' onClick={this.toggleFilterMenu} type='button'>
          Apply
        </button>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  toggleFilterMenu = (): void => {
    this.props.presenter.toggleFilterView()
  }

  handleRoleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setRoleFilters(String(id))
  }

  handleOteSelection = (selectedButton: string): void => {
    this.props.presenter.selectOTEFilter(Number.parseInt(selectedButton, 10))
  }

  handleWorkSituationCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setWorkSituationFilters(String(id))
  }

  clearRoleFilters = () => {
    this.props.presenter.clearRoleFilters()
  }

  clearWorkSituationFilters = () => {
    this.props.presenter.clearWorkSituationFilters()
  }
}

export default CandidateViewJobsFilter
