/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

const images = {
  animatedRocket: require('./Images/animated-rocket.json'),
  astronaut: require('./Images/astronaut.svg').default,
  blackArrow: require('./Images/arrow_to_right.svg').default,
  databased: require('./Images/databased.svg').default,
  candidate: require('./Images/candidate.svg').default,
  desktopSignInBg: require('./Images/sso_login_bg.svg').default,
  failedAssessmentImage: require('./Images/failed_assessment.svg').default,
  filter: require('./Images/filter.svg').default,
  closeIcon: require('./Images/close.svg').default,
  hunterMindesetMetric: require('./Images/hunter_mindset.png'),
  iqMetric: require('./Images/iq_detail_image.png'),
  motor: require('./Images/motor.png'),
  performance: require('./Images/performance.png'),
  databasedWhite: require('./Images/databased_white_logo.svg').default,
  exampleData: require('./Images/example.svg').default,
  hamburgerIcon: require('./Images/hamburger.svg').default,
  gmailIcon: require('./Images/gmail_icon.svg').default,
  linkedInIcon: require('./Images/linkedin_icon.png'),
  editIcon: require('./Images/edit.svg').default,
  whiteArrow: require('./Images/white_arrow.svg').default,
  defaultProfilePicture: require('./Images/default_profile_picture.png'),
  verifiedIcon: require('./Images/assessment_verified.svg').default,
  warning: require('./Images/warning.svg').default,
  welcomeImage: require('./Images/welcome_image.svg').default
}

export default images
