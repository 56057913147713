import MarketCompensationDataDto from '../../../../APIs/MarketCompensationDataGetter/MarketCompensationDataDto'
import INumberFormatter from '../../../../localCommon/NumberFormatter/INumberFormatter'
import StringHelper from '../../../../localCommon/StringHelper/StringHelper'
import ICandidateProfile from '../../../../Models/Interfaces/ICandidateProfile'
import IHomeDataRowsPresenter from '../../../PersonalInfoData/OTEStanding/IHomeDataRowsPresenter'
import IOTEStandingPresenterFactory from './IOTEStandingPresenterFactory'

class OTEStandingPresenterFactory implements IOTEStandingPresenterFactory {
  constructor(
    private readonly oteStandingPresenter: IHomeDataRowsPresenter,
    private readonly numberFormatter: INumberFormatter
  ) {}

  public getOTEStandingPresenter(): IHomeDataRowsPresenter {
    return this.oteStandingPresenter
  }

  public setPresenterData(candidate: ICandidateProfile): void {
    const { profile, marketData, metrics } = candidate

    this.oteStandingPresenter.setMarketCompensationData(marketData as MarketCompensationDataDto)
    this.oteStandingPresenter.updateRole(`${profile?.userInfo?.internalLevel}-internal` ?? '')
    this.oteStandingPresenter.updateNumOfYearsExperience(metrics?.yearsOfExperience ?? '')
    this.oteStandingPresenter.updateFormattedAnnualQuota(
      this.numberFormatter.formatLargeAmount(
        StringHelper.getFormattedDollarAmount(metrics?.currentAnnualQuota?.toString() ?? '')
      )
    )
  }
}

export default OTEStandingPresenterFactory
