import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import ISsoLogInPresenter from './ISsoLogInPresenter'

class SsoLogInPresenter implements ISsoLogInPresenter {
  private view: ISubscribingView | null
  private email: string

  constructor() {
    this.email = ''
    this.view = null
  }

  public getEmail(): string {
    return this.email
  }

  public updateEmail(input: string): void {
    this.email = input
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }
}

export default SsoLogInPresenter
