import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import CandidateProfileDto from './CandidateProfileDto'
import ICandidateProfileFetcher from './ICandidateProfileFetcher'

class CandidateProfileFetcher implements ICandidateProfileFetcher {
  constructor(private readonly fetcher: IFetcher) {}
  public async getCandidateProfile(userId: number): Promise<CandidateProfileDto> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}user/${userId}/candidateProfile`,
      body: null
    })

    if (this.hasValidProfile(result)) {
      return result
    }

    return {
      aggregateHeuristicScores: [],
      performances: [],
      userInfo: null
    }
  }

  private hasValidProfile(result: any): boolean {
    return result?.hasOwnProperty('performances') && result?.hasOwnProperty('userInfo')
  }
}

export default CandidateProfileFetcher
