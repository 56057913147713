import './style.css'
import 'react-quill/dist/quill.snow.css'

import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { Component, ReactElement } from 'react'
import ReactQuill from 'react-quill'
import { Link } from 'react-router-dom'

import PromptDto from '../../APIs/PromptGetter/PromptDto'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import ValidResponseType from '../Home/Dtos/ValidResponseType'
import { jobsManagementRoute } from '../RouteConstants'
import JobDto from './Dtos/JobDto'
import IJobListingPresenter from './Presenters/Interfaces/IJobListingPresenter'

type Props = {
  presenter: IJobListingPresenter
}

class JobListing extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.initialize()
    this.props.presenter.setView(this)
  }

  public render(): ReactElement {
    const { presenter } = this.props
    const job = presenter.getJob()
    const { resultMessage, wasSuccessfulSave, wasSuccessfulDelete, failedPromptCreation } =
      presenter
    const recruitingTypes = presenter.getRecruitingRoleTypes()
    const jobPrompts = presenter.getJobPrompts()
    const isSlideWindowOpen = presenter.isAddPromptOpen()
    const eligiblePrompts = presenter.getEligiblePrompts()
    const shouldShowCreateNewPromptForm = presenter.shouldShowNewPromptForm()

    if (presenter.isLoading()) {
      return (
        <div className='dashboard blank-page-padding'>
          <div className='loading'></div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <Container className='container' maxWidth='sm'>
          <div className='header'>
            <Typography variant='h4' component='h1' gutterBottom>
              Edit Job Listing
            </Typography>
            <Link to={jobsManagementRoute}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Link>
          </div>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} className='formItem'>
                <TextField
                  fullWidth
                  label='Job Title'
                  name='jobTitle'
                  value={job.jobTitle}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} className='formItem'>
                <TextField
                  fullWidth
                  label='Company Name'
                  name='companyName'
                  value={job.companyName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} className='formItem'>
                <TextField
                  fullWidth
                  label='Location'
                  name='location'
                  value={job.location}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} className='formItem'>
                <ReactQuill
                  value={job.jobDescription}
                  onChange={this.handleQuillChange}
                  theme='snow'
                  placeholder='Enter job description here...'
                />
              </Grid>
              <Grid item xs={12} sm={6} className='formItem'>
                <TextField
                  fullWidth
                  label='Base Compensation Low'
                  name='baseCompensationLow'
                  value={job.baseCompensationLow}
                  onChange={this.handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} sm={6} className='formItem'>
                <TextField
                  fullWidth
                  label='Base Compensation High'
                  name='baseCompensationHigh'
                  value={job.baseCompensationHigh}
                  onChange={this.handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} sm={6} className='formItem'>
                <TextField
                  fullWidth
                  label='Variable Compensation'
                  name='variableCompensation'
                  value={job.variableCompensation}
                  onChange={this.handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} className='formItem'>
                <FormControl fullWidth>
                  <InputLabel id='jobType-label'>Job Type</InputLabel>
                  <Select
                    labelId='jobType-label'
                    name='jobType'
                    value={job.jobType}
                    onChange={this.handlePicklistChange}
                  >
                    <MenuItem value='On-site'>On-site</MenuItem>
                    <MenuItem value='Hybrid'>Hybrid</MenuItem>
                    <MenuItem value='Remote'>Remote</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className='formItem'>
                <FormControl fullWidth>
                  <InputLabel id='recruitingType-label'>Job Level</InputLabel>
                  <Select
                    labelId='recruitingType-label'
                    name='recruitingRoleTypeId'
                    value={presenter.getSelectedRecruitingRoleLabel()}
                    onChange={this.handlePicklistChange}
                  >
                    {recruitingTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className='formItem'>
                <div className='jobSpecificQuestionsHeader'>
                  <h3>Job specific questions</h3>
                  <button
                    type='button'
                    onClick={this.handleNewQuestion}
                    className='addQuestionButton'
                  >
                    Add
                  </button>
                </div>
                <div className='promptsList'>
                  {jobPrompts.map((prompt, index) => (
                    <div key={index} className='promptRow'>
                      <Typography variant='body1' className='promptText'>
                        {prompt.prompt}
                      </Typography>
                      <IconButton
                        className='deletePromptButton'
                        onClick={() => this.handlePromptDelete(prompt.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            {resultMessage && wasSuccessfulSave === false && (
              <Typography variant='body2' color='error' className='errorMessage'>
                {resultMessage}
              </Typography>
            )}
            {resultMessage && wasSuccessfulSave && (
              <Typography variant='body2' color='primary' className='successMessage'>
                {resultMessage}
              </Typography>
            )}
            {resultMessage && wasSuccessfulDelete === false && (
              <Typography variant='body2' color='error' className='errorMessage'>
                {resultMessage}
              </Typography>
            )}
            {resultMessage && wasSuccessfulDelete && (
              <Typography variant='body2' color='primary' className='successMessage'>
                {resultMessage}
              </Typography>
            )}
            <br></br>
            {!wasSuccessfulDelete && (
              <div className='buttonContainer'>
                <button className='saveJobButton'>Save Job</button>
                <button type='button' className='deleteButton' onClick={this.handleDelete}>
                  Delete Job
                </button>
              </div>
            )}
          </form>
        </Container>
        <div className={`slideWindow ${isSlideWindowOpen ? 'open' : ''}`}>
          <div className='slideWindowHeader'>
            <Typography variant='h6'>Add Question to Job</Typography>
            <IconButton onClick={this.handleCloseSlideWindow}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='slideWindowContent'>
            <TextField
              label='Search...'
              variant='outlined'
              fullWidth
              value={presenter.promptSearchQuery}
              onChange={this.handleSearchChange}
              className='searchBar'
            />
            {presenter.promptSearchQuery && (
              <ul className='dropdown'>
                {eligiblePrompts.map((option, index) => (
                  <li key={index} onClick={() => this.handleSelectOption(option)}>
                    {option.prompt}
                  </li>
                ))}
              </ul>
            )}
            {shouldShowCreateNewPromptForm && (
              <div className='createNewPromptForm'>
                <div className='createNewPromptHeader'>
                  <Typography variant='h6'>Create New Prompt</Typography>
                  <IconButton onClick={this.handleCloseSlideWindow}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <p>Please double check there isn't an existing prompt before creating a new one!</p>
                <form onSubmit={this.handleCreateNewPromptSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className='formItem'>
                      <TextField
                        fullWidth
                        label='Prompt'
                        name='prompt'
                        placeholder='What was your OTE in 2022?'
                        value={presenter.getNewPrompt()}
                        onChange={this.handleNewPromptUpdate}
                      />
                    </Grid>
                    <Grid item xs={12} className='formItem'>
                      <TextField
                        fullWidth
                        label='Nickname'
                        name='promptNickname'
                        placeholder='OTE 2022'
                        value={presenter.getNewPromptNickname()}
                        onChange={this.handleNewPromptNicknameUpdate}
                      />
                    </Grid>
                    <Grid item xs={12} className='formItem'>
                      <FormControl fullWidth>
                        <InputLabel id='promptInputType-label'>Input Type</InputLabel>
                        <Select
                          labelId='promptInputType-label'
                          name='promptInputType'
                          value={presenter.getDisplayValueForNewPromptResponseType()}
                          onChange={this.handleNewPromptResponseTypeSelection}
                        >
                          <MenuItem value='free_response_short'>Free Response Short</MenuItem>
                          <MenuItem value='free_response_long'>Free Response Long</MenuItem>
                          <MenuItem value='free_response_percentage'>Percentage</MenuItem>
                          <MenuItem value='free_response_numerical'>Number</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className='formItem'>
                      <FormControl fullWidth>
                        <InputLabel id='promptInputType-label'>Unit</InputLabel>
                        <Select
                          labelId='promptInputType-label'
                          name='promptInputType'
                          value={presenter.getDisplayLabelForUnit()}
                          onChange={this.handleUnitSelection}
                        >
                          <MenuItem value={'no unit'}>No unit (text free response) </MenuItem>
                          <MenuItem value='$'>$ (dollars)</MenuItem>
                          <MenuItem value='%'>% (percent)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
                {failedPromptCreation && (
                  <p className='createNewPromptError'>
                    Failed to create new question, please try again
                  </p>
                )}
                <button
                  className='createNewPromptButton'
                  onClick={this.handleCreateNewPromptSubmit}
                >
                  Add
                </button>
              </div>
            )}
            {!shouldShowCreateNewPromptForm && (
              <button className='createPromptButton' onClick={this.handleCreateNewPromptClick}>
                Create New Question
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  handleUnitSelection = (event: any) => {
    const { value } = event.target
    this.props.presenter.updateUnit(value)
  }

  handleCreateNewPromptSubmit = () => {
    this.props.presenter.createNewPrompt()
  }

  handleNewPromptResponseTypeSelection = (event: any) => {
    const { value } = event.target

    this.props.presenter.updateNewPromptResponseType(value as ValidResponseType)
  }

  handleNewPromptUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.presenter.handlePromptValueUpdate(value)
  }

  handleNewPromptNicknameUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.presenter.handleNicknameUpdate(value)
  }

  handleCreateNewPromptClick = () => {
    this.props.presenter.openCreateNewPromptForm()
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.presenter.updatePromptSearchQuery(value)
  }

  handleSelectOption = (option: PromptDto) => {
    this.props.presenter.handlePromptSelection(option)
  }

  handleCloseSlideWindow = () => {
    this.props.presenter.handleCloseAddPromptWindow()
  }

  handleNewQuestion = () => {
    this.props.presenter.handleAddPromptClicked()
  }

  handleQuillChange = (value: string) => {
    this.props.presenter.handleTextChange('jobDescription' as keyof JobDto, value)
  }

  handlePicklistChange = (event: any) => {
    const { name, value } = event.target
    this.props.presenter.handleTextChange(name as keyof JobDto, value)
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    this.props.presenter.handleTextChange(name as keyof JobDto, value)
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await this.props.presenter.updateJob()
  }

  handleDelete = () => {
    this.props.presenter.deleteJob()
  }

  handlePromptDelete = (index: number) => {
    this.props.presenter.deleteJobPrompt(index)
  }

  update(): void {
    this.setState({})
  }
}

export default JobListing
