import './LoadingSpinner.scss'

import React, { Component, Fragment } from 'react'

class LoadingSpinner extends Component {
  render() {
    return (
      <Fragment>
        <div className='loadingSpinner spinner-pink'>
          <span></span>
        </div>
        <div className='loadingSpinner spinner-purple'>
          <span></span>
        </div>
        <div className='loadingSpinner spinner-blue'>
          <span></span>
        </div>
      </Fragment>
    )
  }
}

export default LoadingSpinner
