import IPersonalDataProvider from '../Presenters/Interfaces/IPersonalDataProvider'
import IPersonalInfoInputValidator from './IPersonalInfoInputValidator'

export const invalidYearsOfExperienceMessage = 'Please enter a valid value.'
class PersonalInfoInputValidator implements IPersonalInfoInputValidator {
  public getInvalidYearsOfExperienceMessage(input: string): string {
    if (typeof input !== 'string') {
      return invalidYearsOfExperienceMessage
    }

    if (input !== '20+' && parseInt(input, 10) >= 20) {
      return invalidYearsOfExperienceMessage
    }

    return ''
  }

  public hasMissingRequiredFields(personalDataProvider: IPersonalDataProvider): boolean {
    return (
      !personalDataProvider.getAnnualQuotaInput() ||
      !personalDataProvider.getLocation() ||
      !personalDataProvider.getNumOfYearsExperience() ||
      !personalDataProvider.getOteInput() ||
      !personalDataProvider.getRole() ||
      !personalDataProvider.getYearGraduated()
    )
  }
}

export default PersonalInfoInputValidator
