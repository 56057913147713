class StringHelper {
  public static isValidEmail(email: string): boolean {
    const regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
    return regex.test(email)
  }

  public static getFormattedDollarAmount = (value: string): string => {
    const parsedValue = parseFloat(value.replace(/[^0-9.]/g, ''))

    if (!isNaN(parsedValue)) {
      return parsedValue.toLocaleString('en-US')
    }
    return ''
  }

  public static filterOutCommaFromNumberString = (input: string): string => {
    return input.replace(/[^0-9]/g, '')
  }

  public static extractWordsInParentheses = (input: string): string => {
    const regex = /\(([^)]+)\)/g
    const matches = input.match(regex)

    if (!matches) {
      return ''
    }

    const wordsInParentheses = matches
      .map((match) => {
        const wordsMatch = match.match(/\b\w+\b/g)
        return wordsMatch ? wordsMatch.join(' ') : ''
      })
      .join('')

    return wordsInParentheses
  }

  public static getFirstChars(text: string): string {
    const words: string[] = text.split(' ')

    const firstChars: string = words
      .map((word) => word.charAt(0))
      .filter((char) => /[a-zA-Z]/.test(char))
      .join('')

    return firstChars
  }
}

export default StringHelper
