import IProfileImagePoster from '../../APIs/ProfileImagePoster/IProfileImagePoster'
import { maxImageSize, serverBaseUrl } from '../../localCommon/constant'
import { profileImageKey } from '../../localCommon/CookieKeys'
import ICookieGetter from '../../localCommon/Interfaces/ICookieGetter'
import ICookieSetter from '../../localCommon/Interfaces/ICookieSetter'
import IElementCreator from '../../localCommon/Interfaces/IElementCreator'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import IProfileImageHandler from './IProfileImageHandler'

class ProfileImageHandler implements IProfileImageHandler {
  private errorMessage: string
  private loading: boolean
  private view: ISubscribingView | null
  private image: string
  private name: string
  private id: number | null

  constructor(
    private readonly imageId: string,
    private readonly imageUploadName: string,
    private readonly imagePoster: IProfileImagePoster,
    private readonly elementCreator: IElementCreator,
    private readonly cookieSetter: ICookieSetter,
    private readonly cookieGetter: ICookieGetter,
    name: string
  ) {
    this.id = null
    this.image = this.cookieGetter.get(profileImageKey) ?? ''
    this.loading = false
    this.errorMessage = ''
    this.name = name
    this.view = null
  }

  public getImageElementId(): string {
    return this.imageId
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getImageId(): number | null {
    return this.id
  }

  public getImage(): string {
    if (!this.image) {
      return ''
    }

    return `${serverBaseUrl}profile/${this.image}`
  }

  public setErrorMessage(message: string): void {
    this.errorMessage = message
    this.updateView()
  }

  public async validateThenUploadFile(file: any): Promise<void> {
    if (this.validateFileBeforeUpload(file)) {
      if (this.isValidFileSize(file.size)) {
        const image = await this.createImage(file)

        if (image) {
          const resizedImage = await this.resizeImage(image)
          await this.uploadBlob(resizedImage)
        }
      } else {
        this.uploadFile(file)
      }
    }
  }

  private async uploadFile(file: File): Promise<void> {
    const formData = new FormData()
    formData.append(this.imageUploadName, file as File)

    await this.uploadFileToServer(formData)
  }

  private async createImage(file: any): Promise<HTMLImageElement | null> {
    if (file.type.match(/image.*/)) {
      return new Promise((resolve) => {
        const image = new Image()
        image.onload = () => resolve(image)
        image.src = URL.createObjectURL(file)
      })
    }

    return null
  }

  private resizeImage(image: HTMLImageElement): Promise<Blob> {
    return new Promise((resolve) => {
      const canvas = this.elementCreator.createElement('canvas') as HTMLCanvasElement
      const ctx = canvas.getContext('2d')
      const aspectRatio = image.width / image.height
      const sourceOffset = Math.abs(image.width - image.height) / 2
      const size = 200

      canvas.height = size
      canvas.width = size

      if (!ctx) {
        return
      }

      if (!sourceOffset) {
        ctx.drawImage(image, 0, 0, size, size)
      } else {
        const source = {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height
        }

        if (aspectRatio > 1) {
          source.x = sourceOffset
          source.width = image.width - sourceOffset * 2
        } else {
          source.y = sourceOffset
          source.height = image.height - sourceOffset * 2
        }

        ctx.drawImage(image, source.x, source.y, source.width, source.height, 0, 0, size, size)
      }

      canvas.toBlob(resolve as any, 'image/png')
    })
  }

  private async uploadBlob(resizedImage: Blob): Promise<void> {
    const formData = new FormData()

    formData.append(this.imageUploadName, resizedImage, 'pic.png')
    this.uploadFileToServer(formData)
  }

  private async uploadFileToServer(formData: FormData): Promise<void> {
    const response = await this.imagePoster.uploadProfileImage({ formData })

    if (typeof response === 'string') {
      this.errorMessage = response
    } else {
      this.image = response.profilePicture
      this.cookieSetter.set(profileImageKey, response.profilePicture, {
        expires: 1
      })
      this.id = response.imageId
    }
    this.updateView()
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  private isValidFileExtension(fileType: string): boolean {
    const fileExtensions = ['image/jpeg', 'image/png', 'image/tiff']
    return fileExtensions.includes(fileType)
  }

  public validateFileBeforeUpload(file: File): boolean {
    if (!file) {
      this.errorMessage = 'Error: File Does not exist!'
      this.updateView()
      return false
    }

    if (!this.isValidFileExtension(file.type)) {
      this.errorMessage = 'Error: Invalid image format!'
      this.updateView()
      return false
    }
    this.errorMessage = ''
    this.updateView()
    return true
  }

  private isValidFileSize(fileSize: number): boolean {
    return fileSize < maxImageSize
  }

  public getName(): string {
    const words = this.name.split(' ')
    const result = words.map((word) => word.charAt(0).toUpperCase()).join('')

    return result
  }
}

export default ProfileImageHandler
