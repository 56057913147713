import './OutlinedButton.scss'

import React, { Component, ReactElement } from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  id?: string
  isButtonDisabled?: boolean
  onClick?: (event: React.MouseEvent) => void
  type?: 'button' | 'reset' | 'submit'
}

class OutlinedButton extends Component<Props> {
  render(): ReactElement {
    const { className, children, id, isButtonDisabled, onClick, type } = this.props

    return (
      <button
        className={`${className ?? ''} mdc-button mdc-button--outlined mdc-typography--button`}
        disabled={isButtonDisabled || false}
        id={id ?? ''}
        onClick={onClick}
        type={type || 'button'}
      >
        {children}
      </button>
    )
  }
}

export default OutlinedButton
