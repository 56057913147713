import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { okHttpCode, serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import StringHelper from '../../../localCommon/StringHelper/StringHelper'
import { invalidEmailErrorMessage } from '../../SignUp/Presenter/SignUpPresenter'
import IForgotPasswordPresenter from './IForgotPasswordPresenter'

const forgotPasswordPath = 'forgot/password'
export const forgotPasswordUrl = `${serverBaseUrl}${forgotPasswordPath}`

class ForgotPasswordPresenter implements IForgotPasswordPresenter {
  private errorMessage: string
  private email: string
  private successMessage: string
  private view: ISubscribingView | null

  constructor(private readonly fetcher: IFetcher) {
    this.errorMessage = ''
    this.email = ''
    this.successMessage = ''
    this.view = null
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getErrorMessage(): string {
    return this.errorMessage
  }

  public getSuccessMessage(): string {
    return this.successMessage
  }

  public getEmail(): string {
    return this.email
  }

  public setEmail(newEmail: string): void {
    this.email = newEmail

    this.validateEmailAndSetErrorMessage()
  }

  public async sendEmail(): Promise<void> {
    const result = await this.fetcher.fetch({
      body: { email: this.email },
      method: 'PUT',
      url: forgotPasswordUrl
    })

    if (result?.code === okHttpCode) {
      this.errorMessage = ''
      this.successMessage = result?.message
    } else {
      this.errorMessage = result?.message
      this.successMessage = ''
    }

    this.updateView()
  }

  private validateEmailAndSetErrorMessage(): void {
    if (this.isEmailValid()) {
      this.errorMessage = ''
    } else {
      this.errorMessage = invalidEmailErrorMessage
    }

    this.updateView()
  }

  public shouldSubmitButtonBeDisabled(): boolean {
    return !this.isEmailValid()
  }

  private isEmailValid(): boolean {
    return StringHelper.isValidEmail(this.email)
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default ForgotPasswordPresenter
