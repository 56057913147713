/* eslint-disable react/jsx-props-no-spreading */
import Cookies from 'js-cookie'
import React, { Component } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import {
  assessmentFeatureFlagKey,
  authTokenKey,
  emailKey,
  nameKey,
  profileImageKey,
  userIdKey
} from '../localCommon/CookieKeys'
import { ssoLoginsRoute } from './RouteConstants'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
}

class PrivateRoute extends Component<PrivateRouteProps> {
  render() {
    const { component: Component, ...rest } = this.props
    const isAuthenticated = Cookies.get(authTokenKey)

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthenticated) {
            return <Component {...props} />
          }
          this.handleSignOut()
          return <Redirect to={ssoLoginsRoute} />
        }}
      />
    )
  }

  private handleSignOut = () => {
    Cookies.remove(authTokenKey)
    Cookies.remove(profileImageKey)
    Cookies.remove(emailKey)
    Cookies.remove(userIdKey)
    Cookies.remove(nameKey)
    Cookies.remove(assessmentFeatureFlagKey)
  }
}

export default PrivateRoute
