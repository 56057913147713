export const ssoLoginsRoute = '/ssoLogins'
export const loginRoute = '/login'
export const candidateProfileRoute = '/candidate'
export const candidateSummaryRoute = '/candidates'
export const dashboardRoute = '/dashboard'
export const assessmentRoute = '/assessment'
export const candidateViewJobBoardRoute = '/jobs'
export const candidateViewJobRoute = '/job'
export const jobsManagementRoute = '/jobsManagement'
export const jobManagementRoute = '/jobManagement'
export const summaryRoutePostfix = '/summary'
