import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IAssessmentDetailsMailer from '../../Routes/Assessment/AssessmentDetailsMailer/IAssessmentDetailsMailer'
import IAssessmentFinisher from './IAssessmentFinisher'

class AssessmentFinisher implements IAssessmentFinisher {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number,
    private readonly assessmentDetailsMailer: IAssessmentDetailsMailer
  ) {}

  public async finishAssessment(
    assessmentId: number,
    userAssessmentHistoryId: number
  ): Promise<void> {
    await this.fetcher.fetch({
      method: 'PUT',
      url: `${serverBaseUrl}user/${this.userId}/assessment/${assessmentId}/history/${userAssessmentHistoryId}/finish`,
      body: null
    })

    this.assessmentDetailsMailer.sendAssessmentsCompleteEmail(this.userId)
  }
}

export default AssessmentFinisher
