import AdminHandler from '../AdminHandler'
import IAssessmentScoreCalculator from './IAssessmentScoreCalculator'

const rubric = AdminHandler.isDataBasedAdmin()
  ? [
      { score: 3.5, percentile: 50 },
      { score: 3.6, percentile: 55 },
      { score: 3.7, percentile: 60 },
      { score: 3.8, percentile: 65 },
      { score: 3.9, percentile: 70 },
      { score: 4.0, percentile: 80 },
      { score: 4.1, percentile: 90 },
      { score: 4.2, percentile: 95 },
      { score: 4.3, percentile: 99 }
    ]
  : [
      { score: 3.5, percentile: 30 },
      { score: 3.6, percentile: 40 },
      { score: 3.7, percentile: 50 },
      { score: 3.8, percentile: 60 },
      { score: 3.9, percentile: 70 },
      { score: 4.0, percentile: 80 },
      { score: 4.1, percentile: 90 },
      { score: 4.2, percentile: 95 },
      { score: 4.3, percentile: 99 }
    ]
class AssessmentScoreCalculator implements IAssessmentScoreCalculator {
  public getScoreUpperTail(score: number): number {
    return 100 - this.getScorePercentile(score)
  }

  public getScorePercentile(score: number): number {
    if (typeof score !== 'number') {
      return 0
    }

    if (score < 3.5) {
      return 29
    }

    for (let i = 0; i < rubric.length; i++) {
      if (score < rubric[i].score) {
        const prevScore = rubric[i - 1] ? rubric[i - 1].score : 0
        const prevPercentile = rubric[i - 1] ? rubric[i - 1].percentile : 0
        const percentileRange = rubric[i].score - prevScore
        const scoreRange = score - prevScore
        const percentile =
          prevPercentile + (scoreRange / percentileRange) * (rubric[i].percentile - prevPercentile)

        return Math.round(percentile * 100) / 100
      }
    }

    return 99
  }
}

export default AssessmentScoreCalculator
