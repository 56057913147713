import './style.scss'

import React, { Component, ReactElement } from 'react'

import BackToPreviousPage from '../../localCommon/BackToPreviousPage/BackToPreviousPage'
import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import ISignUpPresenter from './Presenter/ISignUpPresenter'
import NewUserProfilePicture from './ProfilePicture/NewUserProfilePicture'

type Props = {
  presenter: ISignUpPresenter
}

export const emailElementId = 'emailElement'
export const firstNameElementId = 'firstNameElement'
class SignUp extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.handleInputValueLabelPosition()
    this.setEmail()
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    if (presenter.isLoading()) {
      return (
        <div className='signUp blank-page-padding'>
          <p className='headline6'>Create Account</p>
          <div className='loading'></div>
          <div className='buttons-group'>
            <ContainedButton isButtonDisabled onClick={() => this.signUp()} type='submit'>
              Create Account
            </ContainedButton>
          </div>
        </div>
      )
    }

    return (
      <div className='signUp blank-page-padding'>
        <p className='headline6'>Create Account</p>
        <p className='error'>{presenter.getErrorMessage()}</p>
        <NewUserProfilePicture handler={presenter} />
        <div>
          <OutlinedInput
            caption=''
            elementId={firstNameElementId}
            error={presenter.getFirstNameInlineErrorMessage()}
            id='first-name'
            isRequired
            label='First Name'
            onChange={this.updateFirstName}
            shouldShowErrorMessage={presenter.shouldShowInlineErrorMessage()}
            type='text'
            value={presenter.getFirstName()}
          />
        </div>
        <div>
          <OutlinedInput
            caption=''
            error={presenter.getLastNameInlineErrorMessage()}
            id='last-name'
            isRequired
            label='Last Name'
            onChange={this.updateLastName}
            shouldShowErrorMessage={presenter.shouldShowInlineErrorMessage()}
            type='text'
            value={presenter.getLastName()}
          />
        </div>
        <div>
          <OutlinedInput
            caption=''
            elementId={emailElementId}
            error={presenter.getEmailInlineErrorMessage()}
            id='email'
            isRequired
            label='Email'
            onChange={this.updateEmail}
            shouldShowErrorMessage={presenter.shouldShowInlineErrorMessage()}
            pattern='^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$'
            type='email'
            value={presenter.getEmail()}
          />
        </div>
        <div>
          <OutlinedInput
            caption='Must contain: Capital letter, numeral, special character'
            id='password'
            isRequired
            label='Password'
            onChange={this.updatePassword}
            shouldShowErrorMessage={presenter.shouldShowInlineErrorMessage()}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$'
            value={presenter.getPassword()}
            type='password'
          />
        </div>
        <div>
          <OutlinedInput
            caption=''
            id='confirm-password'
            isRequired
            label='Confirm Password'
            onChange={this.updateConfirmPassword}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*d).{8,40}$'
            shouldShowErrorMessage={presenter.shouldShowInlineErrorMessage()}
            value={presenter.getConfirmPassword()}
            type='password'
          />
        </div>
        <div className='buttons-group'>
          <BackToPreviousPage label='Return to Login' />
          <ContainedButton onClick={() => this.signUp()} type='submit'>
            Create Account
          </ContainedButton>
        </div>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  setEmail = (): void => {
    const { state } = history

    if (state && state.state && state.state.email) {
      this.props.presenter.updateEmail(state.state.email)
    }
  }

  updateFirstName = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateFistName(target.value)
  }

  updateLastName = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateLastName(target.value)
  }

  updateEmail = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateEmail(target.value?.toLowerCase())
  }

  updatePassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updatePassword(target.value)
  }

  updateConfirmPassword = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateConfirmPassword(target.value)
  }

  signUp = (): void => {
    this.props.presenter.signUp()
  }

  handleInputValueLabelPosition = (): void => {
    const { state } = history
    const emailInput = document.getElementById(emailElementId)
    const firstNameInput = document.getElementById(firstNameElementId) as HTMLElement

    if (emailInput && state && state.state && state.state.email) {
      const focusEvent = new Event('focus')
      emailInput.dispatchEvent(focusEvent)
    }

    if (firstNameInput) {
      const focusEvent = new Event('focus')
      firstNameInput.dispatchEvent(focusEvent)
    }
  }
}

export default SignUp
