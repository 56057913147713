import './style.css'

import React, { Component, ReactElement } from 'react'

import BackToPreviousPage from '../../localCommon/BackToPreviousPage/BackToPreviousPage'
import ContainedButton from '../../localCommon/Button/ContainedButton'
import OutlinedInput from '../../localCommon/OutlinedInput/OutlinedInput'
import IForgotPasswordPresenter from './Presenter/IForgotPasswordPresenter'

type Props = {
  presenter: IForgotPasswordPresenter
}

class ForgotPassword extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='blank-page-padding'>
        <p className='headline6 forgotPassword-textHeaders'>Forgot Password</p>
        <p className='forgotPassword-textHeaders'>
          {'Please enter the email associated with your account to receive a password reset email'}
        </p>
        <p className='error'>{presenter.getErrorMessage()}</p>
        <p className='success'>{presenter.getSuccessMessage()}</p>
        <div>
          <OutlinedInput
            caption=''
            id='email'
            isRequired
            label='Email'
            onChange={this.updateEmail}
            pattern='^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$'
            shouldShowErrorMessage={false}
            value={presenter.getEmail()}
            type='email'
          />
        </div>
        <div className='buttons-group'>
          <BackToPreviousPage label='Return to Login' />
          <ContainedButton
            onClick={() => this.sendEmail()}
            type='submit'
            isButtonDisabled={presenter.shouldSubmitButtonBeDisabled()}
          >
            Get Password Reset Email
          </ContainedButton>
        </div>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  updateEmail = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.setEmail(target.value)
  }

  sendEmail = (): void => {
    this.props.presenter.sendEmail()
  }
}

export default ForgotPassword
