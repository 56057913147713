type ValidPicklistResponseType =
  | 'education'
  | 'roles'
  | 'location'
  | 'year-graduated'
  | 'years-in-sales'
export const educationPicklistResponse = 'education'
export const rolesPicklistResponse = 'roles'
export const locationPicklistResponse = 'location'
export const degreeListId = 'degree'
export const yearGraduatedListId = 'year-graduated'
export const yearsInSalesListId = 'years-in-sales'
export default ValidPicklistResponseType
