import './ssoLogin.scss'

import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import {
  clickGoogleSso,
  clickLinkedInSso
} from '../../Drivers/UsageTracker/AmplitudeEventConstants'
import AmplitudeUsageTracker from '../../Drivers/UsageTracker/AmplitudeUsageTracker'
import images from '../../images'
import SecondaryContainedButton from '../../localCommon/Button/SecondaryContainedButton'
import TextButton from '../../localCommon/Button/TextButton'
import { serverBaseUrl } from '../../localCommon/constant'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import SecondaryOutlinedInput from '../../localCommon/SecondaryOutlinedInput/SecondaryOutliendInput'
import ISsoLogInPresenter from '../LogIn/Presenters/SsoLogInPresenter/ISsoLogInPresenter'
import { loginRoute } from '../RouteConstants'

type Props = {
  presenter: ISsoLogInPresenter
}

class SsoLogins extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    return (
      <div className='login blank-page-padding ssoLogin'>
        <div className='ssoLogin-icon'>
          <img alt='databased-icon' src={images.databasedWhite} />
        </div>
        <div className='ssoLogin-content'>
          <h1 className='mdc-typography--headline2'>Find out if we could land you a better job</h1>
          <div className='login-content'>
            <div className='login-ssoLogins'>
              <SecondaryContainedButton
                className='white-outlined-button login-button'
                onClick={() => {
                  this.startLinkedInSso()
                }}
                type='button'
              >
                <img
                  alt='linkedIn-icon'
                  className='login-ssoLinkedInIcon'
                  src={images.linkedInIcon}
                />
                <p>Continue with LinkedIn</p>
              </SecondaryContainedButton>
              <SecondaryContainedButton
                className='login-button'
                onClick={() => {
                  this.startGoogleSso()
                }}
                type='button'
              >
                <img alt='gmail-icon' className='login-ssoGmailIcon' src={images.gmailIcon} />
                <p>Continue with GMAIL</p>
              </SecondaryContainedButton>
              <p className='center-text'>or</p>
              <SecondaryOutlinedInput
                id='email-address'
                isRequired
                label='Enter Email address'
                value={presenter.getEmail()}
                onChange={this.updateEmail}
                shouldShowErrorMessage={false}
                type='email'
              />
              <Link
                to={{
                  pathname: '/signup',
                  state: { email: this.props.presenter.getEmail() }
                }}
              >
                <SecondaryContainedButton type='button'>
                  <span></span>
                  <p>Continue with email</p>
                </SecondaryContainedButton>
              </Link>
            </div>
            <TextButton onClick={this.redirectToLogInPage} text='Already have an account? Login' />
          </div>
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  updateEmail = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateEmail(target.value)
  }

  redirectToLogInPage = (): void => {
    window.location.href = loginRoute
  }

  startGoogleSso = (): void => {
    AmplitudeUsageTracker.track(clickGoogleSso)
    window.location.href = `${serverBaseUrl}/auth/google`
  }

  startLinkedInSso = (): void => {
    AmplitudeUsageTracker.track(clickLinkedInSso)
    window.location.href = `${serverBaseUrl}/auth/linkedin`
  }
}

export default SsoLogins
