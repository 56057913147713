import IElementProvider from '../../../../localCommon/Interfaces/IElementProvider'
import INumberFormatter from '../../../../localCommon/NumberFormatter/INumberFormatter'
import StringHelper from '../../../../localCommon/StringHelper/StringHelper'
import { quotaRowId, roleRowId, yearsOfExperienceRowId } from '../EmptyDataDisplayRow'
import IDataRowDataUpdater from './Interfaces/IDataRowDataUpdater'

class DataRowDataUpdater implements IDataRowDataUpdater {
  constructor(
    private readonly numberFormatter: INumberFormatter,
    private readonly elementProvider: IElementProvider
  ) {}

  public updateQuota(input: string): void {
    const element = this.elementProvider.getElementById(quotaRowId)

    if (element) {
      element.innerHTML = `${input ? '$' : ''}${this.numberFormatter.formatLargeAmount(
        StringHelper.filterOutCommaFromNumberString(input)
      )} ${input ? 'Quota' : '&ndash;'}`
    }
  }

  public updateRole(input: string): void {
    const element = this.elementProvider.getElementById(roleRowId)

    if (element) {
      element.innerHTML = `${StringHelper.extractWordsInParentheses(input)}`
    }
  }

  public updateYearsOfExperience(input: string): void {
    const element = this.elementProvider.getElementById(yearsOfExperienceRowId)

    if (element) {
      if (input) {
        element.innerHTML = `${input} ${
          parseInt(input, 10) > 1 ? 'yrs experience' : 'yr experience'
        }`
      } else {
        element.innerHTML = '&ndash;'
      }
    }
  }
}

export default DataRowDataUpdater
