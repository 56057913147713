import './style.scss'

import Cookies from 'js-cookie'
import React, { Component, Fragment, ReactElement } from 'react'

import { assessmentIdKey } from '../../../localCommon/CookieKeys'
import HeaderWithProfile from '../../../localCommon/HeaderWithProfile/HeaderWithProfile'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IMenuVisibilityManager from '../../../localCommon/SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import SlidingMenu from '../../../localCommon/SlidingMenu/SlidingMenu'
import IUserProfilePresenter from '../../Home/UserProfile/Interfaces/IUserProfilePresenter'
import PersonalDataManualInputSection from '../PersonalDataManualInputSection'
import IHomePresenter from '../Presenters/Interfaces/IHomePresenter'
import AssessmentResultWrapper from './AssessmentResult/AssessmentResultWrapper'
import IDashboardPresenter from './Presenter/IDashboardPresenter'

type Props = {
  homePresenter: IHomePresenter
  menuVisibilityManager: IMenuVisibilityManager
  presenter: IDashboardPresenter
  userProfilePresenter: IUserProfilePresenter
}

class Dashboard extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.homePresenter.initialize()
    this.props.homePresenter.setView(this)
    this.props.presenter.initialize()
    this.props.presenter.setView(this)
    this.props.menuVisibilityManager.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
    this.props.menuVisibilityManager.clearView()
  }

  render(): ReactElement {
    const { homePresenter, presenter, userProfilePresenter, menuVisibilityManager } = this.props
    if (presenter.isLoading() || homePresenter.isLoading()) {
      return (
        <div className='dashboard blank-page-padding'>
          <div className='loading'></div>
        </div>
      )
    }

    if (presenter.shouldDisplayDashboard()) {
      return (
        <Fragment>
          <SlidingMenu visibilityManager={menuVisibilityManager} />
          <div className='dashboard blank-page-padding'>
            <HeaderWithProfile
              menuVisibilityManager={menuVisibilityManager}
              userProfilePresenter={userProfilePresenter}
            />
            {homePresenter.isInEditMode() ? (
              <PersonalDataManualInputSection presenter={homePresenter} />
            ) : (
              <Fragment>{this.renderNextStep()}</Fragment>
            )}
          </div>
        </Fragment>
      )
    }
    return this.goToAssessment()
  }

  goToAssessment = (): ReactElement => {
    const assessmentId =
      this.props.presenter.assessment?.assessmentId ?? Cookies.get(assessmentIdKey)

    window.location.href = `/assessment/${assessmentId ?? -1}`
    return <div></div>
  }

  renderNextStep = (): ReactElement | null => {
    const { homePresenter, presenter } = this.props
    return (
      <AssessmentResultWrapper
        homePresenter={homePresenter}
        presenter={presenter.getAssessmentResultPresenter()}
      />
    )
  }

  update = (): void => {
    this.setState({})
  }

  getProgress = (): number => {
    return 100
  }

  openDrawer = (): void => {
    this.props.menuVisibilityManager.open()
  }

  startAssessment = (event: React.MouseEvent): void => {
    const target = event.target as HTMLInputElement
    if (target.id) {
      this.props.presenter.updateInProgressAssessmentId(parseInt(target.id, 10))
    }
  }
}

export default Dashboard
