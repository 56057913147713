import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IPromptGetter from './IPromptGetter'
import PromptDto from './PromptDto'

export const PROMPT_URL = `${serverBaseUrl}prompt/`
class PromptGetter implements IPromptGetter {
  constructor(private readonly fetcher: IFetcher) {}

  public async getPromptById(id: number): Promise<PromptDto> {
    return this.fetcher.fetch({
      method: 'GET',
      url: `${PROMPT_URL}${id}`,
      body: null
    })
  }
}

export default PromptGetter
