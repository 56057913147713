import IFetcher from '../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../localCommon/constant'
import IAssessmentChecker from './IAssessmentChecker'

export const noAssessmentHistoryMessage = 'No assessment history for the given id'
class AssessmentChecker implements IAssessmentChecker {
  constructor(
    private readonly fetcher: IFetcher,
    private readonly userId: number
  ) {}

  public async hasFinishedThisAssessment(assessmentId: number): Promise<boolean> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      body: null,
      url: `${serverBaseUrl}user/${this.userId}/assessment/${assessmentId}`
    })

    if (result.hasOwnProperty('dateTimeEnd')) {
      return !!result.dateTimeEnd
    }

    if (result?.message) {
      return !result.message.includes(noAssessmentHistoryMessage)
    }

    return true
  }
}

export default AssessmentChecker
