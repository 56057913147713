/* eslint-disable no-new */
import './style.scss'

import { MDCTextField } from '@material/textfield/index'
import React, { Component, Fragment, ReactElement } from 'react'

import { missingRequiredFieldsErrorMessage } from '../../Routes/PersonalInfoData/Presenters/HomePresenter'
import IObservablePresenter from '../Interfaces/IObservablePresenter'
import ISubscribingView from '../Interfaces/ISubscribingView'
import { dollarUnit, percentageUnit } from '../ValidHeuristicUnit'
import ValidUnit from '../ValidUnit'

type Props = {
  caption?: string
  elementId?: string
  error?: string
  id?: string
  isRequired: boolean
  label: string
  onChange: (event: React.ChangeEvent) => void
  pattern?: string
  presenter?: IObservablePresenter
  shouldShowErrorMessage: boolean
  step?: number
  type: string
  unit?: ValidUnit
  value: string
}

export const secondaryOutlinedInputId = 'secondaryOutlinedInput'
class SecondaryOutlinedInput extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    const element = document.querySelector(`#${this.props.id}`)

    if (element) {
      new MDCTextField(element)
    }

    if (this.props.presenter) {
      this.props.presenter.setView(this)
    }
  }

  componentDidUpdate(): void {
    if (this.props.presenter) {
      this.props.presenter.setView(this)
    }
  }

  render(): ReactElement {
    const { caption, error, id, isRequired, label, shouldShowErrorMessage, unit, value } =
      this.props

    return (
      <Fragment>
        <label className='mdc-text-field mdc-text-field--outlined' id={id}>
          <span className='mdc-text-field__affix mdc-text-field__affix--prefix'>
            {unit === dollarUnit ? unit : ''}
          </span>
          <span className='mdc-notched-outline'>
            <span className='mdc-notched-outline__leading'></span>
            <span
              className='mdc-notched-outline__notch'
              style={{ display: label ? 'inline-block' : 'none' }}
            >
              <span className='mdc-floating-label' id='secondaryOutlinedInputLabel'>
                {label}
              </span>
            </span>
            <span className='mdc-notched-outline__trailing'></span>
          </span>
          {this.renderInput()}
          <span className='mdc-text-field__affix mdc-text-field__affix--suffix'>
            {unit === percentageUnit ? unit : ''}
          </span>
        </label>
        <div className='mdc-text-field-helper-text' aria-hidden='true'>
          <div className='mdc-text-field-helper-line'>
            {shouldShowErrorMessage && !value && (
              <span className='error mdc-text-field-helper-text'>
                {error ?? missingRequiredFieldsErrorMessage}
              </span>
            )}
          </div>
        </div>
        <div className='mdc-text-field-helper-line caption outlinedCaption'>
          {caption && `${caption}${isRequired ? '* ' : ''}`}
        </div>
      </Fragment>
    )
  }

  update = (): void => {
    this.setState({})
  }

  renderInput = (): ReactElement => {
    const { elementId, value, type, onChange, pattern, step, unit } = this.props

    if (pattern) {
      return (
        <input
          aria-labelledby='secondaryOutlinedInputLabel'
          aria-controls='secondaryOutlinedInput'
          aria-describedby='secondaryOutlinedInput'
          className='mdc-text-field__input'
          id={elementId || secondaryOutlinedInputId}
          max={unit === percentageUnit ? 100 : Number.MAX_SAFE_INTEGER}
          min={unit === percentageUnit ? 0 : Number.MIN_SAFE_INTEGER}
          onChange={onChange}
          pattern={pattern}
          step={step}
          type={type}
          value={value}
        />
      )
    }

    return (
      <input
        aria-labelledby='secondaryOutlinedInputLabel'
        aria-controls='secondaryOutlinedInput'
        aria-describedby='secondaryOutlinedInput'
        className='mdc-text-field__input'
        id={elementId || secondaryOutlinedInputId}
        max={unit === percentageUnit ? 100 : Number.MAX_SAFE_INTEGER}
        min={unit === percentageUnit ? 0 : Number.MIN_SAFE_INTEGER}
        onChange={onChange}
        step={step}
        type={type}
        value={value}
      />
    )
  }
}

export default SecondaryOutlinedInput
