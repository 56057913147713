import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../images'
import { dashboardRoute } from '../../RouteConstants'
import AnimatedRocket from './AnimatedRocket'

type Props = {
  message: string
}

export const hirabilityLoadTime = 2000
class ResultInProgress extends Component<Props> {
  state = {
    progress: 0
  }

  componentDidMount() {
    this.updateProgress()
  }

  render(): ReactElement {
    return (
      <div className='resultInProgress blank-page-padding'>
        <div className='question-title'>
          <img alt='databased' src={images.databased} />
        </div>
        <div className='resultInProgress-content'>
          <p className='headline5'>{this.props.message}</p>
          <div className='progressBar-container'>
            <div className='progressBar' style={{ width: `${this.state.progress}%` }}></div>
          </div>
          <AnimatedRocket />
        </div>
      </div>
    )
  }

  updateProgress = () => {
    const interval = 100
    const increment = (interval / hirabilityLoadTime) * 100
    let currentProgress = 0

    const progressTimer = setInterval(() => {
      currentProgress += increment
      if (currentProgress >= interval) {
        clearInterval(progressTimer)
        window.location.href = dashboardRoute
      } else {
        this.setState({ progress: currentProgress })
      }
    }, interval)
  }
}

export default ResultInProgress
